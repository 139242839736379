import Navigation from "../components/Navigation";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import React, { useState, useRef, useEffect} from "react";
import { stripe_pk, url_server } from '../settings';
import Form from 'react-bootstrap/Form';
import { GetDataFromUserId, saveSession } from '../controllers/AccountController';
import ToggleButton from 'react-bootstrap/ToggleButton';
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';
import InputGroup from 'react-bootstrap/InputGroup';
import axios from "axios";
import Spinner from 'react-bootstrap/Spinner';
import { TagsInput } from "react-tag-input-component";
import ProgressBar from 'react-bootstrap/ProgressBar';
import {useLocation } from 'react-router-dom';



import RangeSurfLevel from "../components/Range";
import UploadFile from "../components/UploadFile";
// import { WithContext as ReactTags } from 'react-tag-input';


function RegisterSuccessfull(){
    const img_successfull = require('../assets/img_successfull.png')

    function goToSignIn(){
        window.location.href = "/account/login"
    }

    function goToEmail(){
        window.location.href = 'mailto:';

    }

    function goToMain(){
        window.location.href = "/";
    }

    return(
        <div className="col-md-5 form_white_centered_no_border text-center">
            <img src={img_successfull} alt="Success" className="mb-5" />
            <p className="fw-bold">Account successfully created</p>
            <p className="text-thin text_grey p_xsmall">We have sent you an email, to proceed please verify your email. If you don't find our email in your inbox, please check your spam folder.</p>
            {/* <Button className='btn_primary_blue btn_register  ms-auto' onClick={goToSignIn} >Log in</Button> */}
            <Button className='btn_primary_blue btn_register mt-3 ms-auto' onClick={goToSignIn}>Log in</Button>

        </div>
    )
}

function LoginError({content}){
    return(
        <Form.Text className="text-danger">
          {content}
        </Form.Text>
    );
}


function PhotographersForm2({setView, photographersInfo}){
    
    const [dataNewUser, setDataNewUser] = useState(photographersInfo != null ? photographersInfo : "");
    const [validated, setValidated] = useState(false);
    const [loginError, setLoginError] = useState(<LoginError />)
    const [signupEnable, setSignupEnable] = useState(true)
    const [showLoading, setShowLoading] = useState(false)
    const [bankError, setBankError] = useState(<LoginError />)

    const [uploadFileDocument, setUploadFileDocument] = useState()
    const [fileFront, setFileFront] = useState()
    const [fileBack, setFileBack] = useState()
    const [valueFileType, setValueFileType] = useState("0")

    const array_states = [
        {
            val: 'QLD',
            text: "Queensland",

        },
        {
            val: "ACT",
            text: "Australian Capital Territory",

        },
        {
            val: "NSW",
            text: "New South Wales",

        },
        {
            val: "SA",
            text: "South Australia",

        },
        {
            val: "NT",
            text: "Northen Territory",

        },
        {
            val: "VIC",
            text: "Victoria",

        },
        {
            val: "TAS",
            text: "Tasmania",

        },
        {
            val: "WA",
            text: "Western Australia",

        },

    ]


    const getIP = async () => {
        const res = await axios.get("https://api.ipify.org/?format=json");
        setDataNewUser((oldSearch) => ({...oldSearch, "ip": res.data.ip}))
        
      };
    
      useEffect(() => {
        //passing getData method to the lifecycle method
        getIP();
      }, []);

    const validateData = async (event) => {
        const form = event.currentTarget;
        event.preventDefault();

        if (form.checkValidity() === false) {

            event.stopPropagation();


        }else{

            setSignupEnable(false)
            setShowLoading(true)

            if(Object.keys(dataNewUser).includes("photographer_abn")){
                if(!validateABN(dataNewUser.photographer_abn)){
                    setLoginError(<LoginError content={"ABN is incorrect"} />)
                    setSignupEnable(true)
                    setShowLoading(false)
                    return
                }else{
                    setLoginError(<LoginError />)

                }
            }

            if(!checkBankFields()){
                setBankError(<LoginError content={"Both fields BSB and bank account number needs to be filled"} />)
                setSignupEnable(true)
                setShowLoading(false)
                return
            }else{
                setBankError(<LoginError />)
            }

            var obj_data = dataNewUser
            var files_valid = true

            if(fileFront != null){
                const fileFrontId = await validateIdentityFiles(fileFront.selectedFile)
                obj_data.fileFront = fileFrontId.id

            }

    
            if(valueFileType != "1" && valueFileType != "0"){
                if(fileFront != null && fileBack != null ){
                    
                    const fileBackId = await validateIdentityFiles(fileBack.selectedFile)
                    obj_data.fileBack = fileBackId.id
                }else{
                    alert("You need to submit both documents")
                    setSignupEnable(true)
                    setShowLoading(false)
                    files_valid = false
                }
                
            }
           
            
            if(files_valid){
                register(obj_data)

            }

 
        }
        setValidated(true);
        
    };

    function checkBankFields(){
        if((!Object.keys(dataNewUser).includes("photographer_bank_account_bsb") || dataNewUser.photographer_bank_account_bsb === "") && (!Object.keys(dataNewUser).includes("photographer_bank_account_number") || dataNewUser.photographer_bank_account_number === "")
        
        ){
            return true
        }

        if(!Object.keys(dataNewUser).includes("photographer_bank_account_bsb") || dataNewUser.photographer_bank_account_bsb === "" || !Object.keys(dataNewUser).includes("photographer_bank_account_number") || dataNewUser.photographer_bank_account_number === ""){
            // alert("Both bank account and BSB needs to be filled or changed.")
            return false
        }

        return true

    }

    function validateABN(value){
        if(value != null && value != ""){
            var weights = [10, 1, 3, 5, 7, 9, 11, 13, 15, 17, 19];

            if(value.length != 11){
                return false
            }
    
            var sum = 0;
    
            for(var i = 0; i < weights.length; i++){
                var digit = parseInt(value[i]) - (i==0?1:0)
                sum += digit * weights[i]
            }
    
            return (sum % 89) == 0
        }else{
            return true
        }
        

    }

    

    function register(obj_data){
        
       
        fetch(url_server + "public/users", {
            method: 'POST',
            body: JSON.stringify(obj_data),
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            },
            })
            .then((response) => response.json())
            
            .then( (data) => {
                // response = data.response
                // // console.log(data.response)
                // console.log(data)
                setSignupEnable(true)
                setShowLoading(false)
                
                if(data.error){
                    if(Object.keys(data.errorDescription).includes("raw")){
                        alert(data.errorDescription.raw.message)
                    }else{
                        alert("Something went wrong, please check your details and try again.")
                    }
                    
                }else{
                    setView(<RegisterSuccessfull />)
                }
                
    
            })
            .catch((err) => {
                // console.log(err)
            });
    
    }

    function goBack(){
        setView(<PhotographersForm setView={setView} basic_info={dataNewUser} />)
    }

    async function validateIdentityFiles(file){

        const data = new FormData();
        data.append('file', file);
        data.append('purpose', 'identity_document');

        const fileResult = await fetch('https://uploads.stripe.com/v1/files', {
          method: 'POST',
          headers: {'Authorization': `Bearer ${stripe_pk}`},
          body: data,
        });
        const fileData = await fileResult.json();
        // console.log(fileData)
        return fileData
        
    }
    
    function setFileUploader(value){
        // console.log(value)
        setValueFileType(value)
        if(value == "0"){
            setUploadFileDocument()
        }
        else if(value == "1"){
            // set uploader only one file
            setUploadFileDocument(<UploadFile twoFilesNeed={false} fileFront={fileFront} setFileFront={setFileFront} fileBack={fileBack} setFileBack={setFileBack} />)
        }else{
            // set uploader two files
            setUploadFileDocument(<UploadFile twoFilesNeed={true} fileFront={fileFront} setFileFront={setFileFront} fileBack={fileBack} setFileBack={setFileBack} />)

        }
    }

    return(
        <div className="mb-5">
            <Form className='col-md-5 form_white_centered2' onSubmit={validateData}>
                <h4 className="mb-4"><b>Register</b></h4>
                <p className="m-0">Billing details</p>
                {/* <p className="p_xsmall text-thin ms-0 mb-3">Write something here Write something here Write something here Write something here Write. You will be able to add this information later in “Billings and payments”</p> */}
                
                {/* <Form.Group className="mb-3" controlId="pAddressCountry">
                    <Form.Label className="fw-bold ">Country</Form.Label>
                    <Form.Control  defaultValue={data.photographer_address_country} type="text" placeholder="Type here" onChange={(e) => setData((oldSearch) => ({...oldSearch, "photographer_address_country": e.target.value}))} />
                </Form.Group> */}

                {/* <Form.Group className="mb-3" controlId="pAddressState">
                    <Form.Label className="fw-bold ">State</Form.Label>
                    <Form.Control  defaultValue={dataNewUser.photographer_address_state} type="text" placeholder="Type here" onChange={(e) => setDataNewUser((oldSearch) => ({...oldSearch, "photographer_address_state": e.target.value}))} />
                </Form.Group> */}
                <Form.Group className="mb-3" >
                        <Form.Label className="fw-bold">State</Form.Label>
                        <Form.Select 
                            aria-label="Default select example" 
                            // onChange={(e) => onChangeState(e.target.value)}
                            onChange={(e) => setDataNewUser((oldSearch) => ({...oldSearch, "photographer_address_state": e.target.value}))}
                            
                            // key={"OKAYG_" + (10000 + Math.random() * (1000000 - 10000))}
                            defaultValue={dataNewUser.photographer_address_state}
                            >
                            <option value=""></option>
                            {array_states.map((obj, index) => {
                                return(
                                    <option key={index} value={obj.val}  >{obj.text}</option>
                                )
                            })}

                            {/* selected={album.location_state != null && album.location_state == obj.val ? "selected" : ""} */}
                            
                        </Form.Select>
                
                    </Form.Group>

                <Form.Group className="mb-3" controlId="pAddressState">
                    <Form.Label className="fw-bold ">City</Form.Label>
                    <Form.Control  defaultValue={dataNewUser.photographer_city} type="text" placeholder="Type here" onChange={(e) => setDataNewUser((oldSearch) => ({...oldSearch, "photographer_city": e.target.value}))} />
                </Form.Group>
            
                <Form.Group className="mb-3" controlId="pAddress">
                    <Form.Label className="fw-bold ">Address</Form.Label>
                    <Form.Control  defaultValue={dataNewUser.photographer_address} type="text" placeholder="Type here" onChange={(e) => setDataNewUser((oldSearch) => ({...oldSearch, "photographer_address": e.target.value}))} />
                </Form.Group>
                <Form.Group className="mb-3" controlId="pAddressState">
                    <Form.Label className="fw-bold ">Postal Code</Form.Label>
                    <Form.Control  defaultValue={dataNewUser.photographer_address_postal_code} type="number" placeholder="Type here" onChange={(e) => setDataNewUser((oldSearch) => ({...oldSearch, "photographer_address_postal_code": e.target.value}))} />
                </Form.Group>

                <Form.Group className="mb-3" controlId="pAbn">
                    <Form.Label className="fw-bold ">ABN</Form.Label>
                    <Form.Control  defaultValue={dataNewUser.photographer_abn} type="text" placeholder="Type here" onChange={(e) => setDataNewUser((oldSearch) => ({...oldSearch, "photographer_abn": e.target.value.replace(/\s/g, "")}))} />
                    {loginError}
                    <div id="emailHelp" className="form-text">You need your ABN in order to get payment from your images</div>

                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicGoals">
                    <Form.Label className="fw-bold">Are you enrolled for GST?</Form.Label>
                    <Form.Select 
                        onChange={(e) => setDataNewUser((oldSearch) => ({...oldSearch, "photographer_is_gst": e.target.value}))}
                        >
                        <option value="">Select option</option>
                        <option value="1">Yes</option>
                        <option value="0">No</option>
                        
                    </Form.Select>
                    <div id="emailHelp" className="form-text">You are responsible to update this field before any withdrawal if the information changes.</div>

                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicGoals">
                    <Form.Label className="fw-bold">Identity document</Form.Label>
                    <Form.Select 
                        aria-label="Default select example" 
                        onChange={(e) => setFileUploader(e.target.value)}
                        
                        
                        >
                        <option value="0"></option>
                        <option value="1">Passport</option>
                        <option value="2">Driver License</option>
                        <option value="3">Photo Card</option>
                        <option value="4">New South Wales Driving Instructor License</option>
                        <option value="5">Tasmanian Goverment Personal Information Card</option>
                        <option value="6">ImmiCard</option>
                        <option value="7">Proof of Age card</option>
                        <option value="8">Australian Defence Force (AFD) identification card</option>

                        
                    </Form.Select>
                    {uploadFileDocument}
                </Form.Group>

                

                <p className="m-0">Bank details</p>

                <Form.Group className="mb-3" controlId="pAccountName">
                    <Form.Label className="fw-bold ">Account name</Form.Label>
                    <Form.Control  defaultValue={dataNewUser.photographer_bank_account_name} type="text" placeholder="Type here" onChange={(e) => setDataNewUser((oldSearch) => ({...oldSearch, "photographer_bank_account_name": e.target.value}))} />
                </Form.Group>

                <Form.Group className="mb-3" controlId="pAccountBsb">
                    <Form.Label className="fw-bold ">BSB</Form.Label>
                    <Form.Control  defaultValue={dataNewUser.photographer_bank_account_bsb} type="number" placeholder="Type here" onChange={(e) => setDataNewUser((oldSearch) => ({...oldSearch, "photographer_bank_account_bsb": e.target.value}))} />
                </Form.Group>

                <Form.Group className="mb-3" controlId="pAccountNumber">
                    <Form.Label className="fw-bold ">Account Number</Form.Label>
                    <Form.Control  defaultValue={dataNewUser.photographer_bank_account_number} type="number" placeholder="Type here" onChange={(e) => setDataNewUser((oldSearch) => ({...oldSearch, "photographer_bank_account_number": e.target.value}))} />
                </Form.Group>
                {bankError}

                
                <Form.Group className="mb-3">
                    <Form.Check
                    required
                    label={<span>
                        I agree to the 
                        <a href="/terms-and-conditions" target="_blank" rel="noopener noreferrer" className="ms-2">
                            terms and conditions
                        </a>
                    </span>}
                    feedback="You must agree before submitting."
                    feedbackType="invalid"
                    />
                    
                </Form.Group>

                <div className="d-flex w-100 justify-content-between mt-3">
                    <Button className='btn_outlined_blue btn_register me-auto' onClick={goBack}>Back</Button>

                    <Button className='btn_primary_blue btn_register  ms-auto' disabled={!signupEnable} type="submit">Register</Button>

                </div>
                
                
            </Form>
            <div className="text-center spinner_screen" >
                    <Spinner animation="border" role="status" className="mt-5 " hidden={!showLoading}>
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
            </div>
        </div>
    )
}


function PhotographersForm({setView, basic_info}){
    const [dataNewUser, setDataNewUser] = useState(basic_info != null ? basic_info : "");
    const [validated, setValidated] = useState(false);
    const [photographerTypeShots, setPhotographerTypeShots] = useState([]);
    const [photographerCateogoryShot, setPhotographerCateogoryShot] = useState([]);

    const [categoriesError, setCategoriesError] = useState(<LoginError />)
    const [photosTypeError, setPhotosTypeError] = useState(<LoginError />)
    const [spotsError, setSpotsError] = useState(<LoginError />)
    const [userExistsError, setUserExistsError] = useState(<LoginError />)

    const [tags, setTags] = React.useState([]);
    const [selectedSuggestionIndex, setSelectedSuggestionIndex] = useState(-1);
    const [suggestions, setSuggestions] = useState([])
    const [inputValue, setInputValue] = useState("");
    const [signupEnable, setSignupEnable] = useState(true)
    const [showLoading, setShowLoading] = useState(false)
    const [btnRegisterEnabled, setBtnRegisterEnabled] = useState(false)
    const origin = new URLSearchParams(useLocation().search).get("origin");



    useEffect(() =>{
        getSuggestions()
        setTags(basic_info != null && Object.keys(basic_info).includes("photographer_main_spots") ? JSON.parse(basic_info.photographer_main_spots) : [])
    }, [basic_info])

    useEffect(() =>{
        setDataNewUser((oldSearch) => ({...oldSearch, "photographer_main_spots": JSON.stringify(tags)}))
    }, [tags])

    useEffect(() => {
        getIP()
    }, [])

    useEffect(() =>{
        if((Object.keys(dataNewUser).includes("user_unique_id") && dataNewUser.user_unique_id != null && dataNewUser.user_unique_id != "") &&
        (Object.keys(dataNewUser).includes("photographer_started_goals") && dataNewUser.photographer_started_goals != null && dataNewUser.photographer_started_goals != "") &&
        (Object.keys(dataNewUser).includes("photographer_goals") && dataNewUser.photographer_goals != null && dataNewUser.photographer_goals != "") &&
        (Object.keys(dataNewUser).includes("photographer_products_type") && dataNewUser.photographer_products_type != null && dataNewUser.photographer_products_type != "") &&
        (Object.keys(dataNewUser).includes("photographer_products_category") && dataNewUser.photographer_products_category != null && dataNewUser.photographer_products_category != "") &&
        signupEnable &&
        tags.length > 0
        ){
            setBtnRegisterEnabled(true)
        }else{
            setBtnRegisterEnabled(false)
        }
    },[dataNewUser])

    const getIP = async () => {
        const res = await axios.get("https://api.ipify.org/?format=json");
        setDataNewUser((oldSearch) => ({...oldSearch, "ip": res.data.ip}))
        
      };

    function getSuggestions(){
        fetch(url_server + "public/locations/suggestions", {
            method: 'GET',
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
                // 'x-access-token': session_details.token
            },
            })
            .then((response) => response.json())
            
            .then( (data) => {
                // response = data.response
                // console.log(data.response)
                const sug = []
                data.response.map((obj, index) =>{
                    sug.push(obj.location_suggestions)
                })

                setSuggestions(sug)
    
            })
            .catch((err) => {
                // console.log(err)
            });
    }

    const handlePhotosCB = (event) => {
        if(event.target.checked){
            photographerTypeShots.push(event.target.value)
        }else{
            const index = photographerTypeShots.indexOf("Photos");

            photographerTypeShots.splice(index, 1);
        }
        setDataNewUser((oldSearch) => ({...oldSearch, "photographer_products_type": JSON.stringify(photographerTypeShots)}))
    }

    const handleVideosCB = (event) => {
        if(event.target.checked){
            photographerTypeShots.push(event.target.value)
        }else{
            const index = photographerTypeShots.indexOf("Videos");

            photographerTypeShots.splice(index, 1);
        }
        setDataNewUser((oldSearch) => ({...oldSearch, "photographer_products_type": JSON.stringify(photographerTypeShots)}))
    }

    const handleDroneCB = (event) => {
        if(event.target.checked){
            photographerCateogoryShot.push(event.target.value)
        }else{
            const index = photographerCateogoryShot.indexOf("Drone");

            photographerCateogoryShot.splice(index, 1);
        }
        setDataNewUser((oldSearch) => ({...oldSearch, "photographer_products_category": JSON.stringify(photographerCateogoryShot)}))
    }

    const handleWCCB = (event) => {
        if(event.target.checked){
            photographerCateogoryShot.push(event.target.value)
        }else{
            const index = photographerCateogoryShot.indexOf("Camera Water");

            photographerCateogoryShot.splice(index, 1);
        }
        setDataNewUser((oldSearch) => ({...oldSearch, "photographer_products_category": JSON.stringify(photographerCateogoryShot)}))
    }


    const handleLandCB = (event) => {
        if(event.target.checked){
            photographerCateogoryShot.push(event.target.value)
        }else{
            const index = photographerCateogoryShot.indexOf("Land");

            photographerCateogoryShot.splice(index, 1);
        }
        setDataNewUser((oldSearch) => ({...oldSearch, "photographer_products_category": JSON.stringify(photographerCateogoryShot)}))
    }



    const validateData = async (event) => {
        const form = event.currentTarget;
        // console.log(form)
        event.preventDefault();

        if (form.checkValidity() === false) {
          event.stopPropagation();


        }else{
            if(dataNewUser.photographer_products_type == null || JSON.parse(dataNewUser.photographer_products_type).length == 0){
                setPhotosTypeError(<LoginError content={"You must select at least one service"} />)
                return
            }else{
                setPhotosTypeError(<LoginError />)
            }

            if(dataNewUser.photographer_products_category == null || JSON.parse(dataNewUser.photographer_products_category).length == 0){
                setCategoriesError(<LoginError content={"You must select at least one type of shot"} />)
                return
            }else{
                setCategoriesError(<LoginError />)
            }

            if(tags.length == 0){
                setSpotsError(<LoginError content={"You must insert at least one spot."} />)
                return
            }else{
                setSpotsError(<LoginError />)
            }

            if(origin != null){
                dataNewUser.origin = origin
            }
           

            if(!validateUserUniqueId() && !(await checkExistUserUniqueId())){
                setSignupEnable(false)
                setShowLoading(true)
                register();
                
            }
            
            // // console.log(data)
            
        }
        setValidated(true);
        
    };

    async function checkExistUserUniqueId(){
        var userExists = false
       
        await fetch(`${url_server}public/users/${dataNewUser.user_unique_id}/exists`, {
            method: 'GET',
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
          
            },
            })
            .then((response) => response.json())
            
            .then( (data) => {
                // response = data.response
                // // console.log(data.response)
                // window.location.reload()
                if(data.response.length > 0){
                    // alert("Profile URL Name already in use.")
                    setUserExistsError(<LoginError content={"Profile URL Name already in use"} />)
                    userExists = true;
                    window.scrollTo(0, 0)

                }else{
                    setUserExistsError(<LoginError />)

                    // setView(<PhotographersForm2 setView={setView} photographersInfo={dataNewUser} />)
                    userExists = false;
                }
    
            })
            .catch((err) => {
                // console.log(err)
                userExists = true
            });

        return userExists;
        
    }

    function register(){
       
        fetch(url_server + "public/users", {
            method: 'POST',
            body: JSON.stringify(dataNewUser),
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            },
            })
            .then((response) => response.json())
            
            .then( (data) => {
                // response = data.response
                // // console.log(data.response)
                // console.log(data)
                setSignupEnable(true)
                setShowLoading(false)
                
                if(data.error){
                    if(Object.keys(data.errorDescription).includes("raw")){
                        alert(data.errorDescription.raw.message)
                        
                    }else{
                        alert("Something went wrong, please check your details and try again.")
                    }
                    
                }else{
                    setView(<RegisterSuccessfull />)
                }
                
    
            })
            .catch((err) => {
                // console.log(err)
            });
    
    }

    function validateUserUniqueId(){
        var regex = /[^a-zA-Z0-9-_.]/; // Matches any character that is not a letter, digit, hyphen, or underscore
        return regex.test(dataNewUser.user_unique_id);
    }

    
    function goBack(){
        setView(<InitialRegisterForm setView={setView} user_data={dataNewUser} />)
    }


    const handleInputChange = (event) => {

        if (event.key === "ArrowUp") {
            event.preventDefault();
            setSelectedSuggestionIndex(
              Math.max(selectedSuggestionIndex - 1, 0)
            );
          } else if (event.key === "ArrowDown") {
            event.preventDefault();
            setSelectedSuggestionIndex(
              Math.min(selectedSuggestionIndex + 1, filteredSuggestions.length - 1)
            );
          } else if (event.key === "Enter" && selectedSuggestionIndex !== -1) {
            event.preventDefault();
            handleSuggestionClick(filteredSuggestions[selectedSuggestionIndex]);
            event.target.value = ""
          }else{
            setInputValue(event.target.value);

          }

        // setInputValue(event.target.value);
    
    };

    const handleSuggestionClick = (suggestion) => {
        // Add the suggestion to the tags list
        const rtiinput = document.getElementsByClassName('rti--input')[0]
        rtiinput.value = ""
        setTags([...tags, suggestion]);
        // Clear the input value
        setInputValue("");
    };

    const filteredSuggestions = suggestions.length > 0 ? suggestions.filter((suggestion) => 
        suggestion.toLowerCase().includes(inputValue.toLowerCase())
    
    ) : [];



    return(
        <div className="mb-5">
            <Form className='col-md-5 form_white_centered2' onSubmit={validateData}>
                {/* <h4 className="mb-4"><b>Register</b></h4> */}
                <div className="d-flex w-100 justify-content-between">
                    <h4 className="mb-4"><b>Register</b></h4>
                    <p className="p_xsmall">Step 2/2</p>
                </div>
                <p className="m-0">Personalise your experience</p>
                <p className="p_xsmall text-thin ms-0 mb-3">This information will help us make the platform better for you</p>

                <Form.Group className="mb-3" controlId="formBasicBrand">
                    <Form.Label className="fw-bold ">Create an unique My Surf Shot URL for your profile *</Form.Label>
                    <InputGroup >
                
                        <InputGroup.Text className=' ' id="inputGroupPrepend">mysurfshot.com.au/</InputGroup.Text>
                        <Form.Control required defaultValue={dataNewUser.user_unique_id} type="text" placeholder="Type here" onChange={(e) => setDataNewUser((oldSearch) => ({...oldSearch, "user_unique_id": e.target.value}))} />

                    </InputGroup>
                    <div id="emailHelp" className="form-text">You only can use letters, numbers, "-", "_" and ".". We recommend being the same as to your instagram.</div>
                    {userExistsError}
                </Form.Group>
                
                <Form.Group className="mb-3" controlId="formBasicBrand">
                    <Form.Label className="fw-bold ">Name of photography brand</Form.Label>
                    <Form.Control  defaultValue={dataNewUser.photographer_business_name} type="text" placeholder="Type here" onChange={(e) => setDataNewUser((oldSearch) => ({...oldSearch, "photographer_business_name": e.target.value}))} />
                    <div id="emailHelp" className="form-text">Optional. This field is shown on your profile. If blank, we will use your name.</div>

                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicSocialMedia">
                    <Form.Label className="fw-bold ">Instagram </Form.Label>
                    {/* <Form.Control  required defaultValue={data.photographer_instagram} type="text" placeholder="Photography brand or name" onChange={(e) => setData((oldSearch) => ({...oldSearch, "photographer_instagram": e.target.value}))} /> */}
                
                    <InputGroup >
                
                        <InputGroup.Text className=' ' id="inputGroupPrepend">@</InputGroup.Text>
                        <Form.Control defaultValue={dataNewUser.photographer_instagram} type="text" placeholder="Type Here" onChange={(e) => setDataNewUser((oldSearch) => ({...oldSearch, "photographer_instagram": e.target.value}))} />

                    </InputGroup>
                    <div id="emailHelp" className="form-text">This will be shown in your albums to promote your social media on the platform</div>


                </Form.Group>

                
                {/* <Form.Group className="mb-3" controlId="formBasicSocialMedia">
                    <Form.Label className="fw-bold ">Instagram</Form.Label>
                    <Form.Control  defaultValue={data.photographer_facebook} type="text" placeholder="Photography brand or name" onChange={(e) => setData((oldSearch) => ({...oldSearch, "photographer_facebook": e.target.value}))} />
                </Form.Group> */}

                <Form.Group className="mb-3" controlId="formBasicGoals">
                    <Form.Label className="fw-bold">What was the main reason you started taking surf shots? *</Form.Label>
                    <Form.Select 
                        aria-label="Default select example" 
                        onChange={(e) => setDataNewUser((oldSearch) => ({...oldSearch, "photographer_started_goals": e.target.value}))}
                        required
                        defaultValue={dataNewUser.photographer_started_goals}
                        >
                        <option value=""></option>
                        <option value="Hobby">Hobby</option>
                        <option  value="Source of extra income">Source extra income</option>
                        <option value="Source of main income">Source of main income</option>
                    </Form.Select>
                                
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicGoals">
                    <Form.Label className="fw-bold">What is your main goal currently with surf shooting? *</Form.Label>
                    <Form.Select 
                        aria-label="Default select example" 
                        onChange={(e) => setDataNewUser((oldSearch) => ({...oldSearch, "photographer_goals": e.target.value}))}
                        required
                        defaultValue={dataNewUser.photographer_goals}
                        >
                        <option value=""></option>
                        <option  value="Source of extra income">Source extra income</option>
                        <option value="Source of main income">Source of main income</option>
                    </Form.Select>
                                
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicCamera">
                    <Form.Label className="fw-bold ">Camera used</Form.Label>
                    <Form.Control  defaultValue={dataNewUser.photographer_camera_details} type="text" placeholder="Camera" onChange={(e) => setDataNewUser((oldSearch) => ({...oldSearch, "photographer_camera_details": e.target.value}))} />
                </Form.Group>

                <Form.Group className="mb-3" >
                    <Form.Label className="fw-bold ">Kind of media you shoot *</Form.Label>
                    <div className='radio_inline_blue'>
                        <Form.Check
                        
                            inline
                            label="Photos"
                            name="group1"
                            type="checkbox"
                            value="Photos"
                            defaultChecked={dataNewUser.photographer_products_type ? JSON.parse(dataNewUser.photographer_products_type).find(function(str){
                                return str == "Photos"
                            }) : ""}
                            onChange={handlePhotosCB}
                        />
                        <Form.Check
                        
                            inline
                            label="Videos"
                            name="group1"
                            type="checkbox"
                            value="Videos"
                            defaultChecked={dataNewUser.photographer_products_type ? JSON.parse(dataNewUser.photographer_products_type).find(function(str){
                                return str == "Videos"
                            }) : ""}
                            onChange={handleVideosCB}
                        />
                
                    </div>
                    {photosTypeError}
                </Form.Group>

                <Form.Group className="mb-3">
                    <Form.Label className="fw-bold ">From which position do you shoot? *</Form.Label>
                    {/* dataNewUser.photographer_products_category != null &&JSON.parse(dataNewUser.photographer_products_category).length > 0 ? false : true */}

                    <div className='radio_inline_blue' >
                        <Form.Check
                        
                            inline
                            label="Drone"
                            name="group2"
                            type="checkbox"
                            value="Drone"
                            onChange={handleDroneCB}
                            defaultChecked={dataNewUser.photographer_products_category ? JSON.parse(dataNewUser.photographer_products_category).find(function(str){
                                return str == "Drone"
                            }) : ""}
                        />
                        <Form.Check
                        
                            inline
                            label="In Water"
                            name="group2"
                            type="checkbox"
                            value="In Water"

                            onChange={handleWCCB}
                            defaultChecked={dataNewUser.photographer_products_category ? JSON.parse(dataNewUser.photographer_products_category).find(function(str){
                                return str == "In Water"
                            }) : ""}
                        />
                        <Form.Check
                        
                            inline
                            label="Land"
                            name="group2"
                            type="checkbox"
                            value="Land"

                            onChange={handleLandCB}
                            defaultChecked={dataNewUser.photographer_products_category ? JSON.parse(dataNewUser.photographer_products_category).find(function(str){
                                return str == "Land"
                            }) : ""}
                        />
                
                    </div>
                    {categoriesError}
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicFirstName">
                    <Form.Label className="fw-bold">The surf spots you go to take photos / videos the most? *</Form.Label>
                    <p className="text-thin p_xsmall m-0">Please, press "," or ";" to insert tags</p>
                   
                    {/* <TagsInput
                        // value={tags}
                        
                        value={tags}
                        onChange={setTags}
                        name="fruits"
                        placeHolder="Enter locations"
                        className="w-100"
                        separators={["Enter", ",", ";"]}
                    /> */}

                    <TagsInput
                        value={tags}
                        onChange={setTags}
                        name="locations"
                        placeHolder="Enter locations"
                        className="w-100"
                        separators={[ ",", ";"]}
                        inputValue={inputValue}
                        onKeyUp={handleInputChange}
                        
                    />
                    {inputValue && filteredSuggestions.length > 0 && (
                        <ul className="ul_list_autocomplete_locations">
                        {filteredSuggestions.map((suggestion, index) => (
                            <li className={selectedSuggestionIndex  === index ? "li_list_autocomplete_locations selected_suggestion" : "li_list_autocomplete_locations"} key={index} onClick={() => handleSuggestionClick(suggestion)}>
                            {suggestion}
                            </li>
                        ))}
                        </ul>
                    )}


                    {spotsError}
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Check
                    required
                    label={<span>
                        I agree to the 
                        <a href="/terms-and-conditions" target="_blank" rel="noopener noreferrer" className="ms-2">
                            terms and conditions
                        </a>
                    </span>}
                    feedback="You must agree before submitting."
                    feedbackType="invalid"
                    />
                    
                </Form.Group>

                

                <div className="d-flex w-100 justify-content-between mt-3">
                    <Button className='btn_outlined_blue btn_register me-auto' onClick={goBack}>Back</Button>

                    <Button className='btn_primary_blue btn_register  ms-auto' disabled={!btnRegisterEnabled} type="submit">Finish</Button>

                </div>
                
                
            </Form>
            <div className="text-center spinner_screen" >
                    <Spinner animation="border" role="status" className="mt-5 " hidden={!showLoading}>
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
            </div>
        </div>
    )
}

function SurferView({setView, basic_info}){

    const [data, setData] = useState(basic_info != null ? basic_info : "");
    const [validated, setValidated] = useState(false);
    const [inputValue, setInputValue] = useState("");
    const [tags, setTags] = React.useState([
        // userInfo.photographer_main_spots
        
    ]);
    const [selectedSuggestionIndex, setSelectedSuggestionIndex] = useState(-1);
    const [suggestions, setSuggestions] = useState([])
    const [btnRegisterEnabled, setBtnRegisterEnabled] = useState(false)
    const origin = new URLSearchParams(useLocation().search).get("origin");


    useEffect(() => {
        getSuggestions()

        setData((oldSearch) => ({...oldSearch, "surfer_surf_level": '3'}))
        
    }, []);

    useEffect(() =>{
        if((Object.keys(data).includes("surfer_surf_level") && data.surfer_surf_level != null && data.surfer_surf_level != "") &&
        (Object.keys(data).includes("surfer_surf_frequency") && data.surfer_surf_frequency != null && data.surfer_surf_frequency != "") &&
        tags.length > 0
        ){
            setBtnRegisterEnabled(true)
        }else{
            setBtnRegisterEnabled(false)
        }
    },[data])

    useEffect(() =>{
        setData((oldSearch) => ({...oldSearch, "surfer_main_surf_spots": JSON.stringify(tags)}))
    }, [tags])

    const validateData = (event) => {
        const form = event.currentTarget;
        // console.log(form)
        event.preventDefault();

        if (form.checkValidity() === false) {
          event.stopPropagation();

        }else{
            // console.log(data)

            if(origin != null){
                data.origin = origin
            }
            // console.log(data)

            register();
        }
        setValidated(true);
        
    };

    function register(){
        // console.log(data)

        fetch(url_server + "public/users", {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            },
            })
            .then((response) => response.json())
            
            .then( (data) => {
                // response = data.response
                // // console.log(data.response)
                // console.log(data)
                if(data.error){
                    if(Object.keys(data.errorDescription).includes("raw")){
                        alert(data.errorDescription.raw.message)
                    }else{
                        alert("Something went wrong")
                    }

                }else{
                    setView(<RegisterSuccessfull />)
                }
                
    
            })
            .catch((err) => {
                // console.log(err)
            });
    
    }

    function goBack(){
        setView(<InitialRegisterForm setView={setView} user_data={data} />)
    }

    function getSuggestions(){
        fetch(url_server + "public/locations/suggestions", {
            method: 'GET',
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
                // 'x-access-token': session_details.token
            },
            })
            .then((response) => response.json())
            
            .then( (data) => {
                // response = data.response
                // console.log(data.response)
                const sug = []
                data.response.map((obj, index) =>{
                    sug.push(obj.location_suggestions)
                })

                setSuggestions(sug)
    
            })
            .catch((err) => {
                // console.log(err)
            });
    }

    const handleInputChange = (event) => {

        if (event.key === "ArrowUp") {
            event.preventDefault();
            setSelectedSuggestionIndex(
              Math.max(selectedSuggestionIndex - 1, 0)
            );
          } else if (event.key === "ArrowDown") {
            event.preventDefault();
            setSelectedSuggestionIndex(
              Math.min(selectedSuggestionIndex + 1, filteredSuggestions.length - 1)
            );
          } else if (event.key === "Enter" && selectedSuggestionIndex !== -1) {
            event.preventDefault();
            handleSuggestionClick(filteredSuggestions[selectedSuggestionIndex]);
            event.target.value = ""
          }else{
            
            setInputValue(event.target.value);

          }

        // setInputValue(event.target.value);
    
    };

    const handleSuggestionClick = (suggestion) => {
        // Add the suggestion to the tags list
        const rtiinput = document.getElementsByClassName('rti--input')[0]
        rtiinput.value = ""
        setTags([...tags, suggestion]);
        // Clear the input value
        setInputValue("");
    };

    const filteredSuggestions = suggestions.length > 0 ? suggestions.filter((suggestion) => 
        suggestion.toLowerCase().includes(inputValue.toLowerCase())
    
    ) : [];


    return(
        <div className="mb-5">
            <Form className='col-md-5 form_white_centered2' onSubmit={validateData}>
                {/* <h4 className="mb-4"><b>Register</b></h4> */}
                <div className="d-flex w-100 justify-content-between">
                    <h4 className="mb-4"><b>Register</b></h4>
                    <p className="p_xsmall">Step 2/2</p>
                </div>
                <p className="m-0">Personalize your experience</p>
                <p className="p_xsmall text-thin ms-0 mb-3">This information will help us make the platform better for you</p>
                
                <Form.Group className="mb-3" controlId="formBasicEmail">
                    {/* <RangeSurfLevel /> */}
                    <RangeSurfLevel  

                    change={(e) => setData((oldSearch) => ({...oldSearch, "surfer_surf_level": e.target.value}))} />
              
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label className="fw-bold">How often do you usually surf? *</Form.Label>
                    <Form.Select 
                        aria-label="Default select example" 
                        onChange={(e) => setData((oldSearch) => ({...oldSearch, "surfer_surf_frequency": e.target.value}))}
                        required
                        defaultValue={data.surfer_surf_frequency}
                        >
                        <option value=""></option>
                        <option value="Rarely">Rarely</option>
                        <option value="A couple times a month">A couple times a month</option>
                        <option value="2-3 days a week">2-3 days a week</option>
                        <option value="4-5 days a week">4-5 days a week</option>
                        <option value="Every day">Every day</option>
                    </Form.Select>
                                
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicFirstName">
                    <Form.Label className="fw-bold">What are the surf spots you surf the most? *</Form.Label>
                    {/* <Form.Control
                    as="textarea"
                    placeholder="Your spots"
                    rows={3}
                    required
                    defaultValue={data.surfer_main_surf_spots}
                    onChange={(e) => setData((oldSearch) => ({...oldSearch, "surfer_main_surf_spots": e.target.value}))}
                    /> */}

                    <p className="text-thin p_xsmall m-0">Please, press "," or ";" to insert tags</p>

                    <TagsInput
                        value={tags}
                        onChange={setTags}
                        name="locations"
                        placeHolder="Enter locations"
                        className="w-100"
                        separators={[ ",", ";"]}
                        inputValue={inputValue}
                        onKeyUp={handleInputChange}
                        
                    />
                    {inputValue && filteredSuggestions.length > 0 && (
                        <ul className="ul_list_autocomplete_locations">
                        {filteredSuggestions.map((suggestion, index) => (
                            <li className={selectedSuggestionIndex  === index ? "li_list_autocomplete_locations selected_suggestion" : "li_list_autocomplete_locations"} key={index} onClick={() => handleSuggestionClick(suggestion)}>
                            {suggestion}
                            </li>
                        ))}
                        </ul>
                    )}

                </Form.Group>
                
                <Form.Group className="mb-3">
                    <Form.Check
                    required
                    label={<span>
                        I agree to the 
                        <a href="/terms-and-conditions" target="_blank" rel="noopener noreferrer" className="ms-2">
                            terms and conditions
                        </a>
                    </span>}
                    feedback="You must agree before submitting."
                    feedbackType="invalid"
                    />
                    
                </Form.Group>

                <div className="d-flex w-100 justify-content-between mt-3">
                    <Button className='btn_outlined_blue btn_register me-auto ' onClick={goBack}>Back</Button>

                    <Button className='btn_primary_blue btn_register  ms-auto' type="submit" disabled={!btnRegisterEnabled}>Finish</Button>

                </div>
                
                
            </Form>
        </div>
        
    );

}



function InitialRegisterForm({setView, user_data, onBackData}){
    const [dataNewUser, setDataNewUser] = useState(user_data != null ? user_data : '');
    const [validated, setValidated] = useState(false);
    const [loginError, setLoginError] = useState(<LoginError />)
    var ic_eye = require("../assets/ic_eye.png")
    var ic_eye_slash = require("../assets/ic_eye_slash.png")
    const [emailExists, setEmailExists] = useState()
    const [showPassword, setShowPassword] = useState(false)
    const [showPasswordRepeat, setShowPasswordRepeat] = useState(false)
    const [minimumDate, setMinimumDate] = useState()
    const dials = require('../lists/countries_dials.json');
    const [btnNextEnabled, setBtnNextEnabled] = useState(false)


    useEffect(() =>{
        setMinimumDate(subtractYears(new Date(), 13))
    }, [])

    useEffect(() =>{
        if((Object.keys(dataNewUser).includes("user_email") && dataNewUser.user_email != null && dataNewUser.user_email != "") &&
        (Object.keys(dataNewUser).includes("user_password") && dataNewUser.user_password != null && dataNewUser.user_password != "") &&
        (Object.keys(dataNewUser).includes("user_phone_prefix") && dataNewUser.user_phone_prefix != null && dataNewUser.user_phone_prefix != "") &&
        (Object.keys(dataNewUser).includes("user_phone_number") && dataNewUser.user_phone_number != null && dataNewUser.user_phone_number != "") &&
        (Object.keys(dataNewUser).includes("user_first_name") && dataNewUser.user_first_name != null && dataNewUser.user_first_name != "") &&
        (Object.keys(dataNewUser).includes("user_last_name") && dataNewUser.user_last_name != null && dataNewUser.user_last_name != "") &&
        (Object.keys(dataNewUser).includes("user_date_of_birth") && dataNewUser.user_date_of_birth != null && dataNewUser.user_date_of_birth != "")
        
        ){
            setBtnNextEnabled(true)
        }else{
            setBtnNextEnabled(false)
        }
    },[dataNewUser])

    function changeProfile(profile){
        if(dataNewUser.user_default_profile != null && (profile !== dataNewUser.user_default_profile)){
            for (let key in dataNewUser) {
                if (key.startsWith("photographer") && profile == 1) {
                    delete dataNewUser[key];
                }else if(key.startsWith("surfer") && profile == 2){
                    delete dataNewUser[key]
                }

            }
            setDataNewUser((oldSearch) => ({...oldSearch, "user_default_profile": profile}))
        }else{
            setDataNewUser((oldSearch) => ({...oldSearch, "user_default_profile": profile}))

        }
    }

    const validateData = (event) => {
        const form = event.currentTarget;
        // console.log(form)
        event.preventDefault();

        if (form.checkValidity() === false) {
          event.stopPropagation();

        }else{

            // console.log(validateInputs())
            if(validateInputs()){
                existsEmail(dataNewUser.user_email)
                
            }
            
        }
        setValidated(true);
        
      };

    // console.log(dataNewUser)

    function validateInputs(){

        var is_valid = true
        if(dataNewUser.user_default_profile == null){
            alert("Please select how will you use the platform");
            is_valid = false;
        }

        if(dataNewUser.user_password != dataNewUser.user_repeat_password){
            setLoginError(<LoginError content={"Passwords don't match"} />)
            is_valid = false;
        }

        if(!dataNewUser.user_password.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+{}\[\]:;<>,.?/~]).{8,}$/)){
            setLoginError(<LoginError content={"Your password must be at least 8 characters and should include a combination of numbers, letters and special characters."} />)
            is_valid = false;
        }

        return is_valid;
    }

     function existsEmail(email){
       
         fetch(`${url_server}public/users/${email}/email/exists`, {
            method: 'GET',
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            },
            })
            .then((response) => response.json())
            
            .then( (data) => {
                // response = data.response
                
                
                if(data.response){
                    alert("Email already in use")
                }else{
                    delete dataNewUser["user_repeat_password"]

                    if(dataNewUser.user_default_profile == 1){

                        setView(<SurferView setView={setView} basic_info={dataNewUser}/>)
        
                    }else{
                        // console.log("photographer")
                        setView(<PhotographersForm setView={setView} basic_info={dataNewUser}/>)
        
                    }
                }
    
            })
            .catch((err) => {
                // console.log(err)
                // alert("Something went wrong. Please check your connection and try again.");
            });
    }


    function passwordNormal(show){

        setShowPassword(show)
    }

    function passwordNormalRepeat(show){

        setShowPasswordRepeat(show)
    }

    function subtractYears(date, years) {
        const newDate = new Date(date.setFullYear(date.getFullYear() - years));
        newDate.setDate(newDate.getDate() - 1)
        // console.log(newDate.toISOString())
       return newDate.toISOString();
     }

     console.log(dataNewUser)
    
    return(
        <div className="mb-5">
            
            <Form className='col-md-5 form_white_centered2 ' onSubmit={validateData}>
                
                <div className="d-flex w-100 justify-content-between">
                    <h4 className="mb-4"><b>Register</b></h4>
                    <p className="p_xsmall">Step 1/2</p>
                </div>
                

                <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label className="fw-bold ">Email address *</Form.Label>
                    <Form.Control  required defaultValue={dataNewUser.user_email} type="email" placeholder="Enter email" onChange={(e) => setDataNewUser((oldSearch) => ({...oldSearch, "user_email": e.target.value}))} />
                    <div id="emailHelp" className="form-text text_grey">To proceed to the platform, we require you to verify this email address.</div>

                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label className="fw-bold">Password *</Form.Label>
                    <InputGroup className="">
                        <Form.Control required type={showPassword === false ? "password" : "text"} placeholder="Password" onChange={(e) => setDataNewUser((oldSearch) => ({...oldSearch, "user_password": e.target.value}))}/>
                        <Button className="btn_show_password" onClick={(e) => passwordNormal(!showPassword)}> 
                        <img src={showPassword === false ? ic_eye : ic_eye_slash} 
                                alt='Search button' 
                                 
                                />
                        </Button>
                    </InputGroup>
                    
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicRepeatPassword">
                    <Form.Label className="fw-bold">Repeat Password *</Form.Label>
                    <InputGroup className="">
                        <Form.Control required  type={showPasswordRepeat === false ? "password" : "text"} placeholder="Password" onChange={(e) => setDataNewUser((oldSearch) => ({...oldSearch, "user_repeat_password": e.target.value}))}/>
                        <Button className="btn_show_password" onClick={(e) => passwordNormalRepeat(!showPasswordRepeat)}> 
                                <img src={showPasswordRepeat === false ? ic_eye : ic_eye_slash} 
                                alt='Search button' 
                                 
                                />
                        </Button>
                    </InputGroup>
                    
                    {loginError}
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicPhonePrefix">
                    <Form.Label className="fw-bold">Phone Prefix *</Form.Label>
                    <Form.Select 
                        aria-label="Phone prefix" 
                        onChange={(e) => setDataNewUser((oldSearch) => ({...oldSearch, "user_phone_prefix": e.target.value}))}
                        defaultValue={dataNewUser.user_phone_prefix}
                        required
                        >
                        <option value=""></option>
                        {dials.map((obj, index) => {
                            return(
                                <option key={index} value={obj.dial_code}>{obj.name} ({obj.dial_code})</option>
                            )
                        })}

                    </Form.Select>

                </Form.Group>
                
                <Form.Group className="mb-3" controlId="formBasicPhoneNumber">
                    <Form.Label className="fw-bold">Phone Number *</Form.Label>
                    <Form.Control required defaultValue={dataNewUser.user_phone_number} type="tel"  placeholder="Phone Number" onChange={(e) => setDataNewUser((oldSearch) => ({...oldSearch, "user_phone_number": e.target.value}))}/>
            
                </Form.Group>


                <Form.Group className="mb-3" controlId="formBasicFirstName">
                    <Form.Label className="fw-bold">First Name *</Form.Label>
                    <Form.Control required defaultValue={dataNewUser.user_first_name} type="text" placeholder="First Name" onChange={(e) => setDataNewUser((oldSearch) => ({...oldSearch, "user_first_name": e.target.value}))}/>
            
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicLastName">
                    <Form.Label className="fw-bold">Last Name *</Form.Label>
                    <Form.Control required defaultValue={dataNewUser.user_last_name} type="text" placeholder="Last Name" onChange={(e) => setDataNewUser((oldSearch) => ({...oldSearch, "user_last_name": e.target.value}))}/>
            
                </Form.Group>
                <Form.Group className="mb-3" controlId="pAbn">
                    <Form.Label  className="fw-bold ">Date of Birth *</Form.Label>
                    <Form.Control required defaultValue={dataNewUser.user_date_of_birth} max={minimumDate != null ? minimumDate.split("T")[0] : ""} onInput={(e) => e.target.setCustomValidity("")}  onInvalid={(e) => e.target.setCustomValidity("Invalid date of birth")} type="date" placeholder="Type here" onChange={(e) => setDataNewUser((oldSearch) => ({...oldSearch, "user_date_of_birth": e.target.value}))} />

                </Form.Group>
                <div>
                    <Form.Label>Select how will you use the platform</Form.Label>
                 
                    {/* <div className="  btn-group w-100 mb-3" role="group" aria-label="Basic radio toggle button group"> */}
                        <div className="w-100 mb-3">
                            <input type="radio" className="btn-check" name="btnradio" id="btnradio1" defaultChecked={dataNewUser.user_default_profile == 1 ? "checked" : ""} onClick={() => changeProfile(1)} />
                            <label className="btn btn-outline-primary btns_register_profile w-100" htmlFor="btnradio1">Buy photos / videos</label>
                        </div>
                        
                        <div className="w-100">
                            <input type="radio" className="btn-check" name="btnradio" id="btnradio2" defaultChecked={dataNewUser.user_default_profile == 2 ? "checked" : ""} onClick={() => changeProfile(2)} />
                            <label className="btn btn-outline-primary btns_register_profile w-100" htmlFor="btnradio2">Sell photos / videos</label>
                        </div>
                        
                    {/* </div> */}
                    

                    {/* <ToggleButtonGroup type="radio" name="options" className="w-100 mb-3 " defaultValue={data.user_default_profile}>

                        <ToggleButton id="tbg-radio-1"  value={1} className='me-2 btns_register_profile' onClick={() => changeProfile(1)}>
                        Buy photos / videos
                        </ToggleButton>


                        <ToggleButton id="tbg-radio-2" value={2} className='ms-2' onClick={() => changeProfile(2)}>
                        Sell photos / videos
                        </ToggleButton> 
                        
                    
                
                    </ToggleButtonGroup> */}


                </div>

                <div className="d-flex w-100 justify-content-between mt-3">
                {/* <Button className='btn_primary_blue btn_register me-auto' onClick={validateData}>Back</Button> */}

                <Button className='btn_primary_blue btn_register  ms-auto' type="submit" disabled={!btnNextEnabled}>Next</Button>

                </div>
                
                
            </Form>
        </div>
        
    );

}



function Register(){
    const [currentView, setCurrentView] = useState();

    useEffect(() => {
        setCurrentView(<InitialRegisterForm setView={setCurrentView} />)
        
    }, []);

    return(
        <div>
            <Navigation showSearchBar={0} />
            <Container id="container_login">
                {currentView} 
                {/* <PhotographersForm /> */}
                {/* <SurferView /> */}
                {/* <PhotographersForm2 /> */}
                {/* <RegisterSuccessfull /> */}
            </Container>

        </div>
    )
}

export default Register