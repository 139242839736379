import { SessionDetails } from "../controllers/AccountController"
import Navigation from "../components/Navigation"
import { Link, useParams, useLocation } from 'react-router-dom';

import Container from 'react-bootstrap/esm/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { Footer } from '../components/Footer';
import { GoogleMap, Marker, useLoadScript } from "@react-google-maps/api";
import React, { useMemo, useState, useEffect, Component } from "react";
import { google_maps_api_key, stripe_pk, url_server } from '../settings';
import Card from 'react-bootstrap/Card';
import Nav from 'react-bootstrap/Nav';
import Form from 'react-bootstrap/Form';
import Compressor from 'compressorjs';
import axios from 'axios';
import SetDiscount from "../components/sections/Discounts";
import Modal from 'react-bootstrap/Modal';
import ProgressBar from 'react-bootstrap/ProgressBar';
import InputGroup from 'react-bootstrap/InputGroup';
import Spinner from 'react-bootstrap/Spinner';
import FormGroup from "react-bootstrap/esm/FormGroup";
import UploadFile from "../components/UploadFile";
import { TagsInput } from "react-tag-input-component";
import watermarkedImage, { generateWatermarkText } from "../components/WatermarkedImage";
import Popover from 'react-bootstrap/Popover';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import MaskedInputPhone from "../components/MaskedInput";
import SectionSecurity from "../components/settings/SectionSecurity";


function TextError({ content }) {
    return (
        <Form.Text className="text-danger">
            {content}
        </Form.Text>
    );
}


function URLNameError({ content }) {
    return (
        <Form.Text className="text-danger">
            {content}
        </Form.Text>
    );
}

function BrandNameError({ content }) {
    return (
        <Form.Text className="text-danger">
            {content}
        </Form.Text>
    );
}


function Error({ content }) {
    return (
        <Form.Text className="text-danger">
            {content}
        </Form.Text>
    );
}



function SectionUser({ userInfo, setCanLeave, refreshUser }) {

    const [newData, setNewData] = useState({})
    // const [userUniqueId, setUserUniqueId] = useState()
    const [validated, setValidated] = useState(false);
    const [loginError, setLoginError] = useState(<TextError />)
    const [accountDetails, setAccountDetails] = useState(SessionDetails())

    var ic_eye = require("../assets/ic_eye.png")
    var ic_eye_slash = require("../assets/ic_eye_slash.png")
    const [showPassword, setShowPassword] = useState(false)
    const [showPasswordRepeat, setShowPasswordRepeat] = useState(false)
    const [minimumDate, setMinimumDate] = useState()

    useEffect(() => {
        setMinimumDate(subtractYears(new Date(), 13))
    }, [])

    useEffect(() => {
        if (Object.keys(newData).length > 0) {
            setCanLeave(false)
        }
    }, [newData])

    const validateData = async (event) => {
        const form = event.currentTarget;
        event.preventDefault();

        if (form.checkValidity() === false) {
            event.stopPropagation();

        } else {

            if (Object.keys(newData).includes("user_first_name") && newData.user_first_name == userInfo.user_first_name) {
                delete newData.user_first_name
            }

            if (Object.keys(newData).includes("user_last_name") && newData.user_last_name == userInfo.user_last_name) {
                delete newData.user_last_name
            }

            if (Object.keys(newData).includes("user_phone_number") && newData.user_phone_number == userInfo.user_phone_number) {
                delete newData.user_phone_number
            }

            if (Object.keys(newData).includes("user_date_of_birth") && newData.user_date_of_birth == userInfo.user_date_of_birth) {
                delete newData.user_date_of_birth
            }

            const data_updated = newData

            updateUser(data_updated)

        }
        setValidated(true);

    };


    function updateUser(newData) {

        fetch(`${url_server}private/users/${accountDetails.user_id}`, {
            method: 'PUT',
            body: JSON.stringify(newData),
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
                'x-access-token': accountDetails.token,
            },
        })
            .then((response) => response.json())

            .then(async (data) => {
                // response = data.response
                if (data.error) {
                    if (Object.keys(data.errorDescription).includes("raw")) {
                        alert(data.errorDescription.raw.message)

                    } else {
                        alert("Something went wrong")
                    }

                } else {
                    alert("Your data has been updated successfully")
                    // // window.location.reload()
                    window.scrollTo(0, 0)
                    setNewData({})
                    setCanLeave(true)
                    refreshUser()
                    // window.location.reload()

                }


            })
            .catch((err) => {
                // console.log(err)
            });

    }




    function passwordNormal(show) {

        setShowPassword(show)
    }

    function passwordNormalRepeat(show) {

        setShowPasswordRepeat(show)
    }

    function subtractYears(date, years) {
        const newDate = new Date(date.setFullYear(date.getFullYear() - years)).toISOString();
        // console.log(newDate)
        return newDate;
    }

    console.log(newData)
    return (
        <>

            {userInfo != null ? (

                <div className="mt-4">
                    <Row className="gx-5">


                        <h5 className="text-blue mb-3"><b>Private details</b></h5>
                        <Form onSubmit={validateData}>
                            <Col lg={6}>

                                <Form.Group className="mb-3" controlId="vFullName">
                                    <Form.Label className="fw-bold ">User First name</Form.Label>
                                    <Form.Control required type="text" placeholder="Type here" defaultValue={userInfo.user_first_name} onChange={(e) => setNewData((oldSearch) => ({ ...oldSearch, "user_first_name": e.target.value }))} />
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="vState">
                                    <Form.Label className="fw-bold ">User Last Name</Form.Label>
                                    <Form.Control required type="text" placeholder="Type here" defaultValue={userInfo.user_last_name} onChange={(e) => setNewData((oldSearch) => ({ ...oldSearch, "user_last_name": e.target.value }))} />
                                </Form.Group>

                                <MaskedInputPhone label="Phone Number" defaultValue={userInfo.user_phone_number} required={false} setNewData={setNewData} />

                                <Form.Group className="mb-3" controlId="vState">
                                    <Form.Label className="fw-bold ">Date of birth</Form.Label>
                                    <Form.Control required type="date" placeholder="Type here" max={minimumDate != null ? minimumDate.split("T")[0] : ""} onInput={(e) => e.target.setCustomValidity("")} onInvalid={(e) => e.target.setCustomValidity("Invalid date of birth")} defaultValue={userInfo.user_date_of_birth.split('T')[0]} onChange={(e) => setNewData((oldSearch) => ({ ...oldSearch, "user_date_of_birth": e.target.value }))} />
                                </Form.Group>

                            </Col>

                            <div>
                                <Button className='btn_primary_blue btn_register float-end' type="submit" >Save changes</Button>

                            </div>

                        </Form>

                    </Row>
                </div>
            ) : (
                ""
            )}
        </>
    )


}


function SectionBilling({ photographerInfo, setCanLeave, refreshPhotographerInfo }) {

    const [newData, setNewData] = useState({})
    const [validated, setValidated] = useState(false);
    const [loginError, setLoginError] = useState(<TextError />)
    const [accountDetails, setAccountDetails] = useState(SessionDetails())
    const [uploadFileDocument, setUploadFileDocument] = useState()
    const [fileFront, setFileFront] = useState()
    const [fileBack, setFileBack] = useState()
    const [valueFileType, setValueFileType] = useState("0")
    const [isStripeVerified, setStripeVerified] = useState()
    const [showLoadingSubmit, setShowLoadingSubmit] = useState()
    const [disabledButtonSave, setDisabledButtonSave] = useState(false)
    const [stateError, setStateError] = useState(<Error />)
    const [cityError, setCityError] = useState(<Error />)
    const [addressError, setAddressError] = useState(<Error />)
    const [postalCodeError, setPostalCodeError] = useState(<Error />)
    const [abnError, setAbnError] = useState(<Error />)
    const [bankError, setBankError] = useState(<Error />)
    const [accountVerification, setAccountVerification] = useState("")
    const ic_time = require('../assets/ic_time.png')
    const ic_success = require('../assets/ic_success.png')
    const ic_warning = require('../assets/ic_warning.png')
    const [verificationText, setVerificationText] = useState()
    const [bankAccountStatus, setBankAccountStatus] = useState()
    const [currentBankId, setCurrentBankId] = useState()

    const array_states = [
        {
            val: 'QLD',
            text: "Queensland",

        },
        {
            val: "ACT",
            text: "Australian Capital Territory",

        },
        {
            val: "NSW",
            text: "New South Wales",

        },
        {
            val: "SA",
            text: "South Australia",

        },
        {
            val: "NT",
            text: "Northen Territory",

        },
        {
            val: "VIC",
            text: "Victoria",

        },
        {
            val: "TAS",
            text: "Tasmania",

        },
        {
            val: "WA",
            text: "Western Australia",

        },

    ]



    useEffect(() => {
        if (Object.keys(newData).length > 0) {
            setCanLeave(false)
        }
    }, [newData])

    useEffect(() => {
        getAccountDetails()

    }, [])

    function getAccountDetails() {

        fetch(`${url_server}private/photographers/vendor/account/${photographerInfo.photographer_stripe_account}`, {
            method: 'GET',
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
                'x-access-token': accountDetails.token
            },
        })
            .then((response) => response.json())

            .then((data) => {
                // response = data.response
                // // console.log(data.response)
                if (data.error) {
                    alert("something went wrong")


                } else {
                    // console.log(data.response)
                    setStripeVerified(data.response.individual.verification.status)
                    setBankAccountStatus(data.response.external_accounts.data[0].status)
                    setCurrentBankId(data.response.external_accounts.data[0].id)
                    // console.log(data.response.external_accounts.data[0].id)

                }



            })
            .catch((err) => {
                // console.log(err)
            });
    }

    useEffect(() => {
        if (isStripeVerified != null && isStripeVerified == "verified") {
            setVerificationText(
                <div className="d-flex align-items-center">
                    <div>
                        <img src={ic_success} alt="success" />
                    </div>
                    <p className={`ms-1 mb-0 p_large text-success`}>Verified</p>
                </div>
            )
        }

        if (isStripeVerified != null && isStripeVerified == "pending") {
            setVerificationText(
                <div className="d-flex align-items-center">
                    <div>
                        <img src={ic_time} alt="time" />
                    </div>
                    <p className={`ms-1 mb-0 p_large text-orange`}>Verification in process</p>
                </div>
            )
        }

        if (isStripeVerified != null && isStripeVerified == "unverified") {
            setVerificationText(
                <div className="d-flex align-items-center">
                    <div>
                        <img src={ic_warning} alt="time" />
                    </div>
                    <p className={`ms-1 mb-0 p_large text-danger`}>Unverified</p>
                </div>
            )
        }

    }, [isStripeVerified])



    const validateData = async (event) => {

        const form = event.currentTarget;
        event.preventDefault();

        if (form.checkValidity() === false) {
            event.stopPropagation();

        } else {
            setShowLoadingSubmit(true)
            setDisabledButtonSave(true)
            var obj_data = newData

            // REMOVE DATA FROM JSON IF DOES'NT CHANGE
            if (Object.keys(obj_data).includes("photographer_address_state") && obj_data.photographer_address_state == photographerInfo.photographer_address_state) {
                delete obj_data.photographer_address_state
            }

            if (Object.keys(obj_data).includes("photographer_city") && obj_data.photographer_city == photographerInfo.photographer_city) {
                delete obj_data.photographer_city
            }

            if (Object.keys(obj_data).includes("photographer_address") && obj_data.photographer_address == photographerInfo.photographer_address) {
                delete obj_data.photographer_address
            }

            if (Object.keys(obj_data).includes("photographer_address_postal_code") && obj_data.photographer_address_postal_code == photographerInfo.photographer_address_postal_code) {
                delete obj_data.photographer_address_postal_code
            }

            if (Object.keys(obj_data).includes("photographer_abn") && obj_data.photographer_abn == photographerInfo.photographer_abn) {
                delete obj_data.photographer_abn
            }

            if (Object.keys(obj_data).includes("photographer_is_gst") && obj_data.photographer_is_gst == photographerInfo.photographer_is_gst) {
                delete obj_data.photographer_is_gst
            }


            // if(Object.keys(obj_data).includes("photographer_bank_account_name") && obj_data.photographer_bank_account_name == photographerInfo.photographer_bank_account_name){
            //     delete obj_data.photographer_bank_account_name
            // }

            if ((Object.keys(obj_data).includes("photographer_bank_account_bsb") && obj_data.photographer_bank_account_bsb == photographerInfo.photographer_bank_account_bsb)
                && (Object.keys(obj_data).includes("photographer_bank_account_number") && obj_data.photographer_bank_account_number == photographerInfo.photographer_bank_account_number)
            ) {
                delete obj_data.photographer_bank_account_bsb
                delete obj_data.photographer_bank_account_number
            }



            if ((Object.keys(obj_data).includes("photographer_abn") && obj_data.photographer_abn == "") && (photographerInfo.photographer_abn != "" && photographerInfo.photographer_abn != null)) {
                setAbnError(<Error content={"ABN cannot be empty"} />)
                setShowLoadingSubmit(false)
                setDisabledButtonSave(false)
                return
            } else {
                setAbnError(<Error />)
            }

            if (Object.keys(obj_data).includes("photographer_abn")) {
                // console.log(validateABN(obj_data.photographer_abn))
                if (!validateABN(obj_data.photographer_abn)) {
                    setAbnError(<Error content={"ABN is incorrect"} />)
                    setShowLoadingSubmit(false)
                    setDisabledButtonSave(false)
                    return
                } else {
                    setAbnError(<Error />)

                }
            }

            // IF VALUES EXISTS IN THE DATABASE, CANNOT BE NULL

            if ((Object.keys(obj_data).includes("photographer_bank_account_bsb") && obj_data.photographer_bank_account_bsb == "") && (photographerInfo.photographer_bank_account_bsb != "" && photographerInfo.photographer_bank_account_bsb != null)) {
                setBankError(<Error content={"BSB cannot be empty"} />)
                setShowLoadingSubmit(false)
                setDisabledButtonSave(false)
                return
            } else {
                setBankError(<Error />)
            }

            if ((Object.keys(obj_data).includes("photographer_bank_account_number") && obj_data.photographer_bank_account_number == "") && (photographerInfo.photographer_bank_account_number != "" && photographerInfo.photographer_bank_account_number != null)) {
                setBankError(<Error content={"Account cannot be empty"} />)
                setShowLoadingSubmit(false)
                setDisabledButtonSave(false)
                return
            } else {
                setBankError(<Error />)
            }




            if (Object.keys(obj_data).includes("photographer_bank_account_bsb") && obj_data.photographer_bank_account_bsb != "") {
                if ((Object.keys(obj_data).includes("photographer_bank_account_number") && obj_data.photographer_bank_account_number == "")) {
                    setBankError(<Error content={"Both bank account and BSB needs to be filled."} />)
                    setShowLoadingSubmit(false)
                    setDisabledButtonSave(false)
                    return
                } else if (!Object.keys(obj_data).includes("photographer_bank_account_number") && (photographerInfo.photographer_bank_account_number == "" || photographerInfo.photographer_bank_account_number == null)) {
                    setBankError(<Error content={"Both bank account and BSB needs to be filled."} />)
                    setShowLoadingSubmit(false)
                    setDisabledButtonSave(false)
                    return
                }
                else if (!Object.keys(obj_data).includes("photographer_bank_account_number") && (photographerInfo.photographer_bank_account_number != "" && photographerInfo.photographer_bank_account_number != null)) {
                    obj_data.photographer_bank_account_number = photographerInfo.photographer_bank_account_number
                    obj_data.photographer_bank_account_name = photographerInfo.photographer_bank_account_name
                    setShowLoadingSubmit(false)
                    setDisabledButtonSave(false)
                    setBankError(<Error />)
                }
            }

            if (Object.keys(obj_data).includes("photographer_bank_account_number") && obj_data.photographer_bank_account_number != "") {
                if ((Object.keys(obj_data).includes("photographer_bank_account_bsb") && obj_data.photographer_bank_account_bsb == "")) {
                    setBankError(<Error content={"Both bank account and BSB needs to be filled."} />)
                    setShowLoadingSubmit(false)
                    setDisabledButtonSave(false)
                    return
                } else if (!Object.keys(obj_data).includes("photographer_bank_account_bsb") && (photographerInfo.photographer_bank_account_bsb == "" || photographerInfo.photographer_bank_account_bsb == null)) {
                    setBankError(<Error content={"Both bank account and BSB needs to be filled."} />)
                    setShowLoadingSubmit(false)
                    setDisabledButtonSave(false)
                    return
                }
                else if (!Object.keys(obj_data).includes("photographer_bank_account_bsb") && (photographerInfo.photographer_bank_account_bsb != "" && photographerInfo.photographer_bank_account_bsb != null)) {
                    obj_data.photographer_bank_account_bsb = photographerInfo.photographer_bank_account_bsb
                    obj_data.photographer_bank_account_name = photographerInfo.photographer_bank_account_name

                    setShowLoadingSubmit(false)
                    setDisabledButtonSave(false)
                    setBankError(<Error />)

                }
            }




            if (obj_data.photographer_bank_account_bsb == "" || obj_data.photographer_bank_account_number == "") {
                delete obj_data.photographer_bank_account_bsb
                delete obj_data.photographer_bank_account_number
            } else {
                obj_data.previous_bank_id = currentBankId

            }



            var files_valid = true

            if (fileFront != null) {
                const fileFrontId = await validateIdentityFiles(fileFront.selectedFile)
                obj_data.fileFront = fileFrontId.id
            }


            if (valueFileType != "1" && valueFileType != "0") {
                if (fileFront != null && fileBack != null) {

                    const fileBackId = await validateIdentityFiles(fileBack.selectedFile)
                    obj_data.fileBack = fileBackId.id
                } else {
                    alert("You need to submit both documents")
                    setShowLoadingSubmit(false)
                    setDisabledButtonSave(false)
                    files_valid = false
                }

            }


            if (files_valid) {
                updateInfo(obj_data)

            }

        }
        setValidated(true);



    }

    function checkBankFields(obj) {
        if ((!Object.keys(obj).includes("photographer_bank_account_bsb") || obj.photographer_bank_account_bsb === "") && (!Object.keys(obj).includes("photographer_bank_account_number") || obj.photographer_bank_account_number === "")

        ) {
            return true
        }


        if (!Object.keys(obj).includes("photographer_bank_account_bsb") || obj.photographer_bank_account_bsb === "" || !Object.keys(obj).includes("photographer_bank_account_number") || obj.photographer_bank_account_number === "") {
            // alert("Both bank account and BSB needs to be filled or changed.")
            return false
        }

        return true

    }

    const validateData_old = async (event) => {
        const form = event.currentTarget;

        // event.preventDefault();
        // event.stopPropagation();
        var files_valid = true

        var fileFrontId = ""
        var fileBackId = ""

        var dataWithImages = newData

        // console.log(dataWithImages)
        if (fileFront != null) {
            fileFrontId = await validateIdentityFiles(fileFront.selectedFile)
            //    setNewData((oldSearch) => ({...oldSearch, "fileFront": fileFrontId.id}))
            dataWithImages.fileFront = fileFrontId.id
        }


        if (valueFileType != "1" && valueFileType != "0") {
            if (fileFront != null && fileBack != null) {
                fileBackId = await validateIdentityFiles(fileBack.selectedFile)
                // setNewData((oldSearch) => ({...oldSearch, "fileBack": fileBackId.id}))
                dataWithImages.fileBack = fileBackId.id
            } else {
                alert("You need to submit both documents")
                files_valid = false
            }

        }


        if (files_valid) {
            if (Object.keys(dataWithImages).length > 0) {

                if (Object.keys(dataWithImages).includes("photographer_abn")) {
                    if (validateABN(dataWithImages.photographer_abn)) {

                        setLoginError(<TextError />)
                        updateInfo(dataWithImages)

                    } else {
                        setLoginError(<TextError content={"ABN is incorrect"} />)
                    }
                } else {

                    updateInfo(dataWithImages)
                }

            }
        }



    };

    async function validateIdentityFiles(file) {

        const data = new FormData();
        data.append('file', file);
        data.append('purpose', 'identity_document');

        const fileResult = await fetch('https://uploads.stripe.com/v1/files', {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${stripe_pk}` },
            body: data,
        });
        const fileData = await fileResult.json();
        // console.log(fileData)
        return fileData

    }

    function validateABN(value) {
        if (value != null && value != "") {
            var weights = [10, 1, 3, 5, 7, 9, 11, 13, 15, 17, 19];

            if (value.length != 11) {
                return false
            }

            var sum = 0;

            for (var i = 0; i < weights.length; i++) {
                var digit = parseInt(value[i]) - (i == 0 ? 1 : 0)
                sum += digit * weights[i]
            }

            return (sum % 89) == 0
        } else {
            return true
        }


    }

    function updateInfo(dataWithImages) {
        fetch(`${url_server}private/photographers/${photographerInfo.photographer_id}`, {
            method: 'PUT',
            body: JSON.stringify(dataWithImages),
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
                'x-access-token': accountDetails.token
            },
        })
            .then((response) => response.json())

            .then((data) => {
                // response = data.response
                // // console.log(data.response)
                // console.log(data)
                // window.location.reload()
                setShowLoadingSubmit(false)
                setDisabledButtonSave(false)
                if (data.error) {

                    if (Object.keys(data.errorDescription).includes("raw")) {
                        alert(data.errorDescription.raw.message)

                    } else {
                        alert("Something went wrong. Please check your details and try again")
                    }


                } else {

                    setAccountVerification("Verification in process")

                    setNewData({})
                    setCanLeave(true)
                    window.scrollTo(0, 0)
                    refreshPhotographerInfo()
                    getAccountDetails()
                    alert("Your data has been updated successfully")


                }


            })
            .catch((err) => {
                // console.log(err)
            });

    }

    function setFileUploader(value) {
        // console.log(value)
        setValueFileType(value)
        if (value == "0") {
            setUploadFileDocument()
        }
        else if (value == "1") {
            // set uploader only one file
            setUploadFileDocument(<UploadFile twoFilesNeed={false} fileFront={fileFront} setFileFront={setFileFront} fileBack={fileBack} setFileBack={setFileBack} />)
        } else {
            // set uploader two files
            setUploadFileDocument(<UploadFile twoFilesNeed={true} fileFront={fileFront} setFileFront={setFileFront} fileBack={fileBack} setFileBack={setFileBack} />)

        }
    }

    // console.log(newData)


    return (
        <div className="mt-4 mb-5">

            {photographerInfo != null ? (

                <Form onSubmit={validateData}>
                    <Row className="gx-5">
                        <Col lg={6}>

                            <div className="d-flex align-items-center mb-3">
                                <h5 className="text-blue m-0 me-3"><b>Payment information</b></h5>

                                {verificationText}

                                {/* <div className="d-flex align-items-center">
                                <div>
                                    {isStripeVerified != null ? (
                                        <img src={ (isStripeVerified ? ic_success : accountVerification != "" ? ic_time : ic_warning)} alt="success" />

                                    ) : ""}

                                </div>
                                <p className={`ms-1 mb-0 p_large ${isStripeVerified!= null ?( isStripeVerified ? "text-success" : accountVerification != "" ? "text-orange" : "text-danger") : ""}`}> {isStripeVerified !=  null ? ( isStripeVerified ? "Verified" : (accountVerification != "" ? accountVerification : "Not verified")) : ""}</p>
                            </div> */}
                            </div>

                            <p className="text-thin">To be elegible to receive payments, you must provide all the information requested on this page so that your receipt account can be verified.</p>

                            {/* <Form.Group className="mb-3" controlId="vFullName">
                                <Form.Label className="fw-bold ">Business name</Form.Label>
                                <Form.Control  type="text" placeholder="Type here" defaultValue={info.photographer_business_name}  onChange={(e) => setNewData((oldSearch) => ({...oldSearch, "photographer_business_name": e.target.value}))} />
                            </Form.Group> */}
                            {/*                      
                            <Form.Group className="mb-3" controlId="vState">
                                <Form.Label className="fw-bold ">State</Form.Label>
                                <Form.Control type="text" placeholder="Type here" defaultValue={photographerInfo.photographer_address_state} onChange={(e) => setNewData((oldSearch) => ({...oldSearch, "photographer_address_state": e.target.value}))} />
                            </Form.Group> */}

                            <Form.Group className="mb-3" >
                                <Form.Label className="fw-bold">State</Form.Label>
                                <Form.Select
                                    aria-label="Default select example"
                                    onChange={(e) => setNewData((oldSearch) => ({ ...oldSearch, "photographer_address_state": e.target.value }))}
                                    defaultValue={photographerInfo.photographer_address_state}
                                    required
                                >
                                    <option value=""></option>
                                    {array_states.map((obj, index) => {
                                        return (
                                            <option key={index} value={obj.val}  >{obj.text}</option>
                                        )
                                    })}


                                </Form.Select>
                                {stateError}
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="pAddressState">
                                <Form.Label className="fw-bold ">City</Form.Label>
                                <Form.Control required defaultValue={photographerInfo.photographer_city} type="text" placeholder="Type here" onChange={(e) => setNewData((oldSearch) => ({ ...oldSearch, "photographer_city": e.target.value }))} />
                                {cityError}
                            </Form.Group>


                            <Form.Group className="mb-3" controlId="vAddress">
                                <Form.Label className="fw-bold ">Address</Form.Label>
                                <Form.Control type="text" required placeholder="Type here" defaultValue={photographerInfo.photographer_address} onChange={(e) => setNewData((oldSearch) => ({ ...oldSearch, "photographer_address": e.target.value }))} />
                                {addressError}
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="pAddressState">
                                <Form.Label className="fw-bold ">Postal Code</Form.Label>
                                <Form.Control required defaultValue={photographerInfo.photographer_address_postal_code} type="number" placeholder="Type here" onChange={(e) => setNewData((oldSearch) => ({ ...oldSearch, "photographer_address_postal_code": e.target.value }))} />
                                {postalCodeError}
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="vABN">
                                <Form.Label className="fw-bold ">ABN</Form.Label>
                                <Form.Control type="text" required placeholder="Type here" defaultValue={photographerInfo.photographer_abn} onChange={(e) => setNewData((oldSearch) => ({ ...oldSearch, "photographer_abn": e.target.value.replace(/\s/g, "") }))} />
                                {abnError}

                            </Form.Group>

                            <Form.Group className="mb-3" controlId="formBasicGoals">
                                <Form.Label className="fw-bold">Are you enrolled for GST?</Form.Label>
                                <Form.Select
                                    aria-label="Default select example"
                                    onChange={(e) => setNewData((oldSearch) => ({ ...oldSearch, "photographer_is_gst": e.target.value }))}
                                    required
                                    defaultValue={photographerInfo.photographer_is_gst != null ? photographerInfo.photographer_is_gst : ""}
                                >
                                    <option value="">Select option</option>
                                    <option value="1">Yes</option>
                                    <option value="0">No</option>
                                </Form.Select>
                                <div id="emailHelp" className="form-text">You are responsible to update this field before any withdrawal if the information changes.</div>


                            </Form.Group>


                            {isStripeVerified == "unverified" ? (
                                <Form.Group className="mb-3" controlId="formBasicGoals">
                                    <Form.Label className="fw-bold">Identity document</Form.Label>
                                    <Form.Select
                                        aria-label="Default select example"
                                        onChange={(e) => setFileUploader(e.target.value)}
                                        required

                                    >
                                        <option value="0"></option>
                                        <option value="1">Passport</option>
                                        <option value="2">Driver License</option>
                                        <option value="3">Photo Card</option>
                                        <option value="4">New South Wales Driving Instructor License</option>
                                        <option value="5">Tasmanian Goverment Personal Information Card</option>
                                        <option value="6">ImmiCard</option>
                                        <option value="7">Proof of Age card</option>
                                        <option value="8">Australian Defence Force (AFD) identification card</option>


                                    </Form.Select>
                                    {uploadFileDocument}

                                </Form.Group>
                            ) : ""}






                            {/* </Form> */}
                        </Col>
                        <Col lg={6}>
                            <h5 className="text-blue mb-3"><b>Your bank details</b></h5>


                            <Form.Group className="mb-3" controlId="vAccountName">
                                <Form.Label className="fw-bold ">Account name</Form.Label>
                                <Form.Control required type="text" placeholder="Type here" defaultValue={photographerInfo.photographer_bank_account_name} onChange={(e) => setNewData((oldSearch) => ({ ...oldSearch, "photographer_bank_account_name": e.target.value }))} />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="vBSB">
                                <Form.Label className="fw-bold ">BSB</Form.Label>
                                <Form.Control required type="text" placeholder="Type here" defaultValue={photographerInfo.photographer_bank_account_bsb} onChange={(e) => setNewData((oldSearch) => ({ ...oldSearch, "photographer_bank_account_bsb": e.target.value }))} />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="vAccountNumber">
                                <Form.Label className="fw-bold ">Account number</Form.Label>
                                <Form.Control required type="text" placeholder="Type here" defaultValue={photographerInfo.photographer_bank_account_number} onChange={(e) => setNewData((oldSearch) => ({ ...oldSearch, "photographer_bank_account_number": e.target.value }))} />
                            </Form.Group>
                            {bankAccountStatus === "verification_failed" || bankAccountStatus === "errored" ? (
                                <p className='mt-2 p_xsmall text-danger text_inline'>Inaccurate bank details provided. Update it in settings to continue receiving payouts</p>

                            ) : ""}
                            {bankError}




                        </Col>
                        <div>
                            <Button className='btn_primary_blue btn_register float-end mt-0' type="submit" disabled={disabledButtonSave}>Save changes</Button>

                        </div>
                    </Row>
                </Form>

            ) : (
                <div className="text-center">
                    <Spinner animation="border" role="status" className="mt-5 ">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                </div>
            )}


            <div className="text-center spinner_screen" >
                <Spinner animation="border" role="status" className="mt-5 " hidden={!showLoadingSubmit}>
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
            </div>
        </div>
    )
}


function SectionPhotographer({ photographerInfo, userInfo, setCanLeave, refreshPhotographerInfo, refreshUser }) {

    const [newData, setNewData] = useState({})
    const [photographerTypeShots, setPhotographerTypeShots] = useState([]);
    const [photographerCateogoryShot, setPhotographerCateogoryShot] = useState([]);
    const [urlError, setURLERRor] = useState(<TextError />)
    const [btandNameError, setBrandNameError] = useState(<BrandNameError />)

    const [accountDetails, setAccountDetails] = useState(SessionDetails())
    const [userUniqueId, setUserUniqueId] = useState()
    const [userUniqueIdExists, setUserUniqueIdExists] = useState()
    // const [tags, setTags] = React.useState([]);
    const [validated, setValidated] = useState(false);

    const [categoriesError, setCategoriesError] = useState(<Error />)
    const [photosTypeError, setPhotosTypeError] = useState(<Error />)
    const [spotsError, setSpotsError] = useState(<Error />)
    let [isBlocking, setIsBlocking] = useState(false);



    useEffect(() => {
        if (Object.keys(newData).length > 0) {
            // if(Object.keys(newData).length == 1){
            // if(Object.keys(newData).includes("photographer_main_spots") && photographerInfo != null && photographerInfo.photographer_main_spots != null){
            // if(compareArray(JSON.parse(newData.photographer_main_spots), JSON.parse(photographerInfo.photographer_main_spots))){
            setCanLeave(false)
            // return

            // }
            // }
            // }else{
            //     setCanLeave(false)
            //     // console.log("entra aqui tambien")

            // }
        }

    }, [newData])

    useEffect(() => {
        setPhotographerTypeShots(photographerInfo != null && photographerInfo.photographer_products_type != null ? JSON.parse(photographerInfo.photographer_products_type) : [])
        setPhotographerCateogoryShot(photographerInfo != null && photographerInfo.photographer_products_category != null ? JSON.parse(photographerInfo.photographer_products_category) : [])
    }, [photographerInfo])


    const handlePhotosCB = (event) => {
        if (event.target.checked) {
            photographerTypeShots.push(event.target.value)
        } else {
            const index = photographerTypeShots.indexOf("Photos");

            photographerTypeShots.splice(index, 1);
        }
        setNewData((oldSearch) => ({ ...oldSearch, "photographer_products_type": JSON.stringify(photographerTypeShots) }))
    }

    const handleVideosCB = (event) => {
        if (event.target.checked) {
            photographerTypeShots.push(event.target.value)
        } else {
            const index = photographerTypeShots.indexOf("Videos");

            photographerTypeShots.splice(index, 1);
        }
        setNewData((oldSearch) => ({ ...oldSearch, "photographer_products_type": JSON.stringify(photographerTypeShots) }))
    }

    const handleDroneCB = (event) => {
        if (event.target.checked) {
            photographerCateogoryShot.push(event.target.value)
        } else {
            const index = photographerCateogoryShot.indexOf("Drone");

            photographerCateogoryShot.splice(index, 1);
        }
        setNewData((oldSearch) => ({ ...oldSearch, "photographer_products_category": JSON.stringify(photographerCateogoryShot) }))
    }

    const handleWCCB = (event) => {
        if (event.target.checked) {
            photographerCateogoryShot.push(event.target.value)
        } else {
            const index = photographerCateogoryShot.indexOf("Camera Water");

            photographerCateogoryShot.splice(index, 1);
        }
        setNewData((oldSearch) => ({ ...oldSearch, "photographer_products_category": JSON.stringify(photographerCateogoryShot) }))
    }


    const handleLandCB = (event) => {
        if (event.target.checked) {
            photographerCateogoryShot.push(event.target.value)
        } else {
            const index = photographerCateogoryShot.indexOf("Land");

            photographerCateogoryShot.splice(index, 1);
        }
        setNewData((oldSearch) => ({ ...oldSearch, "photographer_products_category": JSON.stringify(photographerCateogoryShot) }))
    }

    const validateData = async (event) => {
        const form = event.currentTarget;
        event.preventDefault();

        if (form.checkValidity() === false) {
            event.stopPropagation();

        } else {


            if (userUniqueId != null) {

                if (userUniqueId == userInfo.user_unique_id) {
                    setUserUniqueId(null)
                    return
                }

                if (!validateUserUniqueId()) {
                    setURLERRor(<URLNameError content={"Please, set a correct unique URL name."} />)
                    return
                }

                const exists = await checkUserUniqueId()
                if (userUniqueIdExists) {
                    setURLERRor(<URLNameError content={"This name is already taken, please try a new one."} />)
                    return
                }
            } else {
                setURLERRor(<URLNameError />)

            }

            if (Object.keys(newData).includes("photographer_business_name") && newData.photographer_business_name == photographerInfo.photographer_business_name) {
                delete newData.photographer_business_name
            }


            if (Object.keys(newData).includes("photographer_camera_details") && newData.photographer_camera_details == photographerInfo.photographer_camera_details) {
                delete newData.photographer_camera_details
            }

            if (Object.keys(newData).includes("photographer_main_spots") && newData.photographer_main_spots == photographerInfo.photographer_main_spots) {
                delete newData.photographer_main_spots
            }

            if (Object.keys(newData).includes("photographer_products_type") && compareArray(JSON.parse(newData.photographer_products_type), JSON.parse(photographerInfo.photographer_products_type))) {
                delete newData.photographer_products_type
            }

            if (Object.keys(newData).includes("photographer_products_category") && compareArray(JSON.parse(newData.photographer_products_category), JSON.parse(photographerInfo.photographer_products_category))) {
                delete newData.photographer_products_category
            }

            updateInfo()


        }
        setValidated(true);

    };

    function compareArray(a, b) {
        if (a.length !== b.length) return false

        a.sort()
        b.sort()

        for (let i = 0; i < a.length; i++) {
            if (a[i] !== b[i]) return false
        }

        return true
    }

    async function validateData_old() {
        var is_valid = true;

        if (userUniqueId != null) {

            if (!validateUserUniqueId()) {
                setURLERRor(<URLNameError content={"Please, set a correct unique URL name."} />)
                is_valid = false
            }

            const exists = await checkUserUniqueId()
            if (userUniqueIdExists) {
                setURLERRor(<URLNameError content={"This name is already taken, please try a new one."} />)
                is_valid = false
            }
        } else {
            setURLERRor(<URLNameError />)

        }



        if (newData.photographer_business_name != null && newData.photographer_business_name == "") {
            setBrandNameError(<BrandNameError content={"Profile name / Photography brand cannot be empty"} />)
            is_valid = false

        } else {
            // console.log("asd")
            setBrandNameError(<BrandNameError />)

        }



        if (is_valid) {
            var obj = newData
            if (obj.photographer_default_price_photo) {
                obj.photographer_default_price_photo = parseInt(parseFloat(obj.photographer_default_price_photo) * 100)

            }
            if (obj.photographer_default_price_video) {
                obj.photographer_default_price_video = parseInt(parseFloat(obj.photographer_default_price_video) * 100)

            }

            setNewData(obj)
            // console.log(obj)
            updateInfo()
        } else {
            window.scrollTo(0, 0)


        }

    }



    function validateUserUniqueId() {

        var regex = /[^a-zA-Z0-9-_.]/;
        return !regex.test(userUniqueId.user_unique_id);
    }

    async function checkUserUniqueId() {
        fetch(`${url_server}public/users/${userUniqueId.user_unique_id}/exists`, {
            method: 'GET',
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
                'x-access-token': accountDetails.token
            },
        })
            .then((response) => response.json())

            .then((data) => {

                if (data.response.length > 0) {
                    setUserUniqueIdExists(true)
                } else {
                    setUserUniqueIdExists(false)
                }

            })
            .catch((err) => {
                // console.log(err)
            });
    }

    async function updateInfo() {

        try {

            var array_fetch = []
            if (newData != null && Object.keys(newData).length > 0) {
                array_fetch.push(
                    fetch(`${url_server}private/photographers/${photographerInfo.photographer_id}`, {
                        method: 'PUT',
                        body: JSON.stringify(newData),
                        headers: {
                            'Content-type': 'application/json; charset=UTF-8',
                            'x-access-token': accountDetails.token
                        },
                    })
                )
            }

            if (userUniqueId != null && Object.keys(userUniqueId).length > 0) {
                array_fetch.push(
                    fetch(`${url_server}private/users/${accountDetails.user_id}`, {
                        method: 'PUT',
                        body: JSON.stringify(userUniqueId),
                        headers: {
                            'Content-type': 'application/json; charset=UTF-8',
                            'x-access-token': accountDetails.token,
                        },
                    })

                )
            }

            const res = await Promise.all(array_fetch);

            var error = ""
            const data = await Promise.all(res.map(r => r.json().then(async (data_obj) => {
                if (data_obj.error) {
                    // console.log(data_obj)
                    if (Object.keys(data.errorDescription).includes("raw")) {
                        error = data.errorDescription.raw.message


                    } else {
                        error = "Soemthing went wrong"
                    }
                    return
                }
            })))

            if (userUniqueId != null) {
                localStorage.setItem('user_unique_id', userUniqueId.user_unique_id);
                window.dispatchEvent(new Event("session"));
                // save new watermark
                savePhotographerWatermark()

            }

            if (error != "") {
                alert(error)
            } else {
                setNewData({})
                refreshPhotographerInfo()
                refreshUser()
                window.scrollTo(0, 0)
                setCanLeave(true)
                alert("Information saved")

            }


        } catch (err) {
            // console.log(err)
            alert("Something went wrong")
        }

    }

    async function savePhotographerWatermark() {

        // Create an object of formData
        const formData = new FormData();
        var dataWatermark = { "extension": "png", "path": `photographer-watermark-${photographerInfo.photographer_id}` }

        //      save product image compressed

        const blob = await generateWatermarkText(userUniqueId.user_unique_id);
        const signed_url_response = await getSignedURL(dataWatermark)


        if (signed_url_response != null) {

            var uploaded = await uploadFile(signed_url_response.signedUrl, blob)
            if (uploaded) {
                var pwu = { photographer_watermark_url: signed_url_response.fileUrl }
                // console.log(pwu)
                await saveMedia(pwu)

            }
        }




    };

    async function getSignedURL(data) {
        var response;

        await fetch(url_server + "private/signedurl", {
            method: 'POST',
            body: JSON.stringify(
                // Add parameters here
                data
            ),
            mimeType: "multipart/form-data",
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
                "x-access-token": accountDetails.token,
            },
        })
            .then((response) => response.json())

            .then((data) => {
                // GO TO SUCCESS PAGE
                // signed_url = data.response.signedUrl;
                // file_url = data.response.file_url;
                response = data.response

            })
            .catch((err) => {
                // console.log(err)
                alert("Something went wrong. Please check your connection and try again.");
            });

        return response;

    }

    async function uploadFile(signed_url, compressedFile) {


        var uploaded = false;
        await axios.put(signed_url, compressedFile, {
            timeout: 0,
            mimeType: "multipart/form-data",
            headers: {
                "Content-Type": "image/png",
                "x-amz-acl": "public-read",
            },

            onUploadProgress: (progressEvent) => {
                if (progressEvent.bytes) {
                    var progress = Math.round((progressEvent.loaded / progressEvent.total) * 100)

                    if (progress == 100) {
                        uploaded = true;
                        // console.log("uploaded")
                    }

                }
            },
        }).then((response) => {
            // // console.log("also uploaded")

        })
            .catch((error) => {
                // Error
                if (error.response) {

                    // console.log("Error")
                } else if (error.request) {
                    // console.log("Error")
                } else {
                    // Something happened in setting up the request that triggered an Error
                    // console.log('Error', error.message);
                    // console.log("Error")
                }
            });

        return uploaded;
    }

    async function saveMedia(data) {
        delete data.highlight
        await fetch(`${url_server}private/photographers/${photographerInfo.photographer_id}`, {
            method: 'PUT',
            body: JSON.stringify(data),

            // TODO: get access token from local storage instead
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
                "x-access-token": accountDetails.token,
            },
        })
            .then((response) => response.json())

            .then((data) => {
                // GO TO SUCCESS PAGE

            })
            .catch((err) => {
                // console.log(err)
                // alert("Something went wrong. Please check your connection and try again.");
            });
    }



    return (
        <div className="mt-4">

            {photographerInfo != null && userInfo != null ? (
                <Row className="gx-5 mb-5">

                    <h5 className="text-blue mb-3"><b>Public profile</b></h5>
                    <Form onSubmit={validateData}>
                        <Col lg={6}>
                            <Form.Group className="mb-3" >
                                <Form.Label className="fw-bold ">Profile name / Photography brand</Form.Label>
                                <Form.Control required type="text" placeholder="Type here" defaultValue={photographerInfo.photographer_business_name} onChange={(e) => setNewData((oldSearch) => ({ ...oldSearch, "photographer_business_name": e.target.value }))} />
                                {/* <div id="emailHelp" className="form-text">Optional. This field is shown on your profile. If blank, we will use your name.</div> */}
                                {btandNameError}
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicBrand">
                                <Form.Label className="fw-bold ">Unique URL</Form.Label>
                                <InputGroup >

                                    <InputGroup.Text className=' ' id="inputGroupPrepend">mysurfshot.com.au/</InputGroup.Text>
                                    <Form.Control required defaultValue={userInfo.user_unique_id} type="text" placeholder="Type here" onChange={(e) => setUserUniqueId((oldSearch) => ({ ...oldSearch, "user_unique_id": e.target.value }))} />

                                </InputGroup>
                                <div id="emailHelp" className="form-text">You only can use letters, numbers, "-", "_" and ".". We recommend being the same as to your instagram.</div>
                                {urlError}
                            </Form.Group>

                            <Form.Group className="mb-3" >
                                <Form.Label className="fw-bold ">Camera used</Form.Label>
                                <Form.Control type="text" placeholder="Type here" defaultValue={photographerInfo.photographer_camera_details} onChange={(e) => setNewData((oldSearch) => ({ ...oldSearch, "photographer_camera_details": e.target.value }))} />
                            </Form.Group>


                        </Col>
                        <div>
                            <Button className='btn_primary_blue btn_register float-end' type="submit">Save changes</Button>

                        </div>
                    </Form>

                </Row>
            ) : (
                <div className="text-center">
                    <Spinner animation="border" role="status" className="mt-5 ">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                </div>
            )}


        </div>
    )

}

function DefaultPricesDiscounts({ photographerInfo, setCanLeave, refreshPhotographerInfo }) {
    const [newData, setNewData] = useState({})
    const [accountDetails, setAccountDetails] = useState(SessionDetails())
    const [discounts, setDiscounts] = useState([])
    const [discountsModified, setDiscountsModified] = useState(false)

    useEffect(() => {
        if (Object.keys(newData).length > 0) {
            setCanLeave(false)
        }
    }, [newData])


    function validateData() {
        var obj = {}
        if (newData.photographer_default_price_photo) {
            obj.photographer_default_price_photo = parseInt(parseFloat(newData.photographer_default_price_photo) * 100)

        }
        if (newData.photographer_default_price_video) {
            obj.photographer_default_price_video = parseInt(parseFloat(newData.photographer_default_price_video) * 100)

        }

        updateInfo(obj)

        if (discountsModified == true) {
            if (validateDiscounts) {
                var new_discounts = [...discounts]
                for (var i = 0; i < new_discounts.length; i++) {
                    new_discounts[i].photographer_id = photographerInfo.photographer_id
                }
                deletePhotographerDefaultDiscounts(new_discounts)
            }
        }
    }

    useEffect(() => {
        getPhotographerDefaultDiscounts();
    }, [])

    async function getPhotographerDefaultDiscounts() {
        fetch(`${url_server}private/photographers/${accountDetails.photographer_id}/discounts`, {
            method: 'GET',
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
                'x-access-token': accountDetails.token
            },
        })
            .then((response) => response.json())

            .then((data) => {

                setDiscounts(data.response)

            })
            .catch((err) => {
                // console.log(err)
            });
    }

    function validateDiscounts() {
        if (discounts.length > 0) {

            for (var i = 0; i < discounts.length; i++) {
                if (discounts[i].discount_quantity == "" || discounts[i].discount_percentage == "" || isNaN(discounts[i].discount_percentage)) {
                    alert("Please set all discounts")
                    return false;
                }

                if (parseFloat(discounts[i].discount_percentage) > 0.99 || parseFloat(discounts[i].discount_percentage) < 0.01) {
                    alert("Percentage needs to be between 1% and 99%")

                    return false;
                }

                if (parseFloat(discounts[i].discount_quantity) < 1) {
                    alert("Products quantity needs to be bigger than 1")

                    return false;
                }
            }
        }
        return true;
    }

    async function deletePhotographerDefaultDiscounts(new_discounts) {
        fetch(`${url_server}private/photographers-discounts/${photographerInfo.photographer_id}`, {
            method: 'PUT',
            body: JSON.stringify({ discount_active: 0 }),
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
                'x-access-token': accountDetails.token
            },
        })
            .then((response) => response.json())

            .then((data) => {
                if (data.error) {
                    alert("Something went wrong")
                } else {
                    sendPhotographerDefaultDiscounts(new_discounts)

                }

            })
            .catch((err) => {
                // console.log(err)
            });
    }

    async function sendPhotographerDefaultDiscounts(new_discounts) {
        fetch(url_server + "private/photographers-discounts", {
            method: 'POST',
            body: JSON.stringify(new_discounts),
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
                'x-access-token': accountDetails.token,
            },
        })
            .then((response) => response.json())

            .then(async (data) => {
                // response = data.response
                alert("Information saved")


            })
            .catch((err) => {
                // console.log(err)
            });
    }

    function updateInfo(data) {
        if (data != null && Object.keys(data).length > 0) {
            fetch(`${url_server}private/photographers/${photographerInfo.photographer_id}`, {
                method: 'PUT',
                body: JSON.stringify(data),
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                    'x-access-token': accountDetails.token
                },
            })
                .then((response) => response.json())

                .then((data) => {
                    // response = data.response
                    // // console.log(data.response)
                    // console.log(data)
                    // window.location.reload()
                    if (data.error) {
                        alert("Something went wrong")

                    } else {
                        setCanLeave(true)
                        // window.location.reload()

                        refreshPhotographerInfo()
                        alert("Information saved")

                    }


                })
                .catch((err) => {
                    // console.log(err)
                });
        }
    }

    // discounts
    function addDiscount() {
        setDiscounts(oldArray => [...oldArray, { discount_quantity: "", discount_percentage: "" }])

    }

    const getDefaultPrice = (price_type) => {
        const parseAndCheck = (value) => {
            const parsedValue = Number(value);
            return Number.isFinite(parsedValue) ? parsedValue : 0;
        };


        if (price_type === 1) {
            let price = 0;

            if (newData.photographer_default_price_photo != null) {
                price = parseAndCheck(newData.photographer_default_price_photo);
                price = price * 100

            } else if (photographerInfo.photographer_default_price_photo != null && photographerInfo.photographer_default_price_photo != "") {
                price = parseAndCheck(photographerInfo.photographer_default_price_photo);
            }

            return (price * photographerInfo.photographer_percentage_receive) / 100;
        } else {
            let price = 0;

            if (newData.photographer_default_price_video != null) {
                price = parseAndCheck(newData.photographer_default_price_video);
                price = price * 100

            } else if (photographerInfo.photographer_default_price_video != null && photographerInfo.photographer_default_price_video != "") {
                price = parseAndCheck(photographerInfo.photographer_default_price_video);
            }

            return (price * photographerInfo.photographer_percentage_receive) / 100;
        }


    };

    const shouldDisplayPrice = (price_type) => {

        if (price_type === 1) {
            const hasChangedOnce = newData.photographer_default_price_photo !== null;

            return (
                (hasChangedOnce) ||
                (photographerInfo.photographer_default_price_photo !== null &&
                    photographerInfo.photographer_default_price_photo !== "" &&
                    !isNaN(Number(photographerInfo.photographer_default_price_photo)))
            );
        } else {
            const hasChangedOnce = newData.photographer_default_price_video !== null;

            return (
                (hasChangedOnce) ||
                (photographerInfo.photographer_default_price_video !== null &&
                    photographerInfo.photographer_default_price_video !== "" &&
                    !isNaN(Number(photographerInfo.photographer_default_price_video)))
            );
        }

    };

    return (
        <div className="mt-4">

            {photographerInfo != null ? (
                <Row className="gx-5 mb-5">

                    <Col lg={6}>

                        <h5 className="text-blue mb-3"><b>Default prices</b></h5>
                        <Form >


                            <Form.Group className="mb-3" >
                                <Form.Label className="fw-bold ">Default photo price</Form.Label>
                                <Form.Control type="number" placeholder="Type here" defaultValue={(parseFloat(photographerInfo.photographer_default_price_photo) / 100).toFixed(2)} onChange={(e) => setNewData((oldSearch) => ({ ...oldSearch, "photographer_default_price_photo": e.target.value }))} />
                                <div id="emailHelp" className="form-text">How much the surfer will pay for the photo (price before service fee deduction).</div>

                                {/* {photographerInfo.photographer_default_price_photo != "" && photographerInfo.photographer_default_price_photo != null ? (
                                    <p className="text-thin mt-1 form-text text-blue"><b>You will get </b>${(((parseFloat(photographerInfo.photographer_default_price_photo)) * photographerInfo.photographer_percentage_receive)/100).toFixed(2)}</p>

                                ) : (
                                    <></>
                                )} */}
                                {shouldDisplayPrice(1) ? (
                                    <p className="text-thin mt-1 form-text text-blue">
                                        <b>You will get </b>${getDefaultPrice(1).toFixed(2)}
                                    </p>
                                ) : (
                                    <></>
                                )}
                            </Form.Group>

                            <Form.Group className="mb-3" >
                                <Form.Label className="fw-bold ">Default video price</Form.Label>
                                <Form.Control type="number" placeholder="Type here" defaultValue={(parseFloat(photographerInfo.photographer_default_price_video) / 100).toFixed(2)} onChange={(e) => setNewData((oldSearch) => ({ ...oldSearch, "photographer_default_price_video": e.target.value }))} />
                                <div id="emailHelp" className="form-text">How much the surfer will pay for the video (price before service fee deduction).</div>
                                {/* {photographerInfo.photographer_default_price_video != "" && photographerInfo.photographer_default_price_video != null ? (
                                    <p className="text-thin mt-1 form-text text-blue"><b>You will get </b>${(((parseFloat(photographerInfo.photographer_default_price_video)) * photographerInfo.photographer_percentage_receive)/100).toFixed(2)}</p>

                                ) : (
                                    <></>
                                )} */}
                                {shouldDisplayPrice(2) ? (
                                    <p className="text-thin mt-1 form-text text-blue">
                                        <b>You will get </b>${getDefaultPrice(2).toFixed(2)}
                                    </p>
                                ) : (
                                    <></>
                                )}
                            </Form.Group>

                        </Form>
                    </Col>
                    <Col lg={6}>
                        <div className="d-flex justify-content-between align-items-center w-100">
                            <h5 className="text-blue m-0"><b>Default discounts</b></h5>
                            <Button className='btn_outlined_blue btn_register m-0' onClick={addDiscount}>Add discount</Button>

                        </div>
                        <div id="div_discounts" className="mt-3">
                            {
                                discounts.length > 0 ?
                                    discounts.map((obj, index) => {
                                        return (
                                            <SetDiscount key={`${obj.property}-${index}`} index={index} discount={obj} discounts={discounts} setDiscounts={setDiscounts} setDiscountsModified={setDiscountsModified} />
                                        )
                                    })
                                    :
                                    <p className="text_light_blue">You don't have any default discounts</p>
                            }

                        </div>

                    </Col>

                    <div>
                        <Button className='btn_primary_blue btn_register float-end' onClick={validateData}>Save changes</Button>

                    </div>
                </Row>
            ) : (
                <div className="text-center">
                    <Spinner animation="border" role="status" className="mt-5 ">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                </div>
            )}


        </div>
    )

}


function MenuSectionPhotographer({ photographerInfo, userInfo, refreshPhotographerInfo, refreshUser }) {
    const { state } = useLocation();

    const [pageContent, setPageContent] = useState(state != null && state == "payments" ? "paymentdetails" : 'photographerdetails')
    const [canLeave, setCanLeave] = useState(true)

    const pages = {
        "paymentdetails": <SectionBilling photographerInfo={photographerInfo} setCanLeave={setCanLeave} refreshPhotographerInfo={refreshPhotographerInfo} />,
        "userdetails": <SectionUser userInfo={userInfo} setCanLeave={setCanLeave} refreshUser={refreshUser} />,
        "security": <SectionSecurity userInfo={userInfo} setCanLeave={setCanLeave} refreshUser={refreshUser} />,
        "photographerdetails": <SectionPhotographer photographerInfo={photographerInfo} userInfo={userInfo} setCanLeave={setCanLeave} refreshUser={refreshUser} refreshPhotographerInfo={refreshPhotographerInfo} />,
        "defaultprices": <DefaultPricesDiscounts photographerInfo={photographerInfo} setCanLeave={setCanLeave} refreshPhotographerInfo={refreshPhotographerInfo} />
    }

    function handleUnload(event) {
        if (canLeave == false) {
            event.returnValue = true;

        }
    }

    useEffect(() => {
        window.addEventListener('beforeunload', handleUnload)
        return () => window.removeEventListener('beforeunload', handleUnload)

    }, [canLeave])

    const changePage = (e, val) => {

        if (canLeave) {
            setPageContent(val)
        } else {

            if (window.confirm("Changes that you made may not be saved. ") == true) {
                setCanLeave(true)
                setPageContent(val)
            } else {
                return
            }
        }

    }


    return (
        <div>
            <div>
                <Nav variant="tabs" activeKey={pageContent} defaultActiveKey={state != null && state == "payments" ? "paymentdetails" : pageContent}>

                    <Nav.Item>
                        <Nav.Link eventKey="photographerdetails" className="personalized_menu_item" onClick={(e) => changePage(e, "photographerdetails")}>Public profile</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link eventKey="userdetails" className="personalized_menu_item" onClick={(e) => changePage(e, "userdetails")}>Private details</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link eventKey="defaultprices" className="personalized_menu_item" onClick={(e) => changePage(e, "defaultprices")}>Default Prices / Discounts</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link eventKey="paymentdetails" className="personalized_menu_item" onClick={(e) => changePage(e, "paymentdetails")}>Payment information</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link eventKey="security" className="personalized_menu_item" onClick={(e) => changePage(e, "security")}>Security</Nav.Link>
                    </Nav.Item>

                </Nav>
            </div>
            <div>
                {pages[pageContent]}
            </div>
        </div>
    )
}

function MenuSectionUser({ userInfo, refreshUser }) {
    const { state } = useLocation();

    const [pageContent, setPageContent] = useState('userdetails')
    const [canLeave, setCanLeave] = useState(true)

    const pages = {
        "userdetails": <SectionUser userInfo={userInfo} setCanLeave={setCanLeave} refreshUser={refreshUser} />,
        "security": <SectionSecurity userInfo={userInfo} setCanLeave={setCanLeave} refreshUser={refreshUser} />,

    }

    function handleUnload(event) {
        if (canLeave == false) {
            event.returnValue = true;

        }
    }

    useEffect(() => {
        window.addEventListener('beforeunload', handleUnload)
        return () => window.removeEventListener('beforeunload', handleUnload)

    }, [canLeave])

    const changePage = (e, val) => {

        if (canLeave) {
            setPageContent(val)
        } else {

            if (window.confirm("Changes that you made may not be saved. ") == true) {
                setCanLeave(true)
                setPageContent(val)
            } else {
                return
            }
        }

    }


    return (
        <div>
            <div>
                <Nav variant="tabs" activeKey={pageContent} defaultActiveKey={pageContent}>

                    <Nav.Item>
                        <Nav.Link eventKey="userdetails" className="personalized_menu_item" onClick={(e) => changePage(e, "userdetails")}>User Details</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link eventKey="security" className="personalized_menu_item" onClick={(e) => changePage(e, "security")}>Security</Nav.Link>
                    </Nav.Item>

                </Nav>
            </div>
            <div>
                {pages[pageContent]}
            </div>
        </div>
    )
}

function Settings() {
    const [photographerInfo, setPhotographerInfo] = useState()
    const [userInfo, setUserInfo] = useState()
    const [accountDetails, setAccountDetails] = useState(SessionDetails())

    useEffect(() => {
        if (accountDetails.user_default_profile == 2) {
            getPhotographerInfo()
            getUserInfo()

        } else {
            getUserInfo()
        }
    }, [])

    function getUserInfo() {
        fetch(`${url_server}private/users/${accountDetails.user_id}`, {
            method: 'GET',
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
                'x-access-token': accountDetails.token,
            },
        })
            .then((response) => response.json())

            .then(async (data) => {
                // response = data.response
                setUserInfo(data.response[0])


            })
            .catch((err) => {
                // console.log(err)
            });
    }


    function getPhotographerInfo() {
        fetch(`${url_server}private/photographers/${accountDetails.user_id}`, {
            method: 'GET',
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
                'x-access-token': accountDetails.token,
            },
        })
            .then((response) => response.json())

            .then(async (data) => {
                // response = data.response
                setPhotographerInfo(data.response[0])


            })
            .catch((err) => {
                // console.log(err)
            });

    }


    return (
        <>
            <Navigation showSearchBar={0} />
            <div className="div_albums">

                {accountDetails.user_default_profile == 2 ? (
                    <div>
                        <Container fluid>
                            {photographerInfo != null && userInfo != null ? (
                                <MenuSectionPhotographer photographerInfo={photographerInfo} userInfo={userInfo} refreshPhotographerInfo={getPhotographerInfo} refreshUser={getUserInfo} />

                            ) : ""}
                        </Container>

                    </div>
                ) : (
                    <div>
                        {userInfo != null ? (
                            // <SectionUser userInfo={userInfo} refreshUser={getUserInfo} />
                            <MenuSectionUser userInfo={userInfo} refreshUser={getUserInfo} />

                        ) : ""}
                    </div>
                )}
            </div>

        </>

    )
}

export default Settings