import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';
import Col from 'react-bootstrap/Col';
import { useState, useRef, useEffect} from "react";
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import './components.css';
import { url_server } from '../settings';
import Spinner from 'react-bootstrap/Spinner';
import { SessionDetails } from '../controllers/AccountController';
import { DeleteModal } from './modals';
import axios from 'axios';
import fileDownload from 'js-file-download'


function PhotoPurchaseCard({data, album, showModal, isSingleDownload = true, setUrlsToDownload}){

    const ic_download = require("../assets/ic_download.png");
    const ic_download_blue = require("../assets/ic_download_blue.png");

    const ic_profile = require("../assets/ic_profile_card.png")
    const ic_calendar = require("../assets/ic_calendar_card.png")
    const ic_wave = require("../assets/ic_wave_card.png")
    const ic_image = require("../assets/ic_image.png")
    const ic_video = require("../assets/ic_video.png")
    const img_album_default = require('../assets/img_album_default.jpg')
    const ic_delete = require("../assets/ic_delete.png")
    const [accountDetails, setAccountDetaills] = useState(SessionDetails())

    const [highlightImage, setHighlightImage] = useState()
    const [productsCount, setProductsCount] = useState();
    const [showModalDelete, setShowModalDelete] = useState(false)

    var dayNames = ['Sun','Mon','Tue','Wed','Thu','Fri','Sat'];
	var monthPrefixes = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];

	var start_time = new Date(data.album_datetime_start).toLocaleTimeString([], {hour12: false, hour: '2-digit', minute:'2-digit'})
	var end_time = new Date(data.album_datetime_end).toLocaleTimeString([], {hour12: false, hour: '2-digit', minute:'2-digit'})
	var day = new Date(data.album_datetime_start).getDay()
	var month = new Date(data.album_datetime_start).getMonth()
	var day_number = new Date(data.album_datetime_start).toLocaleDateString("en-au").split("/")[0]
	var year_number = new Date(data.album_datetime_start).toLocaleDateString("en-au").split("/")[2]
    
    const handleDownload = (url, filename) => {
		axios.get(url+"?image=image", {
			headers: {
				'Access-Control-Allow-Origin' : '*',
  				'Access-Control-Allow-Methods':'GET,PATCH,OPTIONS',
			},
		  responseType: 'blob',
		})
		.then((res) => {
		  fileDownload(res.data, filename)
		})
	}


    return (
            
        <Col md={3} className='mb-3 d-flex flex-row'>
            {!isSingleDownload ? (
                <Form.Check
                    className='mt-4 me-0'
                    inline
                    name="url_selected"
                    type="checkbox"
                    onChange={(e) => setUrlsToDownload(e, data.media_url)}
                />
            ): (
                ""
            )}
            
            <Card className='photo_purchase_card m-2' id={"media_id_"+data.media_id} >
                <div>
                    
                    {data.product_type_id == 1 ?(
                        <a href='#' onClick={(e) => showModal(e, data, album)}>
                            <Card.Img className='card_round square mt-3' variant="top" src={data.media_url} />
                        </a>

                    ) : (
                        <a href='#' onClick={(e) => showModal(e, data, album)}>
                            <div>
                                <video className="card_round mt-3" controls>
                                    <source  src={data.media_url} type="video/mp4"/>
                                    <source  src={data.media_url} type="video/ogg"/>
                                    <source  src={data.media_url} type="video/mov"/>
                                    Your browser does not support the video tag.
                                </video> 
                            </div>
                        </a>
                    )}
                </div>
                
                <Card.Body align="left" className='p-0'>
                    <div className='mt-4'>
                        <b><p className='text-blue mb-0'>${data.price/100}</p></b>
                        <p className='light-grey'>Expiry date: No expiry date</p>
                    </div>
                    <div className='text-center'>
                        <p className={`no-decoration ${isSingleDownload ? 'p_bold m-0 clickable' : "light-grey"}`} onClick={() => handleDownload(data.media_url, data.media_url.slice(data.media_url.lastIndexOf("/") , data.media_url.length).split('/')[1])}><img src={isSingleDownload ? ic_download_blue : ic_download} alt="location" className='pb-1 me-1'/>Download Photo</p>
                    </div>
                </Card.Body>
            </Card>
        </Col>
        
        
    )
  }
  export default PhotoPurchaseCard;