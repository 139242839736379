import React, { useEffect, useState } from 'react';
import ReactDOMServer from 'react-dom/server';



// watermark witohut text
// const canvas = document.createElement('canvas');
// const context = canvas.getContext('2d');

// async function watermarkedImage(imageUrl) {
//   return new Promise((resolve, reject) => {
//     const watermarkImage = new Image();
//     watermarkImage.crossOrigin = 'anonymous'; // Set crossOrigin to handle CORS for the watermark image
//     watermarkImage.src = require('../assets/watermark2.png');

//     const mainImage = new Image();
//     mainImage.crossOrigin = 'anonymous'; // Set crossOrigin to handle CORS for the main image
//     mainImage.src = imageUrl;

//     const loadPromises = [imageLoadPromise(mainImage), imageLoadPromise(watermarkImage)];

//     Promise.all(loadPromises)
//       .then(() => {
//         canvas.width = mainImage.width;
//         canvas.height = mainImage.height;

//         // Draw the main image on the canvas
//         context.drawImage(mainImage, 0, 0, mainImage.width, mainImage.height);

//         // Calculate watermark size to be almost the same as the main image
//         const watermarkScale = 0.75; // Adjust the scale as needed
//         const watermarkWidth = mainImage.width * watermarkScale;
//         const watermarkHeight = (watermarkImage.height / watermarkImage.width) * watermarkWidth;

//         // Calculate watermark position in the center
//         const watermarkX = (mainImage.width - watermarkWidth) / 2;
//         const watermarkY = (mainImage.height - watermarkHeight) / 2;

//         // Draw the scaled watermark image on the canvas
//         context.drawImage(watermarkImage, watermarkX, watermarkY, watermarkWidth, watermarkHeight);

//         // Create a Blob from the canvas and resolve the Promise with it
//         canvas.toBlob((blob) => {
//           resolve(blob);
//         }, 'image/jpeg', 0.9); // Adjust quality here, 0.9 is 90% quality
        
//       })
//       .catch((error) => {
//         reject(error);
//       });
//   });
// }

// function imageLoadPromise(image) {
//   return new Promise((resolve, reject) => {
//     image.onload = () => resolve();
//     image.onerror = (error) => reject(error);
//   });
// }



const canvas = document.createElement('canvas');
const context = canvas.getContext('2d');

async function watermarkedImage(imageUrl, watermark_url) {
  return new Promise((resolve, reject) => {
    const watermarkImage = new Image();
    watermarkImage.crossOrigin = 'anonymous';
    watermarkImage.src = watermark_url;

    const mainImage = new Image();
    mainImage.crossOrigin = 'anonymous';
    mainImage.src = imageUrl;

    const loadPromises = [imageLoadPromise(mainImage), imageLoadPromise(watermarkImage)];

    Promise.all(loadPromises)
      .then(() => {
        canvas.width = mainImage.width;
        canvas.height = mainImage.height;

        context.drawImage(mainImage, 0, 0, mainImage.width, mainImage.height);

        const watermarkScale = 0.85;
        const watermarkWidth = mainImage.width * watermarkScale;
        const watermarkHeight = (watermarkImage.height / watermarkImage.width) * watermarkWidth;

        const watermarkX = (mainImage.width - watermarkWidth) / 2;
        const watermarkY = ((mainImage.height - watermarkHeight) / 2);

        // Draw the watermark image onto the canvas
        context.drawImage(watermarkImage, watermarkX, watermarkY, watermarkWidth, watermarkHeight);

        // Add text watermark
        // const text = 'https://mysurfshot.com.au/' + photographer_business_name;
        // const fontSize = 24;

        // context.font = `${fontSize}px Arial`;
        // context.fillStyle = 'white';
        // context.textAlign = 'center';

        // // Calculate the position to embed the text within the watermark image
        // const textX = watermarkX + watermarkWidth / 2;
        // const textY = watermarkY + watermarkHeight + fontSize + 10;

        // context.fillText(text, textX, textY);

        // Create a Blob from the canvas and resolve the Promise with it
        canvas.toBlob((blob) => {
          resolve(blob);
        }, 'image/jpeg');
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function imageLoadPromise(image) {
  return new Promise((resolve, reject) => {
    image.onload = () => resolve();
    image.onerror = (error) => reject(error);
  });
}

async function generateWatermarkText(photographer_business_name) {
  return new Promise((resolve) => {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');

    // Load only the watermark image
    const watermarkImage = new Image();
    watermarkImage.crossOrigin = 'anonymous';
    watermarkImage.src = require('../assets/watermark2_old.png');

    watermarkImage.onload = () => {
      // Set canvas dimensions based on watermark image
      canvas.width = watermarkImage.width;
      canvas.height = watermarkImage.height + 300; // Additional space for text and margin

      // Draw the watermark image onto the canvas
      context.drawImage(watermarkImage, 0, 150, canvas.width, canvas.height - 300);

      // Add text watermark
      const text = 'www.mysurfshot.com.au/' + photographer_business_name;
      const fontSize = 20;

      context.font = `${fontSize}px Arial`;
      context.fillStyle = 'white';
      context.textAlign = 'center';

      // Calculate the position to embed the text below the watermark image with margin
      const textX = canvas.width / 2;
      const textY = canvas.height - (80); // Adjust position for spacing and margin
      context.fillText(text, textX, textY);

      context.fillStyle = '#153250';
      const secondTextY = canvas.height - (50);

      context.fillText(text, textX, secondTextY);

      // Convert canvas to Base64
      // const base64Result = canvas.toDataURL('image/png');
      canvas.toBlob((blob) => {
        resolve(blob);
      }, 'image/png');

      // resolve(base64Result);
    };
  });
}


// async function generateImageWithFooter(photographer_business_name) {
//   return new Promise((resolve) => {
//     const canvas = document.createElement('canvas');
//     const context = canvas.getContext('2d');

//     // Load only the watermark image
//     const watermarkImage = new Image();
//     watermarkImage.crossOrigin = 'anonymous';
//     watermarkImage.src = require('../assets/watermark2_old.png');

//     watermarkImage.onload = () => {
//       // Set canvas dimensions based on watermark image
//       canvas.width = watermarkImage.width;
//       canvas.height = watermarkImage.height + 150; // Additional space for footer

//       // Draw the watermark image onto the canvas
//       context.drawImage(watermarkImage, 0, 0, canvas.width, canvas.height - 150);

//       // Create a React component for the footer
//       const FooterComponent = () => (
//         <div style={{
//           position: 'absolute',
//           bottom: 0,
//           left: 0,
//           width: '100%',
//           height: '150px',
//           backgroundColor: 'rgba(0, 0, 0, 0.7)',
//           color: 'white',
//           textAlign: 'center',
//           padding: '10px'
//         }}>
//           www.mysurfshot.com.au/{photographer_business_name}
//         </div>
//       );

//       // Render the FooterComponent into a virtual DOM element
//       const footerDiv = document.createElement('div');
//       ReactDOMServer.render(<FooterComponent />, footerDiv);

//       // Append the virtual DOM element to the document body
//       document.body.appendChild(footerDiv);

//       // Convert canvas to Blob
//       canvas.toBlob((blob) => {
//         // Clean up: remove the virtual DOM element from the document
//         ReactDOMServer.unmountComponentAtNode(footerDiv);
//         document.body.removeChild(footerDiv);

//         resolve(blob);
//       }, 'image/png');
//     };
//   });
// }

export default watermarkedImage;
export {generateWatermarkText}
