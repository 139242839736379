import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown'
import InputGroup from 'react-bootstrap/InputGroup';
// import $ from 'jquery';
import React, { Component, useState } from 'react';
import './components.css';


// TODO: Remove, only used in old landing page
function FooterSocialNetworksIconsComplete() {
    const icon_facebook = require("../assets/ic_facebook.png")
    const icon_instagram = require("../assets/ic_instagram.png")

    return (
        <div className='footer_complete'>
            <div className='text-center'>
                <h4 >Official launch this week! </h4>
                <ul className='nav_footer'>
                    <li>
                        <a className="nav-link" href="https://www.facebook.com/mysurfshot.au">
                            <img className='icon' src={icon_facebook} alt="Facebook"/>
                        </a>
                    </li>
                    <li>
                        <a className="nav-link" href="https://www.instagram.com/mysurfshot.au/">
                            <img className='icon' src={icon_instagram} alt="Instagram"/>

                        </a>

                    </li>
                    
                </ul>
                
            </div>
           
        </div>
            
    );
}

// TODO: DEPRECATE AND USE FooterSocialNetworksIcons with 
function FooterSocialNetworksIconsLight() {
    const icon_facebook = require("../assets/ic_facebook_light.png")
    const icon_instagram = require("../assets/ic_insta_light.png")

    return (
        <ul className='nav_footer'>
            <li>
                <a className="nav-link" href="https://www.facebook.com/mysurfshot.au">
                    <img className='icon' src={icon_facebook} alt="Facebook"/>
                </a>
            </li>
            <li>
                <a className="nav-link" href="https://www.instagram.com/mysurfshot.au/">
                    <img className='icon' src={icon_instagram} alt="Instagram"/>

                </a>

            </li>
            
        </ul>
    );
}

function FooterSocialNetworksIcons({isLight = false}) {
    const icon_facebook = (isLight ? require("../assets/ic_facebook_light.png") : require("../assets/ic_facebook.png"))
    const icon_instagram = (isLight ? require("../assets/ic_insta_light.png") : require("../assets/ic_instagram.png"))

    return (
        <div className='text-center'>
            <ul className='nav_footer'>
                <li>
                    <a className="nav-link" href="https://www.facebook.com/mysurfshot.au">
                        <img className='icon' src={icon_facebook} alt="Facebook"/>
                    </a>
                </li>
                <li>
                    <a className="nav-link" href="https://www.instagram.com/mysurfshot.au/">
                        <img className='icon' src={icon_instagram} alt="Instagram"/>

                    </a>

                </li>
            </ul>
        </div>
    );
}

// TODO: Deprecate only used in landing page
function FooterLandingPage (){

    return (
        <footer className="d-flex flex-wrap justify-content-between align-items-center py-3 my-4 border-top">

            <a href="/" class="col-md-4 d-flex align-items-center justify-content-center mb-3 mb-md-0 me-md-auto link-dark text-decoration-none">
                Facebook
            {/* <svg class="bi me-2" width="40" height="32"><use xlink:href="#bootstrap"/></svg> */}
            </a>

        </footer>
    );
}


function Footer(){

    var image_wave = require("../assets/logo_white.png");
    var logo_writen = require("../assets/logo_writen_vertical_white.png")
    
    return(
        <footer className='regular-footer mt-5 fixed="bottom"'>
            <Container fluid className='p-5'>
                <Row className='align-middle align-items-center text-center' >
                    <Col md={4}>
                            <img className='img_footer' src={image_wave} alt="Wave"/>
                            <img className='img_footer' src={logo_writen} alt="Logo Writen"/>
                    </Col>
                    
                    <Col md={1} className='link_footer mt-3 text-center'>
                        <a href="/contact-us" >Contact us</a>
                    </Col>

                    <Col md={1} className='link_footer mt-3 text-center'>
                        <a href="/photographers" >Sell your shots</a>
                    </Col>

                    <Col md={1} className='link_footer mt-3 text-center'>
                        <a href="/privacy-policy" >Privacy Policy</a>
                    </Col>

                    <Col md={1} className='link_footer mt-3 text-center'>
                        <a href="/terms-and-conditions" >Terms of Use</a>
                    </Col>

                    <Col md={4} className=' text-center'>
                        <p className='text_footer'>Follow Us</p>
                        <FooterSocialNetworksIcons isLight={true} />
                    </Col>
                   
                </Row>
            </Container>

        </footer>
    );
}

export default FooterLandingPage;
export {FooterSocialNetworksIcons, FooterSocialNetworksIconsLight, FooterSocialNetworksIconsComplete, Footer}