import { SessionDetails } from "../controllers/AccountController"
import Navigation from "../components/Navigation"
import { Link, useParams, useLocation } from 'react-router-dom';

import Container from 'react-bootstrap/esm/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { Footer } from '../components/Footer';
import { GoogleMap, Marker, useLoadScript } from "@react-google-maps/api";
import { useMemo, useState, useEffect, Component } from "react";
import { google_maps_api_key, url_server } from '../settings';
import Card from 'react-bootstrap/Card';
import Nav from 'react-bootstrap/Nav';
import Form from 'react-bootstrap/Form';
import DragDropFile from "../components/handlers/DragDropFile";
import Compressor from 'compressorjs';
import axios from 'axios';
import SetDiscount from "../components/sections/Discounts";
import Modal from 'react-bootstrap/Modal';
import ProgressBar from 'react-bootstrap/ProgressBar';
import InputGroup from 'react-bootstrap/InputGroup';
import Spinner from 'react-bootstrap/Spinner';
import { ModalMovementDetails } from "../components/modals";
import { ReportMovements } from "../components/Reports";
import { EOFYStatement } from "../components/blocks/EOFYStatement";
import fileDownload from 'js-file-download'


function LoginError({content}){
    return(
        <Form.Text className="text-danger">
          {content}
        </Form.Text>
    );
}

function SuccessFulModal({show}){
    const [userDetails, setUserDetails] = useState(SessionDetails())
    const img_tick = require('../assets/img_tick.png')

    function handleClose(){
        window.location.reload()
    }

    return (
        <>

        <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
        >
            <Modal.Header closeButton>
            <Modal.Title>Withdrawal made successfully</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="text-center mb-3">
                    <img src={img_tick} alt="success"/>

                </div>
                <p className="text-center">Your money is in their way to your bank account and can take up to 2 days</p>
            </Modal.Body>
            <Modal.Footer>
            <Button className='btn_primary_blue btn_register w-100'  onClick={handleClose}>Close</Button>

            </Modal.Footer>
        </Modal>
        </>
    );
}

function SectionEOY({info}){
    const [accountDetails, setAccountDetails] = useState(SessionDetails())

    function onClickDownload(){

        axios.get(`${url_server}private/photographers/eoy/${info.photographer_id}?pdf=pdf`, {
            headers: {
                'Access-Control-Allow-Origin' : '*',
                'Access-Control-Allow-Methods':'GET,PATCH,OPTIONS',
                'x-access-token': accountDetails.token
            },
            responseType: 'blob'
        })
        .then((res) => {
            fileDownload(res.data, "statement-2023-2024.pdf")
        })
    }

    return(
        <>
        {info != null ? (
            <div className="mt-4">
                <Row className="gx-5">
                    <Col lg={6}>
                        <h5 className="text-blue mb-3"><b>EOFY Statements</b></h5>
                        <div className="balance_square p-4 mt-3">
                            <EOFYStatement photographerId={info.photographer_id} onClickDownload={onClickDownload} />
                        </div>
                    </Col>
                </Row>
            </div>
        ):("")}
        </>
    )
}

function SectionBalance({info}){
    const ic_info = require('../assets/ic_info.png')
    const ic_info_blue = require('../assets/ic_info_blue.png')
    const img_wave = require('../assets/logo_green.png')
    const [accountDetails, setAccountDetails] = useState(SessionDetails())
    const [balance, setBalance] = useState()
    const [availableBlanace, setAvalableBalance] = useState()
    const [movements, setMovements] = useState()
    // const [canTransfer, setCanTransfer] = useState(false)
    const [canPayout, setCanPayout] = useState(false)
    const [showSuccessfullModal, setShowSuccessfullModal] = useState(false)
    // const [showModalMovementDetails, setShowModalMovementDetails] = useState(false)
    const [selectedPaymentId, setSelectedPaymentId] = useState()
    // const [selectedMovement, setSelectedMovement] = useState()
    const [bankAccountStatus, setBankAccountStatus] = useState()


    useEffect(() =>{
        if(info != null){
            getAccountDetails()
            getBalance()
        }
    }, [info])

    

    function getAccountDetails(){
        
        fetch(`${url_server}private/photographers/vendor/account/${info.photographer_stripe_account}`, {
            method: 'GET',
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
                'x-access-token': accountDetails.token
            },
            })
            .then((response) => response.json())
            
            .then( (data) => {
                if(data.error){
                    alert("something went wrong")
                }else{
                    setBankAccountStatus(data.response.external_accounts.data[0].status)
                    setCanPayout(data.response.payouts_enabled)
                }

               
    
            })
            .catch((err) => {
                // console.log(err)
            });
    }

    function getBalance(){

        fetch(`${url_server}private/movements/${info.photographer_id}/balance`, {
            method: 'GET',
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
                'x-access-token': accountDetails.token
            },
            })
            .then((response) => response.json())
            
            .then( (data) => {
                setAvalableBalance(data.response[0].available)
    
            })
            .catch((err) => {

            });

        fetch(`${url_server}private/movements/${info.photographer_id}/total_balance`, {
            method: 'GET',
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
                'x-access-token': accountDetails.token
            },
            })
            .then((response) => response.json())
            
            .then( (data) => {
                setBalance(data.response[0].available)
            })
            .catch((err) => {
                // console.log(err)
            });
        
    
    }

    function payoutBalance(){
        // console.log("asd")
        fetch(`${url_server}private/photographers/vendor/account/payout`, {
            method: 'POST',
            body: JSON.stringify({account_id: info.photographer_stripe_account, amount: availableBlanace}),
            // body: JSON.stringify({account_id: info.photographer_stripe_account, amount: 10}),

            headers: {
                'Content-type': 'application/json; charset=UTF-8',
                'x-access-token': accountDetails.token
            },
            })
            .then((response) => response.json())
            
            .then( (data) => {
                // response = data.response
                // // console.log(data.response)
                console.log(data)
                if(data.error){
                    alert(data.errorDescription.raw.message)
                }else{
                    setShowSuccessfullModal(true)
                }
               

            })
            .catch((err) => {
                // console.log(err)
            });
    }


    return(
        <>
        {info != null ? (
            <div className="mt-4">
            <Row className="gx-5">
            
                <Col lg={6}>
                    <h5 className="text-blue mb-3"><b>Total funds</b></h5>
                    <div className="balance_square p-4 mt-3">
                        <p className="text-grey">Available balance</p>
                        <h1>AUD ${availableBlanace != null ? (parseFloat(availableBlanace) /100).toFixed(2) : ""}</h1>
                        <p className="m-0 mt-3 text-grey p_xsmall">Total Balance</p>
                        <p className="text-blue"><b>AUD {balance != null ? (parseFloat(balance)/100).toFixed(2) : "0.00"}</b></p>
                    </div>
                    <Button className='btn_primary_blue  w-100' disabled={canPayout && (bankAccountStatus !== "verification_failed" &&  bankAccountStatus !== "errored") && availableBlanace > 100 ? false : true } onClick={payoutBalance} >Withdraw my balance</Button>
                    
                    {bankAccountStatus === "verification_failed" || bankAccountStatus === "errored" ? (
                        <p className='mt-2 p_xsmall text-danger text_inline'>Inaccurate bank details provided. Update it in settings to continue receiving payouts.</p>

                    ) : ""}
                    
                    {!canPayout ? (
                        <div >
                            <img src={ic_info} alt="location" className='pb-1'/>
                            <p className='mt-2 p_xsmall text-grey text_inline'>In order for you to have available balance and request withdrawals, please <Link to={"/account/settings"} state={"payments"} className='ms-0 mt-2 p_xsmall text-grey text_inline'>complete your payment information</Link></p>

                        </div>
                    ) : ""}
                    
                    <div >
                        <img src={ic_info} alt="location" className='pb-1'/>
                        <p className='mt-2 p_xsmall text-grey text_inline'>Funds may take up to 3 business days after a sale to be available.</p>

                    </div>
                    
                    <div className="balance_square p-4 mt-3">
                        <img src={ic_info_blue} alt="info" />
                        <p className="text-blue p_large mt-3"><b>Payment automatically scheduled</b></p>
                        <p className="text-grey">The entire balance will be paid automatically on the last day of the month, you can withdraw your balance at any time, subject to filling in the payment information.</p>
                        
                    </div>

                </Col>
                <Col lg={6} >
                    <h5 className="text-blue mb-3"><b>Sales report</b></h5>

                    <ReportMovements info={info} />  
                </Col>
            </Row>
            <SuccessFulModal show={showSuccessfullModal} />
        </div>
        ) : (
            ""
        )}

        </>
    )
}

function SectionBilling({info}){


    const [newData, setNewData] = useState()
    const [validated, setValidated] = useState(false);
    const [loginError, setLoginError] = useState(<LoginError />)
    const [accountDetails, setAccountDetails] = useState(SessionDetails())


    const validateData = (event) => {
        const form = event.currentTarget;
        
        // event.preventDefault();
        // event.stopPropagation();

        if (newData != null) {
            
            if(Object.keys(newData).includes("photographer_abn")){
                if(validateABN(newData.photographer_abn)){

                    setLoginError(<LoginError />)
                    updateInfo()
    
                }else{
                    setLoginError(<LoginError content={"ABN is incorrect"} />)
                }
            }else{
                updateInfo()
            }

        }
        
    };

    function validateABN(value){
        if(value != null && value != ""){
            var weights = [10, 1, 3, 5, 7, 9, 11, 13, 15, 17, 19];

            if(value.length != 11){
                return false
            }
    
            var sum = 0;
    
            for(var i = 0; i < weights.length; i++){
                var digit = parseInt(value[i]) - (i==0?1:0)
                sum += digit * weights[i]
            }
    
            return (sum % 89) == 0
        }else{
            return true
        }
        

    }

    function updateInfo(){
        // console.log(newData)
        fetch(`${url_server}private/photographers/${info.photographer_id}`, {
            method: 'PUT',
            body: JSON.stringify(newData),
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
                'x-access-token': accountDetails.token
            },
            })
            .then((response) => response.json())
            
            .then( (data) => {
                // response = data.response
                // // console.log(data.response)
                // console.log(data)
                window.location.reload()

               
    
            })
            .catch((err) => {
                // console.log(err)
            });
    
    }


    return(
        <div className="mt-4">

            {info != null ? (
                <Row className="gx-5">

                    <Col lg={6}>
                                         
                        <h5 className="text-blue mb-3"><b>Your details</b></h5>
                        <Form >   
                            <Form.Group className="mb-3" controlId="vFullName">
                                <Form.Label className="fw-bold ">Business name</Form.Label>
                                <Form.Control  type="text" placeholder="Type here" defaultValue={info.photographer_business_name} disabled={info.photographer_business_name != "" && info.photographer_business_name != null ? true : false } onChange={(e) => setNewData((oldSearch) => ({...oldSearch, "photographer_business_name": e.target.value}))} />
                            </Form.Group>
                            
                            <Form.Group className="mb-3" controlId="vState">
                                <Form.Label className="fw-bold ">State</Form.Label>
                                <Form.Control type="text" placeholder="Type here" disabled={info.photographer_address_state != "" && info.photographer_address_state != null ? true : false } defaultValue={info.photographer_address_state} onChange={(e) => setNewData((oldSearch) => ({...oldSearch, "photographer_address_state": e.target.value}))} />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="vAddress">
                                <Form.Label className="fw-bold ">Address</Form.Label>
                                <Form.Control type="text" placeholder="Type here" disabled={info.photographer_address != "" && info.photographer_address != null ? true : false } defaultValue={info.photographer_address} onChange={(e) => setNewData((oldSearch) => ({...oldSearch, "photographer_address": e.target.value}))} />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="vABN">
                                <Form.Label className="fw-bold ">ABN</Form.Label>
                                <Form.Control type="text" placeholder="Type here" disabled={info.photographer_abn != "" && info.photographer_abn != null ? true : false } defaultValue={info.photographer_abn} onChange={(e) => setNewData((oldSearch) => ({...oldSearch, "photographer_abn": e.target.value}))} />
                                {loginError}

                            </Form.Group>

                        </Form>
                    </Col>
                    <Col lg={6}>
                        <h5 className="text-blue mb-3"><b>Your details</b></h5>
                        <Form>                        
                            <Form.Group className="mb-3" controlId="vAccountName">
                                <Form.Label className="fw-bold ">Account name</Form.Label>
                                <Form.Control  required  type="text" placeholder="Type here" disabled={info.photographer_bank_account_name != "" && info.photographer_bank_account_name != null ? true : false } defaultValue={info.photographer_bank_account_name} onChange={(e) => setNewData((oldSearch) => ({...oldSearch, "photographer_bank_account_name": e.target.value}))} />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="vBSB">
                                <Form.Label className="fw-bold ">BSB</Form.Label>
                                <Form.Control  required  type="text" placeholder="Type here" disabled={info.photographer_bank_account_bsb != "" && info.photographer_bank_account_bsb != null ? true : false } defaultValue={info.photographer_bank_account_bsb} onChange={(e) => setNewData((oldSearch) => ({...oldSearch, "photographer_bank_account_bsb": e.target.value}))} />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="vAccountNumber">
                                <Form.Label className="fw-bold ">Account number</Form.Label>
                                <Form.Control  required  type="text" placeholder="Type here" disabled={info.photographer_bank_account_number != "" && info.photographer_bank_account_number != null ? true : false } defaultValue={info.photographer_bank_account_number} onChange={(e) => setNewData((oldSearch) => ({...oldSearch, "photographer_bank_account_number": e.target.value}))}/>
                            </Form.Group>

                           </Form>
                            


                        
                    </Col>
                            <div>
                                <Button className='btn_primary_blue btn_register float-end' onClick={validateData} >Save changes</Button>

                            </div>
                </Row>
            ) : (
                <div className="text-center">
                    <Spinner animation="border" role="status" className="mt-5 ">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                </div>
            )}

            

        </div>
    )
}


function MenuSection({info}){
    const [pageContent, setPageContent] = useState('transactions')
    const pages = {
        "transactions": <SectionBalance info={info} />,
    }

    return (
        <div className="div_albums">
            <SectionBalance info={info} />
            <SectionEOY info={info} />
        </div>
    )
}


function BillingPage(){
    const [info, setInfo] = useState()
    const [accountDetails, setAccountDetails] = useState(SessionDetails())

    useEffect(() =>{
        getPhotographerInfo()
    }, [])

    function getPhotographerInfo(){
        fetch( `${url_server}private/photographers/${accountDetails.user_id}`, {
            method: 'GET',
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
                'x-access-token': accountDetails.token,
            },
            })
            .then((response) => response.json())
            
            .then(async (data) => {
                setInfo(data.response[0])


            })
            .catch((err) => {
            });

    }   

    return(
        <div>
            <Navigation showSearchBar={0} />
            <Container fluid >
                <MenuSection info={info} />
            </Container>

            <Footer />

        </div>
       
    )
}

export default BillingPage