import { SessionDetails } from "../controllers/AccountController"
import Navigation from "../components/Navigation"
import { Link, useParams, useLocation } from 'react-router-dom';

import Container from 'react-bootstrap/esm/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { Footer } from '../components/Footer';
import { GoogleMap, Marker, useLoadScript } from "@react-google-maps/api";
import { useMemo, useState, useEffect, Component, useId } from "react";
import { google_maps_api_key, url_server } from '../settings';
import Card from 'react-bootstrap/Card';
import Nav from 'react-bootstrap/Nav';
import Form from 'react-bootstrap/Form';
import Compressor from 'compressorjs';
import axios from 'axios';
import SetDiscount from "../components/sections/Discounts";
import Modal from 'react-bootstrap/Modal';
import ProgressBar from 'react-bootstrap/ProgressBar';
import InputGroup from 'react-bootstrap/InputGroup';

// function SectionReview({userInfo, album, products, discountsGlobal, pricesGlobal}){

//     const [productsModified, setProductsModified] = useState()
//     const ic_location = require('../assets/ic_location_card.png')
// 	const ic_calendar = require('../assets/ic_calendar_card.png')
//     const ic_empty_profile = require('../assets/ic_empty_profile.png')
//     const ic_mini_instagram = require('../assets/ic_mini_instagram.png')

//     var dayNames = ['Sun','Mon','Tue','Wed','Thu','Fri','Sat'];
// 	var monthPrefixes = ['Jan','Feb','Apr','Mar','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];

// 	var start_time = album.album_datetime_start != null ? new Date(album.album_datetime_start).toLocaleTimeString([], {hour12: false, hour: '2-digit', minute:'2-digit'}) : ""
// 	var end_time = album.album_datetime_end != null ? new Date(album.album_datetime_end).toLocaleTimeString([], {hour12: false, hour: '2-digit', minute:'2-digit'}) : ""
// 	var day = new Date(album.album_datetime_start).getDay()
// 	var month = album.album_datetime_start != null ?  new Date(album.album_datetime_start).getMonth() : ""
// 	var day_number = album.album_datetime_start != null ? new Date(album.album_datetime_start).toLocaleDateString().split("/")[0] : ""
// 	var year_number = album.album_datetime_start != null ?  new Date(album.album_datetime_start).toLocaleDateString().split("/")[2] : ""


//     function changePrice(index, value){
        
//         var newArr = [...productsModified]
//         newArr[index].price = parseInt(parseFloat(value) * 100)
//         setProductsModified(newArr)
//     }

 

//     // useEffect(() =>{

//     //     setProductsGlobal(products)
//     // }, [products])


//     return(
//         <div>
//             <div className='d-flex justify-content-between mt-5'>
//                 <div>
//                     <h4 className='mb-2'><b>{album.location_name}</b></h4>
//                     <div className='mb-2'>
//                         <img src={ic_location} alt="icon" className='pb-1' />
//                         <p className='text-thin text_inline'>{day_number} {monthPrefixes[month]} {year_number} | {start_time} {start_time != "" && end_time != "" ? "-" : ""} {end_time}</p>
//                     </div>
//                     <div className='mb-2'>
//                         <img src={ic_calendar} alt='icon' className='pb-1' />
//                         <p className='text_inline'>{album.location_city} {album.location_city != null && album.location_state != null ? "-" : ""} {album.location_state}</p>

//                     </div>

//                 </div>
//                 <div>
//                     <div className='d-flex align-items-center' id='album_photographer_details'>
//                         <img  src={ic_empty_profile} alt='empty profile' className="img_profile" />
//                         <div>
//                             <p className='m-0'><b>{userInfo.photographer_business_name}</b></p>

//                             <div>
//                                 <img src={ic_mini_instagram} alt="icon" className='me-1' />
//                                 <a href={'https://www.instagram.com/' + userInfo.photographer_instagram} className='text-thin p_xsmall m-0'>{userInfo.photographer_instagram}</a>
//                             </div>

                        
//                         </div>

//                     </div>
//                     <p className='mt-3'>{userInfo.album_categories}</p>
                    

//                 </div>
//             </div>

//             {/* {discountsGlobal.length > 0 ?(
//                 <div className="mt-3 div_discounts_review">
//                     <Row className="pt-2 pb-2">
//                         {discountsGlobal.map((obj, index) =>{
//                             return(
//                                 <Col key={index} lg={discountsGlobal.length < 3 ? 12 / discountsGlobal.length : 4 } className="text-center">
//                                     <p className="text-blue m-0"><b>{parseInt(obj.discount_percentage * 100)}% OFF</b><span className="text-grey"> - Buying {obj.discount_quantity}</span></p>
//                                 </Col>
//                             )
//                         })}
                       
//                     </Row>
//                 </div>

//              ) : ("")}  */}
            

//             <div className="mt-3 mb-5"> 
//                 <div className="d-flex justify-content-between align-items-center">
//                     <h5 className="text-blue m-0"><b>Photos</b></h5>
//                 </div>
//                 <Row className='gx-4'>

//                     {/* {products.map((obj, index) => {
//                         var file_extension = products[index].file.name.split('.');
//                         file_extension = file_extension[file_extension.length - 1];
//                         products[index].photographer_id = userInfo.photographer_id
//                         products[index].location_id = album.location_id
//                         if(!products[index].price){
//                             if(file_extension == "png" || file_extension == "jpg" || file_extension == "jpeg"){
//                                 products[index].price = pricesGlobal.photographer_default_price_photo
//                             }if(file_extension == "mp4" || file_extension == "ogg" || file_extension == "mov"){
//                                 products[index].price = pricesGlobal.photographer_default_price_video

//                             }
//                         }
                        

//                         if(file_extension == "png" || file_extension == "jpg" || file_extension == "jpeg"){
//                             products[index].product_type_id = 1

//                             return (

//                                 <Col key={index} md={2} >
//                                     <div>
//                                         <Card.Img className='card_round square mt-3' variant="top" src={obj.preview} />          

//                                         <Form.Label className="m-0 ">Price (AUD)</Form.Label>
//                                         <Form.Control data-value={index} type="number" className='form-control-md w-100 mt-1'  placeholder='Price' defaultValue={obj.price != "" && obj.price != null ? parseFloat(obj.price)/100 : ""} onChange={(e) => changePrice(index, e.target.value)}/>
    
//                                     </div>
                                    
    
//                                 </Col>
    
//                             );
//                         }else if(file_extension == "mp4" || file_extension == "ogg" || file_extension == "mov"){
//                             products[index].product_type_id = 2
//                             return(
//                                 <Col key={index} md={2} >
//                                     <div>
//                                         <video className="card_round mt-3" controls>
//                                             <source  src={obj.preview} type="video/mp4"/>
//                                             <source  src={obj.preview} type="video/ogg"/>
//                                             <source  src={obj.preview} type="video/mov"/>

//                                             Your browser does not support the video tag.
//                                         </video>

//                                         <InputGroup className="mt-2">
//                                             <InputGroup.Text>Price</InputGroup.Text>
//                                             <Form.Control data-value={index} type="number" className='form-control-md '  placeholder='Type Here' defaultValue={obj.price != "" && obj.price != null ? parseFloat(obj.price)/100 : ""} onChange={(e) => changePrice(index, e.target.value)}/>

//                                         </InputGroup>

                                      
//                                     </div>
                                    
    
//                                 </Col>
//                             )
//                         }

                        
//                     })} */}
                    
//                 </Row>

//             </div>
            
//         </div>
//     )
// }


// function SectionSetDiscounts({userInfo, album, discounts, pricesGlobal, setPricesGlobal, discountsGlobal, setDiscountsGlobal}){

//     const [prices, setPrices] = useState({photographer_default_price_photo: userInfo.photographer_default_price_photo, photographer_default_price_video: userInfo.photographer_default_price_video})
//     const [accountDetails, setAccountDetails] = useState(SessionDetails())

//     // const [discounts, setDiscounts] = useState(discountsGlobal != null ? discountsGlobal : [])


//     useEffect(() =>{
//         var obj_discounts = []
//         discounts.map((obj, index) =>{
//             if(obj.discount_quantity != "" || (obj.discount_percentage != "" && !isNaN(obj.discount_percentage))){
//                 obj_discounts.push(obj)
//             }
//         })

//         setDiscountsGlobal(obj_discounts)
//     }, [discounts])
    

//     useEffect(() =>{
//         setPricesGlobal(prices)
//     }, [prices])




//     function addDiscount(){
//         setDiscountsGlobal(oldArray => [...oldArray, {discount_quantity: "", discount_percentage: ""}])

//     }

    

//     async function sendPrices(){

//         fetch(url_server + "private/photographers/"+userInfo.photographer_id, {
//             method: 'PUT',
//             body: JSON.stringify(prices),
//             headers: {
//                 'Content-type': 'application/json; charset=UTF-8',
//                 'x-access-token': accountDetails.token,
//             },
//             })
//             .then((response) => response.json())
            
//             .then(async (data) => {
//                 // response = data.response
            
//             })
//             .catch((err) => {
//                 // console.log(err)
//             });
        
//     }

//     async function sendDiscounts(){
//         fetch(url_server + "private/albums-discounts", {
//             method: 'POST',
//             body: JSON.stringify(discounts),
//             headers: {
//                 'Content-type': 'application/json; charset=UTF-8',
//                 'x-access-token': accountDetails.token,
//             },
//             })
//             .then((response) => response.json())
            
//             .then(async (data) => {
//                 // response = data.response
            
//             })
//             .catch((err) => {
//                 // console.log(err)
//             });
//     }

    
    

//     return(
//         <div>
//             <Row>
//                 <Col lg={6} className="mt-5 mb-5">
//                     <h5 className="text-blue"><b>Prices</b></h5>
//                     <p className="text-thin text_grey">You can set up default prices for future photos and videos.</p>
//                     <Form.Group className="mb-3" controlId="formBasicBrand">
//                         <Form.Label className="fw-bold ">Price per photo (AUD)</Form.Label>
//                         <Form.Control  required className="pt-3 pb-3" type="number" placeholder="Type here" defaultValue={(parseFloat(userInfo.photographer_default_price_photo) / 100).toFixed(2)} onChange={(e) => setPrices((oldSearch) => ({...oldSearch, "photographer_default_price_photo": parseInt(parseFloat(e.target.value)*100)}))} />
//                     </Form.Group>
//                     <Form.Group className="mb-3" controlId="formBasicBrand">
//                         <Form.Label className="fw-bold ">Price per video (AUD)</Form.Label>
//                         <Form.Control  required className="pt-3 pb-3" type="number" placeholder="Type here" defaultValue={(parseFloat(userInfo.photographer_default_price_video) / 100).toFixed(2)} onChange={(e) => setPrices((oldSearch) => ({...oldSearch, "photographer_default_price_video": parseInt(parseFloat(e.target.value)*100)}))} />
//                     </Form.Group>
//                 </Col>
//                 <Col lg={6} className="mt-5 mb-5">
//                     <div className="d-flex justify-content-between align-items-center w-100">
//                         <h5 className="text-blue m-0"><b>Add discounts</b></h5>
//                         <Button className='btn_outlined_blue btn_register m-0' onClick={addDiscount}>Add discount</Button>

//                     </div>
//                     <div id="div_discounts" className="mt-3">
//                         {
                            
//                             discounts.map((obj, index) =>{
//                                 return(
//                                     <SetDiscount key={`${obj.property}-${index}`} index={index} discount={obj} discounts={discounts} setDiscounts={setDiscountsGlobal} />
//                                 )
//                             })
//                         }

//                     </div>
                
//                 </Col>
//             </Row>

//             <div className="d-flex w-100 justify-content-between mb-5">
//                 {/* <Button className='btn_outlined_blue btn_register me-auto '>Back</Button> */}

//                 <Button className='btn_primary_blue btn_register ms-auto'  >Save</Button>

//             </div>

//         </div>
        
//     )
// }


// function SectionAlbumDetails({album, albumModified, setAlbumModified, locationsGlobal, setLocationsGlobal}){
//     const [ inputDate, setInputDate] = useState('');
//     const [ inputStartTime, setInputStartTime] = useState('');
//     const [ inputEndTime, setInputEndTime] = useState('');

//     const [location, setLocation] = useState({})
//     const [cities, setCities] = useState()
//     const [suburbs, setSuburbs] = useState()
//     const [spots, setSpots] = useState()

//     const [showLoadingModal, setShowLoadingModal] = useState(false)
//     const [loadingProgress, setLoadingProgress] = useState(0)
//     const [maxArray, setMaxArray] = useState()
//     const [accountDetails, setAccountDetails] = useState(SessionDetails())
//     const [locationsAlbum, setLocationsAlbums] = useState(locationsGlobal != null ? locationsGlobal : {})

    



//     useEffect(() =>{
//         setLocationsGlobal(locationsAlbum)
//     }, [locationsAlbum])

    
//     useEffect(() =>{
     
//         getCities(locationsGlobal.location_state)
//         getSpots(locationsGlobal.location_city)
 
//     }, [])


//     const array_states = [
//         {
//             val: 'QLD',
//             text: "Queensland",

//         },
//         {
//             val: "ACT",
//             text: "Australian Capital Territory",

//         },
//         {
//             val: "NSW",
//             text: "New South Wales",

//         },
//         {
//             val: "SA",
//             text: "South Australia",

//         },
//         {
//             val: "NT",
//             text: "Northen Territory",

//         },
//         {
//             val: "VIC",
//             text: "Victoria",

//         },
//         {
//             val: "TAS",
//             text: "Tasmania",

//         },
//         {
//             val: "WA",
//             text: "Western Australia",

//         },

//     ]





//     function onChangeState(value){
//         if(value != null && value != ""){
//             setLocationsAlbums((oldSearch) => ({...oldSearch, "location_state": value}))

//             setLocationsAlbums((oldSearch) => ({...oldSearch, "location_city": ""}))
//             setLocationsAlbums((oldSearch) => ({...oldSearch, "location_name": ""}))

//             setCities([])
//             setSpots([])
//             getCities(value)
//         }else{
//             setLocationsAlbums((oldSearch) => ({...oldSearch, "location_state": ""}))
//             setLocationsAlbums((oldSearch) => ({...oldSearch, "location_name": ""}))
//             setLocationsAlbums((oldSearch) => ({...oldSearch, "location_city":""}))

//         }
//     }

//     function onChangeCity(value){
//         if(value != null && value != ""){
//             setLocationsAlbums((oldSearch) => ({...oldSearch, "location_city": value}))

//             setLocationsAlbums((oldSearch) => ({...oldSearch, "location_name": ""}))

//             setSpots([])
//             getSpots(value)
//         }else{
//             setLocationsAlbums((oldSearch) => ({...oldSearch, "location_city": ""}))

//             setLocationsAlbums((oldSearch) => ({...oldSearch, "location_name": ""}))

//         }
//     }



//     function onChangeSpot(value, name){
//         // console.log(name)
//         if(value != null && value != ""){

//             setLocationsAlbums((oldSearch) => ({...oldSearch, "location_name": name}))

//             setAlbumModified((oldSearch) => ({...oldSearch, "location_id": value}))

//         }else{
//             setLocationsAlbums((oldSearch) => ({...oldSearch, "location_name": ""}))

//         }
//     }

    

//     function onChangeDate(e){
//         setInputDate(e.target.value)
//     }

//     function onChangeStartDateTime(e){

//         setInputStartTime(e.target.value)
//     }

//     function onChangeEndDateTime(e){
//         setInputEndTime(e.target.value)
//     }

//     useEffect(() =>{
        
//         // if(inputDate != "" || inputStartTime != "" || inputEndTime != ""){
//         setAlbumModified((oldSearch) => ({...oldSearch, "album_datetime_start":  (inputDate != '' ? inputDate : new Date(albumModified.album_datetime_start).toISOString().split('T')[0]) + " " + (inputStartTime != '' ? inputStartTime : new Date(albumModified.album_datetime_start).toLocaleTimeString())}))
//         setAlbumModified((oldSearch) => ({...oldSearch, "album_datetime_end":  (inputDate != '' ? inputDate : new Date(albumModified.album_datetime_start).toISOString().split('T')[0]) + " " + (inputEndTime != '' ? inputEndTime : new Date(albumModified.album_datetime_end).toLocaleTimeString())}))
//         // }
        


//     }, [inputDate, inputStartTime, inputEndTime])



//     function getCities(state){
//         var query = `?location_state=${state}`;

//         fetch(url_server + "public/locations/cities" + query, {
//             method: 'GET',
//             headers: {
//                 'Content-type': 'application/json; charset=UTF-8',
//             },
//             })
//             .then( (response) => response.json())
            
//             .then( (data) => {

//                 setCities(data.response)

//             })
//             .catch((err) => {
//                 // console.log(err)
//             });
//     }


    

//     function getSpots(suburb){
//         var query = `?location_city=${suburb}`;

//         fetch(url_server + "public/locations/spots" + query, {
//             method: 'GET',
//             headers: {
//                 'Content-type': 'application/json; charset=UTF-8',
//             },
//             })
//             .then( (response) => response.json())
            
//             .then( (data) => {
           
//                 setSpots(data.response)

//             })
//             .catch((err) => {
//                 // console.log(err)
//             });
//     }


//     // function validateData(){
//     //     var is_valid = true
        
//     //     if(!(albumModified.location_id && albumModified.album_datetime_start && !isNaN(Date.parse(albumModified.album_datetime_start)) && albumModified.album_datetime_end && !isNaN(Date.parse(albumModified.album_datetime_end)) && albumModified.album_categories)){
//     //         is_valid = false
//     //         alert("Please enter all details.")
//     //     }else if(Date.parse(albumModified.album_datetime_start) >= Date.parse(albumModified.album_datetime_end)){
//     //         is_valid = false
//     //         alert("Start date cannot be after end date.")

            
//     //     }else if(new Date(albumModified.album_datetime_start) > new Date() || new Date(albumModified.album_datetime_end) > new Date()){
//     //         is_valid = false
//     //         alert("Dates cannot be in the future")
//     //     }else{
//     //         // console.log("all good")
//     //     }
//     // }

   

//     return(
//         <div>

//             <Col lg={5} className='mt-5 mb-5'>
//                 <h5 className="text-blue"><b>Album details</b></h5>
//                 <Form>
//                     <Form.Group className="mb-3" >
//                         <Form.Label className="fw-bold">State</Form.Label>
//                         <Form.Select 
//                             aria-label="Default select example" 
//                             onChange={(e) => onChangeState(e.target.value)}
                            
//                             required
//                             defaultValue={locationsAlbum.location_state}
//                             >
//                             <option value=""></option>
//                             {array_states.map((obj, index) => {
//                                 return(
//                                     <option key={index} value={obj.val}  >{obj.text}</option>
//                                 )
//                             })}

                            
//                         </Form.Select>
                
//                     </Form.Group>

//                     {cities != null ? (
//                         <Form.Group className="mb-3" >
//                             <Form.Label className="fw-bold">Region</Form.Label>
//                             <Form.Select 
//                                 aria-label="Default select example" 
//                                 onChange={(e) => onChangeCity(e.target.value)}
//                                 required
//                                 defaultValue={locationsAlbum.location_city}
//                                 >
//                                 <option value="" ></option>

//                                 {
                                    
//                                     cities.map((obj, index) =>{
//                                         return(
//                                             <option key={index} value={obj.location_city}>{obj.location_city}</option>

//                                         )
//                                     })
//                                 }

                            
//                             </Form.Select>
                    
//                         </Form.Group>

//                     ) : ("")}
                    
                    
//                     {spots != null ? (
//                         <Form.Group className="mb-3" >
//                             <Form.Label className="fw-bold">Spot</Form.Label>
//                             <Form.Select 
//                                 aria-label="Default select example" 
//                                 onChange={(e) => onChangeSpot(e.target.value, e.target.options[e.target.selectedIndex].dataset.value)}
//                                 required
//                                 defaultValue={albumModified.location_id}

//                                 >
//                                 <option value=""></option>
//                                 {
//                                     spots.map((obj, index) =>{
//                                         return(
//                                             <option key={index} data-value={obj.location_name} value={obj.location_id}>{obj.location_name}</option>

//                                         )
//                                     })
//                                 }

                                
//                             </Form.Select>
                    
//                         </Form.Group>

//                     ) : ("")}
                    
//                     <Form.Group className="mb-3">
//                         <Form.Label className="fw-bold">Date</Form.Label>

//                         <Form.Control type="date" className='form-control-md search_personalized_date'  placeholder='Date' 
//                             onChange={onChangeDate}
//                             defaultValue={new Date(albumModified.album_datetime_start).toISOString().split('T')[0]}
//                             />


//                     </Form.Group>

//                     <div className="d-flex w-100 mb-3">
//                         <Form.Group className="me-2 w-100">
//                             <Form.Label className="fw-bold">Start time</Form.Label>
//                             <Form.Control 
//                             type="time" 
//                             className='form-control-md w-100'  
//                             placeholder='Type Here' 
//                             onChange={onChangeStartDateTime}
//                             defaultValue={new Date(albumModified.album_datetime_start).toLocaleTimeString()}

                        
//                                 />

//                         </Form.Group>
//                         <Form.Group className="ms-2 w-100">
//                             <Form.Label className="fw-bold">End time</Form.Label>
//                             <Form.Control 
//                             type="time" 
//                             className='form-control-md w-100'  
//                             placeholder='Type Here' 
//                             onChange={onChangeEndDateTime}
//                             defaultValue={new Date(albumModified.album_datetime_end).toLocaleTimeString()}

//                             />

//                         </Form.Group>
//                     </div>

                    

//                     <Form.Group className="mb-3">
//                         <Form.Label className="fw-bold">Category <span className="text_thin">(Select one)</span></Form.Label>

//                         <div className='radio_inline_blue d-flex justify-content-between'>
//                             <Form.Check
//                                 required
//                                 inline
//                                 label="Land"
//                                 name="group1"
//                                 type="radio"
//                                 value="Land"
//                                 defaultChecked={albumModified.album_categories == "Land" ? true : false}

//                                 onClick={(e) => setAlbumModified((oldSearch) => ({...oldSearch, "album_categories": e.target.value}))}
//                             />
//                             <Form.Check
//                                 required
//                                 inline
//                                 label="Drone"
//                                 name="group1"
//                                 type="radio"
//                                 value="Drone"
//                                 defaultChecked={albumModified.album_categories == "Drone" ? true : false}


//                                 onClick={(e) => setAlbumModified((oldSearch) => ({...oldSearch, "album_categories": e.target.value}))}
//                             />
//                             <Form.Check
//                                 required
//                                 inline
//                                 label="In water"
//                                 name="group1"
//                                 type="radio"
//                                 value="In water"
//                                 defaultChecked={albumModified.album_categories == "In water" ? true : false}


//                                 onClick={(e) => setAlbumModified((oldSearch) => ({...oldSearch, "album_categories": e.target.value}))}
//                             />
                            
//                         </div>
//                     </Form.Group>
//                 </Form>
//             </Col>
//             {/* <div className="d-flex w-100 justify-content-between mb-5">
// \
//                 <Button className='btn_primary_blue btn_register ms-auto' onClick={validateData}>Save</Button>

//             </div> */}


//         </div>
        
//     )
// }


// function CreateAlbumHeader({userInfo, album, products, discounts}){
//     const [userDetails, setUserDetails] = useState(SessionDetails())
//     const [content, setContent] = useState()
//     const [activeCard, setActiveCard] = useState({activeIndex: 1})

//     // ALBUM TO SAVE LATER
//     const [locationsAlbum, setLocationsAlbums] = useState({location_state: album.location_state, location_city: album.location_city, location_name: album.location_name})
//     const [albumModified, setAlbumModified] = useState({
//         location_id: album.location_id, 
//         album_datetime_start: new Date(album.album_datetime_start).toISOString().split('T')[0] + ' ' + new Date(album.album_datetime_start).toLocaleTimeString(), 
//         album_datetime_end: new Date(album.album_datetime_end).toISOString().split('T')[0] + ' ' + new Date(album.album_datetime_end).toLocaleTimeString(),
//         album_categories: album.album_categories
//     })

//     // console.log(albumModified)
    

//     // FILES TO UPLOAD LATER
//     const [productsGlobal, setProductsGlobal] = useState([])
//     const [filesSelectedGlobal, setFilesSelectedGlobal] = useState([])
//     const [showLoadingModal, setShowLoadingModal] = useState(false)
//     const [loadingProgress, setLoadingProgress] = useState(0)
//     const [maxArray, setMaxArray] = useState()


//     // DISCOUNTS
//     const [pricesGlobal, setPricesGlobal] = useState({})
//     const [discountsGlobal, setDiscountsGlobal] = useState(discounts)

    
//     function onNext(value){
        
//         if(value < activeCard.activeIndex){
//             setActiveCard({activeIndex: value})
//         }else{

//             switch(activeCard.activeIndex){

//                 case 1:
//                     if(!(albumModified.location_id && albumModified.album_datetime_start && !isNaN(Date.parse(albumModified.album_datetime_start)) && albumModified.album_datetime_end && !isNaN(Date.parse(albumModified.album_datetime_end)) && albumModified.album_categories)){
//                         alert("Please enter all details.")
//                     }else if(Date.parse(albumModified.album_datetime_start) >= Date.parse(albumModified.album_datetime_end)){
//                         alert("Start date cannot be after end date.")
            
                        
//                     }else if(new Date(albumModified.album_datetime_start) > new Date() || new Date(albumModified.album_datetime_end) > new Date()){
//                         alert("Dates cannot be in the future")
//                     }else{
//                         setActiveCard({activeIndex: value})

//                     }
//                 break;
    
                
//                 case 2:

//                     if( validateDiscounts()){
//                         setActiveCard({activeIndex: value})

//                     }
//                 break
    
//                 case 3:
//                     if(validatePhotosPrices()){
//                         createAlbumTotal()
//                     }

//                 break;
//             }
//         }
        
//     }

//     function validatePhotosPrices(){
//         var isvalid = true
//         for(var i = 0; i< filesSelectedGlobal.length; i++){
//             if(filesSelectedGlobal[i].price == "" || filesSelectedGlobal[i].price == null){
//                 alert("Please, set a price to all photos and videos.")
//                 isvalid = false
//             }

//             if(filesSelectedGlobal[i].price < 0){
//                 alert("Price cannot be negative")
//                 isvalid = false
//             }
//         }

//         // console.log(isvalid)

//         return isvalid
//     }

    

//     function onBack(e){
        
//         if(activeCard.activeIndex != 1){
//             setActiveCard({activeIndex: activeCard.activeIndex - 1})
//         }
//     }

//     function validateDiscounts(){
//         if(discountsGlobal.length > 0){
            
//             for(var i = 0; i < discountsGlobal.length; i++){
//                 if(discountsGlobal[i].discount_quantity == "" || discountsGlobal[i].discount_percentage == "" || isNaN(discountsGlobal[i].discount_percentage) ){
//                     alert("Please set all discounts")
//                     return false;
//                 }

//                 if(parseFloat(discountsGlobal[i].discount_percentage) > 0.99 || parseFloat(discountsGlobal[i].discount_percentage) < 0.01){
//                     alert("Percentage needs to be between 1% and 99%")

//                     return false;
//                 }

//                 if(parseFloat(discountsGlobal[i].discount_quantity) < 1){
//                     alert("Products quantity needs to be bigger than 1")

//                     return false;
//                 }
//             }
//         }
//         return true;
//     }

//     function validatePrices(){
//         if(pricesGlobal != null){
//             if(isNaN(pricesGlobal.photographer_default_price_photo)){
//                 delete pricesGlobal.photographer_default_price_photo
//             }
    
//             if(isNaN(pricesGlobal.photographer_default_price_video)){
//                 delete pricesGlobal.photographer_default_price_video
//             }
//         }
        

//     }
   
//     useEffect(() => {
//         switch(activeCard.activeIndex){
//             case 1:
                
//                 setContent(<SectionAlbumDetails 
//                     // setActiveCard={setActiveCard} 
//                     userInfo={userInfo} 
//                     album={album}
//                     albumModified={albumModified}
//                     setAlbumModified={setAlbumModified}
//                     locationsGlobal={locationsAlbum}
//                     setLocationsGlobal={setLocationsAlbums}
//                     />)

//             break

           

//             case 2:
//                 setContent(<SectionSetDiscounts  
//                     userInfo={userInfo} 
//                     album={album}
//                     discounts={discounts}
//                     pricesGlobal={pricesGlobal}
//                     setPricesGlobal={setPricesGlobal}
//                     discountsGlobal={discountsGlobal}
//                     setDiscountsGlobal={setDiscountsGlobal}
//                     />
                    
//                     )
                    

//             break;

//             case 3:
//                 setContent(<SectionReview  
//                     userInfo={userInfo}
//                     album={album}  
//                     // products={products}

//                     discountsGlobal={discountsGlobal}
//                     pricesGlobal={pricesGlobal}
//                     />)

//             break;

            
//         }

//     }, [activeCard]);

        
//     function setView(e){
//         setActiveCard({ activeIndex: parseInt(e.currentTarget.dataset.value)})

//     }

//     //############ MODIFY CREATING ALBUMS FROM HERE ###############

//     async function createAlbumTotal(){


//         // var obj_album = albumModified
//         // obj_album.album_datetime_start = new Date(obj_album.album_datetime_start)
//         // obj_album.album_datetime_end = new Date(obj_album.album_datetime_end)



//         // await createAlbum(obj_album)

//         // validatePrices()
//         //  if(Object.keys(pricesGlobal).length > 0){
//         //     await sendPrices()
                
//         // }
    

//     }

//     async function editAlbum(obj){

//         // IF ALBUMID IS IN THE URL, MODIFY THAT ALBUM ELSE CREATE IT
//         setShowLoadingModal(true)

        
//         // fetch(url_server + "private/albums", {
//         //     method: 'POST',
//         //     body: JSON.stringify(obj),
//         //     headers: {
//         //         'Content-type': 'application/json; charset=UTF-8',
//         //         'x-access-token': userDetails.token,
//         //     },
//         //     })
//         //     .then((response) => response.json())
            
//         //     .then( (data) => {
//         //         // response = data.response
//         //         if(data.error){
//         //             alert(data.errorDescription)
//         //         }else{
//         //             // console.log("Album created")
//         //             const album_id = data.response.insertId
                    
//         //             if(discountsGlobal.length > 0){
//         //                 // await sendDiscounts()
//         //                 var discounts = discountsGlobal
//         //                 for(var i = 0; i<discounts.length; i++){
//         //                     discounts[i].album_id = album_id
//         //                 }
//         //                 sendDiscounts(discounts)
//         //             }

//         //             uploadFiles(album_id)
                    
                    
        
//         //         }

//         //     })
//         //     .catch((err) => {
//         //         // console.log(err)
//         //     });
        
//     }

//     async function sendDiscounts(discounts){
//         fetch(url_server + "private/albums-discounts", {
//             method: 'POST',
//             body: JSON.stringify(discounts),
//             headers: {
//                 'Content-type': 'application/json; charset=UTF-8',
//                 'x-access-token': userDetails.token,
//             },
//             })
//             .then((response) => response.json())
            
//             .then(async (data) => {
//                 // response = data.response
            
//             })
//             .catch((err) => {
//                 // console.log(err)
//             });
//     }

//     async function sendPrices(){

//         fetch(url_server + "private/photographers/"+userInfo.photographer_id, {
//             method: 'PUT',
//             body: JSON.stringify(pricesGlobal),
//             headers: {
//                 'Content-type': 'application/json; charset=UTF-8',
//                 'x-access-token': userDetails.token,
//             },
//             })
//             .then((response) => response.json())
            
//             .then(async (data) => {
//                 // response = data.response
            
//             })
//             .catch((err) => {
//                 // console.log(err)
//             });
        
//     }

    

//     async function setAlbumVisible(album_id){
//         fetch(url_server + "private/albums/"+album_id, {
//             method: 'PUT',
//             body: JSON.stringify({is_visible: 1}),
//             headers: {
//                 'Content-type': 'application/json; charset=UTF-8',
//                 'x-access-token': userDetails.token,
//             },
//             })
//             .then((response) => response.json())
            
//             .then(async (data) => {
//                 // response = data.response
            
//             })
//             .catch((err) => {
//                 // console.log(err)
//             });
//     }




//     return(
//         <div className="div_albums">
//             <h4><b>{window.location.pathname == "/create-album" ? "Create Album" : "Edit Album"}</b></h4>
//             <Row className="mt-4 gx-3">
//                 <Col lg={4} className="mt-3">
//                     <div data-value={1} className={`personalized_card p-1 ${activeCard.activeIndex === 1 ? 'card_active' : ""}` } onClick={(e) => onNext(1)}>
//                         <p className="text-thin p_xsmall ms-2">Step 1</p>
//                         <p className="mb-1 mt-2 ms-2"><b>Add album details</b></p>
//                     </div>

                    
//                 </Col>
      
//                 <Col lg={4} className="mt-3" >
//                     <div data-value={3} className={`personalized_card p-1 ${activeCard.activeIndex === 2 ? 'card_active' : ""}` } onClick={(e) => onNext(2)}>
//                         <p className="text-thin p_xsmall ms-2">Step 2</p>
//                         <p className="mb-1 mt-2 ms-2"><b>Set prices and discounts</b></p>
//                     </div>
//                 </Col>
//                 <Col lg={4} className="mt-3">
//                     <div data-value={4} className={`personalized_card p-1 ${activeCard.activeIndex === 3 ? 'card_active' : ""}` } onClick={(e) => onNext(3)}>
//                         <p className="text-thin p_xsmall ms-2">Step 3</p>
//                         <p className="mb-1 mt-2 ms-2"><b>Review album</b></p>
//                     </div>
//                 </Col>
//             </Row>
//             <div>
//                 {content}
//             </div>
//             {/* <div className="d-flex w-100 justify-content-between mb-5">
//                 <Button className='btn_outlined_blue btn_register me-auto ' onClick={onBack}>Back</Button>

//                 <Button className='btn_primary_blue btn_register ms-auto'  onClick={(e) => onNext(parseInt(activeCard.activeIndex)+1)} >{activeCard.activeIndex != 4 ? "Next" : "Publish"}</Button>

//             </div> */}



         
//         </div>
//     )
// }


function EditAlbumPage(){
    const [userDetails, setUserDetails] = useState(SessionDetails())
    const [userInfo, setUserInfo] = useState()
    const [products, setProducts] = useState()
    const [discounts, setDiscounts] = useState()
    const [album, setAlbum] = useState()
    const { album_id } = useParams();


    useEffect(() => {
        getPhotographerInfo();
        if(album_id){
            getAlbum()
            getProducts()
            getAlbumsDiscounts()
        }
       
       
    }, []);


     function getPhotographerInfo(){
        fetch(url_server + "private/photographers/" + userDetails.user_id , {
            method: 'GET',
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
                'x-access-token': userDetails.token
            },
            })
            .then((response) => response.json())
            
            .then( (data) => {
                setUserInfo(data.response[0])

            })
            .catch((err) => {
                // console.log(err)
            });
    
    }


    function getAlbum(){
        fetch(`${url_server}private/albums?user_id=${userDetails.user_id}&album_id=${album_id}`, {
            method: 'GET',
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
                "x-access-token": userDetails.token,
            },
            })
            .then((response) => response.json())
            
            .then( (data) => {
            // GO TO SUCCESS PAGE
                if(data.error){
                    alert("Something went wrong")
                }else{
                    setAlbum(data.response[0])

                }
           
            
            })
            .catch((err) => {
                // console.log(err)
                // alert("Something went wrong. Please check your connection and try again.");
            });
    
    }

    async function getProducts(){

        var query = "?album_id=" + album_id;
       
        
         await fetch(url_server + "public/products" + query, {
            method: 'GET',
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            },
            })
            .then((response) => response.json())
            
            .then( (data) => {

                if(data.error){
                    alert("Something went wrong")
                }else{
                    setProducts(data.response)

                }
        

            })
            .catch((err) => {
                // console.log(err)
            });

    }

    function getAlbumsDiscounts(){
        fetch(url_server + "public/albums/" + album_id + "/discounts", {
           method: 'GET',
           headers: {
               'Content-type': 'application/json; charset=UTF-8',
           },
           })
           .then((response) => response.json())
           
           .then( (data) => {
                if(data.error){
                    alert("Something went wrong")
                }else{
                    if(data.response.length > 0){
                        setDiscounts(data.response)

                    }else{
                        setDiscounts([{discount_quantity: "", discount_percentage: ""}])
                    }
                }

           })
           .catch((err) => {
               // console.log(err)
           });

   }

   
    
    return(
        <>
        <Navigation />
        {userDetails.token != null ? (
            
            <>
            {userInfo != null && album != null ? (
                <div>
                
                    <Container fluid>
                        {/* <CreateAlbumHeader userInfo={userInfo} album={album} products={products} /> */}

                    </Container>
                    
                </div>
            ) : (
                <div className="div_albums">
                    <p>403 forbidden</p>

                </div>
            )}
            </>

        ) :(
            <div className="div_albums">
                <p>403 forbidden</p>

            </div>
        )}
            
        
        
        </>
    )
}




export default EditAlbumPage