// import Container from 'react-bootstrap/Container';
// import Nav from 'react-bootstrap/Nav';
// import Navbar from 'react-bootstrap/Navbar';

import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown'
import InputGroup from 'react-bootstrap/InputGroup';
// import $ from 'jquery';
import React, { Component, useState, useEffect } from 'react';

import './components.css';
import { SessionDetails, deleteSession } from '../controllers/AccountController';
import { SearchBar } from './FormComponents';
import { url_server } from '../settings';


function NavItemLoginAccount(){
    const [session_details, setSessionDetails] = useState(SessionDetails())
    const [photographerInfo, setPhotographerInfo] = useState({})

    window.addEventListener('session', () => {
        setSessionDetails(SessionDetails())
        
    })

    const [account_link, setAccountLink] = useState({})

    const [screenWidth, setScreenWidth] = useState(window.innerWidth)
     useEffect(() => {
        getPhotographerProfileImage();
        window.addEventListener("resize", () => {
            setScreenWidth(window.innerWidth)
        });
        return () => {
            window.removeEventListener("resize", () => {
                setScreenWidth(window.innerWidth)
            })
        }
    }, []);

    function logOut(e){
        e.preventDefault()
        deleteSession()
        window.location.href = "/account/login"
    }

    function getPhotographerProfileImage(){
        fetch(url_server + "public/users/" + session_details.user_unique_id +"/photographer", {
            method: 'GET',
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
                // 'x-access-token': session_details.token
            },
            })
            .then((response) => response.json())
            
            .then( (data) => {
                // response = data.response
                // console.log(data.response)
                setPhotographerInfo(data.response)
    
            })
            .catch((err) => {
                // console.log(err)
            });
    }


    return(
        <div >
        {session_details.token != null ? 
                <div className='menu-logged'>
                {session_details.user_default_profile == 2 ?(

                    <div >
                        <NavDropdown title={

                                photographerInfo.length > 0 && photographerInfo[0].photographer_profile_photo_url != null && photographerInfo[0].photographer_profile_photo_url != "" ? (
                                    // <span className='text-green '>TT</span>
                                    <div  className='round_profile_picture_navbar'>
                                        <img className='w-100 default_profile_img_select' src={photographerInfo[0].photographer_profile_photo_url} alt="photo" />

                                    </div>

                                ): (
                                        <span className='text-green'><b>{session_details.user_first_name[0].toUpperCase()}{session_details.user_last_name[0].toUpperCase()}</b></span>
                                )

                            } 
                            align='end' className={`p-0 nav_link user_account_dropdown ${photographerInfo.length > 0 && photographerInfo[0].photographer_profile_photo_url != null && photographerInfo[0].photographer_profile_photo_url != "" ? 'user_account_dropdown_photo': ""}`}>
                            
                            <NavDropdown.Item href={`/${session_details.user_unique_id}`} className='text_light_blue'>Profile</NavDropdown.Item>

                            <NavDropdown.Item href="/create-album?step=1" className='text_light_blue'>Create Album</NavDropdown.Item>
                            <NavDropdown.Item href={`/${session_details.user_unique_id}?tab=next-sessions`} className='text_light_blue'>Add future sessions</NavDropdown.Item>
                            <NavDropdown.Item href="/account/sales" className='text_light_blue'>Sales and withdrawals</NavDropdown.Item>
                            <NavDropdown.Item href="/account/purchases" className='text_light_blue'>Purchases</NavDropdown.Item>

                            <NavDropdown.Divider />
                            <NavDropdown.Item href="/contact-us" className='text_light_blue'>Help & Support</NavDropdown.Item>
                            <NavDropdown.Item href="/account/settings" className='text_light_blue'>Settings</NavDropdown.Item>

                            <NavDropdown.Item href="#" onClick={logOut} className='text_light_blue'>
                            Log out
                            </NavDropdown.Item>
                        </NavDropdown>
                    </div>
                ) : (
                <NavDropdown title={
                        <span className='text-green '>{session_details.user_first_name[0]}{session_details.user_last_name[0]}</span>
                    } align='end' className='nav_link'>
                        
                        <NavDropdown.Item href="/account/purchases" className='text_light_blue'><b>Purchases</b></NavDropdown.Item>

                        <NavDropdown.Divider />
                        <NavDropdown.Item href="/contact-us"  className='text_light_blue'>Help & Support</NavDropdown.Item>
                        <NavDropdown.Item href="/account/settings"  className='text_light_blue'>Settings</NavDropdown.Item>

                        <NavDropdown.Item href="#" className='text_light_blue' onClick={logOut}>
                        Log out
                    </NavDropdown.Item>
                </NavDropdown>
            )}
                <div className='green-ball'></div>
            </div>
            
        : (
            <div>
                {screenWidth < 768 ? (
                    <Nav.Link href="/account/login" className='nav_link' >Login</Nav.Link> 

                ) : (
                    <>
                    <Button
                        className='link_btn_primary_blue'  size='sm' onClick={(e) => {
                            e.preventDefault();
                            window.location.href='/account/login';
                            } }>Log in
                                
                    </Button>
                    </>
                )}
            </div>
        )}
            

        </div>
        
    )


}


function NavigationLandingPage ({hiddenSurfer, hiddenPhotographer}){
    const img_logo_writen_blue = require("../assets/logo_writen_blue.png")
    const img_logo_blue = require("../assets/logo_blue.png")

    return (
        <Navbar id="navbar_on_position_top" bg="transparent" expand="md">
            <Container fluid>
                <Navbar.Brand href={"/"} className='left_navigation'>
                <div className='brand_top_left'>
                    {/* <img src={img_logo_blue} alt="logo"/> */}
                    <img src={img_logo_writen_blue} alt="logo_writen"/>

                </div>

                </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="me-auto">
                                    
                        </Nav>
                        <Nav id="right_navigation">
                                            
                            <Nav.Link href={'/surfer'} hidden={hiddenSurfer}  className='nav_txt' >I am a surfer</Nav.Link> 
                            <Nav.Link href={'/photographer'} hidden={hiddenPhotographer} className='nav_txt' >I am a Photographer</Nav.Link> 


                        </Nav>
                    </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}


function NavigationBarComplete({searchBarTop=false, showSearchBar = 1}){

    const ic_cart = require('../assets/ic_cart.png')

    const img_logo = require('../assets/logo_blue.png')
    const img_logo_writen = require("../assets/logo_writen_blue.png")
    const [cartAlbums, setCartAlbums] = useState(JSON.parse(localStorage.getItem('cart')));
    const [cartItemsCount, setCartItemsCount] = useState(0);
    const [inputSearch, setInputSearch] = useState('');
    const [screenWidth, setScreenWidth] = useState(window.innerWidth)

    useEffect(() => {
        window.addEventListener("resize", () => {
            setScreenWidth(window.innerWidth)
        });
        return () => {
            window.removeEventListener("resize", () => {
                setScreenWidth(window.innerWidth)
            })
        }
    }, []);

    useEffect(() => {
        var count = 0
        if(cartAlbums != null && cartAlbums.length !== 0){
            cartAlbums.map((album, index) =>{
                album.products.map((prod, i) =>{
                    count += 1
                })
            })
        }
       
        setCartItemsCount(count)
        
    }, [cartAlbums]);

    
    window.addEventListener('cart_item_add', () => {
        setCartAlbums(JSON.parse(localStorage.getItem('cart')))
    })


    function searchFilter(e){
        e.preventDefault();
        window.location.href='/search_photos?' + inputSearch;
    
    }

    function displaySearchBar(){

        switch(showSearchBar){
            case 0:
                return(<></>)
            break;

            case 1:
                return(
                    <div className='w-100 search_bar_animation'>
                        <SearchBar />

                    </div>
                )

            break;

            case 2:
                return(
                    searchBarTop === true ?(
                        <div className='w-100 search_bar_animation'>
                            <SearchBar />

                        </div>
                        
                        ) : (
                        <></>

                    )
                )
            break;
        default:
            break
        }
        
    }

    function displayWiritenLogo(){

        switch(showSearchBar){
            case 0:
                return(<img src={img_logo_writen} alt="logo_writen" className='nav_bar_logo_writen'/>)
            break;

            case 1:
                return(
                    <></>
                )

            break;

            case 2:
                if(searchBarTop == true){
                    return(<></>)
                }else{
                    return(
                        <img src={img_logo_writen} alt="logo_writen" className='nav_bar_logo_writen'/>
    
                    )
                }
                
            break;
        default:
            break
        }

    }

    return (

        <Navbar expand="md" fixed="top" className='navbar_personalized py-3 '>
            <Container fluid>
                <Navbar.Brand href={"/"} className='left_navigation'>
                    <img src={img_logo} alt="logo" className='nav_bar_logo me-4'/>
                    {(displayWiritenLogo())}
                    
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav" className={screenWidth > 768 ? 'd-flex justify-content-between' : ''}>
                    <Nav className={`${(screenWidth > 1200 && ((searchBarTop && showSearchBar === 2 ) || showSearchBar === 1) ? "col-md-4 col-lg-4 col-xl-4 col-xxl-5" : "")}`}>

                    {screenWidth > 1200 ? (
                        displaySearchBar()
                        
                    ): ("")}
                    
                    </Nav>
                    <Nav id="right_navigation">
                        <Nav.Link href={'/search_photos'} className={`nav_link ${screenWidth > 1100 ? 'me-4' : 'me-3'}`} ><b>Find your shots</b></Nav.Link> 
                        <Nav.Link href={'/photographers'} className={`nav_link ${screenWidth > 1100 ? 'me-4' : 'me-3'}`} ><b>Sell your shots</b></Nav.Link> 
                        <Nav.Link href={'/next-sessions'} className={`nav_link ${screenWidth > 1100 ? 'me-4' : 'me-3'}`} ><b>Future sessions</b></Nav.Link> 
                        {screenWidth < 768 ? (
                            <Nav.Link href="/cart" className={`nav_link ${screenWidth > 1100 ? 'me-4' : 'me-3'}`}><b>Cart</b></Nav.Link>
                        ) : (
                            <Nav.Link href={'/cart'} className='nav_link position-relative me-5'>
                                <img src={ic_cart} alt='cart' />
                                <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger " hidden={cartItemsCount === 0 ? true : false} >
                                    {cartItemsCount}
                                <span className="visually-hidden">Cart</span>
                                </span>
                            </Nav.Link>
                        )}
                        

                        <NavItemLoginAccount />

                    </Nav>
                </Navbar.Collapse>
            </Container>
    </Navbar>

  )
}


function Navigation({searchBarTop, showSearchBar}){
   
    return(<NavigationBarComplete searchBarTop={searchBarTop} showSearchBar={showSearchBar}/>);

  
}
export default Navigation;
export {NavigationLandingPage}