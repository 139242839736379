
import { Link, useParams, useLocation } from 'react-router-dom';
import Navigation from '../components/Navigation';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/esm/Container';
import Card from 'react-bootstrap/Card';
import { useState, useRef, useEffect, Suspense} from "react";
import { url_server } from '../settings';
import { ProductModal, ProductModalSlider } from '../components/ProductModal';
import {BottomScrollListener} from 'react-bottom-scroll-listener';
import Button from 'react-bootstrap/Button';
import { SessionDetails } from '../controllers/AccountController';
import { DeleteModal, ModalCategoryEdit, ModalDisocuntsEdit, ModalEditLocation, ModalPricePhotos, ModalPriceVideos, ModalTimeEdit } from '../components/modals';
import Form from 'react-bootstrap/Form';
import { RWebShare } from "react-web-share";
import watermarkedImage from '../components/WatermarkedImage';
import Helmet from 'react-helmet';
import Nav from 'react-bootstrap/Nav';



function DisplayInfo({data, discountsGlobal, productsQuantity}){

	const [accountDetails, setAccountDetails] = useState(SessionDetails())

	const ic_location = require('../assets/ic_location_card.png')
	const ic_calendar = require('../assets/ic_calendar_card.png')
	const ic_instagram_snmall = require('../assets/ic_instagram_small.png')
	const ic_empty_profile = require('../assets/ic_empty_profile.png')
	const ic_mini_instagram = require('../assets/ic_mini_instagram.png')
	const ic_badge_photo = require("../assets/ic_badge_photo.png")
	const ic_badge_video = require("../assets/ic_badge_video.png")
	const ic_share = require('../assets/ic_share.png')
	const ic_edit = require('../assets/ic_edit.png')

	// const [discountsGlobal, setDiscountsGlobal] = useState([])

	const [screenWidth, setScreenWidth] = useState(window.innerWidth)

    useEffect(() => {
        window.addEventListener("resize", () => {
            setScreenWidth(window.innerWidth)
        });
        return () => {
            window.removeEventListener("resize", () => {
                setScreenWidth(window.innerWidth)
            })
        }
    }, []);

	var dayNames = ['Sun','Mon','Tue','Wed','Thu','Fri','Sat'];
	var monthPrefixes = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];

	var start_time = new Date(data.album_datetime_start).toLocaleTimeString([], {hour12: true, hour: '2-digit', minute:'2-digit'})
	var end_time = new Date(data.album_datetime_end).toLocaleTimeString([], {hour12: true, hour: '2-digit', minute:'2-digit'})
	var day = new Date(data.album_datetime_start).getDay()
	var month = new Date(data.album_datetime_start).getMonth()
	var day_number = new Date(data.album_datetime_start).toLocaleDateString("en-au").split("/")[0]
	var year_number = new Date(data.album_datetime_start).toLocaleDateString("en-au").split("/")[2]


	const { id } = useParams();
	// const [productsQuantity, setProductsQuantity] = useState()

	// show editing modals vars
	const [showModalLocationEdit, setShowModalLocationEdit] = useState(false)
	const [showModalTimeEdit, setShowModalTimeEdit] = useState(false)
	const [showModalCategoryEdit, setShowModalCategoryEdit] = useState(false)
	const [showModalDiscountsEdit, setShowModalDiscountsEdit] = useState(false)
	


	function goToPhotographerPage(){
		window.location.href = `/${data.user_unique_id}`
	}

	async function editDiscounts(discounts){

		await setDiscountsInactive(discounts)
	}

	async function setDiscountsInactive(discounts){
		fetch(`${url_server}private/albums-discounts/${data.album_id}`, {
            method: 'PUT',
			body: JSON.stringify({discount_active: 0}),
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
				'x-access-token': accountDetails.token
            },
            })
            .then((response) => response.json())
            
            .then( (data) => {
				if(data.error){
					alert("Something went wrong")
				}else{
					setNewDiscounts(discounts)

				}

            })
            .catch((err) => {
                // console.log(err)
            });
	}

	async function setNewDiscounts(discounts){
		fetch(url_server + "private/albums-discounts", {
            method: 'POST',
            body: JSON.stringify(discounts),
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
                'x-access-token': accountDetails.token,
            },
            })
            .then((response) => response.json())
            
            .then(async (data) => {
                // response = data.response
				window.location.reload();
            
            })
            .catch((err) => {
                // console.log(err)
            });
	}



	function editAlbum(newData){

		fetch(`${url_server}private/albums/${data.album_id}`, {
            method: 'PUT',
			body: JSON.stringify(newData),
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
				'x-access-token': accountDetails.token
            },
            })
            .then((response) => response.json())
            
            .then( (data) => {
				if(data.error){
					alert("Something went wrong")
				}else{
					// console.log("edited correctly")
					window.location.reload()
				}

            })
            .catch((err) => {
                // console.log(err)
            });
	}


	return(
		<>
		{data != null && productsQuantity != null ? (

			<div>
			<div className='d-flex justify-content-between album_info_header'>
				<div>
					<div className='mb-2 d-flex justify-content-between'>
						<h4 className='me-2 album_info_header_title'><b>{data.location_name}</b></h4>

						{accountDetails.photographer_id == data.photographer_id ? (<div className='me-3 clickable' onClick={(e) => setShowModalLocationEdit(true)}><img src={ic_edit} alt='edit' /></div>) : ""}
						
						<div className='d-flex'>
							<span className="badge badge_personalized align-self-center pt-2 pb-2 ps-3 pe-3 mb-2 me-2 ms-2"><img src={ic_badge_photo} alt='photo_badge' className='me-1'/> {productsQuantity.photos}</span>
							<span className="badge badge_personalized align-self-center pt-2 pb-2 ps-3 pe-3 mb-2"><img src={ic_badge_video} alt='photo_badge'  className='me-1'/>{productsQuantity.videos}</span>
							{screenWidth > 420 ? (
								<RWebShare
									data={{
										text: data.location_city + ' - ' + data.location_state,
										url: window.location.href,
										title: data.location_name,
									}}
									
								>
									<Button className='btn_outlined_blue_small align-self-center mb-2 ms-3' ><img src={ic_share} alt="share" className='me-1' /> Share</Button>
								</RWebShare>

							) : ""}
							
						</div>
						
					
					
					</div>
					
					<div className='mb-2 d-flex justify-content-between'>
						<div className='d-flex align-items-center'>
							<div className='me-2'>
								<img src={ic_calendar} alt="icon" className='pb-1' />
								<p className='text-thin text_inline text-grey'>{day_number} {monthPrefixes[month]} {year_number} | {start_time} - {end_time}</p>
							</div>
							{accountDetails.photographer_id == data.photographer_id ? (<div className='me-3 clickable' onClick={(e) => setShowModalTimeEdit(true)}><img src={ic_edit} alt='edit' /></div>) : ""}
							
						</div>
									
						{screenWidth <= 420 ? (
							<RWebShare
								data={{
									text: data.location_city + ' - ' + data.location_state,
									url: window.location.href,
									title: data.location_name,
								}}
								
							>
								<Button className='btn_outlined_blue_small align-self-center mb-2 ms-3' ><img src={ic_share} alt="share" className='me-1' /> Share</Button>
							</RWebShare>
						) : ""}
						
					</div>
					<div className='mb-2 d-flex'>
						<div className='me-2'>
							<img src={ic_location} alt='icon' className='pb-1' />
							<p className='text_inline'>{data.location_city} -  {data.location_state}</p>
						</div>
						{/* {accountDetails.photographer_id == data.photographer_id ? (<div className='me-3 clickable'><img src={ic_edit} alt='edit' /></div>) : ""} */}


					</div>

				</div>
				<div className='album_info_header_photographer'>
					<div className='d-flex align-items-center' id='album_photographer_details'>
						<img  src={ic_empty_profile} alt='empty profile' className='img_profile' />
						<div>
							<p className='m-0 clickable' onClick={goToPhotographerPage}><b>{data.photographer_business_name}</b></p>
							<div>
                                <img src={ic_mini_instagram} alt="icon" className='me-1' />
								<a href={'https://www.instagram.com/' + data.photographer_instagram} className='text-thin p_xsmall m-0'>{data.photographer_instagram}</a>
                            </div>
						</div>
					</div>
					<div className='d-flex align-items-center'>
						
						<p className='mt-3 me-2'>{data.album_categories}</p>
						{accountDetails.photographer_id == data.photographer_id ? (<div className='me-3 clickable' onClick={(e) => setShowModalCategoryEdit(true)}><img src={ic_edit} alt='edit' /></div>) : ""}

					</div>
					

				</div>
			</div>
			<div className='d-flex '>

				{discountsGlobal != null && discountsGlobal.length > 0 ?(
					<>
						<div className="mt-3 div_discounts_review w-100">

							<Row className="pt-2 pb-2">
								{discountsGlobal.map((obj, index) =>{
									return(
										<Col key={index} lg={discountsGlobal.length < 3 ? 12 / discountsGlobal.length : 4 } className="text-center">
											<p className="text_light_blue m-0"><b>{parseInt(obj.discount_percentage * 100)}% OFF</b><span className="text-grey"> - Buying {obj.discount_quantity}</span></p>
										</Col>
									)
								})}						
							</Row>

						</div>
						{accountDetails.photographer_id == data.photographer_id ? (<div className='me-3 clickable align-self-center ms-2' onClick={(e) => setShowModalDiscountsEdit(true)}><img src={ic_edit} alt='edit' /></div>) : ""}
					

					</>
				) : (
					<>
					
					{accountDetails.photographer_id == data.photographer_id ? (
					<div className="mt-3 div_discounts_review w-100 d-flex justify-content-center p-2">
						<span className='text_light_blue'>Add discounts</span>
						<div className='me-3 clickable align-self-center ms-2' onClick={(e) => setShowModalDiscountsEdit(true)}>
							<img src={ic_edit} alt='edit' />
						</div>

					</div>
					
					) : ""}
					
					</>
				)}
				
			</div>


			<ModalEditLocation show={showModalLocationEdit} setShow={setShowModalLocationEdit} album={data} onAccept={editAlbum} />
			<ModalTimeEdit show={showModalTimeEdit} setShow={setShowModalTimeEdit} album={data} onAccept={editAlbum}/>
			<ModalCategoryEdit show={showModalCategoryEdit} setShow={setShowModalCategoryEdit} album={data} onAccept={editAlbum} />
			<ModalDisocuntsEdit show={showModalDiscountsEdit} setShow={setShowModalDiscountsEdit} album={data} discounts={discountsGlobal} onAccept={editDiscounts} />
		</div>
		): ("")}
		</>
	
	)

}


function DisplayProducts({album, discountsGlobal, productsQuantity}){

	const [accountDetails, setAccountDetaills] = useState(SessionDetails())
	const location = useLocation()

	const [products, setProducts] = useState([]);
	const [modalShow, setModalShow] = useState(false);
	const [selectedProduct, setSelectedProduct] = useState();
	const [productSelectedIndex, setProductSelectedIndex] = useState();
	const [showModalDelete, setShowModalDelete] = useState(false)
	const [selectedToDelete, setSelectedToDelete] = useState()
	const [viewEditIndividually, setViewEditIndividually] = useState(false)
	const [itemsPriceChange, setItemsPriceChange] = useState([])
	const [showModalPricePhotos, setShowModalPricePhotos] = useState(false)
	const [showModalPriceVideos, setShowModalPriceVideos] = useState(false)
	const [photographerInfo, setPhotographerInfo] = useState()
	const [showProductsType, setShowProductsType] = useState("photos")
    const [watermarked, setWatermarked] = useState([])
    const img_logo_vertical = require('../assets/watermark2.png')
	const ic_video = require('../assets/ic_video_bigger.png')
    const ic_image = require('../assets/ic_image_bigger.png')

	const album_url = document.URL
    const [page, setPage] = useState(0)
	const [limitItems, setLimitItems] = useState(20)

	const ic_delete = require("../assets/ic_delete.png")
	const ic_edit = require('../assets/ic_edit.png')

	const [screenWidth, setScreenWidth] = useState(window.innerWidth)


    useEffect(() => {
		


        window.addEventListener("resize", () => {
            setScreenWidth(window.innerWidth)
        });
        return () => {
            window.removeEventListener("resize", () => {
                setScreenWidth(window.innerWidth)
            })
        }

    }, []);

	useEffect(() => {
		setShowProductsType(productsQuantity != null && productsQuantity.photos > 0 ? "photos" : "videos")
	}, [productsQuantity]);



	function handleScroll(){
        // getProducts(page+1);
        setPage(page+1)

    }


	useEffect(() => {
		if(accountDetails.photographer_id == album.photographer_id){
			getPhotographerInfo();
		}
		setPage(0)
        getProducts(0);

    }, [album]);

	useEffect(() =>{
		if(products.length > 0){
			if(location.search != "" && location.search.split("=")[0] == "?product_id"){
				products.map((dataObj, index) =>{
					if(dataObj.product_id == location.search.split("=")[1]){
						showModal(null, dataObj, index)
						return
					}
				})
			}
			
		}
	}, [products])

	useEffect(() => {
		if(modalShow == false){
			setSelectedProduct({})
			const urlObj = new URL(album_url)
			urlObj.search = ''
			window.history.replaceState({}, document.title, urlObj.toString());

		}

	}, [modalShow]);

	function getPhotographerInfo(){

        fetch(url_server + "private/photographers/" + accountDetails.user_id , {
            method: 'GET',
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
                'x-access-token': accountDetails.token
            },
            })
            .then((response) => response.json())
            
            .then( (data) => {

                setPhotographerInfo(data.response[0])

            })
            .catch((err) => {
                // console.log(err)
            });
    
    }
	
	

	function showModal(e, product, productIndex){
		if(e != null){
			e.preventDefault()

		}
		window.history.replaceState({}, document.title, '?product_id='+product.product_id);
		setProductSelectedIndex(productIndex)
		setSelectedProduct(product)
		setModalShow(true)

	}



	 async function getProducts(p){

        // var query = "?page=" + p + "&quantity_page=" + limitItems + "&album_id=" + album.album_id;
			var query = `?album_id=${album.album_id}`

         await fetch(url_server + "public/products" + query, {
            method: 'GET',
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            },
            })
            .then((response) => response.json())
            
            .then( (data) => {
       
				setProducts(data.response)

            })
            .catch((err) => {
                // console.log(err)
            });

    }

	function changePrices(index, data){

		var array = [...products]
		array[index].price = data
		setProducts(array)


		
	}

	function onSavePricesIndividually(){
		
		var isvalid = true
        for(var i = 0; i< products.length; i++){
            if(products[i].price == "" || products[i].price == null){
                alert("Please, set a price to all photos and videos.")
                isvalid = false
            }

            if(products[i].price < 0){
                alert("Price cannot be negative")
                isvalid = false
            }
        }

		if(isvalid){
			fetch(url_server + "private/products" , {
				method: 'PUT',
				body: JSON.stringify(products),
				headers: {
					'Content-type': 'application/json; charset=UTF-8',
					'x-access-token': accountDetails.token
	
				},
				})
				.then((response) => response.json())
				
				.then( (data) => {
					
					if(data.error){
						alert("Something went wrong")
					}else{
						window.location.reload()
					}
	
				})
				.catch((err) => {
					// console.log(err)
				});
		}

		
	}


	function onDelete(){
		fetch(url_server + "private/products/" + selectedToDelete.product_id, {
            method: 'PUT',
			body: JSON.stringify({product_is_active: 0}),
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
				'x-access-token': accountDetails.token
            },
            })
            .then((response) => response.json())
            
            .then( (data) => {
                
				if(data.error){
					alert("Something went wrong")
				}else{
					window.location.reload()
				}
				

            })
            .catch((err) => {
                // console.log(err)
            });
	}

	function onSaveGeneralPhotoPrice(price){
		fetch(url_server + "private/products/" + album.album_id + "/1" , {
            method: 'PUT',
			body: JSON.stringify({price: price}),
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
				'x-access-token': accountDetails.token

            },
            })
            .then((response) => response.json())
            
            .then( (data) => {
                
				if(data.error){
					alert("Something went wrong")
				}else{
					window.location.reload()
				}

            })
            .catch((err) => {
                // console.log(err)
            });
	}

	function onSaveGeneralVideoPrice(price){
		fetch(url_server + "private/products/" + album.album_id + "/2" , {
            method: 'PUT',
			body: JSON.stringify({price: price}),
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
				'x-access-token': accountDetails.token

            },
            })
            .then((response) => response.json())
            
            .then( (data) => {
                
				if(data.error){
					alert("Something went wrong")
				}else{
					window.location.reload()
				}

            })
            .catch((err) => {
                // console.log(err)
            });
	}

	function shoOnDelete(product){
		setSelectedToDelete(product)
		setShowModalDelete(true)
	}

	// console.log(products)


	return(
		<Container fluid className='mb-5 p-0 mt-3 '>
			{/* <ProductModal  show={modalShow} onHide={() => setModalShow(false)} product_info={selectedProduct} album_info={album} discounts={discountsGlobal} /> */}
			
			

			{album != null && accountDetails.photographer_id == album.photographer_id ? (

				<div className='mt-3 mb-3'>
						<div className='d-flex mt-2'>
							<span className='text-grey'>Edit general photo prices</span>
							<div className='me-3 clickable align-self-center ms-2' onClick={() => setShowModalPricePhotos(true)}><img src={ic_edit} alt='edit' /></div>
						</div>
			
						<div className='d-flex mt-2'>
							<span className='text-grey'>Edit general video prices</span>
							<div className='me-3 clickable align-self-center ms-2' onClick={() => setShowModalPriceVideos(true)} ><img src={ic_edit} alt='edit' /></div>
						</div>
			
						<div className='d-flex justify-content-between mt-2'>
							<div className='d-flex align-self-center'>
								{viewEditIndividually == true ? (
									<>
										<span className='text-danger clickable' onClick={
											function(){
												setViewEditIndividually(false); 
												setItemsPriceChange([])
											}
											
											}>Cancel</span>
									</>
								) : (
									<>
										<span className='text-grey'>Edit prices individually</span>
										<div className='me-3 clickable align-self-center ms-2' ><img src={ic_edit} alt='edit' onClick={() => setViewEditIndividually(true)} /></div>
									</>
								)}
							</div>
							{viewEditIndividually == true ? (<Button className='btn_primary_blue btn_register m-0' onClick={onSavePricesIndividually}>Apply</Button>) : ""}
							
						</div>
					
				</div>


				) : ""}

			
			<div>
                <Nav  variant="tabs" defaultActiveKey="link-1" >
                    {productsQuantity != null && productsQuantity.photos > 0 ?(
						<Nav.Item>
							<Nav.Link eventKey="link-1" className="personalized_menu_item p_xsmall" onClick={() => setShowProductsType("photos")}><img src={ic_image} alt="photos" className="me-2 mb-1" />Photos ({productsQuantity != null ? productsQuantity.photos : ""})</Nav.Link>
						</Nav.Item>
					) : ""} 
                    
					{productsQuantity != null && productsQuantity.videos > 0 ?(
						<Nav.Item>
							<Nav.Link eventKey="link-2" className="personalized_menu_item p_xsmall" onClick={() => setShowProductsType("videos")}><img src={ic_video} alt="videos" className="me-2 mb-1"/>Videos ({productsQuantity != null ?productsQuantity.videos : ""})</Nav.Link>
						</Nav.Item>
					) : ""}
                   
         
                    
                </Nav>
            </div>

            <Row className={screenWidth < 420 ? "gx-1" : "gx-3"}>
				{/* <Col md={3}>
					<Card.Img className='card_round square' variant="top" src={img} />

				</Col> */}


				{products.map((dataObj, index) =>{
			
					if(showProductsType == "photos") { 
						
							if(dataObj.media_format.toLowerCase() == "png" || dataObj.media_format.toLowerCase() == "jpg" || dataObj.media_format.toLowerCase() == "jpeg"){

								return (
									// <Suspense fallback={"Loading"}>

									
									<Col key={index} className='mb-3'  md={3} xs={4} >
										<div className='img_container_album'>
											<a href='#' onClick={(e) => showModal(e, dataObj, index)}>
												<Card.Img className='card_round square mt-3' variant="top" src={dataObj.media_url} />
												
											</a>
											{accountDetails.photographer_id == album.photographer_id ? (<div className='me-3 clickable' onClick={(e) => shoOnDelete(dataObj)}><img src={ic_delete} alt="delete" className="ic_delete_image" /></div>) : ""}
			
											
			
											{accountDetails.photographer_id == album.photographer_id ? (
												<div className='mt-2'>
													{/* <Form.Label className="m-0 p-0">Price (AUD)</Form.Label> */}
													{viewEditIndividually == true ? (
														<>
														<Form.Label className="m-0 p-0">Price (AUD)</Form.Label>
														<Form.Control  data-value={index} type="number" className='form-control-md w-100 mt-1' onChange={(e) => changePrices(index, parseInt(parseFloat(e.target.value)*100))}  placeholder='Price' defaultValue={dataObj.price != "" && dataObj.price != null ? parseFloat(dataObj.price)/100 : ""} />
														{dataObj.price != "" && dataObj.price != null ? (
															<p className="text-thin mt-1"><b>You will get </b>${(((parseFloat(dataObj.price)) * photographerInfo.photographer_percentage_receive)/100).toFixed(2)}</p>

														) : (
															<></>
														)}
														</>
													) : (
														<Form.Label className="m-0 p-0">Price ${dataObj.price != "" && dataObj.price != null ? parseFloat(dataObj.price)/100 : ""}</Form.Label>

														// <p>${dataObj.price != "" && dataObj.price != null ? parseFloat(dataObj.price)/100 : ""}</p>
													)}
												</div>
											) : ""}
			
											
										</div>
									</Col>
									// </Suspense>
								);
			
							}

						
					} else if(showProductsType == "videos"){
						if(dataObj.media_format.toLowerCase() == "mp4" || dataObj.media_format.toLowerCase() == "ogg" || dataObj.media_format.toLowerCase() == "mov"){
							return(
								<Col key={index} md={3} xs={4} >
									<div className='img_container_album'>
										<a href='#' onClick={(e) => showModal(e, dataObj, index)}>
												<video  className="card_round video_middle mt-3" controls>
													<source  src={`${dataObj.media_url_watermarked}#t=0.001`} type="video/mp4"/>
													<source  src={`${dataObj.media_url_watermarked}#t=0.001`} type="video/ogg"/>
													<source  src={`${dataObj.media_url_watermarked}#t=0.001`} type="video/mov"/>
		
													Your browser does not support the video tag.
												</video> 
												
										</a>

								
										{accountDetails.photographer_id == album.photographer_id ? (<div className='me-3 clickable' onClick={(e) => shoOnDelete(dataObj)}><img src={ic_delete} alt="delete" className="ic_delete_image" /></div>) : ""}
		
										{accountDetails.photographer_id == album.photographer_id ? (
											<div>
												{/* <Form.Label className="m-0 p-0">Price (AUD)</Form.Label> */}
												{viewEditIndividually == true ? (
													<>
													<Form.Label className="m-0 p-0">Price (AUD)</Form.Label>
													<Form.Control  data-value={index} type="number" className='form-control-md w-100 mt-1' onChange={(e) => changePrices(index, parseInt(parseFloat(e.target.value)*100))}  placeholder='Price' defaultValue={dataObj.price != "" && dataObj.price != null ? parseFloat(dataObj.price)/100 : ""} />
													{dataObj.price != "" && dataObj.price != null ? (
														<p className="text-thin mt-1"><b>You will get </b>${(((parseFloat(dataObj.price)) * photographerInfo.photographer_percentage_receive)/100).toFixed(2)}</p>

													) : (
														<></>
													)}
													</>
												) : (
													<Form.Label className="m-0 p-0">Price ${dataObj.price != "" && dataObj.price != null ? parseFloat(dataObj.price)/100 : ""}</Form.Label>

												)}
											</div>
										) : ""}
		
									</div>
									
								
								</Col>
							)
						}

					}
				})}

            
            </Row>
			
			<ProductModalSlider  show={modalShow} onHide={() => setModalShow(false)} selectedProduct={selectedProduct} productIndex={productSelectedIndex} products={products} watermarked={watermarked} album_info={album} discounts={discountsGlobal} />
			
			<DeleteModal show={showModalDelete} setShow={setShowModalDelete} title={"Are you sure you want to delete this media?"} content={"If you delete it, you can't recover it later."} onAccept={onDelete} />
			<ModalPricePhotos show={showModalPricePhotos} setShow={setShowModalPricePhotos} album={album} onAccept={onSaveGeneralPhotoPrice} photographerInfo={photographerInfo} />
			<ModalPriceVideos show={showModalPriceVideos} setShow={setShowModalPriceVideos} album={album} onAccept={onSaveGeneralVideoPrice} photographerInfo={photographerInfo} />

			<BottomScrollListener onBottom={handleScroll} />

        </Container>
	)
}

const Album = props => {
  	const { id } = useParams();
  	// const location = useLocation()
	const [album, setAlbum] = useState({})
	const[discounts, setDiscounts] = useState([])
	// const data = location.state
	const image = require('../assets/img_cta_surfer3.jpg')
	const [productsQuantity, setProductsQuantity] = useState()


    useEffect(() => {
        getAlbum();
		getAlbumsDiscounts()

    }, []);

	useEffect(() =>{
		if(album != null){
			getProductsQuantity()

		}
	}, [album])


	function getProductsQuantity(){
		 fetch(`${url_server}public/products/${album.album_id}/count`, {
            method: 'GET',
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            },
            })
            .then((response) => response.json())
            
            .then( (data) => {
                setProductsQuantity(data.response)

                

            })
            .catch((err) => {
                // console.log(err)
            });
	}


	function getAlbumsDiscounts(){
		 fetch(url_server + "public/albums/" + id + "/discounts", {
            method: 'GET',
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            },
            })
            .then((response) => response.json())
            
            .then( (data) => {
                // response = data.response
				// // console.log(data.response)
                // setProducts(data.response)
				setDiscounts(data.response)

            })
            .catch((err) => {
                // console.log(err)
            });

	}


	async function getAlbum(){

		var query = "?album_id=" + id;
	
	
		await fetch(url_server + "public/albums" + query, {
			method: 'GET',
			headers: {
				'Content-type': 'application/json; charset=UTF-8',
			},
			})
			.then( (response) => response.json())
			
			.then( (data) => {
				// response = data.response
				// // console.log(data.response)

				setAlbum(data.response[0])

			})
			.catch((err) => {
				// console.log(err)
			});

	}

    return (
		<>
			{/* <Helmet>
                <title>Albums</title>
                <meta name='og:description' content='Contact us if you have any question or requests'/>
				<meta property="og:image" content={`https://mysurfshottest.ap-south-1.linodeobjects.com/album-products-89/12aafa7c-115c-4913-aafe-87d94a3cf317.jpg`}
					/>
            </Helmet> */}
			<Navigation  />
			<div className='div_albums mb-5'>
				<DisplayInfo data={album} discountsGlobal={discounts} productsQuantity={productsQuantity} />
				<DisplayProducts album={album} discountsGlobal={discounts} productsQuantity={productsQuantity} />
			</div>
			

		</>
        

    )

  }



export default Album
