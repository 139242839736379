import Navigation from "../components/Navigation";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { useState, useRef, useEffect} from "react";
import { url_server } from '../settings';
import Form from 'react-bootstrap/Form';
import { GetDataFromUserId, saveSession } from '../controllers/AccountController';
import InputGroup from 'react-bootstrap/InputGroup';

function LoginError({content}){
    return(
        <Form.Text className="text-danger">
          {content}
        </Form.Text>
    );
}


function FormResetPassword(){
    const [data, setData] = useState({});
    const [loginError, setLoginError] = useState(<LoginError />)
    const [validated, setValidated] = useState(false);
    const [showPassword, setShowPassword] = useState(false)
    var ic_eye = require("../assets/ic_eye.png")
    var ic_eye_slash = require("../assets/ic_eye_slash.png")
    const [emailSent, setEmailSent] = useState(false)

    function goToSignIn(){
        window.location.href = "/account/login"
    }

    const validateData = (event) => {
        const form = event.currentTarget;
        event.preventDefault();

        if (form.checkValidity() === false) {
          event.stopPropagation();

        }else{
            
            // // console.log(data)
            sendEmail()
        }
        setValidated(true);
        
    };

    function sendEmail(){


        fetch(url_server + "public/users/reset-password", {
            method: 'POST',
            body: JSON.stringify({user_email: data.user_email}),
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            },
            })
            .then((response) => response.json())
            
            .then( (data) => {
                if(data.error){
                    alert(data.errorDescription)
                }else{
                    setEmailSent(true)

                }
    
            })
            .catch((err) => {
                // console.log(err)
            });

    }


    
    return(
        <div>
            {emailSent == false ? (
                <Form className='col-md-4 form_white_centered2' onSubmit={validateData}>
                    <h4 className="mb-4"><b>Forgot password </b></h4>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Enter your email address</Form.Label>
                        <Form.Control required type="email" placeholder="Enter email" onChange={(e) => setData((oldSearch) => ({...oldSearch, "user_email": e.target.value}))} />
                
                    </Form.Group>

                
                    <div className='text-center'>
            
                        <Button className='btn_primary_blue btn_register w-100' type="submit">Reset</Button>

                    </div>
                    
                </Form>
            ) : (
                <div className="col-md-5 form_white_centered_no_border text-center">
                    {/* <img src={img_successfull} alt="Success" className="mb-5" /> */}
                    <p className="fw-bold p_large">Email sent</p>
                    <p className="text-thin p_small">Check your inbox and follow the instructions to change your password. If you don't find our e-mail, check your spam.</p>
                    <Button className='btn_primary_blue btn_register  ms-auto' onClick={goToSignIn} >Log in</Button>

                </div>
            )}
            
        </div>
        
    );

}

function ForgotPasswordPage(){
   
    return(
        <div>
            <Navigation />
            <Container id="container_login">
                <FormResetPassword />

            </Container>

        </div>
    );

}

export default ForgotPasswordPage;