
import { SessionDetails } from "../controllers/AccountController"
import Navigation from "./Navigation"
import { Link, useParams, useLocation } from 'react-router-dom';

import Container from 'react-bootstrap/esm/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { Footer } from './Footer';
import { GoogleMap, Marker, useLoadScript } from "@react-google-maps/api";
import { useMemo, useState, useEffect, Component } from "react";
import { google_maps_api_key, url_server } from '../settings';
import Card from 'react-bootstrap/Card';
import Nav from 'react-bootstrap/Nav';
import Form from 'react-bootstrap/Form';
import Compressor from 'compressorjs';
import axios from 'axios';
import Modal from 'react-bootstrap/Modal';
import ProgressBar from 'react-bootstrap/ProgressBar';
import InputGroup from 'react-bootstrap/InputGroup';
import Spinner from 'react-bootstrap/Spinner';
import { ModalMovementDetails } from "./modals";


function ReportMovements({info}){
    const [movements, setMovements] = useState()
    const [accountDetails, setAccountDetails] = useState(SessionDetails())
    const img_wave = require('../assets/logo_green.png')
    const [selectedMovement, setSelectedMovement] = useState()
    const [showModalMovementDetails, setShowModalMovementDetails] = useState(false)


    useEffect(() =>{
        getMovementsGrouped()
    }, [])

    function getMovementsGrouped(){
        fetch(`${url_server}private/movements/${info.photographer_id}/grouped/payment`, {
            method: 'GET',
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
                'x-access-token': accountDetails.token
            },
            })
            .then((response) => response.json())
            
            .then( (data) => {
                setMovements(data.response)
            })
            .catch((err) => {
            });
    
    
    }

    const onClickMovement = (movement) => {
        setSelectedMovement(movement)
        setShowModalMovementDetails(true)
    }

    return(
        <div className="scrollable div_movements">
            {movements == null ? (
                <div className="text-center">
                    <Spinner animation="border" role="status" className="mt-5 ">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                </div>
            ) : (
                <>
                
                {movements.length === 0 ? (
                    <div>
                        <img className=" mt-5 profile_wave d-block ms-auto me-auto" src={img_wave} alt="logo" /> 
                        <p className="text_light_blue text-center mt-3" >No transactions to show</p>
    
                    </div>

                ) : (
                    <div>
                        {movements.map((obj, index) =>{
                            return(
                                

                                <div key={index} className="clickable" onClick={(e) => onClickMovement(obj)}>
                                    <div className="d-flex justify-content-between">
                                        <div>
                                            <p className=" text-blue m-0">{obj.total_products} {obj.total_products > 1 ? "items" : "item"} sold</p>

                                            <p className="text-thin text-grey m-0">{`${Intl.DateTimeFormat('en-au', {timeZone: "Australia/Brisbane", year: "numeric", month: "numeric", day: "numeric", hour: "numeric", minute: "numeric"}).format(new Date(obj.movement_datetime))} AEST`}</p>
                                        </div>
                                        <div>
                                            <p className="text-blue m-0"><b>AUD ${(parseFloat(obj.total_amount)/100).toFixed(2)}</b></p>
                                            <p className="text-thin text-grey p_small m-0">{obj.movement_stripe_balance_status} </p>

                                        </div>


                                    </div>
                                    <hr></hr>
                                </div>
                            )
                        })}
                        
                        
                        
                    </div>
                )}
                </>
            )}
            <ModalMovementDetails show={showModalMovementDetails} setShow={setShowModalMovementDetails} movement={selectedMovement} />

        </div>
    )
}

export {ReportMovements}