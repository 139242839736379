import Form from 'react-bootstrap/Form';
import { useState, useRef, useEffect} from "react";
import axios from 'axios';
import React, { Component } from 'react';
import { url_server } from '../settings';
import { SessionDetails } from '../controllers/AccountController';
import Compressor from 'compressorjs';
import FormGroup from "react-bootstrap/esm/FormGroup";


function MaskedInputPhone({label, defaultValue, required, setNewData}){

    const [maskedValue, setMaskedValue] = useState("")
    const [allowMasked, setAllowMasked] = useState(true)

    useEffect(() =>{
        // replace digits
        const newVal = defaultValue.replace(/^(\d{2}).*(\d{2})$/, (match, firstTwoDigits, lastTwoDigits) => {
            const middleDigits = match.slice(2, -2).replace(/\d/g, '*');
            return firstTwoDigits + middleDigits + lastTwoDigits;
        });

        setMaskedValue(newVal)   
    }, [])

    function changedValue(e){

        if(e.target.value != maskedValue){
            setNewData((oldSearch) => ({...oldSearch, "user_phone_number": e.target.value}))
            setAllowMasked(false)

        }else{
            setNewData((prevData) => {
                const newData = {...prevData}
                delete newData["user_phone_number"]
                return newData;
            })
            setAllowMasked(true)
        }

    }

    console.log(allowMasked)

    return(
        <Form.Group className="mb-3" controlId="formBasicPhoneNumber">
            <Form.Label className="fw-bold">{label}</Form.Label>
            <Form.Control required={required}  type="tel" pattern={!allowMasked ? "[04]{2}[0-9]{8}" : "[04]{2}[*]{6}[0-9]{2}"}  placeholder={label} defaultValue={maskedValue} onChange={changedValue}/>
        </Form.Group>
    )
}

export default MaskedInputPhone;