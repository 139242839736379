import { SessionDetails } from "../../controllers/AccountController"
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import React, {useState, useEffect } from "react";
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import MaskedInputPhone from "../../components/MaskedInput";
import { url_server } from "../../settings";
import { ModalConfirmPassword } from "../modals";

function PasswordError({content}){
    return(
        <Form.Text className="text-danger">
          {content}
        </Form.Text>
    );
}


function SectionSecurity({userInfo, setCanLeave, refreshUser}){

    const [newData, setNewData] = useState({})
    const [validated, setValidated] = useState(false);
    const [loginError, setLoginError] = useState(<PasswordError />)
    const [accountDetails, setAccountDetails] = useState(SessionDetails())
    var ic_eye = require("../../assets/ic_eye.png")
    var ic_eye_slash = require("../../assets/ic_eye_slash.png")
    const [showPassword, setShowPassword] = useState(false)
    const [showPasswordRepeat, setShowPasswordRepeat] = useState(false)
    const [minimumDate, setMinimumDate] = useState()
    // const [showModalConfirmPassword, setShowModalConfirmPassword] = useState(false);


    useEffect(() =>{
        if(Object.keys(newData).length > 0){
            setCanLeave(false)
        }
    }, [newData])

    const validateData = async (event) => {
        const form = event.currentTarget;
        event.preventDefault();

        if (form.checkValidity() === false) {
          event.stopPropagation();

        }else{

            if(newData.user_password != newData.user_repeat_password){
                setLoginError(<PasswordError content={"Passwords doesn't match"} />)
                
                return
            }else{
                setLoginError(<PasswordError />)
            }

            const data_updated = newData

            if(Object.keys(newData).includes("user_repeat_password")){
                delete data_updated.user_repeat_password
            }            
            
            updateUser(data_updated)
            
        }
        setValidated(true);
        
      };



    function updateUser(newData){

        fetch( `${url_server}private/users/${accountDetails.user_id}`, {
            method: 'PUT',
            body: JSON.stringify(newData),
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
                'x-access-token': accountDetails.token,
            },
            })
            .then((response) => response.json())
            
            .then(async (data) => {
                console.log(data)
                if(data.error){
                    if(Object.keys(data.errorDescription).includes("raw")){
                        alert(data.errorDescription.raw.message)

                    }else{
                        alert(data.errorDescription)
                    }
                   
                }else{
                    alert("Your data has been updated successfully")
                    window.scrollTo(0, 0)
                    setNewData({})
                    setCanLeave(true)
                    refreshUser()

                }


            })
            .catch((err) => {

            });

    }

    
    function passwordNormal(show){

        setShowPassword(show)
    }

    function passwordNormalRepeat(show){

        setShowPasswordRepeat(show)
    }


    return(
        <>
        
        {userInfo != null ? (

            <div className="mt-4">
                <Row className="gx-5">
                                        
                        <h5 className="text-blue mb-3"><b>Private details</b></h5>
                        <Form onSubmit={validateData}>   
                        <Col lg={6}> 

                            <Form.Group className="mb-3" controlId="formBasicPassword">
                                <Form.Label className="fw-bold">Old Password</Form.Label>
                                <InputGroup className="">
                                    <Form.Control required type={showPassword === false ? "password" : "text"} placeholder="Password" onChange={(e) => setNewData((oldSearch) => ({...oldSearch, "old_password": e.target.value}))}/>
                                    <Button className="btn_show_password" onClick={(e) => passwordNormal(!showPassword)}> 
                                    <img src={showPassword === false ? ic_eye : ic_eye_slash} 
                                            alt='Search button' 
                                            
                                            />
                                    </Button>
                                </InputGroup>
                                
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicPassword">
                                <Form.Label className="fw-bold">New Password</Form.Label>
                                <InputGroup className="">
                                    <Form.Control required type={showPassword === false ? "password" : "text"} placeholder="Password" onChange={(e) => setNewData((oldSearch) => ({...oldSearch, "user_password": e.target.value}))}/>
                                    <Button className="btn_show_password" onClick={(e) => passwordNormal(!showPassword)}> 
                                    <img src={showPassword === false ? ic_eye : ic_eye_slash} 
                                            alt='Search button' 
                                            
                                            />
                                    </Button>
                                </InputGroup>
                                
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicRepeatPassword">
                                <Form.Label className="fw-bold">Confirm New Password</Form.Label>
                                <InputGroup className="">
                                    <Form.Control required type={showPasswordRepeat === false ? "password" : "text"} placeholder="Password" onChange={(e) => setNewData((oldSearch) => ({...oldSearch, "user_repeat_password": e.target.value}))}/>
                                    <Button className="btn_show_password" onClick={(e) => passwordNormalRepeat(!showPasswordRepeat)}> 
                                            <img src={showPasswordRepeat === false ? ic_eye : ic_eye_slash} 
                                            alt='Search button' 
                                            
                                            />
                                    </Button>
                                </InputGroup>
                                
                                {loginError}
                            </Form.Group>
                            </Col>

                            <div>
                                <Button className='btn_primary_blue btn_register float-end' type="submit" >Save changes</Button>

                            </div>
                            
                        </Form>
                    
                </Row>
                {/* <ModalConfirmPassword show={showModalConfirmPassword} setShow={setShowModalConfirmPassword} onAccept={(e) =>updateUser(newData)} userInfo={userInfo} /> */}
            </div>

        ) : (
            ""
        )}
        </>
    )

    
}

export default SectionSecurity;