import Form from 'react-bootstrap/Form';


function SetDiscount({index, discount, discounts, setDiscounts, setDiscountsModified}){
    const ic_delete_grey = require('../../assets/ic_delete_grey.png')

    function deleteDiscount(e){
        e.preventDefault();
        setDiscounts(discounts=> discounts.filter((s,i)=>(i != index)))
        if(setDiscountsModified != null){
            setDiscountsModified(true)

        }
    }


    function editQuantity(e){
        const value = e.target.value
        const newobj = discounts[index]

        newobj.discount_quantity = value

        // 1. Make a shallow copy of the items
        let items = [...discounts];
        // 2. Make a shallow copy of the item you want to mutate
        let item = {...items[index]};
        // 3. Replace the property you're intested in
        item.discount_quantity = parseInt(value);
        // 4. Put it back into our array. N.B. we *are* mutating the array here, 
        //    but that's why we made a copy first
        items[index] = item;
        // 5. Set the state to our new copy
        // setDiscountsModified(true)
        if(setDiscountsModified != null){
            setDiscountsModified(true)

        }
        setDiscounts(items);
    }

    function editPercentage(e){
        const value = e.target.value


        // 1. Make a shallow copy of the items
        let items = [...discounts];
        // 2. Make a shallow copy of the item you want to mutate
        let item = {...items[index]};
        // 3. Replace the property you're intested in
        item.discount_percentage = parseFloat(parseFloat(value)/100);
        // 4. Put it back into our array. N.B. we *are* mutating the array here, 
        //    but that's why we made a copy first
        items[index] = item;
        // 5. Set the state to our new copy
        // setDiscountsModified(true)
        if(setDiscountsModified != null){
            setDiscountsModified(true)

        }
        setDiscounts(items);
    }


    return(
        <div className="div_discount p-3 mb-3">
            <div className="d-flex justify-content-between align-items-center">
                <p>Discount</p>
                <img src={ic_delete_grey} alt="delete" className="imgbtn_delete" onClick={deleteDiscount} />

            </div>
            <div className="d-flex  align-items-center w-100">
                <Form.Label className="me-3">Buy</Form.Label>
               
                <div className="me-3">
                    <Form.Control 
                    type="number" 
                    placeholder="Type here" 
                    defaultValue={discount.discount_quantity} 
                    // value={discount.discount_quantity}
                    onChange={editQuantity}
                    />

                </div>
                <Form.Label className="me-3">and get</Form.Label>
               
                <div className="me-3">
                    <Form.Control 
                    type="number" 
                    placeholder="Type here"
                    // min={1}
                    // max={99}
                    defaultValue={discount.discount_percentage != "" ? parseInt(parseFloat(discount.discount_percentage)*100) : ""} 
                    // value={discount.discount_percentage}
                    onChange={editPercentage}/>

                </div>
                <Form.Label className="me-3">% discount</Form.Label>

            </div>
        </div>
    )
}

export default SetDiscount