import Container from 'react-bootstrap/esm/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Navigation from '../components/Navigation';
import { useState, useRef, useEffect, useMemo} from "react";
import GroupSearchFilters, {GroupSearchAllFilters} from '../components/SectionFilters';
import SectionPhotosShort, {SectionPhotosLarge} from '../components/SectionPhotos';
import './pages.css';
import { SearchAlbumsInput } from '../components/FormComponents';
import { GoogleMap, Marker, useLoadScript } from "@react-google-maps/api";
import { google_maps_api_key, url_server } from '../settings';
import { Link, useParams, useLocation } from 'react-router-dom';
import {BottomScrollListener} from 'react-bottom-scroll-listener';


function SearchPhotos() {
    // const [inputSearch, setInputSearch] = useState({});
    var search = new URLSearchParams(useLocation().search).get("search");

    const [filters, setFilters] = useState(search != null && search != '' ? {search: search} : {});
    const [viewMap, setViewMap] = useState(false);

    const [albums, setAlbums] = useState()
    const [albumsMarkers, setAlbumsMarkers] = useState()

    const [page, setPage] = useState(0)
    const [limitItems, setLimitItems] = useState(30)
    const [center, setCenter] = useState({ lat: -28.166049075806885, lng: 153.53519109664748 })
    const [albumMapSelected, setAlbumMapSelected] = useState()

    const ic_map_selector = require('../assets/ic_map_selector.png')
    const ic_map_selector_selected = require('../assets/ic_map_selector_selected.png')
    const [scrollTop, setScrollTop] = useState(false)


    const { isLoaded } = useLoadScript({
        googleMapsApiKey: google_maps_api_key,
    })
    // const center = useMemo(() => ({ lat: -28.166049075806885, lng: 153.53519109664748 }), []);

    
   
    // const ref = useRef()

    function handleScroll(){
        // console.log("scroll")
        getAlbums(page+1);
        setPage(page+1)

    }

    useEffect(() => {
        const search_bar = document.querySelector('#div_search_bar')
        const search_bar_top = search_bar.offsetTop

        const onScroll = () => setScrollTop(window.scrollY >= search_bar_top);

        window.removeEventListener('scroll', onScroll);

        window.addEventListener('scroll', onScroll, { passive: true });
        return () => window.removeEventListener('scroll', onScroll);   
    }, [])

  

    useEffect(() => {
        setPage(0)
        getAlbums(0);
        getAlbumsForMap()
       
    }, [filters]);




    function clickMarker(e, index){
        // console.log(index)
        
        if(index >= 0){
            var albumSelected = albumsMarkers[index]
            setAlbumMapSelected(albumsMarkers[index])
   
            // setCenter({lat: parseFloat(albums[index].location_latitude), lng: parseFloat(albums[index].location_longitude)})
        }else{
            setAlbumMapSelected()
        }
        
        
    }


    async function getAlbums(p){
        var query = "?page=" + p+ "&quantity_page=" + limitItems;
        
        if(Object.keys(filters).includes("search")){
            query += "&search=" + filters.search
        }
        if(Object.keys(filters).includes("date")){
            query += "&date=" + filters.date
        }
        if(Object.keys(filters).includes("location_state")){
            query += "&location_state=" + filters.location_state
        }
        if(Object.keys(filters).includes("location_city")){
            query += "&location_city=" + filters.location_city
        }
        if(Object.keys(filters).includes("location_suburb")){
            query += "&location_suburb=" + filters.location_suburb
        }
        if(Object.keys(filters).includes("location_name")){
            query += "&location_name=" + filters.location_name
        }
        
        await fetch(url_server + "public/albums" + query, {
            method: 'GET',
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            },
            })
            .then((response) => response.json())
            
            .then( (data) => {
                // response = data.response

                if(p === 0){
                    setAlbums(data.response)

                }else{
                    const a = [...albums]
                    var newArray = a.concat(data.response)
                    // console.log(data.response)
                    // console.log(newArray)
                    setAlbums(newArray)
                    // setAlbums(prevItems => [...prevItems, ...data.response]);

                }

            })
            .catch((err) => {
                // console.log(err)
                // alert("Something went wrong. Please check your connection and try again.");
            });


    }

    async function getAlbumsForMap(){
        // var query = "?page=" + p+ "&quantity_page=" + limitItems;
        
        // if(Object.keys(filters).includes("search")){
        //     query += "&search=" + filters.search
        // }
        // if(Object.keys(filters).includes("date")){
        //     query += "&date=" + filters.date
        // }
        
        await fetch(url_server + "public/albums", {
            method: 'GET',
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            },
            })
            .then((response) => response.json())
            
            .then( (data) => {

                setAlbumsMarkers(data.response)

                

            })
            .catch((err) => {
                // console.log(err)
                // alert("Something went wrong. Please check your connection and try again.");
            });


    }
   
   

    return (
        <div>
            {/* <Navigation hideSearch={true} /> */}
            <Navigation searchBarTop={scrollTop}  showSearchBar={2} />

            
            <div className='div_albums '>
        

                <div id="div_search_bar" className='mb-5 ms-3 me-3'>
                    <SearchAlbumsInput filters={setFilters} viewmap={setViewMap} albums={albums} />

                </div>

            
                <Row >
                    <Col lg={viewMap == false ? 12 : 6} className={ viewMap == false ? "" : "scrollable row_content"}>
                        <SectionPhotosLarge  mapActive={viewMap} albums={albums} albumMapSelected={albumMapSelected} />
                        <BottomScrollListener onBottom={handleScroll} />


                    </Col>
                    <Col lg={6} className={ viewMap == false ? "hide" : "d-flex container_map transition-right"} >

                        <div className='flex-fill'>
                            {!isLoaded ? (
                                    <h1>Loading...</h1>
                                ) : (
                                    <GoogleMap
                                    className='map'
                                    mapContainerClassName="map-container"
                                    center={center}
                                    zoom={13}
                                    onClick={(e) => clickMarker(e, -1)}
                                    >

                                    {
                                    albumsMarkers != null ? (
                                        <>
                                        {albumsMarkers.map((obj, index) =>{
                                            return(
                                                <Marker
                                                    icon={{
                                                        url: (albumMapSelected != null && obj.album_id === albumMapSelected.album_id) ? ic_map_selector_selected : ic_map_selector,
                                                        // anchor: new google.maps.Point(5,5 ),
                                                        
                                                    }}
                                                    position={{lat: parseFloat(obj.location_latitude), lng: parseFloat(obj.location_longitude)} }
                                                    key={index}
                                                    onClick={(e) => clickMarker(e, index)}
                                                />
                                            )
                                        })}
                                        </>
                                    ) : ("")
                                    }

                                    </GoogleMap>
                                )}
                        </div>
                        
                    </Col>
                </Row>


            </div>

        </div>
        
    )
}

export default SearchPhotos;