import Container from "react-bootstrap/esm/Container";
import Navigation, { NavigationLandingPage } from "../components/Navigation";

function PrivacyPolicy() {
    return (
        <div>
        <Navigation />
        <Container className="mt-10">
            <div >
                <h1>Privacy Policy</h1>
                <div>
                    <p>At My Surf Shot, we are committed to protecting your privacy and ensuring the security of your personal information. This Privacy Policy outlines how we collect, use, and safeguard the information provided by surfers and photographers on our landing page.</p>
                    <h4>Information we collect</h4>
                    <h6>For Surfers:</h6>
                    <ul>
                        <li>Surfer's First Name</li>
                        <li>Surfer's Last Name</li>
                        <li>Surfer's Email Address</li>
                        <li>Surfer's Phone Number</li>
                        <li>Surfer's Gender</li>
                        <li>Surfer's Surf Level</li>
                        <li>Surfer's Preferred Surf Week</li>
                        <li>Surfer's Main Surf Spots</li>
                        <li>Surfer's Other Surf Spots</li>
                        <li>Surfer's Interest in Buying Photos</li>
                        <li>Surfer's Preferred Amount to Pay for Photos</li>
                        <li>Surfer's Interest in Buying Videos</li>
                        <li>Surfer's Preferred Amount to Pay for Videos</li>
                    </ul>
                    <h6>For Photographers:</h6>
                    <ul>
                        <li>Photographer's First Name</li>
                        <li>Photographer's Last Name</li>
                        <li>Photographer's Email Address</li>
                        <li>Photographer's Phone Number</li>
                        <li>Photographer's Date of Birth</li>
                        <li>Photographer's Gender</li>
                        <li>Photographer's Brand or Studio Name</li>
                        <li>Photographer's Instagram Handle</li>
                        <li>Photographer's Goals and Objectives</li>
                        <li>Photographer's Occupation</li>
                        <li>Photographer's Camera Equipment</li>
                        <li>Photographer's Preferred Type of Photos</li>
                        <li>Photographer's Preferred Surf Spots</li>
                        <li>Photographer's Minimum Price for Photos</li>
                        <li>Photographer's Maximum Price for Photos</li>
                        <li>Photographer's Minimum Price for Videos</li>
                        <li>Photographer's Maximum Price for Videos</li>
                        <li>Photographer's Minimum Price for Sequence Shots</li>
                        <li>Photographer's Maximum Price for Sequence Shots</li>
                        <li>Photographer's Available Discounts</li>
                    </ul>
                    <h4>Use of Information:</h4>
                    <p>The information collected from surfers and photographers is solely for marketing purposes and to provide updates regarding our services, promotions, and events. We may use this information to personalize the content and offerings presented to you, as well as to improve our website and communication strategies. Rest assured that this information will not be sent to third parties or sold.</p>
                    <h4>Data Security:</h4>
                    <p>We implement strict security measures to protect the confidentiality and integrity of the personal information provided by surfers and photographers. We employ industry-standard techniques to safeguard against unauthorized access, use, disclosure, or alteration of the collected information. However, please note that no method of data transmission over the internet or electronic storage is completely secure, and we cannot guarantee absolute security.</p>
                    <h4>Retention of Information:</h4>
                    <p>We will retain the collected information for as long as necessary to fulfill the purposes outlined in this Privacy Policy, unless a longer retention period is required or permitted by law. If you wish to have your information removed from our records, please contact us using the information provided in Section 6.</p>
                    <h4>Cookies and Tracking Technologies:</h4>
                    <p>Our website may use cookies and other tracking technologies to enhance user experience, analyze trends, and gather demographic information. These technologies do not collect personally identifiable information, and you have the option to disable them through your browser settings.</p>
                    <h4>Contact Us:</h4>
                    <p>If you have any questions, concerns, or requests regarding this Privacy Policy or the handling of your personal information, please contact us at letstalk@mysurfshot.com.au. We will make every effort to address your inquiry promptly and ensure your privacy is protected.</p>
                    <p>By providing your information on our landing page, you acknowledge that you have read and understood this Privacy Policy and consent to the collection, use, and storage of your personal information as described herein.</p>
                    <p>This Privacy Policy was last updated on 24/05/2023. We reserve the right to modify or update this policy at any time. It is your responsibility to review this Privacy Policy periodically for any changes.</p>
                    <br/>
                    <br/>
                    <p>My Surf Shot (ABN: 59 214 182 725)</p>
                
                </div>

            </div>

        </Container>
        </div>
        
    );
}



export default PrivacyPolicy;