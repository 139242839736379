import { useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Container from "react-bootstrap/esm/Container";



function PaymentComplete(){
    const img_successfull = require('../assets/img_successfull.png')
    useEffect(() =>{
        localStorage.removeItem("cart")

    }, [])

    function goToMain(){
        window.location.href = "/"
    }

    return(
        <Container fluid className="text-center mt-5">
            <div>
                {/* <img src={img_checked} alt="Checked" className="img_250 mt-5"/> */}
                {/* <img src={img_photo_mark} alt="Checked" className="img_250 mt-5"/> */}
                <img src={img_successfull} alt="Success" className="mb-3" />

                <h1>Payment completed!</h1>
                {/* <h3 className="text-thin text-blue mt-5" >Get ready for this incredible experience!</h3> */}
                {/* <h3 className="text-thin text-blue">Follow us on our social media to stay tuned and posted about our news.</h3> */}
                {/* <div className="mt-5"> */}
                {/* <FooterSocialNetworksIcons /> */}

                {/* </div> */}
                <div className="mt-5">
                    {/* <a href="/" className="text-blue">Go to home page</a> */}
                    <Button className='btn_primary_blue btn_register  ms-auto' onClick={goToMain} >Go to Home</Button>

                </div>
            </div>

        </Container>
    )

}

export default PaymentComplete