import Container from "react-bootstrap/esm/Container";
import Navigation, { NavigationLandingPage } from "../components/Navigation";

function TermsAndConditions() {
    return (
        <div>
        <Navigation />
        <Container className="mt-10">
            <div >
                <h1>Terms And Conditions</h1>

                <h3 className="text-blue">1. Definitions and Interpretation</h3>
    

                <h4 className="text-blue">1.1. Definitions</h4>
                <p>(a)	Account means the My Surf Shot profile of any User that contains basic information of that User.</p>
                <p>   (a)	Account means the My Surf Shot profile of any User that contains basic information of that User.</p>
                <p>   (b)	Customer means the person/surfer purchasing the Content via My Surf Shot.</p>
                <p>    (c)	Content means all content available for purchase from My Surf Shot, including Images and Video.</p>
                <p>    (d)	My Surf Shot means the Australian Company, My Surf Shot Pty Ltd (ABN:61669471185).</p>
                <p>    (e)	Photographer means the person who owns the Content, has the right to sell the Content, and is selling the artwork via My Surf Shot.</p>
                <p>    (f)	Platform means My Surf Shot as accessed via any internet browser or mobile app.</p>
                <p>    (g)	User means any person or entity registered with My Surf Shot as either a Photographer or Surfer or any other person accessing or using the Platform.</p>


                <h4 className="text-blue">1.2.	The interpretation of these Terms and Conditions unless it is expressly stated otherwise:</h4>

                <p>(a)	a reference to a part, clause, annexure, exhibit, appendix or schedule is a reference to a part of, clause of, an annexure, exhibit, appendix or schedule to this Agreement and a reference to this Agreement includes any annexure, exhibit, appendix and schedule;</p>
                <p>(b)	a reference to an agreement includes any variation or replacement of that agreement;</p>
                <p>(c)	a reference to one gender includes all other genders;</p>
                <p>(d)	the singular includes the plural and the opposite also applies;</p>
                <p>(e)	if the due date for any obligation is not a Business Day, the due date will be the next Business Day;</p>
                <p>(f)	all currency amounts are in Australian dollars;</p>
                <p>(g)	headings are provided for convenience and do not affect the interpretation of the documents making up this Agreement;</p>
                <p>(h)	unless used for the usual grammatical purpose, inverted commas around a term indicate industry jargon that will be interpreted according to how that term would be understood by an individual with expertise in the relevant industry;</p>
                <p>(i)	“include”, “includes” and “including” must be read as if followed by the words “without limitation”;</p>
                <p>(j)	a reference to a clause refers to clauses is a reference to its sub-clauses;</p>
                <p>(k)	if a word or phrase is defined, its other grammatical forms have corresponding meanings;</p>
                <p>(l)	agreements, representations and warranties made by two or more people will bind them jointly and severally;</p>
                <p>(m)	a reference to any legislation includes any consolidation, amendment, re-enactment or replacement of legislation;</p>
                <p>(n)	a person includes the person’s executors, administrators and permitted novatees and assignees;</p>
                <p>(o)	a reference to a person includes a firm, a body corporate, an unincorporated association or an authority and vice versa; </p>
                <p>(p)	a reference to conduct includes any omission, representation, statement or undertaking, whether or not in writing;</p>
                <p> (q)	no rule of construction will apply to a provision of a document to the disadvantage of a party merely because that party drafted the provision or would otherwise benefit from it; and</p>
                <p>(r)	if any part of the Agreement is invalid, unlawful or unenforceable, the invalid, unlawful or unenforceable part of the Agreement will not apply but the other parts of the Agreement will not be affected.</p>


                <h3 className="text-blue">2. Overview of My Surf Shot</h3>

                <h4 className="text-blue">2.1.	My Surf Shot provides a Platform to a community of registered users that is a marketplace where photographers may upload albums containing photos or videos of surfers for sale. Content available for sale will be visible on the Platform with a watermark and Purchased Content will be provided to the Customer with the watermark removed. By using our website, you agree not to copy or reproduce any content from our website, unless you have purchased it and obtained permission from the photographer.</h4>

                <h3 className="text-blue">3. Acceptance of Terms</h3>
                <h4 className="text-blue">3.1.	These terms and conditions (Terms) are entered into between My Surf Shot Pty Ltd ABN 59 214 182 725 (we, us or our) and the User (you, your), together the Parties and each a Party.</h4>
                <h4 className="text-blue">3.2.	You accept these Terms by creating an Account on My Surf Shot.</h4>


                <h3 className="text-blue">4. Modification of Terms</h3>
                
                <h4 className="text-blue">4.1.	My Surf Shot reserves the right, at its sole discretion, to modify or replace the Terms at any time. </h4>
                <h4 className="text-blue">4.2.	My Surf Shot will notify you if there is a material change to these Terms and the determination of what constitutes a material change will be at My Surf Shot’s sole discretion. </h4>
                <h4 className="text-blue">4.3.	You are solely responsible for reviewing and becoming familiar with any such modifications. </h4>
                <h4 className="text-blue">4.4.	Your continued use of My Surf Shot constitutes your acceptance of the Terms as modified.</h4>
                <h3 className="text-blue">5. Accounts</h3>

                <h4 className="text-blue">5.1.	To use My Surf Shot, you must open an Account by providing a valid name and email address.</h4>
                <h4 className="text-blue">5.2.	Any information you provide to My Surf Shot must be accurate, complete, and current at all times.</h4> 
                <h4 className="text-blue">5.3.	You must not use as a username the name of another person or entity or that is not lawfully available for use, a name or trademark that is subject to any rights of another person or entity other than you without appropriate authorisation, or a name that is otherwise offensive, vulgar, or obscene.</h4>
                <h4 className="text-blue">5.4.	You are responsible for maintaining the confidentiality of your password and are solely responsible for all activities resulting from the use of your password and conducted through your My Surf Shot account.</h4>
                <h4 className="text-blue">5.5.	By creating an Account, the User consents to receipt of any emails or communications which inform the User of My Surf Shot’s other publications, products, services and events and to promote third party goods and services in which My Surf Shot considers the User may be interested.</h4>
                <h3 className="text-blue">6. Content</h3>

                <h4 className="text-blue">6.1.	All Content uploaded on My Surf Shot will undergo a review process for approval, the review process will be limited to verifying the following:</h4>
                <p>(a)	The Content consists solely of surf photos or surf videos.</p>
                <p>(b)	Each Content Album contains surf photos and/or videos from a single shoot and includes information regarding the location, date, and time of that particular shoot.</p>
                <h4 className="text-blue">6.2.	My Surf Shot reserves the right to remove any material submitted to the Platform, or stored on its servers, or hosted or posted on the Platform.</h4>
                <h4 className="text-blue">6.3.	You agree that you are solely responsible for all Content that you make available on or through the Platform.</h4> 
                <h4 className="text-blue">6.4.	This clause will survive the termination or expiry of these Terms.</h4>
                <h3 className="text-blue">7. Photographer Warranties</h3>
                <h4 className="text-blue">7.1.	The Photographer represents, warrants and agrees that:</h4>
                <p>(a)	The Photographer is either the sole and exclusive owner of all Content uploaded to the Photographer’s Account, or the photographer has all rights, licences, consents and releases that are necessary to grant to us the rights in such Content without obtaining any additional consents or permissions or the payment of additional fees to any third parties.</p>
                <p>(b)	neither the Content nor the posting, uploading, publication, submission or transmission of the Content or our use of the Content on, through or by means of our Platform will infringe, misappropriate or violate a third party’s intellectual property rights, or rights of publicity or privacy, or result in the violation of any applicable law or regulation.</p>
                <p> (c)	The Photographer has not granted to any third party any rights or interest in any Content that conflicts with any of the rights granted to My Surf Shot under these Terms, including without limitation, allowing any other party to license or distribute the Content.</p>
                <h4 className="text-blue">7.2.	The Photographer understands and agrees that:</h4>
                <p>(a)	The photographer’s Content will not contain any matter which violates any applicable law, rule or regulation and will not defame, violate the right or privacy or publicity, or infringe the trademark, intellectual property rights or proprietary rights, of any third party;</p>
                <p> (b)	The Photographer’s Content will not include any material that would be considered to be obscene or pornographic;</p>
                <p> (c)	The Photogrpher’s Content will not contain any viruses, spyware, Trojan horses, time bombs, or other similar harmful or deleterious programming routines or code.</p>
                <h3 className="text-blue">8. User Warranties</h3>
                <h4 className="text-blue">8.1.	The User represents, warrants and agrees that:</h4>
                <p>  (a)	there are no legal restrictions preventing the User from entering into these Terms;</p>
                <p> (b)	all information and documentation the User has provided to My Surf Shot in connection with these Terms is true, correct and complete;</p>
                <p>   (c)	the User has not relied on any representations or warranties made by My Surf Shot in relation to the Platform other than as expressly stated in these Terms;</p>
                <h4 className="text-blue">8.2.	The User understands and agrees that they will not do or attempt to do anything that is unlawful or inappropriate when using the Platform including:</h4>
                <p>   (a)	anything that would constitute a breach of an individual’s privacy or any other legal rights;</p>
                <p>    (b)	using the Platform to defame, harass, threaten, menace or offend any person;</p>
                <p>    (c)	using the Platform for unlawful purposes;</p>
                <p>    (d)	interfering with any user of the Platform;</p>
                <p>   (e)	tampering with or modifying the Platform;</p>
                <p>   (f)	using the Platform to send unsolicited electronic messages;</p>
                <p>   (g)	using data mining, robots, screen scraping or similar data gathering and extraction tools on the Platform; or</p>
                <p>   (h)	facilitating or assisting a third party to do any of the above acts.</p>
                <h4 className="text-blue">8.3.	If the User is using the Platform on behalf of an employer or a business entity, the User, in their individual capacity, represents and warrants that they are authorised to act on behalf of their employer or the business entity and to bind the entity and the entity’s personnel to these Terms.</h4>
                <h3 className="text-blue">9. Storage Policy</h3>
                <h4 className="text-blue">9.1.	My Surf Shot maintains a fair storage policy to ensure stable and fast service to all users.</h4>
                <h4 className="text-blue">9.2.	To maintain optimisation for hosting:</h4>
                <p>(a)	Photo albums will be automatically deleted in 180 days; and</p>
                <p> (b)	video albums will be automatically deleted in 90 days.</p>
                <h4 className="text-blue">9.3.	Photographers may request to remove their uploaded Content at any time by contacting customer service (letstalk@mysurfshot.com.au).</h4>
                <h4 className="text-blue">9.4.	Purchased Content will be available for download in the Customer's profile for:</h4>
                <p> (a)	90 days where the purchased Content is a Video; and</p>
                <p>  (b) 180 days for the duration of the Platform where the Purchased Content is a Photo.</p>
                <h4 className="text-blue">9.5.	The Platform should not be used or relied upon for storage of Content and Photographers are directed to retain their own copies of all Content posted on the Platform.</h4>
                <h3 className="text-blue"> 10. Licence to My Surf Shot</h3>
                <h4 className="text-blue">10.1.	The Photographer hereby grants to My Surf Shot, and My Surf Shot hereby accepts, subject to these Terms, a non-exclusive, non-transferable, non-sublicensable license to the Content. </h4>
                <h4 className="text-blue">10.2.	In granting this license, the Photographer understands and agrees that My Surf Shot is entitled to use any of the submitted Content for any purpose, including but not limited to:</h4>
                <p>  (a)	promotion, marketing or publicity purposes for the Platform.</p>
                <p> (b)	display on My Surf Shot’s social media platforms and in any digital communications.</p>
                <h3 className="text-blue">11. Licence to Customer</h3>
                <h4 className="text-blue">11.1.	Once the fee has been paid by the Customer, the Photographer hereby grants the Customer a perpetual, worldwide non-exclusive, non-transferable, non-sublicensable license to exploit the Content purchased by the Customer. </h4>
                <h4 className="text-blue">11.2.	The use of the Content purchased on this platform is for private use only and does not allow any commercial use.</h4>
                <h4 className="text-blue">11.3.	Commercial use of any Content available on the Platform may be negotiated with the Photographer directly. You can get assistance to connect to the Photographer by contacting customer service (letstalk@mysurfshot.com.au).</h4>

                <h3 className="text-blue">12. Intellectual Property</h3>
                <h4 className="text-blue">12.1.	All proprietary rights, titles and interests in a Photographer’s Content and all intellectual property rights related thereto shall be and remain with the Photographer. Neither My Surf Shot nor Users shall have any right, title or interest in the Content nor in any related intellectual property rights except as expressly set forth herein.</h4>
                <h4 className="text-blue">12.2.	All copyrights and other intellectual property rights to My Surf Shot and its components, including the software, design and operation of the Platform and images displayed through the Platform, belong to My Surf Shot, except to the extent that these rights are legally vested in the User. </h4>
                <h4 className="text-blue">12.3.	Without the prior written consent of My Surf Shot or the respective Photographer it is prohibited to reproduce, distribute, exploit, or create derivative works from the material on which the rights of My Surf Shot or the respective Photographer rest.</h4>
                <h4 className="text-blue">12.4.	In the event of a breach of this clause attributable to a User, My Surf Shot or the Photographer reserves all rights vested in it by law, including the right to claim reasonable damages and immediate cancellation of the agreement.</h4>
                <h4 className="text-blue">12.5.	This clause will survive the termination or expiry of these Terms.</h4>
                <h3 className="text-blue">13. No injunction</h3>
                <h4 className="text-blue">13.1.	All the rights, licences, privileges, and property herein granted to My Surf Shot are irrevocable and not subject to rescission, restraint or injunction under any circumstances. In the event of any breach of this Agreement or any portion thereof by My Surf Shot, the sole remedy of the User will be an action at law for damages and in no event will the User have the right to injunctive relief or to enjoin or restrain or otherwise interfere with the broadcast, distribution, exhibition or other exploitation of the Entry whether by My Surf Shot or any third party.</h4>
                <h3 className="text-blue">14. Billing and Payments</h3>
                <h4 className="text-blue">14.1.	All pricing on My Surf Shot is in Australian dollars (AUD).</h4>
                <h4 className="text-blue">14.2.	All payments for purchases made on My Surf Shot will be processed through Stripe (the third-party payment provider.) My Surf Shot do not store any credit card details, and all payment information is collected and stored through the third-party payment provider.</h4>
                <h4 className="text-blue">14.3.	Photographers must link their Stripe merchant account to their My Surf Shot Account before they will be able to receive any payments that may arise from the sale of any Content. </h4>
                <h4 className="text-blue">14.4.	Pricing </h4>
                <p> (a)	Photographers will set the price for the content that they upload and may set automatic discounts for multiple purchases at their discretion. </p>
                <p>  (b)	The Customer shall be responsible for paying Stripe's transaction fee, which will be in addition to the displayed price and inclusive of any applicable GST.</p>
                <p>  (c)	An invoice will be issued by My Surf Shot to the Customer following the purchase of any Content.</p>
                <h4 className="text-blue"></h4>14.5.	Fees
                <p>   (a)	My Surf Shot will retain 20% of all sales, and the remaining 80% will be held in the account of the seller, where it will be available for withdrawal.</p>
                <p> (b)	The transfer may take up to 7 days and after that it may only be available to withdraw after 2 days.</p>
                <p>  (c)	The percentage of payment retained by the company may be subject to modifications through promotions or partnerships.</p>
                <h4 className="text-blue">14.6.	Photographer Payment Withdrawals</h4>
                <p>  (a)	My Surf Shot will make monthly payments with to the photographer on the last day of the month.</p>
                <p>  (b)	To request a withdrawal of funds from their account, the seller must provide a valid Australian Business Number (ABN) or Australian Company Number (ACN) which will be verified by My Surf Shot. </p>
                <p>  (c)	Requests for payment will be transferred to the Photographer’s designated bank account.</p>
                <p>  (d)	A photographer may withdraw the total amount available in their Account or withdraw an exact amount from any individual sale. Partial withdrawals of any sale amount will not be permitted. </p>
                <h4 className="text-blue">14.7.	The User does acknowledge and agree that My Surf Shot has no control over the actions of the third-party payment provider, and your use of the third-party payment method may be subject to additional terms and conditions.</h4>
                <h4 className="text-blue">14.8.	The User agrees not to pay or attempt to pay by fraudulent or unlawful means. </h4>
                <h3 className="text-blue">15. Release and Indemnity</h3>
                <h4 className="text-blue">15.1.	By creating an Account, Photographers’ warrant to My Surf Shot that their submitted Content is the original work of the Photographer and does not contain any material which would infringe the rights of any third party, including any copyright, trademark, registered design, patent or any other third-party intellectual property rights.</h4>
                <h4 className="text-blue">15.2.	My Surf Shot accepts no responsibility for incorrect assignment of a photographer’s moral rights where a User has provided incorrect information and that User is responsible for any claim of loss or damage whether direct, indirect, special or consequential, arising from such circumstances. </h4>
                <h4 className="text-blue">15.3.	Users agree to indemnify My Surf Shot and keep My Surf Shot indemnified against all claims and costs incurred by third parties arising from a breach of the warranties set out in this clause.</h4>
                <h3 className="text-blue">16. Liability of My Surf Shot </h3>
                <h4 className="text-blue">16.1.	Except for any liability which cannot be excluded by law, My Surf Shot is not responsible for and is excluded from all liability for any personal injury or any loss or damage whether direct, indirect, special or consequential, arising from or in any way connected with the Platform, including:</h4>
                <p> (a)	Any problems or technical malfunction (whether or not under My Surf Shot’s control) of any telephone network or lines, computer online systems, servers, or providers, computer equipment, software, technical problems or traffic congestion on any computer system or at any website, or any combination thereof, including but not limited to any injury or damage to Users or any other person’s property related to or resulting from the use of the Platform;</p>
                <p>  (b)	Any incorrect or inaccurate information, either caused by users or by any of the equipment or programming associated with or utilised in connection with the Platform, or by any technical error that may occur in the course of the use of the Platform;</p>
                <p>  (c)	any error, omission, interruption, deletion, defect, delay in operation or transmission, communications line failure, theft or destruction or third part interference or unauthorised access to or alteration of entries or User’s details;</p>
                <p>  (d)	Nothing in these Terms limits, excludes or modifies or purports to limit, exclude or modify the statutory consumer guarantees or implied warranties as provided under the Competition and Consumer Act 2010 (Cth) or any other applicable consumer protection laws.</p>
                <h3 className="text-blue">17. General Provisions</h3>
                <h4 className="text-blue">17.1.	Jurisdiction</h4>
                <p>  (a)	These Terms are governed by, and are to be construed in accordance with, the laws enforceable in Queensland, Australia. Each party submits to the exclusive jurisdiction of the courts exercising jurisdiction in Queensland, Australia and any court hearing appeals from those courts.</p>
                <h4 className="text-blue">17.2.	Assignment</h4>
                <p>   (a)	These Terms are binding upon and attach to the benefit of the parties’ heirs, executors, administrators, successors, and permitted assigns. My Surf Shot may assign its rights and obligations under these Terms, including without limitation, any assignment resulting from any corporate reorganization, merger, sale of substantially all the assets to which these Terms relate. Your obligations under these Terms are personal and may be assigned only with My Surf Shot’s prior written consent; however, your right to receive payment may be assigned without My Surf Shot’s prior consent.</p>
                <h4 className="text-blue">17.3.	Relationship of the Parties</h4>
                <p>   (a)	Nothing in these Terms will constitute the relationship of an employer and employee, a principal-agent, partnership, or a joint venture between the parties. You are responsible for obtaining and maintaining all applicable business licenses and insurance, and for timely payment of all income, payroll, and employment-related taxes, including without limitation all unemployment, workers compensation, income tax withholding, and any other taxes of any nature.</p>
                <h4 className="text-blue">17.4.	Notices</h4>
                <p>   (a)	Notices to be served in accordance with these Terms may be served by email, or by mail, at the election of My Surf Shot. However, email shall be the predominant source of communication. You agree to notify My Surf Shot of any change in your email or mail address for the purpose of notification pursuant to this Agreement.</p>
                <h4 className="text-blue">17.5.	Termination of Services</h4>
                <p>    (a)	My Surf Shot reserves the right to suspend or discontinue the availability of the Platform, and to remove any Content at any time at its sole discretion and without prior notice. </p>
                <p>   (b)	Your access to and use of My Surf Shot may be interrupted from time to time as a result of equipment malfunction, updating, maintenance or repair of the Site or any other reason within or outside of our control. </p>
                <p>   (c)	My Surf Shot may also impose limits on or restrict your access to parts of or all of the Platform without notice or liability. </p>
                <h4 className="text-blue">17.6.	Privacy</h4>
                <p>   (a)	The collection, use and disclosure of personal information provided in connection with the Platform is governed by My Surf Shot’s privacy policy available at https://www.mysurfshot.com.au/privacy-policy.</p>
                <h4 className="text-blue">17.7.	Severance</h4>
                <p>   (a)	Any provision in these Terms which is invalid or unenforceable in any jurisdiction is to be read down for the purpose of that jurisdiction, if possible, so as to be valid and enforceable, and otherwise must be severed to the extent of the invalidity or unenforceability, without affecting the remaining provisions of these Terms or affecting the validity or enforceability of that provision in any other jurisdiction.</p>
                <h4 className="text-blue">17.8.	Entire Agreement</h4>
                <p>   (a)	These Terms embody the entire agreement between My Surf Shot and each User with respect to use of the Platform.</p>



                {/* <h4>Information we collect</h4>
                <div>
                    <p>At My Surf Shot, we are committed to protecting your privacy and ensuring the security of your personal information. This Privacy Policy outlines how we collect, use, and safeguard the information provided by surfers and photographers on our landing page.</p>
                    <h4>Information we collect</h4>
                    <h6>For Surfers:</h6>
                    <ul>
                        <li>Surfer's First Name</li>
                        <li>Surfer's Last Name</li>
                        <li>Surfer's Email Address</li>
                        <li>Surfer's Phone Number</li>
                        <li>Surfer's Gender</li>
                        <li>Surfer's Surf Level</li>
                        <li>Surfer's Preferred Surf Week</li>
                        <li>Surfer's Main Surf Spots</li>
                        <li>Surfer's Other Surf Spots</li>
                        <li>Surfer's Interest in Buying Photos</li>
                        <li>Surfer's Preferred Amount to Pay for Photos</li>
                        <li>Surfer's Interest in Buying Videos</li>
                        <li>Surfer's Preferred Amount to Pay for Videos</li>
                    </ul>
                    <h6>For Photographers:</h6>
                    <ul>
                        <li>Photographer's First Name</li>
                        <li>Photographer's Last Name</li>
                        <li>Photographer's Email Address</li>
                        <li>Photographer's Phone Number</li>
                        <li>Photographer's Date of Birth</li>
                        <li>Photographer's Gender</li>
                        <li>Photographer's Brand or Studio Name</li>
                        <li>Photographer's Instagram Handle</li>
                        <li>Photographer's Goals and Objectives</li>
                        <li>Photographer's Occupation</li>
                        <li>Photographer's Camera Equipment</li>
                        <li>Photographer's Preferred Type of Photos</li>
                        <li>Photographer's Preferred Surf Spots</li>
                        <li>Photographer's Minimum Price for Photos</li>
                        <li>Photographer's Maximum Price for Photos</li>
                        <li>Photographer's Minimum Price for Videos</li>
                        <li>Photographer's Maximum Price for Videos</li>
                        <li>Photographer's Minimum Price for Sequence Shots</li>
                        <li>Photographer's Maximum Price for Sequence Shots</li>
                        <li>Photographer's Available Discounts</li>
                    </ul>
                    <h4>Use of Information:</h4>
                    <p>The information collected from surfers and photographers is solely for marketing purposes and to provide updates regarding our services, promotions, and events. We may use this information to personalize the content and offerings presented to you, as well as to improve our website and communication strategies. Rest assured that this information will not be sent to third parties or sold.</p>
                    <h4>Data Security:</h4>
                    <p>We implement strict security measures to protect the confidentiality and integrity of the personal information provided by surfers and photographers. We employ industry-standard techniques to safeguard against unauthorized access, use, disclosure, or alteration of the collected information. However, please note that no method of data transmission over the internet or electronic storage is completely secure, and we cannot guarantee absolute security.</p>
                    <h4>Retention of Information:</h4>
                    <p>We will retain the collected information for as long as necessary to fulfill the purposes outlined in this Privacy Policy, unless a longer retention period is required or permitted by law. If you wish to have your information removed from our records, please contact us using the information provided in Section 6.</p>
                    <h4>Cookies and Tracking Technologies:</h4>
                    <p>Our website may use cookies and other tracking technologies to enhance user experience, analyze trends, and gather demographic information. These technologies do not collect personally identifiable information, and you have the option to disable them through your browser settings.</p>
                    <h4>Contact Us:</h4>
                    <p>If you have any questions, concerns, or requests regarding this Privacy Policy or the handling of your personal information, please contact us at letstalk@mysurfshot.com.au. We will make every effort to address your inquiry promptly and ensure your privacy is protected.</p>
                    <p>By providing your information on our landing page, you acknowledge that you have read and understood this Privacy Policy and consent to the collection, use, and storage of your personal information as described herein.</p>
                    <p>This Privacy Policy was last updated on 24/05/2023. We reserve the right to modify or update this policy at any time. It is your responsibility to review this Privacy Policy periodically for any changes.</p>
                    <br/>
                    <br/>
                    <p>My Surf Shot (ABN: 59 214 182 725)</p>
                
                </div> */}

            </div>

        </Container>
        </div>
        
    );
}



export default TermsAndConditions;