import Navigation from "../components/Navigation";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { useState, useRef, useEffect} from "react";
import { url_server } from '../settings';
import Form from 'react-bootstrap/Form';
import { GetDataFromUserId, saveSession } from '../controllers/AccountController';
import InputGroup from 'react-bootstrap/InputGroup';
import {useLocation } from 'react-router-dom';


function LoginError({content}){
    return(
        <Form.Text className="text-danger">
          {content}
        </Form.Text>
    );
}


function FormLogin(){
    const [data, setData] = useState({});
    const [loginError, setLoginError] = useState(<LoginError />)
    const [validated, setValidated] = useState(false);
    const [showPassword, setShowPassword] = useState(false)
    var ic_eye = require("../assets/ic_eye.png")
    var ic_eye_slash = require("../assets/ic_eye_slash.png")
    const origin = new URLSearchParams(useLocation().search).get("origin");


    const validateData = (event) => {
        const form = event.currentTarget;
        event.preventDefault();

        if (form.checkValidity() === false) {
          event.stopPropagation();

        }else{
            login();
            // // console.log(data)
        }
        setValidated(true);
        
      };


    async function login(){
        await fetch(url_server + 'public/login', {
            method: 'POST',
            body: JSON.stringify(
              // Add parameters here
              data
            ),
            headers: {
              'Content-type': 'application/json; charset=UTF-8',
            },
          })
            .then((response) => response.json())
            .then( (data) => {
            // GO TO SUCCESS PAGE
            // console.log(data)
                if(data.error){

                    setLoginError(<LoginError content={data.errorDescription} />)

                }else{
                    setLoginError(<LoginError content={""} />)
                    if(!(data.response.user_id && data.response.user_first_name && data.response.user_last_name && data.response.user_default_profile)){
                        alert("Something went wrong. Please check your connection and try again.")
                    }else{

                        saveSession(data.response)

                        if(origin == "cart"){
                            window.location.href="/cart"
                            return;
                        }

                        if(data.response.user_default_profile === 2){
                            window.location.href='/'+data.response.user_unique_id
                        }else{
                            window.location.href='/account/purchases'

                        }
                            
                    }

                }

            })
            .catch((err) => {
                alert("Something went wrong. Please check your connection and try again.");
            });

    }

    function passwordNormal(show){

        setShowPassword(show)
    }

    return(
        <div>
            <Form className='col-md-4 form_white_centered2' onSubmit={validateData}>
                <h4 className="mb-4"><b>Log In</b></h4>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Email address</Form.Label>
                    <Form.Control required type="email" placeholder="Enter email" onChange={(e) => setData((oldSearch) => ({...oldSearch, "user_email": e.target.value}))} />
              
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label>Password</Form.Label>
                    {/* <Form.Control required type="password" placeholder="Password" onChange={(e) => setData((oldSearch) => ({...oldSearch, "user_password": e.target.value}))}/> */}
                
                    <InputGroup className="">
                        <Form.Control required type={showPassword === false ? "password" : "text"} placeholder="Password" onChange={(e) => setData((oldSearch) => ({...oldSearch, "user_password": e.target.value}))}/>
                        <Button className="btn_show_password" onClick={(e) => passwordNormal(!showPassword)}> 
                                <img src={showPassword === false ? ic_eye : ic_eye_slash} 
                                alt='Search button' 
                                 
                                />
                        </Button>
                    </InputGroup>
                    
                    <div className='mt-2'>
                        <p className='text_display_block me-1'>Don't have an account?</p>
                        <a className='text_display_block text-dark fw-bold' href={origin == "cart" ? "/sign-up?origin=cart" : "/sign-up"}>Register</a>
                    </div>
                </Form.Group>
                {loginError}
                <div className='text-center'>
                    {/* <Button variant="primary" type="submit" className='mb-4 mt-4' >
                        Submit
                    </Button> */}
                    <Button className='btn_primary_blue btn_register w-100' type="submit">Log In</Button>

                    <div className='mt-2'>
                        <p className='text_display_block'>Forgot your password?</p>
                        <a className='text_display_block' href="/account/reset-password">Reset</a>
                    </div>
                    

                </div>
                
            </Form>
        </div>
        
    );

}

function LoginPage(){
    return(
        <div>
            <Navigation showSearchBar={0} />
            <Container id="container_login">
                <FormLogin />

            </Container>

        </div>
    );

}

export default LoginPage;