import GroupSearchFilters from '../components/SectionFilters';
import PhotoPurchaseCard from '../components/SectionPurchaseCard';
import Container from 'react-bootstrap/esm/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import SectionPhotosShort, { SectionPhotosShortCarousel } from '../components/SectionPhotos';
import Navigation from '../components/Navigation'; 
import { SearchBar, SearchBarMultipleElements } from '../components/FormComponents';
import {ListNextSessions} from '../components/Lists';
import { Footer } from '../components/Footer';

import { GoogleMap, Marker, useLoadScript } from "@react-google-maps/api";
import { useMemo, useState } from "react";
import { google_maps_api_key } from '../settings';
import Accordion from 'react-bootstrap/Accordion';
import { SessionDetails } from '../controllers/AccountController';



function FaqSection(){
    const [sessionDetails, setSessionDetails] = useState(SessionDetails())

    return(
        <div className=' mb-5 full_height position-relative' >
            <div className='text-center m-5'>
                {/* <h1>Latest Sessions</h1> */}
                <p className='display-3 heading_font text-blue'>FAQ</p>

            </div>
            <Container fluid >

                <Row className='mt-5 right_margin left_margin'>
                    <Col lg={6} >
                       
                        <Accordion>
                            <Accordion.Item eventKey="0" >
                                <Accordion.Header>What can I sell?</Accordion.Header>
                                <Accordion.Body>
                                <p>You can sell surf photos and videos taken by you.</p>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="1" >
                                <Accordion.Header>How much does it costs?</Accordion.Header>
                                <Accordion.Body>
                                <p>The platform usage is free and available to everyone. All the sales in our platform have a 20% administration fee, which will be already deducted from the customer’s payment.</p>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="2">
                                <Accordion.Header>How do I post a session?</Accordion.Header>
                                <Accordion.Body>
                                <p>If you are not registered, just sign up through the log in button. If you are registered, log it to your profile. Then, again on the top right, open the options list and click on “Create an album”. </p>
                                <li>Add location, date, time, and category;</li>
                                <li>Set price and discounts;</li>
                                <li>Upload the photos and videos you took in that session;</li>
                                <li>Edit individual prices if wanted;</li>
                                <li>Review information before posting.</li>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>

                    </Col>
                    <Col lg={6} >
                        <Accordion>
                            <Accordion.Item eventKey="3">
                                <Accordion.Header>What will be the price of my photos and videos?</Accordion.Header>
                                <Accordion.Body>
                                <p>You will define your own prices, based on your experience, camera used and expectations.</p>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="4">
                                <Accordion.Header>How do I get paid?</Accordion.Header>
                                <Accordion.Body>
                                    <p>All your sales will be transferred to your balance on your profile after de fee deduction. The money can take up to 2 days to be shown on your balance and up to 7 days to be available.</p>
                                    <li>To be able to receive your payments you will have to add your “Payment information” to your account.</li>
                                    <li>By the end of the month, we will automatically transfer your available funds to your account. You are able to request a earlier withdrawal on your profile as soon as your funds are available.</li>
                                    <li>For each payment, we will provide you with a Recipient-Created Tax Invoice to ease your management.</li>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="5">
                                <Accordion.Header>Can I sell outside Australia?</Accordion.Header>
                                <Accordion.Body>
                                <p>With our payments solution we can get payments in other currencies besides AUD, but our data base just includes surf spots in Australia at the moment. Follow us on our social media and keep posted for any news. </p>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                            

                    </Col>
            
                </Row>

                
            </Container>
            <div className='text-center m-5'>
                <h5 className='text-blue'><b>Sign up and start selling today!</b></h5>
                <Button className='btn_primary_blue btn_register' size='lg' onClick={(e) => {
                    e.preventDefault();
                    if(sessionDetails.user_unique_id == null || sessionDetails.user_unique_id == ""){
                        window.location.href="/account/login"

                    }else{
                        window.location.href='/'+sessionDetails.user_unique_id;
                    }
                    } }>Sell your shots</Button>
            </div>
                
        </div>
    )
}

function BenefitsSection(){

    const img_photographers_info_1 = require('../assets/img_photographers_info_1.jpg')
    const img_photographers_info_2 = require('../assets/img_photographers_info_2.jpg')
    const [sessionDetails, setSessionDetails] = useState(SessionDetails())


    return(
        <div className=' bg-light-green '>
            <div className='mt-5 div_titles text-center w-100'>
                {/* <h1>Next Sessions</h1> */}
                <p className='display-3 heading_font text-blue'>Benefits</p>
            </div>
            <Container fluid className=' mt-5'>
                <Row >
                    <Col xs={{ order: 'last' }} sm={{ order: 'last' }} md={{ order: 'last' }} lg={{span:5, order:"first"}} xxl={5}>
                        <div className='left_margin me-3 mt-5'>
                            <p className='display-3 heading_font text-blue'>Spend More Time Doing What You Love</p><br/><br/>
                            <h5 className='text-blue'>Earn money doing what you loved while we do the rest!</h5><br/><br/>
                            <h5 className='text-blue'>Promote your work through our portfolio where you can display your latest shots, people can contact you or follow your work on the social media.</h5><br/><br/>
                            <h5 className='text-blue'>Promote your work through “next sessions” where everyone can see where to find you and buy your shots!</h5><br/><br/>
                            <Button className='btn_primary_blue btn_register'  size='lg' onClick={(e) => {
                                e.preventDefault();
                                if(sessionDetails.user_unique_id == null || sessionDetails.user_unique_id == ""){
                                    window.location.href="/account/login"

                                }else{
                                    window.location.href='/'+sessionDetails.user_unique_id;
                                }
                                } }>Share your shots!
                            </Button>
                        </div>
                        

                    </Col>

                    <Col  xs={{ order: 'first' }} sm={{ order: 'first' }}  md={{order: 'first' }} lg={7} xxl={7} className='img_landing_container '>
                        <div className='img_wrapper' id="id_img_landing_photographer">
                            <img className='img_landing_special' src={img_photographers_info_1} alt="Image Photographer" />

                        </div>
                        
                    </Col>
                </Row>

                
            </Container>
            <Container fluid >
                <Row >

                    <Col lg={7} xxl={7}  className='pt-5  img_landing_container'>
                        <div className='img_wrapper' id="id_img_landing_surfer">
                            <img className='img_landing_special' src={img_photographers_info_2} alt="Image Photographer" />

                        </div>
                        
                    </Col>

                    <Col lg={5} xxl={5}>
                        <div className='right_margin ms-3 mt-5 mb-5'>
                            <p className='display-3 heading_font text-blue'>Selling Photos or Videos Has Never Been so Easy!</p><br/><br/>
                            <h5 className='text-blue'>We simplify the sale process, forget about boring admin jobs! We do that for you so you can spend more time on what really matters.</h5><br/><br/>
                            <h5 className='text-blue'>We provide invoicing reports so you can keep track of every sale when needed.</h5><br/><br/>
                            <h5 className='text-blue'>Be your own boss! Set prices and discounts for your own products and we will display them for you.</h5><br/><br/>
                            <Button className='btn_primary_blue btn_register'  size='lg' onClick={(e) => {
                                e.preventDefault();
                                if(sessionDetails.user_unique_id == null || sessionDetails.user_unique_id == ""){
                                    window.location.href="/account/login"

                                }else{
                                    window.location.href='/'+sessionDetails.user_unique_id;
                                }
                                } }>Sell your shots!
                            </Button>
                        </div>
                        

                    </Col>

                    
                </Row>

                
            </Container>
        </div>
    )
}

function HowItWorksSection(){

    const img_hw_1 = require('../assets/img_photographers_hw_1.png')
    const img_hw_2 = require('../assets/img_photographers_hw_2.png')
    const img_hw_3 = require('../assets/img_photographers_hw_3.png')

    const [sessionDetails, setSessionDetails] = useState(SessionDetails())

    return (
        <div className=' mb-5 full_height position-relative' >
            <div className='text-center m-5'>
                {/* <h1>Latest Sessions</h1> */}
                <p className='display-3 heading_font text-blue'>How it works</p>

            </div>
            <Container fluid >

                <Row className='mt-5 right_margin left_margin'>
                    <Col lg={4} className='ps-5 pe-5'>
                        <div className='text-center'>
                           <img src={img_hw_1} alt="img_1" />
                           <p className='p_large text-blue mt-3'><b>1. Create your profile</b></p>
                           <p className='mt-3'>Sign up for free, create your profile , share your usual locations so surfers in your area can easily find you and see your work</p>
                        </div>
                            

                    </Col>
                    <Col lg={4} className='ps-5 pe-5'>
                        <div className='text-center'>
                           <img src={img_hw_2} alt="img_1" />
                           <p className='p_large text-blue mt-3'><b>2. Upload your content</b></p>
                           <p className='mt-3'>Create an album, select the surf spot and the date where the photos / videos where taken and upload all your content</p>
                        </div>
                            

                    </Col>
                    <Col lg={4} className='ps-5 pe-5'>
                        <div className='text-center'>
                           <img src={img_hw_3} alt="img_1" />
                           <p className='p_large text-blue mt-3'><b>3. Get paid</b></p>
                           <p className='mt-3'>Get paid on time, every time! Payment is available for withdrawal as soon as is purchased easy without complications</p>
                        </div>
                            

                    </Col>
                </Row>

                
            </Container>
            <div className='text-center m-5'>
                <Button className='btn_primary_blue btn_register'  size='lg' onClick={(e) => {
                    e.preventDefault();
                    if(sessionDetails.user_unique_id == null || sessionDetails.user_unique_id == ""){
                        window.location.href="/account/login"

                    }else{
                        window.location.href='/'+sessionDetails.user_unique_id;
                    }
                    } }>Share your shots!</Button>
            </div>

                
        </div>
        
    )
}


function CtaSection(){
    const img_cta = require('../assets/img_photographers_cta.jpg')
    const [sessionDetails, setSessionDetails] = useState(SessionDetails())

    return (
        <div className='div_cta_section_photographers'>
            {/* <img  src={img_cta} alt='CTA Image'/> */}
            <Container fluid className='container_cta'>
                <Row className=''>
                    <Col md={10} lg={8} xl={6} xxl={5}>
                    <div className='cta_content_photographers'>
                        <p className='display-2 display_2_blue '>Share your shots</p>
                        {/* <p className='display-2 display_2_blue'>magical moments</p> */}
                        <p className='h5_blue'>The first platform that allows you to sell photos and videos of your sessions and share with the community your talents!</p>
                        <Button className='btn_primary_blue'  size='lg' onClick={(e) => {
                        e.preventDefault();
                        if(sessionDetails.user_unique_id == null || sessionDetails.user_unique_id == ""){
                            window.location.href="/account/login"

                        }else{
                            window.location.href='/'+sessionDetails.user_unique_id;
                        }
                        } }>Start selling your shots!</Button>
                    </div>
                    </Col>
                </Row>
            </Container>
            

        </div>
  
    );
  }


function PagePhotographers(){
    return(
        <div>
            <Navigation showSearchBar={0} />
            <CtaSection />
            <HowItWorksSection />
            <BenefitsSection />
            <FaqSection />
            <Footer />

        </div>
    )
}

export default PagePhotographers