import Container from 'react-bootstrap/esm/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Navigation from '../components/Navigation';
import { useState, useRef, useEffect, useMemo} from "react";
import GroupSearchFilters, {GroupSearchAllFilters} from '../components/SectionFilters';
import SectionPhotosShort, {SectionPhotosLarge} from '../components/SectionPhotos';
import { SearchAlbumsInput } from '../components/FormComponents';
import { GoogleMap, Marker, useLoadScript } from "@react-google-maps/api";
import { google_maps_api_key, url_server } from '../settings';
import { SessionDetails } from '../controllers/AccountController';
import PaymentModal from '../components/PaymentModal';
import Modal from 'react-bootstrap/Modal';
import UrlController, { saveLoginRegistrationPreviousUrl } from '../controllers/UrlController'
import Card from 'react-bootstrap/Card';
import PhotoPurchaseCard from './PhotoPurchaseCard';


function PurchasedItemsObject({album_id, album_datetime_start, album_datetime_end, location_name, photographer_business_name, location_state, user_unique_id, media, album, showModal, isSingleDownload, setUrlsToDownload}){

    var dayNames = ['Sun','Mon','Tue','Wed','Thu','Fri','Sat'];
	var monthPrefixes = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
	var start_time = new Date(album_datetime_start).toLocaleTimeString([], {hour12: false, hour: '2-digit', minute:'2-digit'})
	var end_time = new Date(album_datetime_end).toLocaleTimeString([], {hour12: false, hour: '2-digit', minute:'2-digit'})
	var day = new Date(album_datetime_start).getDay()
	var month = new Date(album_datetime_start).getMonth()
	var day_number = new Date(album_datetime_start).toLocaleDateString().split("/")[0]
	var year_number = new Date(album_datetime_start).toLocaleDateString().split("/")[2]
	const ic_empty_profile = require('../assets/ic_profile_card.png')

    const [screenWidth, setScreenWidth] = useState(window.innerWidth)

    useEffect(() => {
        window.addEventListener("resize", () => {
            setScreenWidth(window.innerWidth)
        });
        return () => {
            window.removeEventListener("resize", () => {
                setScreenWidth(window.innerWidth)
            })
        }
    }, []);

    return(
        <div className='cart_items_album mb-3'>
            <div className='ms-3 me-3'> 
                <div className='mb-2 d-flex justify-content-between'>
                    <div className={screenWidth < 768 ? "" : "d-flex align-items-center"}>
                        <p className='me-3 mb-0'><b>Album: </b>{location_name}</p>
                        <a href={'/album/'+album_id} className='m-0 p_xsmall text_light_blue'><b>View album</b></a>

                    </div>
                    <div className={screenWidth < 768 ? "" : "d-flex align-items-center"}>
                        <p className='text-thin text-grey p_xsmall m-0 mt-0 ms-0 text-end' >{day_number} {monthPrefixes[month]} {year_number} | {start_time} - {end_time}</p>
                       
                        <div className='ms-3 d-flex align-items-center'>
                            <img src={ic_empty_profile} alt="photographer" className=''/>
                            <Card.Link href={`/${user_unique_id}`} className=' link_album_photographer text_inline ms-1 p_xsmall_no_margin'>{photographer_business_name}</Card.Link>

                        </div>
                    </div>
                </div>
               
                <hr className='mt-0 mb-0'></hr>

            </div>
            <div >
                {/* IMAGES HERE */}
                <Row className='gx-1'>
                    
                    {media.map((dataObj, index) => {
                        return(
                            <PhotoPurchaseCard data={dataObj} album={album} showModal={showModal} isSingleDownload={isSingleDownload} setUrlsToDownload={setUrlsToDownload} key={index} />
                        );
                     })}
                </Row>

            </div>
        </div>
    )

}

export default PurchasedItemsObject;