import Container from 'react-bootstrap/esm/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Navigation from './Navigation';
import { useState, useRef, useEffect, useMemo} from "react";
import GroupSearchFilters, {GroupSearchAllFilters} from './SectionFilters';
import SectionPhotosShort, {SectionPhotosLarge} from './SectionPhotos';
import { SearchAlbumsInput } from './FormComponents';
import { GoogleMap, Marker, useLoadScript } from "@react-google-maps/api";
import { google_maps_api_key, url_server } from '../settings';
import { SessionDetails } from '../controllers/AccountController';



function ProductSales({item}){
    const img = require('../assets/photo_card_example.jpg')
   

    return(
     
        <div className='mb-3'> 
            {/* <Col lg={12}> */}
                <div className='d-flex justify-content-between div_picture_cart' >


                 {item.product_type_id == 1 ? (
                        <img  src={item.media_url} alt='photo' />


                    ) : (
                        <div className='video-container'>
                            <video >
                                <source  src={item.media_url} type="video/mp4"/>
                                <source  src={item.media_url} type="video/ogg"/>
                                <source  src={item.media_url} type="video/mov"/>

                                Your browser does not support the video tag.
                            </video>
                        </div>
                    
                    )
                    
                    }

                 <div className='text-end'>
                    <p className='m-0 p_xsmall text-thin'><b>Product:</b> {item.product_type_id == 1 ? "Photo" : "Video"}</p>
                    <p className='m-0 p_xsmall text-thin'><b>Item price:</b> ${parseFloat(item.price)/100}</p>
                    {/* <p className='m-0 p_xsmall text-thin'><b>Discount defined:</b> {item.discount_percentage != null ? `${parseInt(parseFloat(item.discount_percentage)*100)}%` : "No discount applied"}</p> */}
                    {/* <p className='m-0 p_xsmall text-thin'><b>Adm fee:</b> {parseInt(parseFloat(item.movement_paid_fee)*100)}%</p> */}
                    <p className='m-0 p_xsmall text-thin'>(Received ${(parseFloat(item.movement_amount)/100).toFixed(2)})</p>

                </div>


                    

                    {/* <div className='align-self-start'>
                        <p className='m-0 p_xsmall text-thin'>{item.product_type_id == 1 ? "Photo" : "Video"}</p>
                        <p className='m-0 p_xsmall '>{item.media_format}</p>

                    </div> */}
                </div>
            {/* </Col> */}

            {/* <Col lg={3} >
                <div className={`d-flex align-items-center h-100 ${screenWidth < 768 ? 'mt-3' : ""}`}>
                    <p className='align-self-center'><b>{screenWidth < 768 ? 'Price: ' : ""}</b> ${(item.price / 100).toFixed(2)}</p>

                </div>
            </Col>
            <Col lg={1}>
                <div className='d-flex align-items-center h-100'>
                    
                    {screenWidth < 768 ? (
                        <a className="text-danger" href='#' onClick={() => deleteFromCart()}>Delete</a>
                    ) : (
                        <a href='#' onClick={() => deleteFromCart()}><img src={ic_delete} alt='delete' /></a>
                    )}
                        
                    

                </div>
            </Col> */}
        </div>
    )
}

export default ProductSales