import GroupSearchFilters from '../components/SectionFilters';
import PhotoPurchaseCard from '../components/SectionPurchaseCard';
import Container from 'react-bootstrap/esm/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import SectionPhotosShort, { SectionPhotosShortCarousel } from '../components/SectionPhotos';
import Navigation from '../components/Navigation'; 
import { SearchAlbumsInput, SearchBar, SearchBarMultipleElements } from '../components/FormComponents';
import {ListNextSessions} from '../components/Lists';
import { Footer } from '../components/Footer';

import { GoogleMap, Marker, useLoadScript } from "@react-google-maps/api";
import { useMemo, useState, useEffect } from "react";
import { google_maps_api_key } from '../settings';
import Accordion from 'react-bootstrap/Accordion';
import { url_server } from '../settings';
import Spinner from 'react-bootstrap/Spinner';


// import React from 'react'
// import { SafeAreaView } from 'react-native'
// import MapView from 'react-native-maps'
// import {FAB, Title} from 'react-native-paper'

function NestSessionsSection(){
    const [filters, setFilters] = useState({})
    const [nextSessions, setNextSessions] = useState()
    const img_wave = require('../assets/logo_green.png')

    const [center, setCenter] = useState({ lat: -28.166049075806885, lng: 153.53519109664748 })
    const [sessionSelected, setSessionSelected] = useState()


    const ic_map_selector = require('../assets/ic_map_selector.png')
    const ic_map_selector_selected = require('../assets/ic_map_selector_selected.png')

    const google = window.google

    const { isLoaded } = useLoadScript({
        googleMapsApiKey: google_maps_api_key,
    });

    // const center = useMemo(() => ({ lat: -28.166049075806885, lng: 153.53519109664748 }), []);


    function clickMarker(e, index){
        // console.log("click marker")
        if(index !== sessionSelected){
            var session = nextSessions
            
            if(sessionSelected != null) {delete session[sessionSelected].selected}
            session[index].selected = true

            setNextSessions(session)
            setSessionSelected(index)
            setCenter({lat: parseFloat(session[index].location_latitude), lng: parseFloat(session[index].location_longitude)})
        }
        

        
    }

    

    useEffect(() => {
        getNextSessions();
        
    }, [filters]);

    async function getNextSessions(){
        var response;
        var query = "?page=0"
    
        if(Object.keys(filters).includes("search")){
          query += "&search=" + filters.search
        }
        if(Object.keys(filters).includes("date")){
            query += "&date=" + filters.date
        }
    
        
        await fetch(`${url_server}public/next-sessions${query}`, {
            method: 'GET',
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            },
            })
            .then((response) => response.json())
            
            .then( (data) => {
                // response = data.response
                // console.log(data.response)
                setNextSessions(data.response)
    
            })
            .catch((err) => {
                // console.log(err)
                // alert("Something went wrong. Please check your connection and try again.");
            });
    
            return response;
    
    }


    return(
        <div className='h-100  '>
            <div  className='div_titles text-center w-100' id="container_login">
                {/* <h1>Next Sessions</h1> */}
                <p className='display-3 heading_font text-blue'>Future Sessions</p>

                <p className='m-3'>Find out where there will be someone shooting close to you</p>
            </div>
            <Container fluid className='mt-5 mb-5 '>
                <Row >
                    

                    <Col md={5} lg={5} xl={5} xxl={5} className='container_list_next_sessions'>
                        <div className='ms_3vw me_3vw'>
                            <SearchBarMultipleElements filters={setFilters} />

                        </div>
                        <div className='mt-3 ms_3vw me_3vw' id='list_next_sessions'>

                            {nextSessions == null ? (
                                <div className="text-center">
                                    <Spinner animation="border" role="status" className="mt-5 ">
                                        <span className="visually-hidden">Loading...</span>
                                    </Spinner>
                                </div>
                            ) : (
                                <>
                                
                                {nextSessions.length === 0 ? (
                                    <div>
                                        <img className=" mt-5 profile_wave d-block ms-auto me-auto" src={img_wave} alt="logo" /> 
                                        <p className="text_light_blue text-center mt-3" >There are no future sessions set up yet</p>
                    
                                    </div>
                                ) : (
                                    <ListNextSessions  nextSessions={nextSessions} 
                                        setNextSessions={setNextSessions} 
                                        sessionSelected={sessionSelected} 
                                        setSessionSelected={setSessionSelected} 
                                        setCenter={setCenter}
                                        />
                                )}
                                </>
                            )}

                           
                        </div>
                        
                    </Col>

                    <Col md={7} className='container_map'>
                        
                        {/* COMMENT FOR BILLING */}

                            {!isLoaded ? (
                                <h1>Loading...</h1>
                            ) : (
                                <GoogleMap
                                className='map'
                                mapContainerClassName="map-container"
                                center={center}
                                zoom={13}
                                >
                                    
                                    {nextSessions != null ? (
                                        <>
                                        {nextSessions.map((obj, index) =>{
                                            // console.log(obj.selected)
                                            return(
                                                <Marker
                                                    icon={{
                                                        url: obj.selected == true ? ic_map_selector_selected : ic_map_selector,
                                                        // anchor: new google.maps.Point(5,5 ),
                                                        
                                                    }}
                                                    position={{lat: parseFloat(obj.location_latitude), lng: parseFloat(obj.location_longitude)} }
                                                    key={index}
                                                    onClick={(e) => clickMarker(e, index)}
                                                />
                                            )
                                        })}
                                        </>
                                    ) : ("")}
                                    
                                </GoogleMap>
                            )}

                           

                    </Col>
                </Row>

            
        </Container>
        </div>
    );
}

function NestSessions(){
    return(
        <div>
            <Navigation />
            <NestSessionsSection />
        </div>
        
    )
}

export default NestSessions