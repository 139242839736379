import logo from './logo.svg';
import Main from './pages/main'
import Aboutus from './pages/aboutus'
import Navigation from './components/Navigation';
import React, { Component, useState, useEffect } from 'react';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Contact_us from './pages/contactus';
import LandingPage from './pages/landingpage';
import SearchPhotos from './pages/search_photos';
import { NavigationLandingPage } from './components/Navigation';
import PageSurferInfo from './pages/PageSurferInfo';
import PagePhotographerInfo from './pages/PagePhotographerInfo';
import FooterLandingPage from './components/Footer';
import { Navigate } from "react-router-dom";

import './App.css';

import LoginPage from './pages/login';
import PrivacyPolicy from './pages/privacy_policy';
import PageSuccess from './pages/form_successful';
import Album from './pages/album';
import {Details} from './pages/album'
import { deleteSession, isLogged, refreshSession, SessionDetails } from './controllers/AccountController';
import Account from './pages/account';
import {Cart} from './pages/cart';
import Register from './pages/register';
import CreateAlbumPage from './pages/create_album';
import EditAlbumPage from './pages/edit-album';
import PagePhotographers from './pages/photographers';
import NestSessions from './pages/next_sessions';
import PaymentComplete from './pages/payment_complete';
import BillingPage from './pages/billing_payments';
import PhotographerPublicProfile from './pages/photographer_public_profile';
import UserPurchases from './pages/UserPurchases';
import VerifyEmail from './pages/verify_email';
import Settings from './pages/user_settings';
import TermsAndConditions from './pages/termsandconditions';
import ForgotPasswordPage from './pages/reset-password';
import { url_server } from './settings';
import ResetPasswordForm from './pages/reset-password-form';

const Content = ({content}) => {
	return (
		<div >{content}</div>
	);
  }

// function isLogged(){
// 	var logged_in = false;
// 	// CHECK IF THERE IS SESSION AND APPLY TO THE WHOLE PAGE

// 	return logged_in;
// }



function App() {
	
	const [userDetails, setUserDetails] = useState(SessionDetails())

	

	useEffect(() =>{
		if(userDetails.token != null && userDetails.toke != ""){
			getUserInfo()
		}
	}, [])

	function getUserInfo(){
        fetch( `${url_server}private/users/${userDetails.user_id}`, {
            method: 'GET',
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
                'x-access-token': userDetails.token,
            },
            })
            .then((response) => response.json())
            
            .then(async (data) => {
                // response = data.response
				if(data.status == 200){
					refreshSession(data.response[0])
				}else{
					deleteSession();
				}
                // refreshSession(data.response[0])


            })
            .catch((err) => {
            });
    }



	return (
		<div>
			{/* LANDING PAGE */}

			{/* <Routes>
				<Route path='/' element={<LandingPage />} />
				<Route path='/surfer' element={<PageSurferInfo />} />
				<Route path='/photographer' element={<PagePhotographerInfo />} />
				<Route path='/privacy-policy' element={<PrivacyPolicy />} />
				<Route path='/success' element={<PageSuccess />} />
			</Routes> */}
			{/* <FooterLandingPage /> */}

			

			{/* *******END******** */}

			{/* NORMAL WEBSITE */}
			<Routes>
				{/* PUBLIC ROUTES */}
				<Route path='/' element={<Main/>} />
				<Route path='/aboutus' element={<Aboutus />}/>
				<Route path='/contact-us' element={<Contact_us/>} />
				<Route path='/search_photos' element={<SearchPhotos/>} />
				<Route exact path="/album/:id" element={<Album />} />
				<Route path='/account/login' element={Object.keys(userDetails).includes("token") == true ? (userDetails.user_default_profile == 2 ? <Navigate to={"/"+userDetails.user_unique_id} replace /> :  <Navigate to={"/"} replace />): <LoginPage />} />
				<Route path='/privacy-policy' element={<PrivacyPolicy />} />
				<Route path='/terms-and-conditions' element={<TermsAndConditions />} />
				<Route path='/account/reset-password' element={<ForgotPasswordPage />} />
				<Route path='/account/reset-password-form' element={<ResetPasswordForm />} />
				<Route path='/cart' element={<Cart />} />
				{/* <Route path='/sign-up' element={<Register />} /> */}
				<Route path='/sign-up' element={Object.keys(userDetails).includes("token") == true ? (userDetails.user_default_profile == 2 ? <Navigate to={"/"+userDetails.user_unique_id} replace /> :  <Navigate to={"/"} replace />): <Register />} />
				<Route path='/photographers' element={<PagePhotographers />} />
				<Route path="/next-sessions" element={<NestSessions />} />
				<Route path="/payment-complete" element={<PaymentComplete />} />
				{/* <Route path='/:user_unique_id' element={<PhotographerPublicProfile />} /> */}
				<Route path='/:user_unique_id' element={<Account />} />
				<Route path='/email/verify/:email' element={<VerifyEmail />} />
				<Route path="/surfers-and-photographers" element={<Navigate to="/" replace />} />
				
 
				{/* PRIVATE ROUTES */}
				<Route path='/create-album' element={Object.keys(userDetails).includes("token") === true ?  <CreateAlbumPage /> : <Navigate to="/account/login" replace />} />
				<Route path='/account/settings'  element={Object.keys(userDetails).includes("token") === true ?  <Settings /> : <Navigate to="/account/login" replace />} />
				{/* <Route path='/account/:user_id'  element={Object.keys(userDetails).includes("token") === true ? <Account /> : <Navigate to="/account/login" replace />} /> */}
				<Route path='/album/:album_id/edit' element={Object.keys(userDetails).includes("token") === true ?  <EditAlbumPage /> : <Navigate to="/account/login" replace />} />
				<Route path='/account/sales' element={Object.keys(userDetails).includes("token") === true ?  <BillingPage /> : <Navigate to="/account/login" replace />} />
				<Route path='/account/purchases' element={Object.keys(userDetails).includes("token") === true ?  <UserPurchases /> : <Navigate to="/account/login" replace />} />

				
			</Routes>
			{/* ********END******** */}
		</div>
	);
}

export default App;
