import Container from 'react-bootstrap/esm/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Navigation from '../components/Navigation';
import { useState, useRef, useEffect, useMemo} from "react";
import GroupSearchFilters, {GroupSearchAllFilters} from '../components/SectionFilters';
import SectionPhotosShort, {SectionPhotosLarge} from '../components/SectionPhotos';
import './pages.css';
import { SearchAlbumsInput } from '../components/FormComponents';
import { GoogleMap, Marker, useLoadScript } from "@react-google-maps/api";
import { google_maps_api_key, url_server } from '../settings';
import { SessionDetails } from '../controllers/AccountController';
import PaymentModal from '../components/PaymentModal';
import Modal from 'react-bootstrap/Modal';
import UrlController, { saveLoginRegistrationPreviousUrl } from '../controllers/UrlController'


function ModalDiscounts({show, setShow, discounts}){
    // const [show, setShow] = useState(false);
  

    function handleClose(){
        setShow(false)
    }

    return (
        <>

        <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}

        >
            <Modal.Header closeButton>
            <Modal.Title>Offers</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div >
                    {discounts.map((obj, index) =>{
                        return(
                            <p key={index} className="label_product_modal mb-2"><b>{parseInt(obj.discount_percentage * 100)}% OFF</b><span className="text-grey"> - Buying {obj.discount_quantity}</span></p>

                        )
                    })}

                </div>

            </Modal.Body>
            <Modal.Footer>
            <Button className='btn_primary_blue btn_register w-100'  onClick={handleClose}>Close</Button>

            </Modal.Footer>
        </Modal>
        </>
    );
}



function CartProduct({album, product, album_index, product_index}){
    const img = require('../assets/photo_card_example.jpg')
    const ic_delete = require('../assets/ic_delete.png')
    const [array_albums, setArray_albums] = useState(JSON.parse(localStorage.getItem('cart')));


    var dayNames = ['Sun','Mon','Tue','Wed','Thu','Fri','Sat'];
	var monthPrefixes = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];


	var month = new Date(album.album_datetime_start).getMonth()
	var day_number = new Date(album.album_datetime_start).toLocaleDateString().split("/")[0]
	var year_number = new Date(album.album_datetime_start).toLocaleDateString().split("/")[2]

    const [screenWidth, setScreenWidth] = useState(window.innerWidth)

    useEffect(() => {
        window.addEventListener("resize", () => {
            setScreenWidth(window.innerWidth)
        });
        return () => {
            window.removeEventListener("resize", () => {
                setScreenWidth(window.innerWidth)
            })
        }
    }, []);


    function deleteFromCart(){
        if(album.products.length == 1){
            // DELETE WHJOLE ALBUM
            array_albums.splice(album_index, 1)
        }else{
            // DELETE ONLY PRODUCT
            array_albums[album_index].products.splice(product_index, 1)
        }

        localStorage.setItem('cart', JSON.stringify(array_albums));
        window.location.reload()
    }

    

    return(
        <Row className='mb-3'> 
            <Col lg={8}>
                <div className='d-flex align-items-center div_picture_cart' >

                    {product.media_format.toLowerCase() == "jpg" || product.media_format.toLowerCase() == 'jpeg' || product.media_format.toLowerCase() == "png" ? (
                        <img  src={product.media_url} alt='photo' />

                    ) : (product.media_format.toLowerCase() == "ogg" || product.media_format.toLowerCase() == 'mp4' || product.media_format.toLowerCase() == 'mov' ? (
                        <video  >
                            <source  src={product.media_url} type="video/mp4"/>
                            <source  src={product.media_url} type="video/ogg"/>
                            <source  src={product.media_url} type="video/mov"/>

                            Your browser does not support the video tag.
                        </video>
                    ) : (""))
                    
                    }
                    <div className='align-self-start'>
                        <p className='m-0 p_xsmall text-thin'>{product.product_type_id == 1 ? "Photo" : "Video"}</p>
                        <p className='m-0 p_xsmall '>{product.media_format}</p>

                    </div>
                </div>
            </Col>

            <Col lg={3} >
                <div className={`d-flex align-items-center h-100 ${screenWidth < 768 ? 'mt-3' : ""}`}>
                    <p className='align-self-center'><b>{screenWidth < 768 ? 'Price: ' : ""}</b> ${(product.price / 100).toFixed(2)}</p>

                </div>
            </Col>
            <Col lg={1}>
                <div className='d-flex align-items-center h-100'>
                    
                    {screenWidth < 768 ? (
                        <a className="text-danger" href='#' onClick={() => deleteFromCart()}>Delete</a>
                    ) : (
                        <a href='#' onClick={() => deleteFromCart()}><img src={ic_delete} alt='delete' /></a>
                    )}
                        
                    

                </div>
            </Col>
        </Row>
)
}

function CartAlbum({album, album_index, array_albums, setArray_albums}){


    const [discounts, setDiscounts] = useState()
    const [showModalDiscounts, setShowModalDiscounts] = useState(false)

    var dayNames = ['Sun','Mon','Tue','Wed','Thu','Fri','Sat'];
	var monthPrefixes = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];

	var start_time = new Date(album.album_datetime_start).toLocaleTimeString([], {hour12: false, hour: '2-digit', minute:'2-digit'})
	var end_time = new Date(album.album_datetime_end).toLocaleTimeString([], {hour12: false, hour: '2-digit', minute:'2-digit'})
	var day = new Date(album.album_datetime_start).getDay()
	var month = new Date(album.album_datetime_start).getMonth()
	var day_number = new Date(album.album_datetime_start).toLocaleDateString().split("/")[0]
	var year_number = new Date(album.album_datetime_start).toLocaleDateString().split("/")[2]
    const ic_discount_applied = require('../assets/ic_discount_applied.png')
    const [albumPrice, setAlbumPrice] = useState(0)
    const [discountAppliedIndex, setDiscountAppliedIndex] = useState(-1)

    const [screenWidth, setScreenWidth] = useState(window.innerWidth)

    useEffect(() => {
        window.addEventListener("resize", () => {
            setScreenWidth(window.innerWidth)
        });
        return () => {
            window.removeEventListener("resize", () => {
                setScreenWidth(window.innerWidth)
            })
        }
    }, []);

    useEffect(() =>{
        var album_price = 0
        album.products.map((obj, index) =>{
            album_price += obj.price
        })

        setAlbumPrice(album_price)

        getAlbumsDiscounts()
    }, [album])

    function getAlbumsDiscounts(){
        fetch(url_server + "public/albums/" + album.album_id + "/discounts", {
           method: 'GET',
           headers: {
               'Content-type': 'application/json; charset=UTF-8',
           },
           })
           .then((response) => response.json())
           
           .then( (data) => {
   
                var disc = data.response;

                if(disc != null && disc.length > 0){
                    var counter = -1
                    // while(album.products.length >= disc[counter].discount_quantity){
                    //     counter++
                    // }

                    for(var i = 0; i < disc.length; i++){
                        if(album.products.length >= disc[i].discount_quantity){
                            counter++
                        }
                    }

                    var arr = [...array_albums]
                    arr[album_index].discount_applied = disc[counter]
                    setDiscountAppliedIndex(counter)

                    setArray_albums(arr)

                }

                setDiscounts(data.response)

               
            //    for(var i = 0; i < data.response.length; i++){

            //    }

           })
           .catch((err) => {
               // console.log(err)
           });

   }



    return(
        <div className='cart_items_album mb-5'>
            <div > 
                <div className='d-flex justify-content-between'>
                    <div className={screenWidth < 768 ? "" : "d-flex"}>
                        <p className='me-3 mb-0'><b>Album: </b>{album.location_name}</p>
                        <a href={'/album/'+album.album_id} className='m-0 p_xsmall text_light_blue'><b>View album</b></a>

                    </div>
                    <div>
                        <p className='m-0'><b>Seller: </b>{album.photographer_business_name}</p>
                    </div>
                </div>
                <p className='text-thin text-grey p_xsmall mt-0 mb-3 ms-0' >{day_number} {monthPrefixes[month]} {year_number} | {start_time} - {end_time}</p>
                {screenWidth < 768 ?("") : (
                    <Row>
                        <Col lg={8}>
                            <p>Product</p>
                        </Col>
                    
                        <Col lg={3}>
                            <p>Price</p>
                        </Col>
                        <Col lg={1}>

                        </Col>
                    </Row>
                )}
                
            </div>
            <div className="mb-5">

                {album.products.map((dataObj, index) => {
                    return(
                        <CartProduct key={index} album={album} product={dataObj} album_index={album_index} product_index={index} /> 
                    )
                }) }

                {/* <CartProduct />
                <CartProduct /> */}

            </div>

            <div className='d-flex justify-content-between'>

                {album.discount_applied != null ? (
                    <div className='d-flex'>
                        <div className='align-self-center me-3'>
                            <img src={ic_discount_applied} alt='discount applied' />
                        </div>
                        <div>
                            <p className='text_green m-0'><b>Offer applied</b></p>
                            <p className='text-grey m-0'>You have saved ${((album.discount_applied.discount_percentage * albumPrice)/100).toFixed(2)} ({parseInt(album.discount_applied.discount_percentage*100)}%) on this purchase</p>
                            
                            {discounts[discountAppliedIndex+1] != null ? (
                                <a href={`/album/${album.album_id}`} className='text_green p_xsmall m-0 mt-2'><b>Add {discounts[discountAppliedIndex+1].discount_quantity - album.products.length} more {discounts[discountAppliedIndex+1].discount_quantity - album.products.length > 1 ? "items" : "item"} from the same album and get an extra {parseInt(discounts[discountAppliedIndex+1].discount_percentage*100) - parseInt(discounts[discountAppliedIndex].discount_percentage*100)}% off</b></a>

                            ) : ("")}
                        </div>
                        
                    </div>


                ) : (
                    <>
                    {discounts != null && discounts.length > 0 ? (
                        <a href={`/album/${album.album_id}`} className='text_green m-0 mt-2'><b>Add {discounts[0].discount_quantity - album.products.length} more {discounts[0].discount_quantity - album.products.length > 1 ? "items" : "item"} from the same album and get {parseInt(discounts[0].discount_percentage*100)}% off</b></a>

                    ) : ("")}

                    </>

                )}
                
                {discounts != null && discounts.length > 0 ? (
                    <>
                    <Button className='btn_outlined_green btn_register align-self-center m-0' onClick={(e) => setShowModalDiscounts(true)}>More offers available</Button>
                    <ModalDiscounts show={showModalDiscounts} setShow={setShowModalDiscounts} discounts={discounts} />

                    </>

                ) : ("")}
            </div>

        </div>
    )

}


function CartObject(){
    // // console.log(array_items)
    // var array_albums = JSON.parse(localStorage.getItem('cart'));
    const [array_albums, setArray_albums] = useState(JSON.parse(localStorage.getItem('cart')))
	const [userDetails, setUserDetails] = useState(SessionDetails())
    const [showPayment, setShowPayment] = useState(false)
    const [totalPrice, setTotalPrice] = useState(0)
    const [itemsCount, setItemsCount] = useState(0)
    const [priceDiscount, setPriceDiscount] = useState(0)
    const [totalPriceWithDiscounts, setTotalPriceWithDiscounts] = useState(0)
   

    function goToPay(){
        if(Object.keys(userDetails).includes("token") === true){
            // GO TO PAY
            setShowPayment(true)
        }else{
            window.location.replace(window.location.origin + '/account/login?origin=cart');
        }
    }


    useEffect(() =>{

        if(array_albums != null && array_albums.length > 0){
            var count = 0
            var total_price = 0
            var price_discount = 0
    
            array_albums.map((dataObj, index) =>{
                var album_price = 0
                dataObj.products.map((p, i) => {
                    count += 1
                    total_price += p.price
                    album_price += p.price
                })

                if(dataObj.discount_applied){
                    price_discount += album_price * dataObj.discount_applied.discount_percentage
                }
                
            })
         
            setItemsCount(count)
            setPriceDiscount(price_discount)
            setTotalPrice(total_price)
            setTotalPriceWithDiscounts(total_price - price_discount)

        }

    }, [array_albums])



    return(
        <>
        {array_albums === null || array_albums.length == 0 ? (
            <Container fluid>
                <h5 className='mb-3'><b>Your cart</b></h5>
                <h3 className='text-blue text-center'>You don't have items in your cart</h3>
            </Container>
        ) : (
            <Container fluid>
                <Row>
                    <Col xl={8}>
                        <h5 className='mb-3'><b>Your cart</b></h5>
                        <div>
                            {
                                array_albums.map((dataObj, index) => {
                                    return(
                                        <CartAlbum key={index} album={dataObj} album_index={index} array_albums={array_albums} setArray_albums={setArray_albums}  />
                                    )
                                })
                            }
                            
                        </div>
                    </Col>
                    <Col xl={4}>
                    
                        <div className=''>
                            <h5 className='mb-3'><b>Order Summary</b></h5>
                            <div className='d-flex justify-content-between'>
                                <div>
                                    <p>Items ({itemsCount})</p>
                                    {/* <p>Transaction Fee (1.75%)</p> */}
                                    <p>Discounts</p>
                                    <p>Total</p>
                                </div>
                                <div>
                                    <p><b>${(totalPrice / 100).toFixed(2)}</b></p>
                                    {/* <p>${((totalPriceWithDiscounts * 0.0175) / 100).toFixed(2)}</p> */}
                                    <p>${(parseFloat(priceDiscount)/100).toFixed(2)}</p>
                                    {/* <p>${((totalPriceWithDiscounts + (totalPriceWithDiscounts * 0.0175)) / 100).toFixed(2)}</p> */}
                                    <p>${(totalPriceWithDiscounts / 100).toFixed(2)}</p>

                                </div>
                            </div>
                            <Button className='btn_primary_blue btn_register w-100' onClick={() => goToPay()}>Go to checkout</Button>
                        </div>
                    </Col>
                </Row>
                {/* <PaymentModal show={showPayment} setShow={setShowPayment} albums={array_albums} user={userDetails} total_price={((totalPriceWithDiscounts + (totalPriceWithDiscounts * 0.0175)) / 100).toFixed(2)} /> */}
                <PaymentModal show={showPayment} setShow={setShowPayment} albums={array_albums} user={userDetails} total_price={(totalPriceWithDiscounts / 100).toFixed(2)} />
            </Container>
        )}

        </>
    )


}

function Cart(){
    return(
        <div>
			<Navigation hideSearch={true} />
			<div className='div_albums '>
				<CartObject />
			</div>
			

		</div>
    );
}

export {Cart}