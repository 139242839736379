import Badge from 'react-bootstrap/Badge';
import ListGroup from 'react-bootstrap/ListGroup';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useState, useRef, useEffect} from "react";
import { url_server } from '../settings';
import { SessionDetails } from '../controllers/AccountController';
import { DeleteModal } from './modals';

function ListNextSessionsAdmin({nextSessions, getPhotographerNextSessions}){
	const ic_delete = require('../assets/ic_delete.png')
	const [accountDetails, setAccountDetaills] = useState(SessionDetails())
	const [objToDelete, setObjToDelete] = useState()
	const [showDeleteModal, setShowDeleteModal] = useState(false)

	var dayNames = [
		'Sun',
		'Mon',
		'Tue',
		'Wed',
		'Thu',
		'Fri',
		'Sat',
	];
	var monthPrefixes = [
		'Jan',
		'Feb',
		'Mar',
		'Apr',
		'May',
		'Jun',
		'Jul',
		'Aug',
		'Sep',
		'Oct',
		'Nov',
		'Dec',
		
	];


	

	function onDeleteSession(session){
        // console.log(session)
		setObjToDelete(session)
		setShowDeleteModal(true)


		

    }

	function deleteSession(){
		// console.log(objToDelete)
		fetch(`${url_server}private/next-sessions/${objToDelete.next_session_id}`, {
            method: 'PUT',
			body: JSON.stringify({is_active: 0}),
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
				'x-access-token': accountDetails.token
            },
            })
            .then((response) => response.json())
            
            .then( (data) => {
                // response = data.response
                // console.log(data)
				getPhotographerNextSessions()

    
            })
            .catch((err) => {
                // console.log(err)
                // alert("Something went wrong. Please check your connection and try again.");
            });
	}


	return(
		// <ListGroup as="ol" className='scrollable' >
		<>
		{nextSessions.map((dataObj, index) => {
			{
				
				var start_time = new Date(dataObj.next_session_start_datetime).toLocaleTimeString([], {hour12: false, hour: '2-digit', minute:'2-digit'})
				var end_time = new Date(dataObj.next_session_end_datetime).toLocaleTimeString([], {hour12: false, hour: '2-digit', minute:'2-digit'})
				var day = new Date(dataObj.next_session_start_datetime).getDay()
				var month = new Date(dataObj.next_session_start_datetime).getMonth()
				var day_number = new Date(dataObj.next_session_start_datetime).toLocaleDateString().split("/")[0]

			}

			return (
				<div key={index} className="">
					<ListGroup.Item as="li" className="d-flex justify-content-between align-items-start"  key={index}>
						<div className="ms-2 text-start div_list_date">
							<h2 className='text-blue p_bold'>{day_number}</h2>
							<p className='p_xlarge margin_5px '>{monthPrefixes[month]}</p>
						</div>
						<div className="ms-2 me-auto text-start">
							<p className="p_large p_bold">{dataObj.location_name}</p>
							<p className='text-thin p_small'>{dayNames[day]}, {start_time} - {end_time}</p>
							<p className='text-grey p_xsmall'>{dataObj.location_city} -  {dataObj.location_state}</p>
						</div>

						{accountDetails.photographer_id == dataObj.photographer_id ? (
							<div className='me-2'>
								<img src={ic_delete} alt='delete' className='clickable' onClick={(e) => onDeleteSession(dataObj)} />
							</div>
						) : ""}
						
					</ListGroup.Item>
					<hr/>
				</div>
				
				);
			})}

		<DeleteModal 
			show={showDeleteModal}
			setShow={setShowDeleteModal}
			title={"Are you sure you want to delete this future session?"} 
			content={"You won't be able to recover it again"}
			onAccept={(e) => deleteSession()}
			objToDelete={objToDelete}
		
		/>
			</>

		// </ListGroup>
	)
}



function ListNextSessions({nextSessions, setNextSessions, sessionSelected, setSessionSelected, setCenter}) {

//   const [nextSessions, setNextSessions] = useState([])
const ic_photo_round = require('../assets/ic_photo_round.png')
const ic_video_round = require('../assets/ic_video_round.png')

  var dayNames = [
	'Sun',
	'Mon',
	'Tue',
	'Wed',
	'Thu',
	'Fri',
	'Sat',
];
var monthPrefixes = [
	'Jan',
	'Feb',
	'Mar',
	'Apr',
	'May',
	'Jun',
	'Jul',
	'Aug',
	'Sep',
	'Oct',
	'Nov',
	'Dec',
	
];

function clickMarker(e, index){
	// console.log("click marker")
	if(index !== sessionSelected){
		var session = nextSessions
		
		if(sessionSelected != null) {delete session[sessionSelected].selected}
		session[index].selected = true

		setNextSessions(session)
		setSessionSelected(index)
		setCenter({lat: parseFloat(session[index].location_latitude), lng: parseFloat(session[index].location_longitude)})

	}
	

	
}


// console.log(nextSessions)

  return (
    <ListGroup >

		{nextSessions.map((dataObj, index) => {
			{
				
				var start_time = new Date(dataObj.next_session_start_datetime).toLocaleTimeString([], {hour12: false, hour: '2-digit', minute:'2-digit'})
				var end_time = new Date(dataObj.next_session_end_datetime).toLocaleTimeString([], {hour12: false, hour: '2-digit', minute:'2-digit'})
				var day = new Date(dataObj.next_session_start_datetime).getDay()
				var month = new Date(dataObj.next_session_start_datetime).getMonth()
				var day_number = new Date(dataObj.next_session_start_datetime).toLocaleDateString().split("/")[0]

			}

			return (
				// <ListGroup.Item as="li" className={"d-flex justify-content-between align-items-start " }  key={index}>
				<ListGroup.Item as="li" 
								className={`d-flex clickable justify-content-between align-items-start ${ dataObj.selected == true ? "ns-selected" : ""}`}  
								key={index}  
								onClick={(e) => clickMarker(e, index)}>
				
					<div className="ms-2 text-start div_list_date">
						<h2 className={`${dataObj.selected == true ? "" : "text-blue"} p_bold`}>{day_number}</h2>
						<p className='p_xlarge margin_5px '>{monthPrefixes[month]}</p>
					</div>
					<div className="ms-2 me-auto text-start">
						<p className="p_large p_bold">{dataObj.location_name}</p>
						<p className='text-thin p_small'>{dayNames[day]}, {start_time} - {end_time}</p>
						<p className={`${dataObj.selected == true ? "" : "text-grey"} p_xsmall`}>{dataObj.location_city} -  {dataObj.location_state}</p>
					</div>
					<div className='me-2 text-end flex-fill'>
						<a href={`/${dataObj.user_unique_id}`} className={`${dataObj.selected == true ? "" : "text-blue p_xsmall"}`} ><b>{dataObj.photographer_business_name}</b></a>
						<br/><br/>
						<div className='m-0 mt-1' >
							<span className='me-3 p_xsmall text-thin'>{dataObj.next_session_category}</span>
							<img src={ic_video_round} alt='video round' className='me-2' hidden={!JSON.parse(dataObj.product_types_id).find((element) =>{
								return element == "2"
							})} />

							<img src={ic_photo_round} alt='video round' hidden={!JSON.parse(dataObj.product_types_id).find((element) =>{
								return element == "1"
							})} />


							
						</div>
						
					</div>

				</ListGroup.Item>
				);
			})}
      
	</ListGroup>
  );
}

export {ListNextSessions, ListNextSessionsAdmin};