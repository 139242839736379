import Button from 'react-bootstrap/Button';
import React, {useState, useEffect } from "react";



function EOFYStatement({photographerId, onClickDownload}){

    return(
        <div>
            <div className="d-flex justify-content-between align-items-center">
                <p className="m-0 text-blue p_large">July 1, 2023 - June 30, 2024 Financial Year Statement</p>
                <Button className='btn_primary_blue btn_register float-end' type="button" onClick={onClickDownload} >Download</Button>
            </div>
            {/* <hr></hr> */}
        </div>
        
    )
}
export {EOFYStatement}