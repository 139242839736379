import { useState } from 'react';
import Button from 'react-bootstrap/Button';

function DownloadButton({className, text, onClick}){
    const ic_download_white = require("../../assets/ic_download_white.png")
	const ic_download_blue = require("../../assets/ic_download_blue.png")
    const[icDownload, setIcDownload] = useState(ic_download_white)

    function changeIcon(isHover){
        if(isHover){
            setIcDownload(ic_download_blue)
        }else{
            setIcDownload(ic_download_white)
        }
    }

    return(
        <Button 
            className={className} 
            onMouseEnter={(e) => changeIcon(true)} 
            onMouseLeave={(e) => changeIcon(false)}
            onClick={onClick}>
                <img 
                    className='img-btn' 
                    src={icDownload} 
                    alt="">
                </img>
                {text}
        </Button>

    )
}

export default DownloadButton