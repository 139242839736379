import React from 'react';
import ReactDOM from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.css';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import App from './App';
import reportWebVitals from './reportWebVitals';
import Helmet from 'react-helmet';
import Aboutus from './pages/aboutus';
import './index.css';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { hydrate, render } from "react-dom";




const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
	// <React.StrictMode>
	<div>

		<BrowserRouter>
			<Routes>
				<Route path='*' element={<App />}/>
				
			</Routes>
    	</BrowserRouter>
		</div>
  	// {/* </React.StrictMode> */}
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(// console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
