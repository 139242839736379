import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown'
import InputGroup from 'react-bootstrap/InputGroup';
// import $ from 'jquery';
import React, { Component, useState, useEffect } from 'react';

import './components.css';
import { url_server } from '../settings';

function SearchAlbumsInput({filters, viewmap}){
    const [inputSearch, setInputSearch] = useState({"search": "", "date": ""});
    const [viewMap, setViewMap] = useState(false)

    const [ inputDate, setInputDate] = useState('');

    const [location, setLocation] = useState({})
    const [cities, setCities] = useState([])
    const [suburbs, setSuburbs] = useState([])
    const [spots, setSpots] = useState([])

    var btn_search_input = require("../assets/btn_search_input.png")
    var ic_more_filters = require("../assets/ic_more_filters.png")
    var ic_map = require('../assets/ic_map.png')
    var ic_list = require('../assets/ic_list.png')
    const [screenWidth, setScreenWidth] = useState(window.innerWidth)

    useEffect(() => {
        window.addEventListener("resize", () => {
            setScreenWidth(window.innerWidth)
        });
        return () => {
            window.removeEventListener("resize", () => {
                setScreenWidth(window.innerWidth)
            })
        }
    }, []);


    function setFilters(event){
        var detected_filters = {}

        if(inputSearch.search != ''){
            detected_filters.search = inputSearch.search;
        }
        if(inputSearch.date != ''){
            detected_filters.date = inputSearch.date
        }
        if(inputSearch.location_state != ""){
            detected_filters.location_state = inputSearch.location_state
        }
        if(inputSearch.location_city != ""){
            detected_filters.location_city = inputSearch.location_city
        }
        if(inputSearch.location_suburb != ""){
            detected_filters.location_suburb = inputSearch.location_suburb
        }
        if(inputSearch.loscation_name != ""){
            detected_filters.location_name = inputSearch.location_name
        }

        // console.log(detected_filters)
        filters(detected_filters)

    }

    function setMap(event){
        setViewMap(!viewMap) 
        viewmap(!viewMap)
    }

    const array_states = [
        {
            val: 'QLD',
            text: "Queensland",

        },
        {
            val: "ACT",
            text: "Australian Capital Territory",

        },
        {
            val: "NSW",
            text: "New South Wales",

        },
        {
            val: "SA",
            text: "South Australia",

        },
        {
            val: "NT",
            text: "Northen Territory",

        },
        {
            val: "VIC",
            text: "Victoria",

        },
        {
            val: "TAS",
            text: "Tasmania",

        },
        {
            val: "WA",
            text: "Western Australia",

        },

    ]

    function onChangeState(value){
        if(value != null && value != ""){
            setInputSearch((oldSearch) => ({...oldSearch, "location_state": value}))

            setInputSearch((oldSearch) => ({...oldSearch, "location_city": ""}))
            // setInputSearch((oldSearch) => ({...oldSearch, "location_suburb": ""}))
            setInputSearch((oldSearch) => ({...oldSearch, "location_name": ""}))


            setCities([])
            // setSuburbs([])
            setSpots([])

            getCities(value)
        }else{
            setInputSearch((oldSearch) => ({...oldSearch, "location_state": ""}))

        }
    }

    function onChangeCity(value){
        if(value != null && value != ""){
            setInputSearch((oldSearch) => ({...oldSearch, "location_city": value}))

            // setInputSearch((oldSearch) => ({...oldSearch, "location_suburb": ""}))
            setInputSearch((oldSearch) => ({...oldSearch, "location_name": ""}))
            // setSuburbs([])
            setSpots([])
            // getSuburbs(value)
            getSpots(value)
        }else{
            setInputSearch((oldSearch) => ({...oldSearch, "location_city": ""}))

        }
    }

    // function onChangeSuburb(value){
    //     if(value != null && value != ""){
    //        setInputSearch((oldSearch) => ({...oldSearch, "location_suburb": value}))

    //         setSpots([])
    //         getSpots(value)
    //     }else{
    //         setInputSearch((oldSearch) => ({...oldSearch, "location_suburb": ""}))

    //     }
    // }

    function onChangeSpot(value){
        if(value != null && value != ""){
            setInputSearch((oldSearch) => ({...oldSearch, "location_name": value}))

            // setAlbum((oldSearch) => ({...oldSearch, "location_id": value}))

        }else{
            setInputSearch((oldSearch) => ({...oldSearch, "location_name": ""}))

        }
    }

    function getCities(state){
        var query = `?location_state=${state}`;

        fetch(url_server + "public/locations/cities" + query, {
			method: 'GET',
			headers: {
				'Content-type': 'application/json; charset=UTF-8',
			},
			})
			.then( (response) => response.json())
			
			.then( (data) => {
				// response = data.response

				setCities(data.response)

			})
			.catch((err) => {
				// console.log(err)
			});
    }


    // function getSuburbs(city){

    //     var query = `?location_city=${city}`;

    //     fetch(url_server + "public/locations/suburbs" + query, {
	// 		method: 'GET',
	// 		headers: {
	// 			'Content-type': 'application/json; charset=UTF-8',
	// 		},
	// 		})
	// 		.then( (response) => response.json())
			
	// 		.then( (data) => {
	// 			// response = data.response
	// 			// // console.log(data.response)

	// 			setSuburbs(data.response)

	// 		})
	// 		.catch((err) => {
	// 			// console.log(err)
	// 		});

    // }

    function getSpots(suburb){
        var query = `?location_city=${suburb}`;

        fetch(url_server + "public/locations/spots" + query, {
			method: 'GET',
			headers: {
				'Content-type': 'application/json; charset=UTF-8',
			},
			})
			.then( (response) => response.json())
			
			.then( (data) => {
				// response = data.response
				// // console.log(data.response)
                // console.log(data)


				setSpots(data.response)

			})
			.catch((err) => {
				// console.log(err)
			});
    }

    return(
        
        <div>
            <div className='d-flex justify-content-between'>
                <div className='flex-fill'>
                    <InputGroup className='me-auto  input_group_multiple'>
                        <Form.Control onChange={(e) => setInputSearch((oldSearch) => ({...oldSearch, "search": e.target.value}))} type="text" id='txt_search' className='form-control-md round_input round_input_border search_personalized_text w-50' placeholder="Search"/>
                        <Form.Control type="date" className='form-control-md round_input round_input_border search_personalized_date'  placeholder='Date' 
                            onChange={(e) => setInputSearch((oldSearch) => ({...oldSearch, "date": e.target.value}))}
                            // onFocus={(e) => (e.target.type = "date")}
                            // onBlur={(e) => (e.target.type = "text")} 
                            />

                    </InputGroup>
                </div>
                

                <div className='d-flex'>
                    <Button className='ms-2'  
                            id="btn_search_input_separate" 
                            // onClick={(e) => filters({"search": inputSearch, "date": inputDate})}
                            onClick={setFilters}
                            >
                                <img src={btn_search_input} 
                                alt='Search button' 
                                className='img_btn_search_input flex-fill' />
                    </Button>

                    {screenWidth < 768 ? ("") : (
                        <Button className='ms-2'  
                            id="btn_search_input_map_list" 
                            // onClick={(e) => filters({"search": inputSearch, "date": inputDate})}
                            onClick={setMap}
                            >
                                <img src={viewMap == false ?  ic_map : ic_list} 
                                alt='map' 
                                className='ic_btn_map_list ' />
                        </Button>
                    )}
                    
                </div>

                

            </div>


            {/* <div className='d-flex mt-3'>
                <InputGroup className='me-auto flex-fill input_group_multiple'>

                    <Form.Select 
                    onChange={(e) => onChangeState(e.target.value)}
                    type="select" id='txt_search' 
                    className='form-control-md round_input search_personalized_text w-25' 
                    placeholder="State"
                    
                    >
                            <option value="">State</option>
                            {array_states.map((obj, index) => {
                                return(
                                    <option key={index} value={obj.val}  >{obj.text}</option>
                                )
                            })}
                    </Form.Select>

                    <Form.Select 
                    onChange={(e) => onChangeCity(e.target.value)}
                    type="select" id='txt_search' 
                    className='form-control-md round_input search_personalized_text w-25' 
                    placeholder="State"
                    
                    >
                            <option value="">Region</option>
                            {
                                
                                cities.map((obj, index) =>{
                                    return(
                                        <option key={index} value={obj.location_city}>{obj.location_city}</option>

                                    )
                                })
                            }
                    </Form.Select>
                  
                    <Form.Select 
                    onChange={(e) => onChangeSpot(e.target.value)}
                    type="select" id='txt_search' 
                    className='form-control-md round_input search_personalized_text w-25' 
                    placeholder="State"
                    
                    >
                            <option value="">Spot Name</option>
                            {
                                spots.map((obj, index) =>{
                                    return(
                                        <option key={index} value={obj.location_name}>{obj.location_name}</option>

                                    )
                                })
                            }
                    </Form.Select>
        

                </InputGroup>
                
                

            </div> */}


        </div>
        
           
    )
}


function SearchBarMultipleElements({filters}){
    const [inputSearch, setInputSearch] = useState({"search": "", "date": ""});
    const [ inputDate, setInputDate] = useState('');
    
    var btn_search_input = require("../assets/btn_search_input.png")

    function setFilters(event){
        var detected_filters = {}

        if(inputSearch.search != ''){
            detected_filters.search = inputSearch.search;
        }
        if(inputSearch.date != ''){
            detected_filters.date = inputSearch.date
        }

        filters(detected_filters)

    }


    return(
        
        <div className='d-flex '>
            <InputGroup className='me-auto flex-fill input_group_multiple'>
            {/* <div> */}
                <Form.Control onChange={(e) => setInputSearch((oldSearch) => ({...oldSearch, "search": e.target.value}))} type="text" id='txt_search' className='form-control-md round_input search_personalized_text w-50' placeholder="Search"/>
                <Form.Control type="date" className='form-control-md round_input search_personalized_date'  placeholder='Date' 
                     onChange={(e) => setInputSearch((oldSearch) => ({...oldSearch, "date": e.target.value}))}
                    // onFocus={(e) => (e.target.type = "date")}
                    // onBlur={(e) => (e.target.type = "text")} 
                    />


            </InputGroup>
            {/* <InputGroup className='ms-2 flex-fill'> */}
                <Button className='ms-2'  
                        id="btn_search_input_separate" 
                        onClick={setFilters}>
                            <img src={btn_search_input} 
                            alt='Search button' 
                            className='img_btn_search_input flex-fill' />
                </Button>

            {/* </InputGroup> */}

        </div>
           
    )
}

function SearchBar(){
    const [inputSearch, setInputSearch] = useState('');
    var btn_search_input = require("../assets/btn_search_input.png")

    function searchFilter(e){
        e.preventDefault();
        // var search_value = $("#txt_search").value
        window.location.href='/search_photos?search=' + inputSearch;
    
    }


    return(
        
        <InputGroup>
            <Form.Control onChange={(e) => setInputSearch(e.target.value)} type="text" id='txt_search' className='form-control-md round_input round_input_border_white' placeholder="Type surf spot or photographer name"/>
            <Button className=''  id="btn_search_input" onClick={searchFilter}><img src={btn_search_input} alt='Search button' className='img_btn_search_input ' /></Button>
            

        </InputGroup>
    )
}

export {SearchBar, SearchBarMultipleElements, SearchAlbumsInput}