
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useMemo, useState, useEffect } from "react";
import Form from 'react-bootstrap/Form';
import { stripePromise, url_server } from '../settings';
import { SessionDetails } from '../controllers/AccountController';

import {Elements} from '@stripe/react-stripe-js';
import {PaymentElement, useStripe, useElements,} from '@stripe/react-stripe-js';

const CheckoutForm = ({album, user, total_price}) => {

    const stripe = useStripe();
    const elements = useElements();
  
    const handleSubmit = async (event) => {
      // We don't want to let default form submission happen here,
      // which would refresh the page.
      event.preventDefault();
  
      if (!stripe || !elements) {
        // Stripe.js hasn't yet loaded.
        // Make sure to disable form submission until Stripe.js has loaded.
        return;
      }
  
      const result = await stripe.confirmPayment({
        //`Elements` instance that was used to create the Payment Element
        elements,
        confirmParams: {
          return_url: window.location.origin + "/payment-complete",
        },
      });
  
      if (result.error) {
        // Show error to your customer (for example, payment details incomplete)
        // console.log(result.error.message);
        alert("Payment error")
      } else {
        // Your customer will be redirected to your `return_url`. For some payment
        // methods like iDEAL, your customer will be redirected to an intermediate
        // site first to authorize the payment, then redirected to the `return_url`.

      }
    };
  
    return (
        <form onSubmit={handleSubmit}>
            <PaymentElement />
            {/* <button disabled={!stripe}>Submit</button> */}
            <div className=''>
              <Button type='submit' className='btn_primary_blue btn_register float-end' disabled={!stripe}>Pay ${total_price}</Button>

            </div>

        </form>
    );
  };

function PaymentModal({show, setShow, albums, user, total_price}){
    const [clientSecret, setClientSecret] = useState()
	const [options, setOptions] = useState()

    // const options = {
    //     // passing the client secret obtained from the server
    //     clientSecret: clientSecret,
    // };


	useEffect(() =>{
		if(show){
			// console.log("entra")
			getSecret()
		}
	}, [show])

    function getSecret(){
		fetch(url_server + "private/create-payment-intent", {
		method: 'POST',
		body: JSON.stringify(
			{
				user_id: user.user_id,
				items: albums
			
			}
		),
		headers: {
			'Content-type': 'application/json; charset=UTF-8',
			"x-access-token": user.token,
		},
		})
		.then((response) => response.json())
		
		.then( (data) => {
		// GO TO SUCCESS PAGE
			// console.log(data)
			setOptions({clientSecret: data.response.clientSecret})
		
		})
		.catch((err) => {
			// console.log(err)
			// alert("Something went wrong. Please check your connection and try again.");
		});
    }

	
    
    function handleClose(){
  
        setShow(false);


    } 


    return (
		<>
		{options != null && options.clientSecret != null ? (
			
			<Modal
				show={show}
				onHide={handleClose}
				backdrop="static"
				keyboard={false}
			   
			>
				<Modal.Header closeButton>
				<Modal.Title>Payment Details</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Elements stripe={stripePromise} options={options}>
						<CheckoutForm albums={albums} user={user} total_price={total_price} />
					</Elements>
				</Modal.Body>
				<Modal.Footer>
					{/* <div className='d-flex w-100 justify-content-between'>
						<Button className='btn_outlined_blue btn_register'  onClick={handleClose}>Close</Button>
					</div> */}
					
	
				</Modal.Footer>
			</Modal>
			
		) : (
			""
		)}
       </>
    );
}

export default PaymentModal