import GroupSearchFilters from '../components/SectionFilters';
import PhotoPurchaseCard from '../components/SectionPurchaseCard';
import Container from 'react-bootstrap/esm/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import SectionPhotosShort, { SectionPhotosShortCarousel } from '../components/SectionPhotos';
import Navigation from '../components/Navigation'; 
import { SearchBar, SearchBarMultipleElements } from '../components/FormComponents';
import {ListNextSessions} from '../components/Lists';
import { Footer } from '../components/Footer';

import { GoogleMap, Marker, useLoadScript } from "@react-google-maps/api";
import { useMemo, useState, useEffect } from "react";
import { google_maps_api_key } from '../settings';
import { url_server } from '../settings';
import Spinner from 'react-bootstrap/Spinner';
import { SessionDetails } from '../controllers/AccountController';
import FileSize from '../components/FileSize';


function InfoSurferSection(){

    var img_photographer = require('../assets/img_landing_surfer_info.jpg')

    return(
        <Container fluid id='container_surfer'>
            <Row >

                <Col lg={7} xxl={7}  className='pt-5  img_landing_container'>
                    <div className='img_wrapper' id="id_img_landing_surfer">
                        <img className='img_landing_special' src={img_photographer} alt="Image Photographer" />

                    </div>
                    
                </Col>

                <Col lg={5} xxl={5} id='id_landing_info_surfer'>
                    <div className='right_margin ms-3 mt-5 mb-5'>
                        <p className='display-3 heading_font text-blue'>Made By Surfers For Surfers</p><br/>
                        <h5 className='text-blue'>Were you surfing and realized someone was shooting? If you talk to the photographer, ask if he is already on My Surf Shot. </h5><br/>
                        <h5 className='text-blue'>If yes, just search the session by location, date and time or photographer name.</h5><br/><br/>
                        <h5 className='text-blue'>Using our platform you can preview all your shots and select your favorites before buying them. </h5><br/>
                        <h5 className='text-blue'>Never lose a great moment again!</h5><br/>

                        <Button className='btn_primary_blue btn_register'  size='lg' onClick={(e) => {
                            e.preventDefault();
                            window.location.href='/search_photos';
                            } }>Find your shots
                        </Button>
                    </div>
                    

                </Col>

                
            </Row>

            
        </Container>
    );

}

function InfoPhotographerSection(){

    var img_photographer = require('../assets/img_girl_photographer_inverted.jpg')

    return(
        <Container fluid className=' mt-5' id='container_photographer'>
            <Row >
                <Col id="id_landing_info_photographer" xs={{ order: 'last' }} sm={{ order: 'last' }} md={{ order: 'last' }} lg={{span:5, order:"first"}} xxl={5}>
                    <div className='left_margin me-3 mt-5'>
                        <p className='display-3 heading_font text-blue'>Capture the Thrills, Share the Waves</p><br/><br/>
                        <h5 className='text-blue'>Upload your surf shots on our platform for surfers from around Australia to discover and automatically purchase them.</h5><br/>
                        <h5 className='text-blue'>Ready to make new waves? Share your next photo session location and let surfers go after you!</h5><br/>
                        <h5 className='text-blue'>Register now and dive into a world of benefits. Connect, Inspire and Grow your business as a Surf Photo/Videographer with us!</h5><br/>
                        <Button className='btn_primary_blue btn_register'  size='lg' onClick={(e) => {
                            e.preventDefault();
                            window.location.href='/account/login';
                            } }>Start sharing!
                        </Button>
                    </div>
                    

                </Col>

                <Col  xs={{ order: 'first' }} sm={{ order: 'first' }}  md={{order: 'first' }} lg={7} xxl={7} className='img_landing_container '>
                    <div className='img_wrapper' id="id_img_landing_photographer">
                        <img className='img_landing_special' src={img_photographer} alt="Image Photographer" />

                    </div>
                    
                </Col>
            </Row>

            
        </Container>
    );
}

function NextSessionsSection(){
    
    // const [filters, setFilters] = useState("")
    // const [nextSessions, setNextSessions] = useState([])

    const [filters, setFilters] = useState({})
    const [nextSessions, setNextSessions] = useState()
    const img_wave = require('../assets/logo_blue.png')
    const [center, setCenter] = useState({ lat: -28.166049075806885, lng: 153.53519109664748 })
    const [sessionSelected, setSessionSelected] = useState()

    const ic_map_selector = require('../assets/ic_map_selector.png')
    const ic_map_selector_selected = require('../assets/ic_map_selector_selected.png')
    const [sessionDetails, setSessionDetails] = useState(SessionDetails())


    const { isLoaded } = useLoadScript({
        googleMapsApiKey: google_maps_api_key,
    });

    function clickMarker(e, index){
        // console.log("click marker")
        if(index !== sessionSelected){
            var session = nextSessions
            
            if(sessionSelected != null) {delete session[sessionSelected].selected}
            session[index].selected = true

            setNextSessions(session)
            setSessionSelected(index)
            setCenter({lat: parseFloat(session[index].location_latitude), lng: parseFloat(session[index].location_longitude)})
        }
    
    }

    useEffect(() => {
        getNextSessions();
        
    }, [filters]);

    async function getNextSessions(){
        var response;
        var query = "?quantity_page=5"
    
        if(Object.keys(filters).includes("search")){
          query += "&search=" + filters.search
        }
        if(Object.keys(filters).includes("date")){
            query += "&date=" + filters.date
        }
    
        
        await fetch(`${url_server}public/next-sessions${query}`, {
            method: 'GET',
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            },
            })
            .then((response) => response.json())
            
            .then( (data) => {
                // response = data.response
                // console.log(data.response)
                setNextSessions(data.response)
    
            })
            .catch((err) => {
                // console.log(err)
                // alert("Something went wrong. Please check your connection and try again.");
            });
    
            return response;
    
    }

    
    // const center = useMemo(() => ({ lat: -28.166049075806885, lng: 153.53519109664748 }), []);

    return(
        <div className='full_height bg-light-green text-center'>
            <div className='mt-5 div_titles'>
                {/* <h1>Next Sessions</h1> */}
                <p className='display-3 heading_font text-blue'>Future Sessions</p>

                <p className='m-3'>Find out where there will be someone shooting close to you</p>
            </div>
            <Container fluid className='mt-5 mb-5 '>
                <Row >
                    <Col md={7} className='container_map'>
                        
                        {/* COMMENT FOR BILLING */}

                            {!isLoaded ? (
                                <h1>Loading...</h1>
                            ) : (
                                <GoogleMap
                                className='map'
                                mapContainerClassName="map-container"
                                center={center}
                                zoom={13}
                                >
                                    
                                    {nextSessions != null ? (
                                        <>
                                        {nextSessions.map((obj, index) =>{
                                            // console.log(obj.selected)
                                            return(
                                                <Marker
                                                    icon={{
                                                        url: obj.selected == true ? ic_map_selector_selected : ic_map_selector,
                                                        // anchor: new google.maps.Point(5,5 ),
                                                        
                                                    }}
                                                    position={{lat: parseFloat(obj.location_latitude), lng: parseFloat(obj.location_longitude)} }
                                                    key={index}
                                                    onClick={(e) => clickMarker(e, index)}
                                                />
                                            )
                                        })}
                                        </>
                                    ) : ("")}
                                    
                                </GoogleMap>
                            )}

                    </Col>

                    <Col md={5} lg={5} xl={5} xxl={5} className='container_list_next_sessions'>
                        {/* <div className='ms_3vw me_3vw'>
                            <SearchBarMultipleElements filters={setFilters} />
                        </div> */}
                        <div className=' ms_3vw me_3vw' id='list_next_sessions'>
                            {/* <ListNextSessions nextSessions={nextSessions} /> */}
                            {nextSessions == null ? (
                                <div className="text-center">
                                    <Spinner animation="border" role="status" className="mt-5 ">
                                        <span className="visually-hidden">Loading...</span>
                                    </Spinner>
                                </div>
                            ) : (
                                <>
                                
                                {nextSessions.length === 0 ? (
                                    <div>
                                        <img className=" mt-5 profile_wave d-block ms-auto me-auto" src={img_wave} alt="logo" /> 
                                        <p className="text_light_blue text-center mt-3" >There are no future sessions set up yet</p>
                    
                                    </div>
                                ) : (
                                    <ListNextSessions  nextSessions={nextSessions} 
                                        setNextSessions={setNextSessions} 
                                        sessionSelected={sessionSelected} 
                                        setSessionSelected={setSessionSelected} 
                                        setCenter={setCenter}
                            
                                        />
                                )}
                                </>
                            )}

                        </div>
                        <div className='mt-3 text-center'>
                            <p className='p_large text-blue'><b>Are you a photographer?</b></p>
                            <p className='text-blue'>Promote your work by posting your surf shoot</p>
                            <Button className='btn_primary_blue btn_register m-0'  size='lg' onClick={(e) => {
                                e.preventDefault();
                                if(sessionDetails.user_unique_id == null || sessionDetails.user_unique_id == ""){
                                    window.location.href="/account/login"

                                }else{
                                    window.location.href='/'+sessionDetails.user_unique_id;
                                }
                               
                                } }>Post your session
                            </Button>
                        </div>
                        
                        
                    </Col>
                </Row>

            
        </Container>
        </div>
    );
}


function LatestSessionsSection(){

    return (
        <div className=' mb-5 full_height position-relative' >
            <div className='text-center m-5'>
                {/* <h1>Latest Sessions</h1> */}
                <p className='display-3 heading_font text-blue'>Latest Sessions</p>

                <p>Latest albums of videos and photos uploaded in your area</p>
            </div>
            <div className='latest_sessions_div'>
                {/* <Row> */}
                    {/* <Col md={12}> */}
                        <SectionPhotosShortCarousel />
                    {/* </Col> */}
                    {/* <Col md={3}>
                        <ListPhotographersBestContributors />
                    </Col> */}

                {/* </Row> */}
            </div>


            {/* position-absolute bottom-0 start-50 translate-middle */}
            <div className='text-center left_margin right_margin'>
                <h5>Can't find what you are looking for?</h5>
                {/* <Button>Search More</Button> */}
                
                <Button className='btn_primary_blue btn_register'  size='lg' onClick={(e) => {
                    e.preventDefault();
                    window.location.href='/search_photos';
                    } }>Search More
                </Button>
            </div>

                
        </div>
        
    )
}


function CtaSection({scrollTop, setScrollTop}){
    const img_cta = require('../assets/img_cta_surfer3.jpg')


    useEffect(() =>{
        const search_bar = document.querySelector('#cta_search_bar')
        const search_bar_top = search_bar.offsetTop

        const onScroll = () => setScrollTop(window.scrollY >= search_bar_top);

        window.removeEventListener('scroll', onScroll);

        window.addEventListener('scroll', onScroll, { passive: true });
        return () => window.removeEventListener('scroll', onScroll);
    }, [])


    return (
        <div className='div_cta_section'>
            {/* <img  src={img_cta} alt='CTA Image'/> */}
            <Container fluid className='container_cta'>
                <Row className=''>
                    <Col md={10} lg={8} xl={8} xxl={7}>
                    <div className='cta_content'>
                        <p className='display-2 display_2_personalized'>Find your</p>
                        <p className='display-2 display_2_personalized'>magical moments</p>
                        <p className='h5_personalized'>The first platform that allows you to buy photos and videos of your sessions, locate photographers and share the fun!</p>
                        <div className='mt-5 cta_search_bar w-75' id='cta_search_bar'>
                            <SearchBar />

                        </div>
                    </div>
                    </Col>
                </Row>
            </Container>
            

        </div>
  
    );
  }


  

function Main() {
    const [scrollTop, setScrollTop] = useState(false)

    return (
        <div > 
            <Navigation searchBarTop={scrollTop}  showSearchBar={2} />
            <CtaSection scrollTop={scrollTop} setScrollTop={setScrollTop} />
            <LatestSessionsSection />
            <NextSessionsSection />
            <InfoPhotographerSection />
            <InfoSurferSection />
            <Footer />
        </div>
       
    );
}

export default Main;