import {loadStripe} from '@stripe/stripe-js';
// ####### DEVELOPMENT #########
// const url_server = "http://localhost:11000/api/"

//const url_server = "https://mysurfshotbackendtest-baeb3d3ee5db.herokuapp.com/api/";
//const google_maps_api_key = "AIzaSyAKKRBEyJYjxj43Gm_hS2EtKkhIJn67EQA";
//const stripePromise = loadStripe("pk_test_51MxgAiLG5KIpmqesywCmHnaKDWvxOChLj9E9pmHtjza8jhxcTlOoYcPeGqAiUDJBIwJ3wVlpO9W7mlbHdJBylR1700UYdMCDXx")
//const stripe_pk = "pk_test_51MxgAiLG5KIpmqesywCmHnaKDWvxOChLj9E9pmHtjza8jhxcTlOoYcPeGqAiUDJBIwJ3wVlpO9W7mlbHdJBylR1700UYdMCDXx"


// ####### PRODUCTION ########
const url_server = "https://mysurfshot-production-5bbec3c798ed.herokuapp.com/api/";
const google_maps_api_key = "AIzaSyC61mtsGguh51zW0PdoaIXRv2hG9yYBEoE";
const stripePromise = loadStripe("pk_live_51MxgAiLG5KIpmqesITKnV5q2OYKGL0GzZ6JdOcEJRMsYuxbbw62FEV4H2gjtkCr565kubJeCu4kFFiNQRnJiB1Pc002IbiLi4w")
const stripe_pk = "pk_live_51MxgAiLG5KIpmqesITKnV5q2OYKGL0GzZ6JdOcEJRMsYuxbbw62FEV4H2gjtkCr565kubJeCu4kFFiNQRnJiB1Pc002IbiLi4w"




export {url_server, google_maps_api_key, stripePromise, stripe_pk}
