import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';
import { useState, useRef, useEffect} from "react";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './components.css';
import { url_server } from '../settings';
import {Link, useParams, useLocation } from 'react-router-dom'
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Watermark from '@uiw/react-watermark';
import { Carousel } from 'react-responsive-carousel'
import WatermarkedImage from './WatermarkedImage';
import ReactPlayer from 'react-player';
import watermarkedImage from './WatermarkedImage';
import Spinner from 'react-bootstrap/Spinner';
import axios from 'axios';
import { RWebShare } from "react-web-share";



function ProductModalSlider({show, onHide, selectedProduct, productIndex, watermarked, products, album_info, discounts}){
    // const [product, setProduct] = useState();
    const img = require('../assets/photo_card_example.jpg');
    const [cartStatus, setCartStatus] = useState(0)
    var current_cart = JSON.parse(localStorage.getItem('cart'))
    // const product_id = new URLSearchParams(useLocation().search).get("product_id");

    var dayNames = ['Sun','Mon','Tue','Wed','Thu','Fri','Sat'];
	var monthPrefixes = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];

    var start_time = new Date(album_info.album_datetime_start).toLocaleTimeString([], {hour12: false, hour: '2-digit', minute:'2-digit'})
	var end_time = new Date(album_info.album_datetime_end).toLocaleTimeString([], {hour12: false, hour: '2-digit', minute:'2-digit'})
	var month = new Date(album_info.album_datetime_start).getMonth()
	var day_number = new Date(album_info.album_datetime_start).toLocaleDateString().split("/")[0]
	var year_number = new Date(album_info.album_datetime_start).toLocaleDateString().split("/")[2]
    const [selectedInCarousel, setSelectedInCarousel] = useState(productIndex)
    // var watermark = require('dynamic-watermark');
    const img_logo_vertical = require('../assets/watermark.png')
    const arrow_back = require("../assets/arrow_back.png")
    const arrow_next = require('../assets/arrow_next.png')
    const [screenWidth, setScreenWidth] = useState(window.innerWidth)
    const [videoHeight, setVideoHeight] = useState("auto")
    const ic_share = require('../assets/ic_share.png')



    useEffect(() => {
        window.addEventListener("resize", () => {
            setScreenWidth(window.innerWidth)
        });
        return () => {
            window.removeEventListener("resize", () => {
                setScreenWidth(window.innerWidth)
            })
        }
    }, []);

    useEffect(() => {
        // // console.log("entraaaa")
        
        if(show){

            // const wm = []
            // // console.log("asd")
            // products.map((obj, index) =>{
            //     const imageUrl = obj.media_url;
            //     const watermarkUrl = img_logo_vertical;

            //     // Call the function to get the watermarked image blob
            //     watermarkedImage(imageUrl, watermarkUrl)
            //     .then((blob) => {
            //         // Do something with the blob, like creating a URL to display or download the image
            //         const watermarkedImageUrl = URL.createObjectURL(blob);
            //         // console.log(watermarkedImageUrl)
            //         wm.push(watermarkedImageUrl)
            //         // setWatermarkedImageUrl(watermarkedImageUrl);
            //     })
            //     .catch((error) => {
            //         console.error('Error while processing watermarked image:', error);
            //     });
            // })

            // setWatermarked(wm)
            setSelectedInCarousel(productIndex)

            
            
            
        }
   
    }, [show]);



    useEffect(() =>{
        if(show){
            window.history.replaceState({}, document.title, '?product_id='+products[selectedInCarousel].product_id);

            setCartStatus(0)
            if(current_cart != null){
                current_cart.map((dataObj, index) => {
                    const array = dataObj.products
                    array.map((p, i) =>{
                        if(p.product_id == products[selectedInCarousel].product_id){
                            // console.log("yes")
                            setCartStatus(1)
                            
                            return;
                        }
                    })
                        
                })

    
            }
        }else{
            setCartStatus(0)
        }
    }, [selectedInCarousel])



    function addToCart(product){
        if(cartStatus === 0){
            if(current_cart === null || current_cart.length == 0){
                // CREATE CART IF NULL
                album_info.products = [product]
                var album_products = JSON.stringify([album_info])

                localStorage.setItem('cart', album_products);
            }else{
                // add to current cart
                var exists_album_index = -1

                current_cart.map((dataObj, index) => {
                    // console.log(dataObj.album_id + " " + album_info.album_id )
                    if(dataObj.album_id == album_info.album_id){
                        exists_album_index = index
                        // current_cart[index].products.push(product)
                    }
                })

                if(exists_album_index === -1){
                    album_info.products = [product]
                    current_cart.push(album_info)
                }else{
                    current_cart[exists_album_index].products.push(product)

                    
                }


                localStorage.setItem('cart', JSON.stringify(current_cart));
            }
    
            // localStorage.setItem('cart', data.user_first_name);
            window.dispatchEvent(new Event("cart_item_add"));

            setCartStatus(1)
        }else{
            // GO TO CART PAGE
            window.location.replace(window.location.origin + '/cart');

        }
        
    }

    function stopActions(e){
        // console.log(e.button)
        if(e.button == 2){
            e.preventDefault()
            return false;
        }
    }

    const arrowStyles = {
        position: 'absolute',
        zIndex: 2,
        top: 'calc(50% - 15px)',
        width: 30,
        height: 30,
        cursor: 'pointer',
    };

    

  return (
    <Modal
    //   {...props}
        show={show}
        onHide={onHide}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      
    >
      <Modal.Header closeButton className='border-0'>
        {/* <Modal.Title id="contained-modal-title-vcenter">
          Modal heading
        </Modal.Title> */}
        
      </Modal.Header>

      <Modal.Body className='modal_product'>
        { selectedProduct != null && album_info != null  ? (
            <Row className={screenWidth < 768 ? "" : "p-3"}>

                <Col xl={8} className='col_modal_product'>
                    <Carousel 
                    selectedItem={productIndex} 
                    showStatus={false} 
                    dynamicHeight={true} 
                    swipeable={screenWidth < 768 ? false : true}
                    autoFocus
                    showIndicators={false}
                    useKeyboardArrows={true}
                    // showThumbs={false}
                    showThumbs={screenWidth < 576 ? false: true}
                    thumbWidth={230} onChange={(e) => setSelectedInCarousel(e)}
                    renderArrowPrev={(onClickHandler, hasPrev, label) =>
                        hasPrev && (
                            <button className='transparent' type="button" onClick={onClickHandler} title={label} style={{ ...arrowStyles, left: 15 }}>
                                <img src={arrow_back} alt='arrow back' onClick={onClickHandler} />

                            </button>
                        )
                    }
                    renderArrowNext={(onClickHandler, hasNext, label) =>
                        hasNext && (
                            <button className='transparent' type="button" onClick={onClickHandler} title={label} style={{ ...arrowStyles, right: 15 }}>
                                <img src={arrow_next} alt='arrow back' onClick={onClickHandler}/>
                            </button>
                            

                        )
                    }
                    >
                        {products.map((obj, index) =>{

                            return(
                                <div key={index} onContextMenu={(e) => e.preventDefault()} onMouseDown={stopActions}>
                                
                                    {products[index].media_format.toLowerCase() == "png" || products[index].media_format.toLowerCase() == "jpg" || products[index].media_format.toLowerCase() == "jpeg" ? (
                                        // <WatermarkedImage imageUrl={products[index].media_url} watermarkUrl={img_logo_vertical} />
                                        <img src={products[index].media_url_watermarked} alt="image" onContextMenu={(e) => e.preventDefault()} onMouseDown={stopActions} className='img_popup_product' />

                                        
                                    ) : (products[index].media_format.toLowerCase() == "mp4" || products[index].media_format.toLowerCase() == "ogg" || products[index].media_format.toLowerCase() == "mov" ? (

                                            <div className='video-container'>
                                
                                                <video height={videoHeight} className="video_round" controls onContextMenu={(e) => e.preventDefault()} onMouseDown={stopActions} onLoadedData={(e) => setVideoHeight(e.target.offsetHeight)}>
                                                    <source  src={products[index].media_url_watermarked} type="video/mp4"/>
                                                    <source  src={products[index].media_url_watermarked} type="video/ogg"/>
                                                    <source  src={products[index].media_url_watermarked} type="video/mov"/>
                                                    Your browser does not support the video tag.
                                                </video>  

                                                {/* <video className="video_round" controls onContextMenu={(e) => e.preventDefault()} onMouseDown={stopActions} src={products[index].media_url_watermarked} /> */}

                                                {/* <div className="watermark"></div> */}


                                            </div> 
                                            
                                                
                                        ) : (""))
                                    }
                                </div>
                            )

                            
                        })}

                    
                    </Carousel>
                    <div id='item_counter'><span>{selectedInCarousel+1}/{products.length}</span></div>


                </Col>
         
                <Col xl={4} className=' flex-column algin-items-start div_info_product_popup mt-3 p-1'>
                    {selectedInCarousel != null ? (
                        <>
                        <div className='mb-auto'>
                            <h3 className='text-blue mb-4'><b>{album_info.location_name}</b></h3>
                            <p className='label_product_modal'>Format: <span className='text-dark'>{products[selectedInCarousel].product_type_id == 1 ? "Photo" : "Video"} ({products[selectedInCarousel].media_format != null ? products[selectedInCarousel].media_format.toUpperCase() : ""})</span></p>
                            <p className='label_product_modal'>Date: <span className='text-thin text-dark'>{day_number} {monthPrefixes[month]} {year_number} | {start_time} - {end_time}</span></p>
                            <p className='label_product_modal'>Author: <a href={`/${album_info.user_unique_id}`} className='text-dark'>{products[selectedInCarousel].photographer_business_name}</a></p>
                            <p className='label_product_modal'>Size: <span className='text-dark'>{(products[selectedInCarousel].media_size/1000000).toFixed(2)} MB</span></p>
                            <div>
                                {discounts != null && discounts.length > 0 ? (
                                    <>
                                
                                    <p className='label_product_modal'>Special Offers: </p>
                                    <div className='ms-5'>
                                        {discounts.map((obj, index) =>{
                                            return(
                                                <p key={index} className="label_product_modal mb-2"><b>{parseInt(obj.discount_percentage * 100)}% OFF</b><span className="text-grey"> - Buying {obj.discount_quantity}</span></p>

                                            )
                                        })}

                                    </div>
                                    </>
                                ) : (
                                    ""
                                )}
                                

                            </div>
                        </div>
                        <div className='mt-5'>
                            <p className='p_large'>Price: <b className='text-dark'>${(products[selectedInCarousel].price / 100).toFixed(2)}</b></p>
                            <Button className='btn_primary_blue btn_register w-100' onClick={() => addToCart(products[selectedInCarousel])} >{cartStatus == 0 ? 'Add To Cart' : 'See your cart'}</Button>
                            <RWebShare
									data={{
										text: products[selectedInCarousel].photographer_business_name,
										url: window.location.href,
										title: album_info.location_name,
									}}
									
								>
                                    <Button className='btn_outlined_blue btn_register w-100'><img src={ic_share} alt="share" className='me-1' /> Share</Button>
								</RWebShare>
                        
                        </div>
                        </>

                    ): ""}
                    
                    
                </Col>
            </Row>
        ) : (
            <div className="text-center">
                <Spinner animation="border" role="status" className="mt-5 ">
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
            </div>
        )}
        
      </Modal.Body>

    </Modal>
  );

}



function ProductModal({show, onHide, product_info, album_info, discounts}){
    const [product, setProduct] = useState();
    const img = require('../assets/photo_card_example.jpg');
    const [cartStatus, setCartStatus] = useState(0)
    var current_cart = JSON.parse(localStorage.getItem('cart'))
    const product_id = new URLSearchParams(useLocation().search).get("product_id");
    // console.log(product_id)


    var dayNames = ['Sun','Mon','Tue','Wed','Thu','Fri','Sat'];
	var monthPrefixes = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];

    var start_time = new Date(album_info.album_datetime_start).toLocaleTimeString([], {hour12: false, hour: '2-digit', minute:'2-digit'})
	var end_time = new Date(album_info.album_datetime_end).toLocaleTimeString([], {hour12: false, hour: '2-digit', minute:'2-digit'})
	var month = new Date(album_info.album_datetime_start).getMonth()
	var day_number = new Date(album_info.album_datetime_start).toLocaleDateString().split("/")[0]
	var year_number = new Date(album_info.album_datetime_start).toLocaleDateString().split("/")[2]
    // var watermark = require('dynamic-watermark');

    
    const img_logo_vertical = require('../assets/watermark.png')
    useEffect(() => {
        if(show){
            getProduct();
            if(current_cart != null){
                current_cart.map((dataObj, index) => {
                    const array = dataObj.products
                    array.map((p, i) =>{
                        if(p.product_id == product_info.product_id){
                            setCartStatus(1)
                    
                        }
                    })
                        
                })
    
            }
            
        }else{
            setCartStatus(0)
        }
   
    }, [show]);

   function getProduct(){

    var query = "?product_id=" + product_info.product_id;
   
    
     fetch(url_server + "public/products" + query, {
        method: 'GET',
        headers: {
            'Content-type': 'application/json; charset=UTF-8',
        },
        })
        .then((response) => response.json())
        
        .then( (data) => {
            // response = data.response
            // // console.log(data.response)

            setProduct(data.response[0])

        })
        .catch((err) => {
            // console.log(err)
        });

    }

    function addToCart(product){
        if(cartStatus === 0){
            if(current_cart === null || current_cart.length == 0){
                // CREATE CART IF NULL
                album_info.products = [product]
                var album_products = JSON.stringify([album_info])

                localStorage.setItem('cart', album_products);
            }else{
                // add to current cart
                var exists_album_index = -1

                current_cart.map((dataObj, index) => {
                    // console.log(dataObj.album_id + " " + album_info.album_id )
                    if(dataObj.album_id == album_info.album_id){
                        exists_album_index = index
                        // current_cart[index].products.push(product)
                    }
                })

                if(exists_album_index === -1){
                    album_info.products = [product]
                    current_cart.push(album_info)
                }else{
                    current_cart[exists_album_index].products.push(product)

                    
                }


                localStorage.setItem('cart', JSON.stringify(current_cart));
            }
    
            // localStorage.setItem('cart', data.user_first_name);
            window.dispatchEvent(new Event("cart_item_add"));

            setCartStatus(1)
        }else{
            // GO TO CART PAGE
            window.location.replace(window.location.origin + '/cart');

        }
        
    }

    function stopActions(e){
        // console.log(e.button)
        if(e.button == 2){
            e.preventDefault()
            return false;
        }
    }

   

  return (
    <Modal
    //   {...props}
        show={show}
        onHide={onHide}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    
      
    >
      <Modal.Header closeButton className='border-0'>
        {/* <Modal.Title id="contained-modal-title-vcenter">
          Modal heading
        </Modal.Title> */}
      </Modal.Header>

      <Modal.Body className='modal_product'>
        { product != null && product_info != null && album_info != null ? (
            <Row className='p-3'>
                <Col xl={8} className='div_image_product_popup'>
                    {product_info.media_format.toLowerCase() == "png" || product_info.media_format.toLowerCase() == "jpg" || product_info.media_format.toLowerCase() == "jpeg" ? (
                        
                        <>  
                        <Watermark
                        rotate={0}
                        height={400}
                        width={550}
                        image={img_logo_vertical}
                        
                        
                        >
                            <img src={product_info.media_url} alt="image" onContextMenu={(e) => e.preventDefault()} onMouseDown={stopActions} />

                        </Watermark>

                        
                        {/* <img src={product_info.media_url} alt="image" onContextMenu={(e) => e.preventDefault()} onMouseDown={stopActions} /> */}
                        </>
        
                    ) : (product_info.media_format.toLowerCase() == "mp4" || product_info.media_format.toLowerCase() == "ogg" || product_info.media_format.toLowerCase() == "mov" ? (
                            <div>
                                <video className="card_round mt-3" controls onContextMenu={(e) => e.preventDefault()} onMouseDown={stopActions}>
                                    <source  src={product_info.media_url} type="video/mp4"/>
                                    <source  src={product_info.media_url} type="video/ogg"/>
                                    <source  src={product_info.media_url} type="video/mov"/>
                                    Your browser does not support the video tag.
                                </video> 
                            </div>
                                
                        ) : (""))
                    }
                    
                    

                </Col>
                <Col xl={4} className='d-flex flex-column algin-items-start div_info_product_popup'>
                    <div className='mb-auto'>
                        <h3 className='text-blue mb-4'><b>{album_info.location_name}</b></h3>
                        {/* <p className='label_product_modal'>Resolution: <span className='text-dark'>{product.media_resolution}</span></p> */}
                        <p className='label_product_modal'>Format: <span className='text-dark'>{product.product_type_id == 1 ? "Photo" : "Video"} ({product.media_format.toUpperCase()})</span></p>
                        <p className='label_product_modal'>Date: <span className='text-thin text-dark'>{day_number} {monthPrefixes[month]} {year_number} | {start_time} - {end_time}</span></p>
                        <p className='label_product_modal'>Author: <a href={`/${album_info.user_unique_id}`} className='text-dark'>{product.photographer_business_name}</a></p>
                        <div>
                            {discounts != null && discounts.length > 0 ? (
                                <>
                            
                                <p className='label_product_modal'>Special Offers: </p>
                                <div className='ms-5'>
                                    {discounts.map((obj, index) =>{
                                        return(
                                            <p key={index} className="label_product_modal mb-2"><b>{parseInt(obj.discount_percentage * 100)}% OFF</b><span className="text-grey"> - Buying {obj.discount_quantity}</span></p>

                                        )
                                    })}

                                </div>
                                </>
                            ) : (
                                ""
                            )}
                            

                        </div>
                    </div>
                    <div className='mt-4'>
                        <p className='p_large'>Price: <b className='text-dark'>${(product.price / 100).toFixed(2)}</b></p>
                        <Button className='btn_primary_blue btn_register w-100' onClick={() => addToCart(product)} >{cartStatus == 0 ? 'Add To Cart' : 'See your cart'}</Button>
                    </div>
                    
                </Col>
            </Row>
        ) : (
            ""
        )}
        
      </Modal.Body>

    </Modal>
  );

}

export {ProductModal, ProductModalSlider}