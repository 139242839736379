import Container from 'react-bootstrap/esm/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Navigation from '../components/Navigation';
import Accordion from 'react-bootstrap/Accordion';
import Form from 'react-bootstrap/Form';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import { Footer } from '../components/Footer';
import Modal from 'react-bootstrap/Modal';

import './pages.css';
import { useState, useRef, useEffect} from "react";
import { url_server } from '../settings';

function SuccessfullModal({show, setShow}){
    // const [show, setShow] = useState(false);
    const img_tick = require('../assets/img_tick.png')
    const img_successfull = require('../assets/img_successfull.png')
    
    function handleClose(){
        setShow(false);
        
    }

    return (
        <>

        <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
        >
            <Modal.Header>
            <Modal.Title>Your Message is On Its Way!</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className='text-center'>            
                    <img src={img_successfull} alt="Success" className="mb-5" />

                    <p className="text-center">Thanks for getting in touch! Your message has been successfully sent to us. Our team is already at work, and you'll hear back from us shortly.</p>
                </div>
               
                
                
            </Modal.Body>
            <Modal.Footer>
               
                <Button className={'btn_primary_blue btn_register w-100'} onClick={handleClose}>Close</Button>

               

            </Modal.Footer>
            
        </Modal>
        </>
    );
}

function SectionHeaderContactUs(){

    return (
        <div className='header_div' id="container_account">
            <Container >
                
                <h1>Get in touch</h1>
                <h3 className='text-blue'>Any questions or remarks? Just write us a message!</h3>
                <h3 className='text-blue'>We will get back to you as soon as possible</h3>
            </Container>
        </div>
        
    );

}

function SectionFAQ() {
    return(
        <Container className='mt-5'>
            <h2 align='center'>Frequently Asked Questions</h2>

            <Row className='mt-5' align="center">
                <Col xl={4} lg={4} md={4} sm={12} xs={12} className='mb-5' align='start'>
                    <h6 >Category</h6>
                    <h5>Question 1 dsfsdfdsafdsf</h5>
                </Col>
                <Col xl={4} lg={4} md={4} sm={12} xs={12} className='mb-5' align='start'>
                    <h6>Category</h6>
                    <h5>Question 1 dsfdsfdfasfd</h5>
                </Col>
                <Col xl={4} lg={4} md={4} sm={12} xs={12} className='mb-5' align='start'>
                    <h6>Category</h6>
                    <h5>Question 1 dfsasdfdfdsf</h5>
                </Col>
                <Col xl={4} lg={4} md={4} sm={12} xs={12} className='mb-5' align='start'>
                    <h6>Category</h6>
                    <h5>Question 1</h5>
                </Col>
                <Col xl={4} lg={4} md={4} sm={12} xs={12} className='mb-5' align='start'>
                    <h6>Category</h6>
                    <h5>Question 1</h5>
                </Col>
                <Col xl={4} lg={4} md={4} sm={12} xs={12} className='mb-5' align='start'>
                    <h6>Category</h6>
                    <h5>Question 1</h5>
                </Col>
                    
            </Row>

            <hr></hr>

        </Container>
    );
}

function SectionContactForm() {
    const img_contactus = require('../assets/img_contactus.png');
    const [validated, setValidated] = useState(false);
    const [data, setData] = useState({})
    const [showModal, setShowModal] = useState(false)
    const validateData = (event) => {
        const form = event.currentTarget;
        // console.log(form)
        event.preventDefault();

        if (form.checkValidity() === false) {
          event.stopPropagation();

        }else{
            
            sendForm();
            form.reset()
            
        }
        setValidated(true);
        
    };

    function sendForm(){
        fetch(url_server + "public/contact", {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            },
            })
            .then((response) => response.json())
            
            .then( (data) => {
         
                if(data.error){
                    
                    alert("Something went wrong, please check your details and try again.")
                    
                }else{
                    // alert("Your enquiry has been sent successfully")
                    setData({})
                    setShowModal(true)
                }
                
    
            })
            .catch((err) => {
                // console.log(err)
            });
    }

    return(
        <Container className='div_negative mb-5'>
            <Row> 
                <Col  md={{ span: 6, order: 'first' }} xs={{order: 'last'}} >
                    <Form className='white_round2 p-5' onSubmit={validateData} > 
                        <Form.Group className="mb-3" controlId="formBasicFirstName">
                            <Form.Label>First Name</Form.Label>
                            <Form.Control type="text" required placeholder="Enter First Name" onChange={(e) => setData((oldSearch) => ({...oldSearch, "user_first_name": e.target.value}))} />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicLastName">
                            <Form.Label>Last Name</Form.Label>
                            <Form.Control type="text" required placeholder="Enter Last Name" onChange={(e) => setData((oldSearch) => ({...oldSearch, "user_last_name": e.target.value}))}/>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Email address</Form.Label>
                            <Form.Control type="email" required placeholder="Enter email" onChange={(e) => setData((oldSearch) => ({...oldSearch, "email_from": e.target.value}))} />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicEnquiry">
                            {/* <FloatingLabel controlId="floatingTextarea" label="Enquiry"> */}
                            <Form.Label>Inquiry</Form.Label>

                                <Form.Control
                                as="textarea"
                                placeholder="Your Inquiry"
                                rows={5}
                                style={{ height: '100%' }}
                                required
                                onChange={(e) => setData((oldSearch) => ({...oldSearch, "enquiry": e.target.value}))}
                                />
                            {/* </FloatingLabel> */}
                        </Form.Group>
                        
                        <div className='text-center'>
                            <Button className='btn_primary_blue btn_register' type='submit' size='lg'>Submit</Button>
                        </div>
                       
                    </Form>

                </Col>

                <Col md={{span: 5, offset: 1, order: 'last'}} xs={{order: 'first'}} className='mb-3'>
                    <img className='img_section' src={img_contactus} alt="Image Contact us"/>
                </Col>
            </Row>
            <SuccessfullModal show={showModal} setShow={setShowModal} />

        </Container>
    );
}


function Contact_us() {
    
        return (
            <div className='background-light'>
                <Navigation />
                <SectionHeaderContactUs />
                <SectionContactForm />
                <Footer />
    
            </div>
           
        );
    }
    
    export default Contact_us;