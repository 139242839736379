import Form from 'react-bootstrap/Form';
import { useState, useRef, useEffect} from "react";
import axios from 'axios';
import React, { Component } from 'react';
import { url_server } from '../settings';
import { SessionDetails } from '../controllers/AccountController';
import Compressor from 'compressorjs';

function FileDataBack ({file}){
 

    return (
        <>
        {file != null ? (
            <div>
            <p>File Name: {file.selectedFile.name}</p>

        </div>
        ) : ""}
        </>
    );
    
};

function FileDataFront ({file}){
 

    return (
        <>
        {file != null ? (
            <div>
            <p>File Name: {file.selectedFile.name}</p>

        </div>
        ) : ""}
        </>
    );
    
};


function UploadFile({twoFilesNeed, fileFront, setFileFront, fileBack, setFileBack}) {

    const [userData, setUserData] = useState(SessionDetails())


    function onFileChangeFront(event){
 
        // Update the state
        setFileFront({ selectedFile: event.target.files[0] });
 
    };

    function onFileChangeBack(event){
 
        // Update the state
        setFileBack({ selectedFile: event.target.files[0]});
 
    };

    
 
    return (
        <div className='mt-3'>
            <div>
                <Form.Label className="fw-bold">Upload Front </Form.Label><br/>

                <input type="file" id="id-file" name="id-file" accept=".jpeg,.jpg,.png" onChange={onFileChangeFront} />
                
            </div>

            <div className='mt-3'>
                {twoFilesNeed == true ? (
                    <>
                    <Form.Label className="fw-bold">Upload Back </Form.Label><br/>
                    <input type="file" id="id-file" name="id-file" accept=".jpeg,.jpg,.png" onChange={onFileChangeBack} />
                    </>
                ) : ""}
            </div>

            {/* <FileDataFront file={fileFront} />

            {twoFilesNeed == true ? (
                <FileDataBack file={fileBack} />

            ) : ""} */}

        </div>
    );
    
}

  export default UploadFile;