import Container from 'react-bootstrap/esm/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Navigation from '../components/Navigation';
import './pages.css';
import { useState, useRef, useEffect} from "react";




function SectionAboutUs() {
    const img_au_1 = require('../assets/aboutus_2.png')

    return (
            <Row align="center" className='align-items-center section'>
                <Col xl={6} lg={6} md={6} sm={12} xs={12} className='p-5'>
                    <h1>About us</h1>
                    <h2>Subtitle info</h2>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum</p>

                </Col>
                <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                    <img className='img_section' src={img_au_1} allt="About us ilustration"/>

                </Col>
            </Row>

            
    );
}

function SectionAboutPhotographers() {
    const img_au_1 = require('../assets/aboutus_3.jpg')

    return (
            <Row align="center" className='align-items-center section'>
                <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                    <img className='img_section' src={img_au_1} allt="About us ilustration"/>

                </Col>
                <Col xl={6} lg={6} md={6} sm={12} xs={12} className='p-5'>
                    <h1>About Photographers</h1>
                    <h2>Subtitle info</h2>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum</p>
                    <Button>Start selling</Button>
                </Col>
                
            </Row>
    );
}

function SectionAboutSurfers() {
    const img_au_1 = require('../assets/aboutus_1.jpg')

    return (

            <Row align="center" className='align-items-center section'>
                <Col xl={6} lg={6} md={6} sm={12} xs={12} className='p-5'>
                    <h1>About Surefers</h1>
                    <h2>Subtitle info</h2>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum</p>
                    <Button>Search photos</Button>
                </Col>
                <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                    <img className='img_section' src={img_au_1} allt="About us ilustration"/>

                </Col>
                
            </Row>
            
    );
}




function Aboutus() {
// ON SCROLL REVEAL DEVELOPMENT
    
    // const [isIntersecting, setIsIntersecting] = useState(false);
    // const ref = useRef(null);

    // useEffect(() => {
    //     const observer = new IntersectionObserver(
    //         ([entry]) => {
    //             setIsIntersecting(entry.isIntersecting);
    //           },
    //           {rootMargin: "-100px"}
    //     );
    //     // console.log(isIntersecting);
    
    //     observer.observe(ref.current);
    //     return () => observer.disconnect();
    
    
    //   }, [isIntersecting]);


// **END**

    return (
        <div>
            <Navigation />
            <Container >
                <SectionAboutUs />
                <SectionAboutPhotographers />
                <SectionAboutSurfers />
            </Container>

        

        </div>
       
    );
}

export default Aboutus;