import Navigation from "../components/Navigation";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { useState, useRef, useEffect} from "react";
import { url_server } from '../settings';
import Form from 'react-bootstrap/Form';
import { GetDataFromUserId, saveSession } from '../controllers/AccountController';
import InputGroup from 'react-bootstrap/InputGroup';
import { Link, useParams, useLocation } from 'react-router-dom';


function LoginError({content}){
    return(
        <Form.Text className="text-danger">
          {content}
        </Form.Text>
    );
}


function FormResetPassword({token, email}){
    const [data, setData] = useState({});
    const [loginError, setLoginError] = useState(<LoginError />)
    const [validated, setValidated] = useState(false);
    const [showPassword, setShowPassword] = useState(false)
    var ic_eye = require("../assets/ic_eye.png")
    var ic_eye_slash = require("../assets/ic_eye_slash.png")
    const [passwordChanged, setPasswordChanged] = useState(false)

    function goToSignIn(){
        window.location.href = "/account/login"
    }

    const validateData = (event) => {
        const form = event.currentTarget;
        event.preventDefault();

        if (form.checkValidity() === false) {
          event.stopPropagation();

        }else{
            
            if(data.user_password == data.user_repeat_password){
                setLoginError(<LoginError />)
                changePassword()

            }else{
                setLoginError(<LoginError content="Passwords don't match" />)
            }
            
        }
        setValidated(true);
        
    };

    function changePassword(){


        fetch(url_server + "private/users", {
            method: 'PUT',
            body: JSON.stringify({user_password: data.user_password}),
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
                'x-access-token': token
            },
            })
            .then((response) => response.json())
            
            .then( (data) => {
                if(data.error){
                    alert(data.errorDescription)
                }else{
                    setPasswordChanged(true)

                }
    
            })
            .catch((err) => {
                // console.log(err)
            });

    }


    
    return(
        <div>
            {passwordChanged == false ? (
                <Form className='col-md-4 form_white_centered2' onSubmit={validateData}>
                    <h4 className="mb-4"><b>Change password </b></h4>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Enter your new password</Form.Label>
                        <Form.Control required type="password" placeholder="Type here" onChange={(e) => setData((oldSearch) => ({...oldSearch, "user_password": e.target.value}))} />
                
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Confirm password</Form.Label>
                        <Form.Control required type="password" placeholder="Type here" onChange={(e) => setData((oldSearch) => ({...oldSearch, "user_repeat_password": e.target.value}))} />
                
                    </Form.Group>

                    {loginError}

                
                    <div className='text-center'>
            
                        <Button className='btn_primary_blue btn_register w-100' type="submit">Change password</Button>

                    </div>
                    
                </Form>
            ) : (
                <div className="col-md-5 form_white_centered_no_border text-center">
                    {/* <img src={img_successfull} alt="Success" className="mb-5" /> */}
                    <p className="fw-bold p_large">Password changed</p>
                    <p className="text-thin p_small">Your password has been changed correctly.</p>
                    <Button className='btn_primary_blue btn_register  ms-auto' onClick={goToSignIn} >Log in</Button>

                </div>
            )}
            
        </div>
        
    );

}

function ResetPasswordForm(){
    const email = new URLSearchParams(useLocation().search).get("email");
    const token = new URLSearchParams(useLocation().search).get("token");
    
    return(
        <>
        {email != null && email != "" && token != null && token != "" ? (
            <div>
                <Navigation />
                <Container id="container_login">
                    <FormResetPassword token={token} email={email} />

                </Container>

            </div>
        ) : (
            <div>
                <Navigation />
                <Container id="container_login">
                    <h3 className="text-blue text-center">Access Forbidden</h3>
                </Container>

            </div>
        )} 

        </>
        
    );

}

export default ResetPasswordForm;