import { url_server } from "../settings";
import { useState, useRef, useEffect} from "react";



function SessionDetails(){
    var details = {};
    // var details = {
    //     "user_id": 1,
    //     "user_first_name": "Razvan",
    //     "user_last_name": "Pavel",
    //     "token": "asdsad",
    //     "user_default_profile": "surfer"
        
    // }

    if(localStorage.getItem("token") === null){
        return {}
    }else{
        var details = {}
        details.user_id = localStorage.getItem('user_id') != "" ? localStorage.getItem('user_id') : null ;
        details.user_first_name = localStorage.getItem('user_first_name') != "" ? localStorage.getItem('user_first_name') : null ;
        details.user_last_name = localStorage.getItem('user_last_name') != "" ? localStorage.getItem('user_last_name') : null ;
        details.token = localStorage.getItem('token') != "" ? localStorage.getItem('token') : null ;
        details.user_default_profile = localStorage.getItem('user_default_profile') != "" ? localStorage.getItem('user_default_profile') : null ;
        details.user_unique_id = localStorage.getItem('user_unique_id') != "" ? localStorage.getItem("user_unique_id") : null;
        details.photographer_id = localStorage.getItem('photographer_id') != "" ? localStorage.getItem("photographer_id") : null;
        details.client_id = localStorage.getItem('client_id') != "" ? localStorage.getItem("client_id") : null;


        return details;
    }

    


    // if (items) {
    //     setItems(items);
    // }

    // return details;
}

function isLogged(){

	// CHECK IF THERE IS SESSION AND APPLY TO THE WHOLE PAGE
    var details = SessionDetails();
    // console.log(details)

    if(details.token != null || details.token === ""){
        return true;
    }else{
        return false
    }

}

async function GetDataFromUserId(user_id, token){
    const [response, setResponse] = useState({})

     fetch(url_server + 'private/users/' + user_id, {
        method: 'GET',
        
        headers: {
            'Content-type': 'application/json; charset=UTF-8',
            'x-access-token': token,
        },
        })
        .then((response) => response.json())
        .then((data) => {
        // GO TO SUCCESS PAGE
            // if(data.error){
            //     alert("Something went wrong. Please check your connection and try again.");

            // }else{
            //     // console.log(data)
            // }

            setResponse(data)

        })
        .catch((err) => {
            // alert("Something went wrong. Please check your connection and try again.");
            setResponse(err)
        });

        return response
    
}

function refreshSession(data){
    localStorage.setItem('user_first_name', data.user_first_name);
    localStorage.setItem('user_last_name', data.user_last_name);
    localStorage.setItem('user_unique_id', data.user_unique_id);

    window.dispatchEvent(new Event("session"));

    
}

function saveSession(data){
    // console.log(data);
    localStorage.setItem('user_id', data.user_id);
    localStorage.setItem('user_first_name', data.user_first_name);
    localStorage.setItem('user_last_name', data.user_last_name);
    localStorage.setItem('token', data.token);
    localStorage.setItem('user_default_profile', data.user_default_profile);
    localStorage.setItem('user_unique_id', data.user_unique_id);
    if(data.user_default_profile == 2){
        localStorage.setItem('photographer_id', data.photographer_id);
        localStorage.setItem('client_id', data.client_id);

    }else{
        localStorage.setItem('client_id', data.client_id);

    }


}

function deleteSession(){
    // FUNCTION THAT DELETES SESSION FROM LOCAL STORAGE
    // localStorage.clear();
    localStorage.removeItem("user_id")
    localStorage.removeItem("user_first_name")
    localStorage.removeItem("user_last_name")
    localStorage.removeItem("token")
    localStorage.removeItem("user_default_profile")
    localStorage.removeItem("user_unique_id")
    localStorage.removeItem('client_id');
    localStorage.removeItem('photographer_id');


}




export {isLogged, SessionDetails, deleteSession, saveSession, GetDataFromUserId, refreshSession}