import GroupSearchFilters from './SectionFilters';
import AlbumPurchaseCard from './SectionPurchaseCard';
import Container from 'react-bootstrap/esm/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { useState, useRef, useEffect, useCallback} from "react";
import Carousel from 'react-bootstrap/Carousel';
import { url_server } from '../settings';
import { useBottomScrollListener } from 'react-bottom-scroll-listener';
import {BottomScrollListener} from 'react-bottom-scroll-listener';
import Spinner from 'react-bootstrap/Spinner';



// DYNAMIC DATA INSIDE CONTAINER
function SectionPhotosLarge({mapActive, albums, albumMapSelected}){
    const img_wave = require('../assets/logo_green.png')


    return (
            <Container fluid className='mb-5 p-0' >
                <Row className='gx-0'>

                    {albumMapSelected != null ? (
                        <>
                            <AlbumPurchaseCard key={albumMapSelected.album_id} card_max_size={mapActive == false ? 3 : 6} data={albumMapSelected} linkTo={'/album/' + albumMapSelected.album_id} />

                        </>

                    ) : (
                        <>
                        {albums != null ? (
                            <>
                            {albums.length === 0 ? (
                                <div>
                                    <img className=" mt-5 profile_wave d-block ms-auto me-auto" src={img_wave} alt="logo" /> 
                                    <p className="text_light_blue text-center mt-3" >There are no sessions based on your search</p>

                                </div>
                            ) : (
                                <>
                                {albums.map((dataObj, index) => {
                                    return (
                                        <AlbumPurchaseCard key={dataObj.album_id} card_max_size={mapActive == false ? 3 : 6} data={dataObj} linkTo={'/album/' + dataObj.album_id} />
    
                                    );
                                })}
                                </>
                            )} 
                            </>
                            
                            
                        ) : (
                            <div className="text-center">
                                <Spinner animation="border" role="status" className="mt-5 ">
                                    <span className="visually-hidden">Loading...</span>
                                </Spinner>
                            </div>
                        )}

                        </>
                    )}



                </Row>
                {/* <BottomScrollListener onBottom={handleScroll} /> */}


            </Container>
        
        
    );

    
}


// DYNAMIC DATA INSIDE CONTAINER
function SectionPhotosShort() {
    return (
        <Container fluid className='mb-5'>
            {/* <Row>
                <h2>Coolangata - Snapper Rocks</h2>
                <Col xl={2} lg={2} md={3} sm={3} xs={12}><b>Photographer</b></Col>
                <Col xl={3} lg={3} md={3} sm={4} xs={12}><p>8 Apr 2023 06:30am</p></Col>
            </Row> */}
            <Row>
                <AlbumPurchaseCard card_max_size={3}/>
                <AlbumPurchaseCard card_max_size={3}/>
                <AlbumPurchaseCard card_max_size={3}/>
                <AlbumPurchaseCard card_max_size={3}/>

                {/* <PhotoPurchaseCard card_max_size={3}/>
                <PhotoPurchaseCard card_max_size={3}/>
                <PhotoPurchaseCard card_max_size={3}/> */}
            </Row>
            {/* <Row>
                <Col align="center">
                    <Button>Find more</Button>
                </Col>
            </Row> */}


        </Container>
    );
}

function SectionPhotosShortCarousel() {

    const [albums, setAlbums] = useState()
    const img_wave = require('../assets/logo_green.png')
    const [screenWidth, setScreenWidth] = useState(window.innerWidth)

    useEffect(() => {
        getAlbums();
        window.addEventListener("resize", () => {
            setScreenWidth(window.innerWidth)
        });
        return () => {
            window.removeEventListener("resize", () => {
                setScreenWidth(window.innerWidth)
            })
        }
    }, []);

    

    function getAlbums(){
        var response;
        
         fetch(url_server + "public/albums?quantity_page=4", {
            method: 'GET',
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            },
            })
            .then((response) => response.json())
            
            .then( (data) => {
                // response = data.response
                setAlbums(data.response)

            })
            .catch((err) => {
                // console.log(err)
                // alert("Something went wrong. Please check your connection and try again.");
            });

            return response;

        }


    return (
        <Container fluid className='mb-5 p-0'>

            {albums == null ?(
                <div className="text-center">
                    <Spinner animation="border" role="status" className="mt-5 ">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                </div>
            ) : (
                <>
               
                {albums.length === 0 ?(
                        <div>
                            <img className=" mt-5 profile_wave d-block ms-auto me-auto" src={img_wave} alt="logo" /> 
                            <p className="text_light_blue text-center mt-3" >There are no present sessions at the moment</p>

                        </div>
                ) : (
                <div className={`card-grid ${screenWidth < 960 ? 'scrollable' : ''}`}>
                    {albums.map((dataObj, index) => {
                        return (
                            <AlbumPurchaseCard  key={dataObj.album_id} card_max_size={3} data={dataObj} linkTo={'/album/' + dataObj.album_id} />


                        );
                    })}
    
                </div>
                )}
                 </>
            )}
            

        </Container>
        
    );
}


export default SectionPhotosShort
export {SectionPhotosLarge, SectionPhotosShortCarousel}