import { Link, useParams, useLocation } from 'react-router-dom';
import Navigation from '../components/Navigation';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/esm/Container';
import Card from 'react-bootstrap/Card';
import { useState, useRef, useEffect } from "react";
import { url_server } from '../settings';
import { ProductModal } from '../components/ProductModal';
import { BottomScrollListener } from 'react-bottom-scroll-listener';
import { SessionDetails } from '../controllers/AccountController';
import Spinner from 'react-bootstrap/Spinner';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import axios from 'axios';
import fileDownload from 'js-file-download'
import PurchasedItemsObject from '../components/PurchasedItemsObject';
import JSZip from 'jszip';
import JSZipUtils from 'jszip-utils';
import DownloadButton from '../components/buttons/DownloadButton';
import { ProgressBar } from 'react-bootstrap';
import saveAs from 'save-as';


function MediaModal({ show, onHide, media, album }) {
	var dayNames = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
	var monthPrefixes = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
	var start_time = new Date(album.album_datetime_start).toLocaleTimeString([], { hour12: false, hour: '2-digit', minute: '2-digit' })
	var end_time = new Date(album.album_datetime_end).toLocaleTimeString([], { hour12: false, hour: '2-digit', minute: '2-digit' })
	var day = new Date(album.album_datetime_start).getDay()
	var month = new Date(album.album_datetime_start).getMonth()
	var day_number = new Date(album.album_datetime_start).toLocaleDateString().split("/")[0]
	var year_number = new Date(album.album_datetime_start).toLocaleDateString().split("/")[2]

	const ic_download_white = require("../assets/ic_download_white.png")

	const handleDownload = (url, filename) => {
		axios.get(url + "?image=image", {
			headers: {
				'Access-Control-Allow-Origin': '*',
				'Access-Control-Allow-Methods': 'GET,PATCH,OPTIONS',
			},
			responseType: 'blob',
		})
			.then((res) => {
				fileDownload(res.data, filename)
			})
	}

	return (
		<Modal
			show={show}
			onHide={onHide}
			size="lg"
			aria-labelledby="contained-modal-title-vcenter"
			centered>
			<Modal.Header closeButton className='border-0'>
			</Modal.Header>

			<Modal.Body className='modal_product ps-5 pe-5'>
				<Row>
					<Col xl={12} className='div_image_product_popup'>

						{Object.keys(media).length > 0 ? (
							<>
								{media.media_format.toLowerCase() == "png" || media.media_format.toLowerCase() == "jpg" || media.media_format.toLowerCase() == "jpeg" ? (
									<img src={media.media_url} alt="image" />

								) : (media.media_format.toLowerCase() == "mp4" || media.media_format.toLowerCase() == "ogg" || media.media_format.toLowerCase() == "mov" ? (
									<div>
										<video className="card_round mt-3" controls>
											<source src={media.media_url} type="video/mp4" />
											<source src={media.media_url} type="video/ogg" />
											<source src={media.media_url} type="video/mov" />

											Your browser does not support the video tag.
										</video>
									</div>

								) : (""))
								}
							</>
						) : ""}

					</Col>

				</Row>

				{show ? (
					<Row>
						<Col lg={8}>
							<div className='mb-1'>
								<p className='mb-0 text-blue'><b>{album.location_name}</b> - <span className='m-0 mt-0 ms-0 text-end'>${media.price / 100}</span></p>

							</div>
							<div className='mb-1'>
								<p className='mb-0 text-grey' >{day_number} {monthPrefixes[month]} {year_number} - {start_time} - {end_time}</p>
							</div>
							<div className='mb-1'>
								<p className=' mb-0 light-grey'>Expiry date: No expiry date</p>
							</div>
							<div className='mb-1'>
								<p className='mb-0 light-grey'>.{media.media_format.toUpperCase()}</p>
							</div>
						</Col>
						<Col lg={4}>
							<div>
								<DownloadButton className="btn_primary_blue btn_register w-100" text="Download" onClick={() => handleDownload(media.media_url, media.media_url.slice(media.media_url.lastIndexOf("/"), media.media_url.length).split('/')[1])} />
							</div>
						</Col>
					</Row>
				) : ""}


			</Modal.Body>
		</Modal>
	);

}

function DisplayProducts({isSingleDownload, setUrlsToDownload}) {
	const [album, setAlbum] = useState();
	const [modalShow, setModalShow] = useState(false);
	const [selectedProduct, setSelectedProduct] = useState({});
	const [selectedAlbum, setSelectedAlbum] = useState({});
	const [page, setPage] = useState(0)
	const [limitItems, setLimitItems] = useState(20)
	const [userDetails, setUserDetails] = useState(SessionDetails())
	const img_wave = require('../assets/logo_green.png')

	function handleScroll() {
		// setMedia(page+1);
		// setPage(page+1)
	}

	useEffect(() => {
		getMedia(0);

	}, []);

	useEffect(() => {
		if (modalShow == false) {
			setSelectedProduct({})
			setSelectedAlbum({})
		}

	}, [modalShow]);

	function showModal(e, product, album) {
		e.preventDefault()
		setSelectedProduct(product)
		setSelectedAlbum(album)
		setModalShow(true)

	}

	async function getMedia(p) {
		var query = "?page=" + p + "&quantity_page=" + limitItems;

		await fetch(url_server + `private/users/${userDetails.user_id}/media`, {
			method: 'GET',
			headers: {
				'Content-type': 'application/json; charset=UTF-8',
				'x-access-token': userDetails.token
			},
		})
			.then((response) => response.json())

			.then((data) => {
				var latest_album_id = null
				var imagesGroupedAlbum = []
				data.response.map((obj, index) => {
					if (obj.album_id != latest_album_id) {
						latest_album_id = obj.album_id

						imagesGroupedAlbum.push({
							location_name: obj.location_name,
							location_state: obj.location_state,
							album_id: obj.album_id,
							album_datetime_start: obj.album_datetime_start,
							album_datetime_end: obj.album_datetime_end,
							photographer_business_name: obj.photographer_business_name,
							user_unique_id: obj.user_unique_id,
							media: [{
								media_id: obj.media_id,
								asset_purchase_date: obj.asset_purchase_date,
								media_format: obj.media_format,
								media_size: obj.media_size,
								media_url: obj.media_url,
								product_type_id: obj.product_type_id,
								price: obj.price
							}]
						})

					} else {
						imagesGroupedAlbum[imagesGroupedAlbum.length - 1].media.push({
							media_id: obj.media_id,
							asset_purchase_date: obj.asset_purchase_date,
							media_format: obj.media_format,
							media_size: obj.media_size,
							media_url: obj.media_url,
							product_type_id: obj.product_type_id,
							price: obj.price
						})

					}

				})

				setAlbum(imagesGroupedAlbum)
			})
			.catch((err) => {
			});
	}

	return (
		<>
			{album == null ? (
				<div className="text-center">
					<Spinner animation="border" role="status" className="mt-5 ">
						<span className="visually-hidden">Loading...</span>
					</Spinner>
				</div>
			) : (
				<div>
					{album.length === 0 ? (
						<div>
							<img className=" mt-5 profile_wave d-block ms-auto me-auto" src={img_wave} alt="logo" />
							<p className="text_light_blue text-center mt-3" >You didn't purchase any photo or video</p>

						</div>
					) : (
						<Container fluid className='mb-5 p-0 '>
							<MediaModal show={modalShow} onHide={() => setModalShow(false)} media={selectedProduct} album={selectedAlbum} />
								<div className='mt-3'>
									{album.map((obj, index) => {
										return (
											<PurchasedItemsObject
												album_id={obj.album_id}
												album_datetime_start={obj.album_datetime_start}
												album_datetime_end={obj.album_datetime_end}
												location_name={obj.location_name}
												photographer_business_name={obj.photographer_business_name}
												location_state={obj.location_state}
												user_unique_id={obj.user_unique_id}
												media={obj.media}
												album={{
													location_name: obj.location_name,
													location_state: obj.location_state,
													album_id: obj.album_id,
													album_datetime_start: obj.album_datetime_start,
													album_datetime_end: obj.album_datetime_end,
													photographer_business_name: obj.photographer_business_name
												}}
												showModal={showModal} 
												isSingleDownload={isSingleDownload}
												setUrlsToDownload={setUrlsToDownload}
												key={index}
												/>
										)
									})}
								</div>
							<BottomScrollListener onBottom={handleScroll} />
						</Container>
					)}

				</div>
			)}
		</>
	)
}

function UserPurchases() {
	const ic_download_white = require("../assets/ic_download_white.png")
	const [isSingleDownload, setIsSingleDownload] = useState(true)
	const [multipleDownloadUrls, setMultipleDownloadUrls] = useState([])
	const [downloadStarted, setDownlaodStarted] = useState(false);
	const [downloadProgress, setDownloadProgress] = useState(0);
    const [urlsCount, setUrlsCount] = useState(0)
	
    useEffect(() => {
        if(multipleDownloadUrls.length > 0){
            setUrlsCount(multipleDownloadUrls.length * 100)
        }

	}, [multipleDownloadUrls]);

	function changeToMasssDownload(e){
		setIsSingleDownload(!isSingleDownload)
	}

	function setUrlsToDownload(e, url){
		var urls = [...multipleDownloadUrls]
		if(e.target.checked){
            urls.push(url+"?image=image")
        }else{
            const index = urls.indexOf("Photos");
            urls.splice(index, 1);
        }
        setMultipleDownloadUrls(urls)
	}

	function multipleDownload(){
		setDownlaodStarted(true);
		var zipFilename = `my-surf-shots-${Date.now()}.zip`
		var zip = new JSZip();
		let count = 0;
		multipleDownloadUrls.forEach(async function (url){
			const filename = url.slice(url.lastIndexOf("/"), url.length).split('/')[1].split("?")[0];
			try {
				await JSZipUtils.getBinaryContent(url, {
					progress: function(e) {
						setDownloadProgress(e.percent * (count + 1));
					},
					callback: function (err, data) {
                        count++;

                        if(!err){
                            zip.file(filename, data, { binary: true});
                            
                            if(count === multipleDownloadUrls.length) {
                                zip.generateAsync({type:'blob'}).then(function(content) {
                                    saveAs(content, zipFilename);
                                    setIsSingleDownload(true)
                                    setDownlaodStarted(false);
                                    setMultipleDownloadUrls([])
                                });
                            }
                        }

					}
				});
				// const file = await JSZipUtils.getBinaryContent(url)
				// zip.file(filename, file, { binary: true});
				// count++;
				
				// if(count === multipleDownloadUrls.length) {
				// 	zip.generateAsync({type:'blob'}).then(function(content) {
				// 	saveAs(content, zipFilename);
				// 	setIsSingleDownload(true)
				// 	setDownlaodStarted(false);
				// 	});
				// }
			} catch (err) {
			  	console.log(err);
			}
		  });
	}

    console.log(multipleDownloadUrls.length * 100)

	return (
		<div>
			<Navigation showSearchBar={1} />
			<div className='div_albums '>
				<Row>
					<Col md={8} className='mt-2 d-flex align-items-center'>
						<h5 className="text-blue "><b>My Purchases</b></h5>
					</Col>
					<Col md={isSingleDownload ? 4 : 2} className="text-end">
						{isSingleDownload ? (
							<DownloadButton className='btn_primary_blue btn_register w-50' text="Multiple downloads" onClick={changeToMasssDownload} />
						) : (
							<DownloadButton className='btn_primary_blue btn_register w-100' text="Download" onClick={multipleDownload} />
						)}
	
					</Col>
					{!isSingleDownload ? (
						<Col md={2} className='text-end'>
							<Button className='link_btn_primary_invisible w-75' onClick={changeToMasssDownload}>Cancel</Button>
						</Col>
					) : ""}
				</Row>
				{!isSingleDownload && downloadStarted ? (
					<Col md={12} className='mt-3'>
						<p className='text-center'>Preparing files. Please wait...</p>
						<ProgressBar className="w-100" min={0} max={multipleDownloadUrls.length * 100} now={downloadProgress} variant={downloadProgress == urlsCount ? "success": ""} />
					</Col>
				):""}

				<DisplayProducts isSingleDownload={isSingleDownload} setUrlsToDownload={setUrlsToDownload} />
			</div>
		</div>
	)
}

export default UserPurchases