import Form from 'react-bootstrap/Form';
import { useState, useRef, useEffect} from "react";


function RangeSurfLevel({change}) {
  var [value, setValue] = useState(3)
  var [surfLevel, setSurfLevel] = useState("Intermediate");

  useEffect(() =>{
    setLevel()
  }, [value])

    function setLevel(){
      switch(value){
        
        case "1":
        setSurfLevel("Beginner")
        
        break;

        case "2":
        setSurfLevel("Beginner / Intermediate")
        break;
        case "3":
        setSurfLevel("Intermediate")
        break;
        case "4":
        setSurfLevel("Intermediate / Advanced")
        break;
        case "5":
        setSurfLevel("Advanced")
        break;
        case "6":
        setSurfLevel("Advanced / Professional")
        break;
        case "7":
        setSurfLevel("Professional")
        break;
    }
  }


    return (
      <>
        <Form.Label><b>Surf Level *</b></Form.Label>
        <Form.Range min="1" max="7" defaultValue="3" step="1" className='blue_range' onChange={change} onInput={(e) => setValue(e.target.value)} />
        <p className='text-center'>{surfLevel}</p>
      </>
    );
  }

  export default RangeSurfLevel;