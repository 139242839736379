import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';
import Col from 'react-bootstrap/Col';
import { useState, useRef, useEffect} from "react";

import './components.css';
import { url_server } from '../settings';
import {Link} from 'react-router-dom'
import Spinner from 'react-bootstrap/Spinner';
import { SessionDetails } from '../controllers/AccountController';
import { DeleteModal } from './modals';




function AlbumPurchaseCard({card_max_size, data, linkTo, fromProfile}){

    const ic_location = require("../assets/ic_location_card.png");
    const ic_profile = require("../assets/ic_profile_card.png")
    const ic_calendar = require("../assets/ic_calendar_card.png")
    const ic_wave = require("../assets/ic_wave_card.png")
    const ic_image = require("../assets/ic_image.png")
    const ic_video = require("../assets/ic_video.png")
    const img_album_default = require('../assets/img_album_default.jpg')
    const ic_delete = require("../assets/ic_delete.png")
    const [accountDetails, setAccountDetaills] = useState(SessionDetails())


    const [highlightImage, setHighlightImage] = useState()
    const [productsCount, setProductsCount] = useState();
    const [showModalDelete, setShowModalDelete] = useState(false)


    var dayNames = ['Sun','Mon','Tue','Wed','Thu','Fri','Sat'];
	var monthPrefixes = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];

	var start_time = new Date(data.album_datetime_start).toLocaleTimeString([], {hour12: false, hour: '2-digit', minute:'2-digit'})
	var end_time = new Date(data.album_datetime_end).toLocaleTimeString([], {hour12: false, hour: '2-digit', minute:'2-digit'})
	var day = new Date(data.album_datetime_start).getDay()
	var month = new Date(data.album_datetime_start).getMonth()
	var day_number = new Date(data.album_datetime_start).toLocaleDateString("en-au").split("/")[0]
	var year_number = new Date(data.album_datetime_start).toLocaleDateString("en-au").split("/")[2]

    useEffect(() => {
        getHighlightImage();
        getCountProducts();
        
    }, []);

    async function getHighlightImage(){
        var response;
        
        await fetch(url_server + "public/products/"+data.album_id+"/highlight", {
            method: 'GET',
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            },
            })
            .then((response) => response.json())
            
            .then( (data) => {
                setHighlightImage(data.response)

            })
            .catch((err) => {
                // alert("Something went wrong. Please check your connection and try again.");
            });

            return response;

    }

        
    async function getCountProducts(){
        var response;
        
        await fetch(url_server + "public/products/"+data.album_id+"/count", {
            method: 'GET',
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            },
            })
            .then((response) => response.json())
            
            .then( (data) => {
                
                setProductsCount(data.response)
                

            })
            .catch((err) => {
                // alert("Something went wrong. Please check your connection and try again.");
            });

            return response;

    }

    function onDelete(){
		fetch(url_server + "private/albums/" + data.album_id, {
            method: 'PUT',
			body: JSON.stringify({is_visible: 0}),
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
				'x-access-token': accountDetails.token
            },
            })
            .then((response) => response.json())
            
            .then( (data) => {
                
				if(data.error){
					alert("Something went wrong")
				}else{
					window.location.reload()
				}
				

            })
            .catch((err) => {
                // console.log(err)
            });
	}
    

    return (
        <Col xl={card_max_size} lg={card_max_size} md={4} sm={6} xs={12} className='mb-3'>

            
            <Card className='photo_purchase_card m-3' id={"album_id_"+data.album_id} >

                <div  className='position-relative'>
           
                    <Link to={linkTo} state={data}>
                    {/* <Card.Img  className='card_round square' variant="top" src={highlightImage.length > 0 ? highlightImage[0].media_url : "#"} /> */}

                    {highlightImage != null ? (
                        <>
                       
                        {highlightImage.length > 0 ? (
                            <>
                            {highlightImage[0].product_type_id == 1 ?(
                                <Card.Img  className='card_round square' variant="top" src={highlightImage.length > 0 ? highlightImage[0].media_url : "#"} />

                            ) : (
                                <video className="card_round square m-0" >
                                    <source  src={highlightImage.length > 0 ? `${highlightImage[0].media_url_watermarked}#t=0.001` : "#"} type="video/mp4"/>
                                    <source  src={highlightImage.length > 0 ? `${highlightImage[0].media_url_watermarked}#t=0.001` : "#"} type="video/ogg"/>
                                    <source  src={highlightImage.length > 0 ? `${highlightImage[0].media_url_watermarked}#t=0.001` : "#"} type="video/mov"/>

                                    Your browser does not support the video tag.
                                </video> 
                            )}
                            </>
                        ) : (
                            <Card.Img  className='card_round square' variant="top" src={img_album_default} alt="default logo" />

                            
                        )}
                         </>
                    ) : (
                        <div className="text-center">
                            <Spinner animation="border" role="status" className="mt-5 ">
                                <span className="visually-hidden">Loading...</span>
                            </Spinner>
                        </div>
                    )}

                    

                        
                        
                    {/* {highlightImage.map((dataObj, index) => {
                        return (
                            
                           <Card.Img key={index} className='card_round square' variant="top" src={dataObj.media_url} />
                                
                        );
                    })} */}

                    {/* </a> */}
                    </Link>

                    <div className='div_badges_quantity d-flex'>
                       
                        
                        {productsCount != null ? (
                             <>
                            {productsCount.photos > 0 ? (
                                <div className='div_items_quantity ms-1 me-1'>
                                    <img src={ic_image} alt="icon" />
                                    <span>{productsCount.photos}</span>
                                </div>
                            ) : ""}
                            {productsCount.videos > 0 ? (
                                <div className='div_items_quantity ms-1 me-1'>
                                    <img src={ic_video} alt="icon" />
                                    <span>{productsCount.videos}</span>
                                </div>
                            ) : ""}
                            </>

                        ) : ""}

                        
                        
                    </div>

                    {accountDetails.photographer_id == data.photographer_id && fromProfile == true ? (<div className='me-3 clickable' onClick={(e) => setShowModalDelete(true)} ><img src={ic_delete} alt="delete" className="ic_delete_image" /></div>) : ""}

                    
                </div>

                <Card.Body align="left" className='p-0'>
                    <div className='mt-2 d-flex justify-content-between'>
                        <div>
                            <img src={ic_wave} alt="wave" className='pb-1'/>
                            <Card.Title className='mt-2 float-left text_inline card_title'>{data.location_name}</Card.Title>
                        </div>
                        <Card.Text className='text-thin text-grey'>{data.album_categories}</Card.Text>
                        
                    </div>
                    <div className='mt-2'>
                        <img src={ic_location} alt="location" className='pb-1'/>
                        <Card.Subtitle className='mt-2 text_normal text_inline'>{data.location_city} - {data.location_state}</Card.Subtitle>

                    </div>
                    <div className='mt-2'>
                        <img src={ic_calendar} alt="date"className='pb-1' />
                        <Card.Text className=' text_thin text_inline'>{day_number} {monthPrefixes[month]} {year_number} | {start_time} - {end_time}</Card.Text>

                    </div>
                    <div className='mt-2'>
                        <img src={ic_profile} alt="photographer" className='pb-1'/>
                        <Card.Link href={`/${data.user_unique_id}`} className='link_album_photographer text_inline'>{data.photographer_business_name}</Card.Link>

                    </div>
         
                </Card.Body>
            </Card>
            <DeleteModal show={showModalDelete} setShow={setShowModalDelete} title={"Are you sure you want to delete this session?"} content={"If you delete it, you can't recover it later."} onAccept={onDelete} />

        </Col>
        
    )
  }
  export default AlbumPurchaseCard;