
import Compressor from 'compressorjs';
import Resizer from "react-image-file-resizer";


async function ImageResizer(file){
    return new Promise((resolve) => {
        Resizer.imageFileResizer(
          file,
          300,
          1000,
          "JPEG",
          40,
          0,
          (uri) => {
            resolve(uri);
          },
          "blob"
        );
      });
}


async function ImageCompressor(file){

    return new Promise((resolve) =>{
        new Compressor(file, {
            quality: 0.4, // 0.6 can also be used, but its not recommended to go below.
            success: async (compressedResult) => {

                resolve(compressedResult)
            
            },
            error(err) {
                // console.log(err.message);
                resolve(null)
            },

            
        });
    })


}

export {ImageCompressor, ImageResizer}