
import Container from 'react-bootstrap/esm/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Navigation from '../components/Navigation';
import { useState, useRef, useEffect, useMemo} from "react";
import GroupSearchFilters, {GroupSearchAllFilters} from '../components/SectionFilters';
import SectionPhotosShort, {SectionPhotosLarge} from '../components/SectionPhotos';
import './pages.css';
import { SearchAlbumsInput } from '../components/FormComponents';
import { GoogleMap, Marker, useLoadScript } from "@react-google-maps/api";
import { google_maps_api_key, url_server } from '../settings';
import { Link, useParams, useLocation } from 'react-router-dom';
import {BottomScrollListener} from 'react-bottom-scroll-listener';
import { GetDataFromUserId, saveSession } from '../controllers/AccountController';


function VerifyEmail(){

    const { email } = useParams();
    const token = new URLSearchParams(useLocation().search).get("token");
    const origin = new URLSearchParams(useLocation().search).get("origin");


    const [emailVerified, setEmailVerified] = useState()
    const img_successfull = require('../assets/img_successfull.png')

    const [urlSession, setUrlSession] = useState("")
    const [sessionText, setSesionText] = useState("")

    useEffect(() =>{
        verifyEmail()
        autologin();
    },[])

    async function autologin(){
        await fetch(url_server + 'private/users/autologin', {
            method: 'POST',
            body: JSON.stringify(
              // Add parameters here
              {user_email: email}
            ),
            headers: {
              'Content-type': 'application/json; charset=UTF-8',
              'x-access-token': token

            },
          })
            .then((response) => response.json())
            .then( (data) => {
            // GO TO SUCCESS PAGE
            // console.log(data)
                if(data.error){

                    alert(data.errorDescription)

                }else{
                    if(!(data.response.user_id && data.response.user_first_name && data.response.user_last_name && data.response.user_default_profile)){
                        alert("Something went wrong. Please check your connection and try again.")
                    }else{
                        data.response.token = token
                        saveSession(data.response)

                        if(origin == "cart"){
                            setSesionText("Return to cart")
                            setUrlSession("/cart");
                            return;
                        }

                        if(data.response.user_default_profile === 2){
                            // window.location.href='/'+data.response.user_unique_id
                            setUrlSession('/'+data.response.user_unique_id)
                            setSesionText("Start Posting")
                        }else{
                            // window.location.href='/account/purchases'
                            setUrlSession('/account/purchases')
                            setSesionText("Start Searching")
                        }
                            
                    }

                }

            })
            .catch((err) => {
                alert("Something went wrong. Please check your connection and try again.");
            });

    }

     
    function verifyEmail(){
        fetch(`${url_server}public/users/verify/${email}`, {
            method: 'GET',
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            },
            })
            .then((response) => response.json())
            
            .then( (data) => {
                // response = data.response

                console.log(data.response)

                if(data.response.is_verified == true){
                    setEmailVerified(true)
                    return;
                }

                if(data.response.affectedRows === 1){
                    setEmailVerified(true)
                }else{
                    setEmailVerified(false)

                }
                
    
            })
            .catch((err) => {
                // console.log(err)
                // alert("Something went wrong. Please check your connection and try again.");
            });
    }


    return(
        <div>
        {emailVerified != null ? (
            <>
            {!emailVerified ? (
                <Container fluid className="text-center mt-5">
                    <div>
                    

                        <h3 className='text-blue'>An error has been produced verifying your account.</h3>
                    
                        <div >
                            {/* <a href="/" className="text-blue">Go to home page</a> */}
                            <Button className='btn_primary_blue btn_register  ms-auto' onClick={(e) => window.location.href ="/"} >Go to main</Button>

                        </div>
                    </div>

                </Container>

            ) : (

                <Container fluid className="text-center mt-5">
                    <div>
                    
                        <img src={img_successfull} alt="Success" className="mb-3" />

                        <h3 className='text-blue'>Account successfully verified!</h3>
                     
                        <div className="mt-5">
                            {/* <a href="/" className="text-blue">Go to home page</a> */}
                            <Button className='btn_primary_blue btn_register  ms-auto' onClick={(e) => window.location.href = urlSession} >{sessionText}</Button>

                        </div>
                    </div>

                </Container>
            )}
            </>
        ) : ("")}

        

        </div>
    )
}

export default VerifyEmail